<script>
import { onMount, getContext } from "svelte";
import { formKey } from "./form";

export let value;
export let id;
export let label = undefined;
export let required = false;
export let type;
export let placeholder = undefined;
export let errorMessage = undefined;
export let minLength = undefined;
export let maxLength = undefined;
export let max = undefined;
export let min = undefined;
export let rows = undefined;
export let resizeable = false;
export let validity = undefined;
export let maxIntegers = '';
export let validator = undefined;
export let inputIcon = undefined;
export let forceError = false;

const formValidity = getContext(formKey);

const TIME_INPUT_REGEX = new RegExp(`^(\\d{0,2})(?:[:\\.](\\d{0,2}))?$`);
const TIME_VALIDATION_REGEX = new RegExp(`^\\d{1,2}[:\\.]\\d{2}$`);
const PRICE_REGEX = new RegExp(`^\\d{0,${maxIntegers}}((,|\\.)\\d{0,2})?$`);
const NUMBER_INPUT_REGEX = new RegExp(`(?:^$)|(?:^\\d+$)`);

let isValid = false;
let previousInput;
let error = false;
let field;

$: inputMode = type === "number" ? "number" : type === "price" ? "decimal" : "text";

const onBlur = () => {
  error = !isValid;
};

const validate = () => {
  if (validator) {
    return validator(previousInput);
  }
  if (required && previousInput === '') {
    return false;
  }
  if (minLength !== undefined && previousInput.length < minLength) {
    return false;
  }
  if (maxLength !== undefined && previousInput.length > maxLength) {
    return false;
  }
  if (type === "time") {
    return !!previousInput.match(TIME_VALIDATION_REGEX);
  } else if (type === "price") {
    return previousInput.replace(',', '.') !== '.';
  } else {
    return true;
  }
};

const handleTimeInput = (input) => {
  let validInput = true;
  let valueResult = '';
  const result = input.match(TIME_INPUT_REGEX);
  if (result) {
    const [, hour, minute] = result;
    if (hour !== undefined && hour !== '') {
      validInput = validInput && +hour < 24;
      if (validInput) {
        valueResult = hour.padStart(2, '0');
      }
    }
    if (minute !== undefined && minute !== '') {
      validInput = validInput && +minute < 60;
      if (validInput) {
        valueResult += `:${minute.padStart(2, '0')}`;
      }
    }
    if (valueResult.length === 2) {
      valueResult += ':00';
    }
  } else {
    validInput = false;
  }
  if (!validInput) {
    // Revert input
    field.value = previousInput;
  } else {
    previousInput = input;
    value = valueResult;
  }
};

const handlePriceInput = (input) => {
  if (input.match(PRICE_REGEX)) {
    previousInput = input;
    const parseableInput = input.replace(',', '.');
    if (parseableInput === '.' || parseableInput === '') {
      value = null;
    } else {
      value = +parseableInput;
    }
  } else {
    field.value = previousInput;
  }
};

const handleNumberInput = (input, valid) => {
  if (!valid) {
    field.value = previousInput;
    return;
  }
  if (input.match(NUMBER_INPUT_REGEX)) {
    let numberResult = (input === undefined || input === '') ? null : +input;
    if (numberResult !== null) {
      if (max !== undefined && max < numberResult) {
        numberResult = max;
      }
      if (min !== undefined && min > numberResult) {
        numberResult = min;
      }
      if (maxLength !== undefined && input.length > maxLength) {
        numberResult = +previousInput;
      }
    }
    value = numberResult;
    if (numberResult !== null) {
      previousInput = numberResult.toString();
      field.value = previousInput;
    } else {
      field.value = '';
      previousInput = input;
    }
  } else {
    // Revert input
    field.value = previousInput;
  }
};

const onInput = (event) => {
  error = false;
  const input = event.target.value;
  switch (type) {
    case "time": {
      handleTimeInput(input);
      break;
    }
    case "number": {
      handleNumberInput(input, event.target.validity.valid);
      break;
    }
    case "price": {
      handlePriceInput(input);
      break;
    }
    default:
      previousInput = input;
      value = input;
  }
  isValid = validate();
  if (formValidity) $formValidity[id] = isValid;
  if (validity) validity[id] = isValid;
};

onMount(() => {
  field.value = value == undefined ? '' : value.toString();
  previousInput = value == undefined ? '' : value.toString();
  isValid = validate();
  if (formValidity) $formValidity[id] = isValid;
  // if (formValidity) formValidity.update((values) => ({ ...values, [id]: isValid }));
  if (validity) validity[id] = isValid;
  if (!isValid && previousInput.length) {
    error = true;
  }
});
</script>

<style>
  label {
    font-size: 1em;
    margin-bottom: 0.25em;
    display: block;
  }
  input, textarea {
    width: 100%;
    padding: 0.4em;
    height: unset;
    font-size: 1em;
    margin: 0;
  }
  input.input-error {
    border-color: #EA5798;
  }
  p.input-error {
    color: #EA5798;
  }
  .icon-container {
    margin-right: 0.4em;
    position: absolute;
  }
</style>

<div class="input-field-main-div">
  {#if label}
    <label class="input-label" for={id}>{label}:{required ? ' *' : ''}</label>
  {/if}
  {#if type === "textarea"}
    <textarea
      {id}
      {rows}
      placeholder={placeholder}
      bind:this={field}
      on:input={onInput}
      on:blur={onBlur}
      style={resizeable ? '' : 'resize: none'}
    />
  {:else}
    <div class="flex items-center justify-end">
      <div class="icon-container"><svelte:component this={inputIcon} /></div>
      <input
        type="text"
        inputmode={inputMode}
        {id}
        class:input-error={error}
        placeholder={placeholder}
        bind:this={field}
        on:input={onInput}
        on:blur={onBlur}
      />
    </div>
  {/if}
  {#if (error || forceError) && errorMessage}
    <p class="input-error">{typeof errorMessage === 'function' ? errorMessage(error) : errorMessage}</p>
  {/if}
</div>

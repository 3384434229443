<script>
  import { _ } from 'svelte-i18n';
  import { getContext, afterUpdate } from 'svelte';
  import Loading from '../Loading.svelte';
  import Modal from '../Modal.svelte';
  import {
    companiesLoaded,
    companies as companyList,
    getCompanies,
  } from '../../stores/companies';
  import { showError } from '../../stores/notifications';
  import {
    createUser,
    deleteUser,
    updateUser,
  } from '../../stores/users';
  import { features as featureList } from '../../stores/features';
  import CheckboxWithExternalSlot from '../CheckboxWithExternalSlot.svelte';
  import UserFeatures from './UserFeatures.svelte';
  import { getCompaniesByFeature } from '../../stores/features';
  import { currentUser } from '../../stores/user';

  $: currentUserFeatures = ($currentUser && $currentUser.features) || [];
  $: companiesForAdmin = getCompaniesByFeature(currentUserFeatures, 'ADMIN') || [];
  $: companyListFilteredByFeature = $companyList && $companyList.filter(company => companiesForAdmin.includes(company.number));



  export let selectedUser;

  const { open } = getContext('simple-modal');

  let lastId;

  let firstname = '';
  let lastname = '';
  let email = '';
  let companies = [];
  let deleteWarning = false;
  $: features = [];

  afterUpdate(() => {
    if (!selectedUser || lastId === selectedUser.id) return;
    lastId = selectedUser.id;
    firstname = selectedUser.firstname;
    lastname = selectedUser.lastname;
    email = selectedUser.email;

    deleteWarning = false;
    companies = selectedUser.companies.map(company =>
      typeof company === 'string' ? company : company.number,
    );
    
    features = selectedUser.features.map(feature =>
      typeof feature === 'string' ? feature : feature.feature_code,
    );
    
  });

  if (!$companiesLoaded) {
    getCompanies().catch(showError($_('error.title')));
  }

  let loading = false;

  const errorNotification = showError($_('error.title'), 5000);

  const updateCompanyFeatures = (company, companyFeatures) => {
    features = features.filter(it => !it.startsWith(`${company}$`)).concat(companyFeatures);
  };

  const openUserFeaturesModal = async (company) => {
    if (!companies.includes(company.number)) {
      return;
    }

    open(UserFeatures, {
      featureList: $featureList,
      features,
      company,
      onConfirm: (companyUpdated, updatedFeatures) => updateCompanyFeatures(companyUpdated, updatedFeatures)
    });
  };

  const handleCreateUser = async () => {
    loading = true;
    try {
      await createUser({
        firstname,
        lastname,
        email,
        companies,
        features: filterFeaturesByCompanies(features, companies),
      });
      selectedUser = undefined;
      // selectedUser = user;
    } catch (e) {
      errorNotification(e);
    } finally {
      loading = false;
    }
  };

  const handleDeleteUser = async () => {
    loading = true;
    try {
      await deleteUser(selectedUser.id);
      selectedUser = null;
    } catch (e) {
      errorNotification(e);
    } finally {
      loading = false;
    }
  };

  const filterFeaturesByCompanies = (features, companies) => {
    return features.filter(it => companies.includes(it.split('$')[0]));
  };

  const handleUpdateUser = async () => {
    loading = true;
    try {
      await updateUser({
        firstname,
        lastname,
        email,
        id: selectedUser.id,
        companies,
        features: filterFeaturesByCompanies(features, companies),
      });
      selectedUser = null;
    } catch (e) {
      errorNotification(e);
    } finally {
      loading = false;
    }
  };
</script>

<style>
  .content {
    padding: 2em 4em 1em 4em;
  }

  button {
    min-width: 10em;
  }

  .buttons,
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }

  .buttons > div:first-child,
  .fields {
    display: flex;
  }

  .buttons > div:first-child > button:not(:first-child) {
    margin-left: 1.5em;
  }

  .fields > *:not(:first-child) {
    padding-left: 2em;
  }

  input[type=text] {
    min-width: 23em;
    border-radius: 5px;
    height: 2rem;
    
  }

  .companies {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .tooltip {
    color: white;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    border: 2px solid #333333;
  }

  h4 {
    font-weight: 100;
  }

  h1 {
    margin: 0 0 2em 0;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  label {
    font-size: 0.8rem;
  }

  .delete-button {
    margin-right: calc(24px + 0.5em);
  }

  .password-policy {
    list-style: none;
    padding: 0;
    font-size: 0.8em;
  }

  .modal-buttons > button:first-child {
    min-width: 220px;
  }

  .modal-buttons > button:last-child {
    min-width: 175px;
    margin-right: 1rem;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
</style>

<Modal bind:opened={deleteWarning}>
  <div class="modal px-4 py-5 w-480px">
    <h3 class="mb-8">{$_('users.editor.deleteSure')}</h3>
    <span class="mb-10">
      {$_('users.editor.deleteSub')
        .replace(/\{1\}/, `${selectedUser.firstname} ${selectedUser.lastname}`)
        .replace(/\{2\}/, selectedUser.email)}
    </span>
    <div class="modal-buttons" style="margin-top: 2em;">
      <button class="small" on:click={handleDeleteUser}
        >{$_('users.editor.delete')}</button
      >
      <button class="small secondary" on:click={() => (deleteWarning = false)}>
        {$_('users.editor.cancel')}
      </button>
    </div>
  </div>
</Modal>

<div class="content">
  <h1 class="mb-6 text-3xl font-bold"> 
    {#if selectedUser.id}
      {$_('users.editor.title')}
    {:else}
      {$_('users.create')}
    {/if}
  </h1>

  <div>
    <div class="fields mb-6">
      <div class="flex flex-col items-start">
        <label for="firstname">{$_('users.editor.firstname')}: *</label>
        <input id="firstname" type="text" bind:value={firstname} />
      </div>
      <div class="flex flex-col items-start">
        <label for="lastname">{$_('users.editor.lastname')}: *</label>
        <input id="lastname" type="text" bind:value={lastname} />
      </div>
    </div>
    <div class="fields mb-6">
      <div class="flex flex-col items-start">
        <label for="email">{$_('users.editor.email')}: *</label>
        <input id="email" type="text" bind:value={email} />
      </div>
    </div>

    <div>
      <h4 class="text-mnormal">{$_('users.editor.seeCompanies')}:</h4>

      <div class="companies mb-6">
        {#if $companiesLoaded}
          <div class="mb-6 flex flex-col items-start text-mnormal" style="display:flex;justify-content:center;">
            {#each companyListFilteredByFeature as company}
             <!--
              <div class="row">
                <div class="col1">
                  <input type="checkbox" checked={companies.includes(company.number)} on:change={({ target: { checked } }) =>
                      (companies = checked
                        ? [...companies, company.number]
                        : companies.filter(number => number !== company.number))} />

                  <span class="label">{`${company.name} (${company.number})`}</span>
                </div>
                <div class="col2">
                </div>
              </div>
            -->
            
              <CheckboxWithExternalSlot
                 on:change={({ target: { checked } }) =>
                  (companies = checked
                    ? [...companies, company.number]
                    : companies.filter(number => number !== company.number))}

                  label={`${company.name} (${company.number})`}
                  checked={companies.includes(company.number)}
                  disabled={!companies.includes(company.number)}
                >
                <span class="features {companies.includes(company.number) ? 'text-mnormal' : 'text-mgray'} underline" on:keydown={() => openUserFeaturesModal(company)} on:click|self={() => openUserFeaturesModal(company)}>Toimintoasetukset</span>

              </CheckboxWithExternalSlot>
            {/each}
            
          </div>
        {:else}
          <Loading />
        {/if}
      </div>
    </div>
    <div class="buttons">
      <div>
        {#if selectedUser.id}
          <button
            on:click={handleUpdateUser}
            disabled={loading ||
              !firstname ||
              !lastname ||
              !email ||
              !companies.length ||
              !features.length}
          >
            {$_('users.editor.done')}
          </button>
        {:else}
          <button
            on:click={handleCreateUser}
            disabled={loading ||
              !firstname ||
              !lastname ||
              !email ||
              !companies.length ||
              !features.length}
          >
            {$_('users.editor.create')}
          </button>
        {/if}
        <button on:click={() => (selectedUser = null)} class="secondary">
          {$_('users.editor.cancel')}
        </button>
      </div>
      {#if selectedUser.id}
        <button
          on:click={() => (deleteWarning = true)}
          class="delete-button"
          disabled={loading}
        >
          {$_('users.editor.delete')}
        </button>
      {/if}
    </div>
  </div>
</div>

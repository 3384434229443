<script>
  import { _ } from 'svelte-i18n';
  import Loading from '../Loading.svelte';
  import Expandable from '../Expandable.svelte';
  import Icon from 'fa-svelte';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import { fetchWorkshiftInfo, workshiftInfo } from '../../stores/map_tracking';
  import { showError } from '../../stores/notifications';

  export let selectedDriver;
  export let driver;
  export let currentStop;
  export let stops;
  export let showStops;
  export let border;
  export let workshift;
  export let companyId;
  export let lineId;
  export let departureId;

  const getStopName = stop => {
    const stopName = stop.stopName.replace(/\(.*\)/g, '');
    return `${stopName} (${stop.municipalityName})`;
  };

  $: currentStopIndex = getCurrentStopIndex(stops, currentStop);
  $: currentStopHash = currentStopIndex === -1 ? '' : stops[currentStopIndex].id;

  $: if (showStops && !$workshiftInfo[workshift] && currentStopHash && workshift) {
    fetchWorkshiftInfo(workshift, currentStopHash, companyId, lineId, departureId).catch(
      showError($_('error.title')),
    );
  }

  const getCurrentStopIndex = (stops, currentStop) =>
    stops ? stops.findIndex(({ digiStopId }) => digiStopId === currentStop) : -1;

  $: capacity = !$workshiftInfo[workshift]
    ? '-'
    : !$workshiftInfo[workshift].totalCapacity
    ? `${$workshiftInfo[workshift].currentOnBoardStatus.totalCount}`
    : `${$workshiftInfo[workshift].currentOnBoardStatus.totalCount} / ${$workshiftInfo[workshift].totalCapacity}`;
</script>

<style>
  .show-stops {
    font-weight: bold;
    cursor: pointer;
    color: #001E61;
  }

  .stops {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    padding-bottom: 0.5em;
  }

  .stops.border {
    border-bottom: 1px solid #dddddd;
  }

  .icon {
    transition: transform 250ms ease-in-out;
    margin-right: 0.5em;
    transform: rotate(-90deg);
    display: flex;
  }

  div.icon > :global(*) {
    font-size: 1em;
    color: #001E61 !important;
  }

  .icon.expanded {
    transform: rotate(0deg);
  }

  .title {
    display: flex;
    padding-top: 0.5em;
    display: flex;
    align-items: center;
  }

  .stop-info {
    display: flex;
    max-height: 5em;
    overflow: hidden;
  }

  .listed .stop {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0% 25%,
      #06324c 25% 75%,
      rgba(255, 255, 255, 0) 75% 100%
    );
  }

  .nextStop .stop {
    background-image: linear-gradient(
        to bottom,
        #06324c 0% 50%,
        rgba(255, 255, 255, 0) 0%
      ),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0% 25%,
        #06324c 25% 75%,
        rgba(255, 255, 255, 0) 75% 100%
      );
    background-size: 2px 100%, 2px 8px;
  }

  .stop {
    display: flex;
    min-height: 2em;
    min-width: 12px;
    justify-content: center;
    background-image: linear-gradient(to bottom, #06324c 0% 100%);
    background-position: center;
    background-size: 2px 8px;
    background-repeat: repeat-y;
  }

  .terminal .stop {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        rgb(255, 255, 255) 0%
      ),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0% 25%,
        #06324c 25% 75%,
        rgba(255, 255, 255, 0) 75% 100%
      );
    background-size: 2px 100%, 2px 8px;
  }

  .terminal:not(.listed) .stop,
  .terminal.nextStop .stop {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        rgb(255, 255, 255) 0%
      ),
      linear-gradient(to bottom, #06324c 0% 100%);
  }

  *:first-child > .stop {
    background-image: linear-gradient(
        to bottom,
        rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0) 0%
      ),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0% 25%,
        #06324c 25% 75%,
        rgba(255, 255, 255, 0) 75% 100%
      );
    background-size: 2px 100%, 2px 8px;
  }

  *:first-child:not(.listed) > .stop {
    background-image: linear-gradient(
        to bottom,
        rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0) 0%
      ),
      linear-gradient(to bottom, #06324c 0% 100%);
  }

  .stop::before {
    content: '';
    box-sizing: border-box;
    display: inline-block;
    width: 10px;
    height: 10px;
    align-self: center;
    border-radius: 7.5px;
    background-color: #06324c;
  }

  .listed:not(.terminal) .stop::before {
    width: 12px;
    height: 12px;
    border: 2px solid #06324c;
    background-color: white;
  }

  .stop-hour {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    width: 4em;
    min-width: 4em;
    max-width: 4em;
  }

  .info {
    display: flex;
    padding: 0.5em 0 1em 0;
  }

  .chip {
    background-color: #ecf5fc;
    border-radius: 4px;
    padding: 0.4em 0.6em;
    flex-grow: 1;
  }

  .chip:not(:first-child) {
    margin-left: 1em;
  }

  .stop-info > *:last-child {
    align-items: center;
  }

  .stop-name {
    align-self: center;
  }

  div:first-child > .stop-name,
  div:last-child > .stop-name {
    font-weight: 600;
  }

  .info {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
  }

  .chip {
    background-color: #ecf5fc;
    border-radius: 4px;
    padding: 0.2em 0.5em;
  }
</style>

<div
  class="title"
  class:show-stops={showStops}
  on:click={showStops
    ? () =>
        selectedDriver === driver ? (selectedDriver = '') : (selectedDriver = driver)
    : null}
>
  {#if showStops}
    <div class="icon" class:expanded={selectedDriver === driver}>
      <Icon icon={faChevronDown} />
    </div>
  {/if}
  <span class:show-stops={showStops}>{$_('results.driver')} {driver}</span>
</div>
{#if showStops}
  <div class="info">
    <span class="chip">{$_('map.travelers')}: {capacity}</span>
    <span class="chip">
      {$_('map.shipments')}:
      {($workshiftInfo[workshift] && $workshiftInfo[workshift].shipments) || '-'}
    </span>
  </div>
{/if}
<Expandable
  expanded={showStops && selectedDriver === driver}
  containerStyle="overflow: auto;"
>
  <div class="stops" class:border>
    {#if showStops && stops}
      {#each stops as stop, i}
        <div
          class="stop-info"
          class:listed={currentStopIndex < i}
          class:nextStop={currentStopIndex !== -1 && i == currentStopIndex + 1}
          class:terminal={i === stops.length - 1}
        >
          <span class="stop-hour pr-3">{stop.departureTime}</span>
          <span class="stop mr-3" />
          <span class="stop-name">{getStopName(stop)}</span>
        </div>
      {/each}
    {:else if showStops}
      <Loading />
    {/if}
  </div>
</Expandable>

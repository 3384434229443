import {
  init,
  addMessages,
  getLocaleFromQueryString,
  getLocaleFromHash,
} from 'svelte-i18n';
import en from './en';
import fi from './fi';

addMessages('en', en);
addMessages('fi', fi);

init({
  fallbackLocale: 'fi',
  initialLocale: getLocaleFromQueryString('lang') || getLocaleFromHash('lang') || 'fi',
  //getLocaleFromNavigator(),
  formats: {
    number: {
      EUR: { style: 'currency', currency: 'EUR' },
    },
    date: {
      twoDigits: { month: '2-digit', day: '2-digit', year: '2-digit' },
      twoDigitsFullYear: { month: '2-digit', day: '2-digit', year: 'numeric' },
    },
  },
});

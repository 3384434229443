import { _ } from 'svelte-i18n';
import { get, writable } from 'svelte/store';
import { v1 as uuid } from 'uuid';

export const notifications = writable([]);

let notificationMap = {};

export function showNotification(notification, duration = 2000) {
  const uid = uuid();
  notifications.update(value => [...value, { ...notification, uid }]);
  if (duration)
    notificationMap[uid] = setTimeout(() => {
      notifications.update(value =>
        value.filter(({ uid: notifUid }) => notifUid !== uid),
      );
      delete notificationMap[uid];
    }, duration);
  return uid;
}

export function discardNotification(uid) {
  clearTimeout(notificationMap[uid]);
  notifications.update(value => value.filter(({ uid: notifUid }) => notifUid !== uid));
}

export const showError = (title, duration = 3000) => e => {
  const isAuthError = e.config && e.config.url === '/auth/refresh';
  console.error(e);
  showNotification(
    {
      title,
      body: isAuthError ? get(_)('login.sessionExpired') : e.message,
      type: isAuthError ? 'warning' : 'error',
    },
    duration,
  );
};

export const showErrorMessage = (message, { title = get(_)('error.title'), duration = 3000 } = {}) => {
  showNotification({
    title: title,
    body: message,
    type: 'error'
  },
  duration);
};

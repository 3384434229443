<script>
  import { _ } from 'svelte-i18n';
  import Datepicker from './calendar/Datepicker.svelte';

  export let selected = undefined;
  export let start = undefined;
  export let end = undefined;
  export let dateChosen = false;
  export let allowEmpty = false;
  export let dateSelected = undefined;
  export let registerResetHook = undefined;

  if (!end) {
    end = new Date();
    end.setFullYear(2050);
  }

  $: daysOfWeek = [
    [$_('calendar.days.long.sun'), $_('calendar.days.short.sun')],
    [$_('calendar.days.long.mon'), $_('calendar.days.short.mon')],
    [$_('calendar.days.long.tue'), $_('calendar.days.short.tue')],
    [$_('calendar.days.long.wed'), $_('calendar.days.short.wed')],
    [$_('calendar.days.long.thu'), $_('calendar.days.short.thu')],
    [$_('calendar.days.long.fri'), $_('calendar.days.short.fri')],
    [$_('calendar.days.long.sat'), $_('calendar.days.short.sat')],
  ];

  $: monthsOfYear = [
    [$_('calendar.months.long.jan'), $_('calendar.months.short.jan')],
    [$_('calendar.months.long.feb'), $_('calendar.months.short.feb')],
    [$_('calendar.months.long.mar'), $_('calendar.months.short.mar')],
    [$_('calendar.months.long.apr'), $_('calendar.months.short.apr')],
    [$_('calendar.months.long.may'), $_('calendar.months.short.may')],
    [$_('calendar.months.long.jun'), $_('calendar.months.short.jun')],
    [$_('calendar.months.long.jul'), $_('calendar.months.short.jul')],
    [$_('calendar.months.long.aug'), $_('calendar.months.short.aug')],
    [$_('calendar.months.long.sep'), $_('calendar.months.short.sep')],
    [$_('calendar.months.long.oct'), $_('calendar.months.short.oct')],
    [$_('calendar.months.long.nov'), $_('calendar.months.short.nov')],
    [$_('calendar.months.long.dec'), $_('calendar.months.short.dec')],
  ];
</script>

<Datepicker
  on:dateSelected={(e) => dateSelected && e && e.detail && e.detail.date && dateSelected(e.detail.date)}
  bind:selected
  {start}
  {end}
  {daysOfWeek}
  {monthsOfYear}
  {allowEmpty}
  bind:dateChosen
  weekStart={1}
  highlightColor="#001E61"
  dayBackgroundColor="#efefef"
  dayTextColor="#fff"
  dayOutsideMonthTextColor="#fff"
  dayHighlightedBackgroundColor="#001E61"
  dayHighlightedTextColor="#fff"
  style="margin: 0;"
  {registerResetHook}
>
  <slot {dateChosen} />
</Datepicker>

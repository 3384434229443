<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <path
    fill="#06324C"
    d="M9.61063219,4.6747581 C14.822231,2.69758872 20.7529897,5.0746448
    22.966591,10.0972213 C24.5386612,13.6641884 23.8180296,17.6145202
    21.4515715,20.4185697 L28.0304248,26.7634263 C28.4279565,27.1468151
    28.4394217,27.7798762 28.056033,28.177408 C27.7021357,28.5443604
    27.1354997,28.5823567 26.7377495,28.2844853 L26.6420513,28.2030162
    L20.0161261,21.8134978 C19.2605179,22.4155712 18.4037888,22.9236276
    17.4569376,23.3116583 C12.1830942,25.4729407 6.08774363,23.1011949
    3.8366237,17.99349 C1.57709108,12.8666971 4.05387716,6.94794015
    9.34627703,4.77905302 L9.34627703,4.77905302 Z M21.1364539,10.903818
    C19.3270369,6.79832023 14.3852742,4.87544391 10.1046852,6.62967915
    L10.1046852,6.62967915 L9.87026604,6.72975454 C5.77072987,8.55106042
    3.89910842,13.1761578 5.66676077,17.1868933 C7.47617774,21.292391
    12.4179405,23.2152674 16.6985295,21.4610321 C17.707838,21.0474057
    18.5889942,20.4667963 19.3221731,19.7672176 C19.3227763,19.7635485
    19.3249367,19.7612877 19.3271097,19.7590345 L19.378633,19.712826
    C21.6980924,17.4568021 22.4999501,13.9975386 21.1364539,10.903818 Z"
  />
</svg>

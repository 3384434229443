import { _ } from 'svelte-i18n';
import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import { showNotification, discardNotification } from './notifications';
import { currentUser } from './user';

export const usersLoaded = writable(false);
export const usersLoading = writable(false);
export const users = writable([]);
export const passwordPolicyLoaded = writable(false);
export const passwordPolicyLoading = writable(false);
export const passwordPolicy = writable([]);

currentUser.subscribe(user => {
  if (!user || !user.loggedIn) {
    users.set([]);
    usersLoaded.set(false);
    usersLoading.set(false);
    passwordPolicy.set([]);
    passwordPolicyLoaded.set(false);
    passwordPolicyLoading.set(false);
  }
});

export async function getUsers() {
  usersLoaded.set(false);
  usersLoading.set(true);
  users.set([]);
  const res = await getClient().get('/api/users');
  if (res.status === 200) {
    usersLoaded.set(true);
    usersLoading.set(false);
    users.set(
      res.data.map(user => ({
        ...user,
        companies: (user.companies || '').split(',').map(it => it.trim()),
        features: (user.features || '').split(',').map(it => it.trim()),
      })),
    );
  } else {
    usersLoaded.set(false);
    usersLoading.set(false);
    users.set([]);
  }
}

export async function getPasswordPolicy() {
  passwordPolicyLoaded.set(false);
  passwordPolicyLoading.set(true);
  passwordPolicy.set([]);
  const res = await getClient().get('/api/users/password/policy');
  if (res.status === 200) {
    passwordPolicyLoaded.set(true);
    passwordPolicyLoading.set(false);
    passwordPolicy.set(res.data);
  } else {
    passwordPolicyLoaded.set(false);
    passwordPolicyLoading.set(false);
    passwordPolicy.set([]);
  }
}

let translations = () => '';

_.subscribe(value => (translations = value));

function createLoading() {
  return showNotification(
    {
      title: translations('loading'),
    },
    0,
  );
}

function showSuccess(text) {
  return showNotification({
    title: translations(text),
    type: 'success',
  });
}

export async function createUser(user) {
  const notification = createLoading();
  try {
    const { data } = await getClient().post('/api/users/create', { user });
    await getUsers();
    showSuccess('users.editor.userCreated');
    return {
      ...data,
      companies: (data.companies || '').split(',').map(it => it.trim()),
      features: (data.features || '').split(',').map(it => it.trim()),
    };
  } finally {
    discardNotification(notification);
  }
}

export async function deleteUser(id) {
  const notification = createLoading();
  try {
    await getClient().delete(`/api/users/delete/${id}`);
    await getUsers();
    showSuccess('users.editor.userDeleted');
  } finally {
    discardNotification(notification);
  }
}

export async function updateUser(user) {
  const notification = createLoading();
  try {
    const { data } = await getClient().put('/api/users/update', {
      user,
    });
    await getUsers();
    showSuccess('users.editor.userUpdated');
    return {
      ...data,
      companies: (data.companies || '').split(',').map(it => it.trim()),
      features: (data.features || '').split(',').map(it => it.trim()),
    };
  } finally {
    discardNotification(notification);
  }
}

<script>
  import { _, locale, number } from 'svelte-i18n';
  import Loading from '../Loading.svelte';
  import { companies as companyList } from '../../stores/companies';
  import {
    searchLoaded,
    searchLoading,
    searchResults,
  } from '../../stores/travel_reports';
  import { getExcelDownloadGeneralByDay } from '../../common/http';
  import { createEventDispatcher } from 'svelte';
  import PassengerIcon from '../images/Passenger.svelte';
  import ShipmentIcon from '../images/Shipment.svelte';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import Icon from 'fa-svelte';
  import ReportByLineByDayWithProducts from './ReportByLineByDayWithProducts.svelte';

  const dispatch = createEventDispatcher();

  export let start;
  export let end;
  export let lineId;
  export let departureId;
  export let companies;
  export let weekdays;
  export let tickets;
  export let selectedDay;
  export let avgPassengers;
  export let maxPassengers;
  export let passengers;
  export let deviceType;

  $: results = getResults($searchResults, $companyList);

  const getResults = searchResults => searchResults.days;

  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const res = await getExcelDownloadGeneralByDay({
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId,
      departureId,
      companies,
      weekdays,
      lang,
      tickets,
      deviceType,
    });
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
</script>

<style>
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  table.table-results {
    width: 100%;
  }

  .table-results th {
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  .table-results th,
  .table-results th span {
      }

  tr:not(.selected) td {
    border-bottom: 1px solid #cccccc;
  }

  .table-results th.price-vatfree,
  td.price-vatfree {
    padding-right: 0.75em;
  }

  td {
    padding: 0;
    padding-right: 2em;
    padding-bottom: 0.7em;
    padding-top: 1em;
  }

  td:last-child {
    padding: 0;
  }

  tr:not(:first-child) {
    cursor: pointer;
  }

  button {
    margin-top: 2em;
  }

  .icon {
    display: flex;
    justify-content: center;
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }
</style>

{#if $searchLoading}
  <div class="loading-content">
    <Loading />
  </div>
{/if}

{#if !$searchLoading && $searchLoaded}
  {#if results.length}
    <table class="table-results" cellspacing="0">
      <tr>
        <th class="text-left" colspan="5">{$_('travel.dayDate')}</th>
        <th class="text-right">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span>{$_('travel.total')}</span>
          </div>
        </th>
        <th class="text-right price-vatfree">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span> {$_('travel.totalSumVatFree')}</span>
          </div>
        </th>
        <th class="text-right">
          <div class="inline-flex">
            <span> {$_('travel.totalSumVat')}</span>
          </div>
        </th>
        <th class="text-right">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span>
              {$_('travel.euroPerKm')}</span
            >
          </div>
        </th>
        <th class="text-right">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span>
              {$_('travel.byLineMax')}</span
            >
          </div>
        </th>
        <th class="text-right">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span> {$_('travel.avg')}</span>
          </div>
        </th>
        <th class="text-right">
          <div class="inline-flex">
            <span>{$_('travel.line_length')}</span>
          </div>
        </th>
        <th class="text-right">
          <div class="inline-flex">
            <ShipmentIcon width="20px" height="20px" />
            <span> {$_('travel.total')}</span>
          </div>
        </th>
        <th class="w-20" />
      </tr>
      {#each results as result}
        <tr
          on:click={() =>
            selectedDay === result.date
              ? (selectedDay = null)
              : (selectedDay = result.date)}
          class:font-semibold={selectedDay === result.date}
          class:selected={selectedDay === result.date}
          class:bg-mlight={selectedDay === result.date}
        >
          <td>{result.date.format('DD.MM.YYYY')}</td>
          <td colspan="4">
            {$_('travel.totalLinesPerDay').replace('%d', result.line_count)}
          </td>
          <td class="text-right">{result.quantity || '0'}</td>
          <td class="text-right price-vatfree">
            {$number(result.sum, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">
            {$number(result.sum_vat, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">
            {$number(result.euro_per_km, { format: 'EUR' }).replace('€', '')}
          </td>
          <td class="text-right" />
          <td class="text-right" />
          <td class="text-right">
            {$number(result.line_length || 0).replace('€', '')}
          </td>
          <td class="text-right">{result.shipments || '0'}</td>
          <td class="w-20">
            <div class="icon" class:expanded={selectedDay === result.date}>
              <Icon icon={faChevronDown} />
            </div>
          </td>
        </tr>
        {#if selectedDay === result.date}
          <ReportByLineByDayWithProducts
            bind:start
            bind:end
            data={result.lines}
            bind:avgPassengers
            bind:maxPassengers
            bind:passengers
            on:line={({ detail: line }) =>
              dispatch('day', {
                day: result.date,
                line,
              })}
          />
        {/if}
      {/each}
    </table>
    <button on:click={createExcel}>{$_('results.createExcel')}</button>
  {:else}
    <h3>{$_('results.noResults')}</h3>
  {/if}
{/if}

<script>
  import { _ } from 'svelte-i18n';
  import {
    createLineSeries,
    deleteLineSeries,
    updateLineSeries,
    getLineSeries,
    lineSeries,
  } from '../../stores/line_series';
  import ConfirmationModal from '../../components/ConfirmationModal.svelte';
  import SortableRowList from '../../components/SortableRowList.svelte';
  import Select from '../../components/Select.svelte';
  import Checkbox from '../../components/Checkbox.svelte';
  import Loading from '../../components/Loading.svelte';
  import LineSeriesRow from '../../components/pricing/LineSeriesRow.svelte';
  import { showNotification } from '../../stores/notifications';
  import { getContext } from 'svelte';

  const { open } = getContext('simple-modal');

  const ViewMode = Object.freeze({
    DEFAULT: 'DEFAULT',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
  });

  const WeekDayNames = Object.freeze(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);

  export let currentCompany;

  let currentMode = ViewMode.DEFAULT;
  let currentLineSeries = 'default';

  let lineSeriesName = '';
  let selectedWeekDays = new Set(Array.from(Array(8).keys()).slice(1));
  let lines = [{}, {}, {}];
  let lineSeriesId = '';
  let loading = true;

  const fetchLineSeries = async company => {
    loading = true;
    try {
      await getLineSeries(company);
    } finally {
      loading = false;
    }
  };

  $: {
    fetchLineSeries(currentCompany);
  }

  $: {
    const seriesExists =
      $lineSeries[currentCompany] &&
      $lineSeries[currentCompany].some(entry => entry.id === currentLineSeries);
    if (!seriesExists) {
      currentLineSeries =
        ($lineSeries[currentCompany] &&
          $lineSeries[currentCompany][0] &&
          $lineSeries[currentCompany][0].id) ||
        'default';
    }
  }
</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div {
    overflow: auto;
  }
  div.title-container,
  div.subtitle-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.subbutton-container {
    flex: 1 1 0;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
  }
  div.container > * {
    margin-left: 3rem;
  }
  div.select-container {
    display: flex;
    min-width: 250px;
    padding-bottom: 0.25rem;
    min-height: 42px;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  input {
    width: 300px;
    margin-bottom: 1rem;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
  }
  p.error {
    color: #ea5798;
  }
</style>

{#if loading}
  <Loading />
{:else}
  <div class="title-container">
    <div>
      <h2 class="my-6">
        {#if currentMode === ViewMode.CREATE}
          {$_('lineSeries.createNewLineSeries')}
        {:else if currentMode === ViewMode.EDIT}
          {$_('lineSeries.modifyLineSeries')}
        {:else}
          {$_('lineSeries.title')}
        {/if}
      </h2>
    </div>

    <div class="button-container">
      {#if currentMode !== ViewMode.DEFAULT}
        <button
          class="secondary"
          on:click={() => {
            currentMode = ViewMode.DEFAULT;
          }}
        >
          {$_('pricing.cancel')}
        </button>
        <button
          disabled={!lineSeriesName ||
            !selectedWeekDays.size ||
            !lines.some(x => x.departure)}
          on:click={async () => {
            const result = await (lineSeriesId
              ? updateLineSeries(
                  currentCompany,
                  lineSeriesId,
                  lineSeriesName,
                  lines,
                  selectedWeekDays,
                )
              : createLineSeries(
                  currentCompany,
                  lineSeriesName,
                  lines,
                  selectedWeekDays,
                ));
            if (result) {
              currentMode = ViewMode.DEFAULT;
              currentLineSeries = result;
            }
          }}
        >
          {$_('pricing.done')}
        </button>
      {:else}
        <button
          on:click={() => {
            lineSeriesName = '';
            selectedWeekDays = new Set(Array.from(Array(8).keys()).slice(1));
            lines = [{}, {}, {}];
            lineSeriesId = '';
            currentMode = ViewMode.CREATE;
          }}>{$_('lineSeries.addLineSeries')}</button
        >
      {/if}
    </div>
  </div>
  {#if currentMode === ViewMode.DEFAULT && $lineSeries[currentCompany] && $lineSeries[currentCompany].length}
    <div class="subtitle-container">
      <div class="select-container">
        <Select
          flexGrow={true}
          disabled={currentMode !== ViewMode.DEFAULT}
          entries={$lineSeries[currentCompany].map(entry => ({
            label: entry.title,
            value: entry.id,
          }))}
          bind:value={currentLineSeries}
        />
      </div>
      <div class="button-container subbutton-container">
        {#if currentMode === ViewMode.DEFAULT}
          <button
            on:click={() => {
              const currentEntry = $lineSeries[currentCompany].find(
                entry => entry.id === currentLineSeries,
              );
              lineSeriesName = currentEntry.title;
              selectedWeekDays = new Set(currentEntry.weekdays);
              lines = currentEntry.lines.map(entry => ({ ...entry }));
              lineSeriesId = currentEntry.id;
              currentMode = ViewMode.EDIT;
            }}
          >
            {$_('pricing.modify')}
          </button>
          <button
            class="secondary"
            on:click={() => {
              open(ConfirmationModal, {
                title: $_('lineSeries.removeLineSeries'),
                body: $_('lineSeries.removeLineSeriesConfirmation'),
                onConfirm: () => {
                  deleteLineSeries(currentCompany, currentLineSeries);
                },
              });
            }}
          >
            {$_('pricing.remove')}
          </button>
        {/if}
      </div>
    </div>
    <div class="mt-2 mb-2">
      <p>{$_('lineSeries.validity')}</p>
      <p>
        {(
          $lineSeries[currentCompany].find(entry => entry.id === currentLineSeries) || {}
        ).weekdays
          .sort()
          .map(x => $_(`calendar.days.shortSentenceCase.${WeekDayNames[x - 1]}`))
          .join(', ')}
      </p>
    </div>
    <div class="container">
      <div class="price-container">
        <SortableRowList
          editingMode={false}
          list={$lineSeries[currentCompany].find(entry => entry.id === currentLineSeries)
            .lines}
          renderComponent={LineSeriesRow}
          placeholder={$_('pricing.travelRightDescriptionsMissing')}
          title={$_('lineSeries.lineSeriesOrder')}
          headerColumns={[
            {
              title: $_('lineSeries.line'),
            },
            {
              title: $_('lineSeries.departure'),
            },
            {
              editingOnly: true,
            },
          ]}
        />
      </div>
    </div>
  {:else if currentMode === ViewMode.DEFAULT}
    <p>{$_('lineSeries.noLineSeriesExist')}</p>
  {:else}
    <div class="flex flex-col">
      <label for="name">{$_('lineSeries.lineSeriesName')}</label>
      <input name="name" id="name" bind:value={lineSeriesName} width="300px" />
    </div>
    <p>{$_('lineSeries.validity')}</p>
    <div class="flex">
      {#each WeekDayNames as weekday, index}
        <div class="mt-1 mr-8">
          <Checkbox
            size="32px"
            markColor={'#001E61'}
            checked={selectedWeekDays.has(index + 1)}
            label={$_(`calendar.days.shortSentenceCase.${weekday}`)}
            on:change={event => {
              selectedWeekDays = new Set(selectedWeekDays);
              if (event.target.checked) {
                selectedWeekDays.add(index + 1);
              } else {
                selectedWeekDays.delete(index + 1);
              }
            }}
          />
        </div>
      {/each}
    </div>
    {#if !selectedWeekDays.size}
      <p class="error">{$_('lineSeries.atleastOneWeekdayRequired')}</p>
    {/if}
    <div class="container">
      <div class="price-container">
        <SortableRowList
          editingMode={true}
          list={lines}
          renderComponent={LineSeriesRow}
          componentProps={{
            companyId: currentCompany,
            onDeleteRow: (__, index) => {
              if (lines.length > 1) {
                lines.splice(index, 1);
                lines = [...lines];
              } else {
                showNotification({
                  title: '',
                  body: $_('lineSeries.atleastOneLineCombinationRequired'),
                  type: 'warning',
                });
              }
            },
            onChange: item => {
              lines[item.detail.id.id][item.detail.id.field] = item.detail.value;
              lines = [...lines];
            },
          }}
          placeholder={$_('pricing.travelRightDescriptionsMissing')}
          title={$_('lineSeries.lineSeriesOrder')}
          onAddClicked={() => {
            lines = [...lines, {}];
          }}
          on:sort={event => {
            lines = event.detail.list;
          }}
          headerColumns={[
            {
              title: $_('lineSeries.line'),
            },
            {
              title: $_('lineSeries.departure'),
            },
            {
              editingOnly: true,
            },
          ]}
        />
      </div>
    </div>
  {/if}
{/if}

<script>
  import DeviceManagementList from './DeviceManagementList.svelte';
  import DeviceManagementEdit from './DeviceManagementEdit.svelte';

  let active = 'list';
  let editDeviceId;
</script>


<div class="w-full pt-4 px-16 pb-2">
  {#if active === 'list'}
    <DeviceManagementList bind:activeView={active} bind:editDeviceId />
  {:else}
    <DeviceManagementEdit bind:activeView={active} bind:editDeviceId />
  {/if}
</div>

<script>
  import { _ } from 'svelte-i18n';
  import moment from 'moment-timezone';
  import { getServiceLines, serviceLines, initializeModifyServiceLineData, initializeNewServiceLineData, serviceLineCreateState } from '../../stores/lines';
  import Loading from '../../components/Loading.svelte';
  import { tick, onMount } from 'svelte';
  import ServiceLineCreate from './ServiceLineCreate.svelte';

  export let currentCompany;
  let creatingLine = false;
  let displayedMessage = undefined;

  let tableElement;
  let messageElement;

  let prevCompany = currentCompany;

  let isLoading = true;
  let isError = false;

  $: {
    currentCompany;
    creatingLine = false;
    displayedMessage = undefined;
  }

  $: {
    if (currentCompany && prevCompany != currentCompany) {
      prevCompany = currentCompany;
      fetchServiceLines();
    }
  }

  const fetchServiceLines = async () => {
    isLoading = true;
    isError = false;
    try {
      await getServiceLines(currentCompany);
      updateDimensions();
    } catch (error) {
      isError = true;
    } finally {
      isLoading = false;
    }
  };

  onMount(() => {
    if (currentCompany)
      fetchServiceLines();
  });

  const onServiceLineCreated = (entry) => {
    if ($serviceLineCreateState.modification) {
      displayedMessage = $_('lines.service.serviceLineModificationSuccessMessage');
    } else {
      displayedMessage = $_(
          'lines.service.serviceLineCreatedSuccessMessage',
          {
            values:
              {
                lineId: entry.lineId,
                departureId: entry.departureId,
              }
          }
        );
    }
    fetchServiceLines();
  };

  $: {
    $serviceLines;
    if (!creatingLine) {
      updateDimensions();
    }
  }

  const updateDimensions = async () => {
    await tick();
    const dimensions = tableElement && tableElement.getBoundingClientRect();
    if (dimensions && messageElement) {
      messageElement.style.width = `${dimensions.width}px`;
    }
  };

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.title-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  table {
    border-collapse: collapse;
  }
  td, th {
    padding: 0.75rem 1rem;
    border-bottom: solid 1px #D1D1D2;
  }
  th {
    color: #5B6670;
    text-align: left;
    font-weight: normal;
  }
  .text-button {
    color: #001E61;
    cursor: pointer;
    user-select: none;
  }
  .text-button:hover {
    color: #173043;
  }
  .message-box {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
    align-items: center;
    background-color: #eef5fb;
    font-weight: 600;
    margin: 0.5rem 0 1.5rem 0;
  }
  .message-box > button {
    min-width: unset;
  }
</style>

<svelte:window on:resize={updateDimensions} />

{#if !currentCompany}
  <!-- Show nothing -->
{:else if isLoading}
  <Loading />
{:else if isError}
<p>{$_('lines.service.lineFetchError')}</p>
{:else if creatingLine}
  <ServiceLineCreate bind:inProgress={creatingLine} onSuccess={onServiceLineCreated} />
{:else}
  <div class="title-container">
    <div>
      <h2 class="my-6">
        {$_('lines.service.title')}
      </h2>
    </div>

    <div class="button-container">
      <button on:click={() => {
        const company = currentCompany;
        if (!company) return;
        initializeNewServiceLineData(company);
        creatingLine = true;
      }}>{$_('lines.service.createNew')}</button>
    </div>
  </div>
  {#if displayedMessage}
  <div class="message-box" bind:this={messageElement}>
    <span>{displayedMessage}</span>
    <button on:click={() => {
      displayedMessage = undefined;
    }}>{$_('lines.common.messageDismiss')}</button>
  </div>
  {/if}
  {#if $serviceLines[currentCompany] && $serviceLines[currentCompany].length}
    <div class="container">
      <table bind:this={tableElement}>
        <tr>
          <th>{$_('lines.service.serviceLineName')}</th>
          <th>{$_('lines.service.validFrom')}</th>
          <th>{$_('lines.service.validUntil')}</th>
          <th>{$_('lines.service.startingTime')}</th>
          <th>{$_('lines.service.endingTime')}</th>
          <th>{$_('lines.service.serviceLineArea')}</th>
          <th>{$_('lines.common.lineId')}</th>
          <th>{$_('lines.common.departureId')}</th>
          <th></th>
        </tr>
        {#each $serviceLines[currentCompany] as serviceLine}
          <tr>
            <td>{serviceLine.lineName}</td>
            <td>{moment(serviceLine.validFrom).tz('Europe/Helsinki').format('D.M.yyyy')}</td>
            <td>{serviceLine.validUntil ? moment(serviceLine.validUntil).tz('Europe/Helsinki').format('D.M.yyyy') : '-'}</td>
            <td>{serviceLine.startingTime}</td>
            <td>{serviceLine.endingTime}</td>
            <td>{serviceLine.serviceLineArea}</td>
            <td>{serviceLine.lineId}</td>
            <td>{serviceLine.departureId}</td>
            <td class="text-button" on:click={() => {
              initializeModifyServiceLineData(serviceLine);
              creatingLine = true;
            }
            }>{$_('lines.service.modify')}</td>
          </tr>
        {/each}
      </table>
    </div>
  {:else}
    <p>{$_('lines.service.noLinesFound')}</p>
  {/if}
{/if}

<script>
  import { _, number } from 'svelte-i18n';
  export let daily;
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  $: totalPrepurchase = daily.reduce(
    (acc, { prepurchase }) => acc + (prepurchase || 0),
    0,
  );
  $: totalPrepurchaseValidated = daily.reduce(
    (acc, { prepurchase_validated }) => acc + (prepurchase_validated || 0),
    0,
  );
  $: totalPrepurchaseSum = daily.reduce(
    (acc, { prepurchase_sum }) => acc + (prepurchase_sum || 0),
    0,
  );
  $: totalCash = daily.reduce((acc, { cash }) => acc + (cash || 0), 0);
  $: totalCashSum = daily.reduce((acc, { cash_sum }) => acc + (cash_sum || 0), 0);
  $: totalTravelcard = daily.reduce((acc, { travelcard }) => acc + (travelcard || 0), 0);
  $: totalTravelcardSum = daily.reduce(
    (acc, { travelcard_sum }) => acc + (travelcard_sum || 0),
    0,
  );
  $: totalNoSale = daily.reduce((acc, { no_sale }) => acc + (no_sale || 0), 0);
  $: totalNoSaleSum = daily.reduce((acc, { no_sale_sum }) => acc + (no_sale_sum || 0), 0);
  $: totalTotal = daily.reduce(
    (acc, { prepurchase, cash, travelcard, no_sale }) =>
      acc + (prepurchase || 0) + (cash || 0) + (travelcard || 0) + (no_sale || 0),
    0,
  );
  $: totalTotalSum = daily.reduce((acc, { total_vatfree }) => acc + (total_vatfree || 0), 0);
  $: totalTotalSumVat = daily.reduce((acc, { total_vat }) => acc + (total_vat || 0), 0);
  $: totalAverage = daily.reduce((acc, { traveler_avg }) => acc + (traveler_avg || 0), 0);
</script>

<style>
  table {
    width: 100%;
  }

  th {
    text-align: end;
    padding-right: 2rem;
    border-bottom: 1px solid #333333;
        font-weight: 600;
  }

  th:first-child,
  th:nth-child(2) {
    padding-left: 0;
    text-align: start;
  }

  td {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    padding-right: 2rem;
    color: #001E61;
    cursor: pointer;
    text-align: right;
  }

  td:first-child {
    padding-right: 1em;
  }

  td:first-child,
  td:nth-child(2):not(.second) {
    text-align: start;
  }

  tr:not(:last-child):not(:nth-last-child(2)) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:nth-last-child(2) td {
    border-bottom: 1px solid #333333;
    padding-bottom: 0.5em;
  }

  tr:last-child td {
    padding-top: 0.5em;
    color: #333333;
  }

  button {
    margin-top: 2.5em;
  }

  .last-row td:not([colspan='2']) {
    font-weight: 600;
  }
</style>

<table cellspacing="0">
  <tr>
    <th>{$_('travel.dayDate')}</th>
    <th>{$_('results.driver')}</th>
    <th>{$_('travel.prepurchaseValidated')}</th>
    <th>{$_('travel.prepurchaseSold')}</th>
    <th>{$_('travel.prepurchaseSum')}</th>
    <th>{$_('travel.cash')}</th>
    <th>{$_('travel.cashSum')}</th>
    <th>{@html $_('travel.travelcard')}</th>
    <th>{@html $_('travel.travelcardSum')}</th>
    <th>{$_('travel.noSale')}</th>
    <th>{$_('travel.noSaleSum')}</th>
    <th>{$_('travel.total')}</th>
    <th>{$_('travel.totalSumVatFree')}</th>
    <th>{$_('travel.totalSumVatLong')}</th>
  </tr>
  {#each daily as result}
    <tr>
      <td on:click={() => dispatch('day', result)}>
        {result.day.format('D.M')}
      </td>
      <td on:click={() => dispatch('day', result)}>{result.driver}</td>
      <td on:click={() => dispatch('day', result)}>
        {result.prepurchase_validated}
      </td>
      <td on:click={() => dispatch('day', result)}>{result.prepurchase}</td>
      <td on:click={() => dispatch('day', result)}>
        {$number(result.prepurchase_sum, { format: 'EUR' }).replace('€', '')}
      </td>
      <td on:click={() => dispatch('day', result)}>{result.cash}</td>
      <td on:click={() => dispatch('day', result)}>
        {$number(result.cash_sum, { format: 'EUR' }).replace('€', '')}
      </td>
      <td on:click={() => dispatch('day', result)}>{result.travelcard}</td>
      <td on:click={() => dispatch('day', result)}>
        {$number(result.travelcard_sum, { format: 'EUR' }).replace('€', '')}
      </td>
      <td on:click={() => dispatch('day', result)}>{result.no_sale}</td>
      <td on:click={() => dispatch('day', result)}>
        {$number(result.no_sale_sum, { format: 'EUR' }).replace('€', '')}
      </td>
      <td on:click={() => dispatch('day', result)}>
        {result.prepurchase + result.cash + result.travelcard + result.no_sale}
      </td>
      <td on:click={() => dispatch('day', result)}>
        {$number(result.total_vatfree, { format: 'EUR' }).replace('€', '')}
      </td>
      <td on:click={() => dispatch('day', result)}>
        {$number(result.total_vat, { format: 'EUR' }).replace('€', '')}
      </td>
    </tr>
  {/each}
  <tr class="last-row">
    <td colspan="2" style="white-space: nowrap;">
      {$_('results.totalCount').replace('%d', daily.length)}
    </td>
    <td class="second">{totalPrepurchaseValidated}</td>
    <td>{totalPrepurchase}</td>
    <td>{$number(totalPrepurchaseSum, { format: 'EUR' }).replace('€', '')}</td>
    <td>{totalCash}</td>
    <td>{$number(totalCashSum, { format: 'EUR' }).replace('€', '')}</td>
    <td>{totalTravelcard}</td>
    <td>{$number(totalTravelcardSum, { format: 'EUR' }).replace('€', '')}</td>
    <td>{totalNoSale}</td>
    <td>{$number(totalNoSaleSum, { format: 'EUR' }).replace('€', '')}</td>
    <td>{totalTotal}</td>
    <td>{$number(totalTotalSum, { format: 'EUR' }).replace('€', '')}</td>
    <td>{$number(totalTotalSumVat, { format: 'EUR' }).replace('€', '')}</td>
  </tr>
</table>
<button on:click={() => dispatch('excel')}>{$_('results.createExcel')}</button>

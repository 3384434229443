import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import { FEATURE_BY_FEATURE, isSuperUser, getUserCompanies } from './user';

export const featuresLoaded = writable(false);
export const featuresLoading = writable(false);
export const features = writable([]);

export async function getFeatures() {
  featuresLoaded.set(false);
  featuresLoading.set(true);
  features.set([]);
  const res = await getClient().get('/api/features');
  if (res.status === 200) {
    featuresLoaded.set(true);
    featuresLoading.set(false);
    features.set(res.data);
  } else {
    featuresLoaded.set(false);
    featuresLoading.set(false);
    features.set([]);
  }
}

export function hasFeature(features, code) {
  const startCode = `${code};`;
  return Object.keys(features || {}).some(it => it === code || it.startsWith(startCode));
}

export function hasAnyFeature(features, code) {
  if ( isSuperUser(features) ) {
    return true;
  }
  return hasFeature(features && features[FEATURE_BY_FEATURE], code);
}

export function getCompaniesByFeature(features, code) {
  if ( isSuperUser(features) ) {
    return getUserCompanies();
  }
  const featureKeys = (features && Object.keys(features[FEATURE_BY_FEATURE] || {}) || [])
    .filter(feature => feature === code || feature.startsWith(`${code};`));

  const companies = featureKeys.map(key => features[FEATURE_BY_FEATURE][key]).flat();
  return companies;
}
import Papa from 'papaparse/papaparse.min.js';
import isUtf8 from 'is-utf8';

Papa.defaultDelimiter = '\t';

const NUM_DATA_HEADER_ROWS = 16;

export const ErrorCodes = Object.freeze({
  FILE_READ_FAILED: 100,
  FILE_PARSING_FAILED: 101,
  FILE_DATA_INVALID: 102,
});

export const readFileAsBuffer = file => {
  const reader = new FileReader();

  const promise = new Promise((resolve, reject) => {
    reader.onloadend = function (evt) {
      if (evt.target.readyState == FileReader.DONE) {
        resolve(evt.target.result);
      } else {
        reject(evt);
      }
    };

    reader.onerror = function (evt) {
      reject(evt);
    };
  });

  reader.readAsArrayBuffer(file);
  return promise;
};

export const readTextFile = async (file, detectEncoding) => {
  const buffer = await readFileAsBuffer(file);
  const array = new Uint8Array(buffer);
  //MINFO exports tables with windows-1252 encoding, but resaving might encode the table as UTF-8
  const encoding = detectEncoding
    ? (isUtf8(array) && 'utf-8') || 'windows-1252'
    : undefined;
  const decoder = new TextDecoder(encoding);
  return decoder.decode(array);
};

export const readPriceTableFile = async file => {
  const isValidData = (data, emptyFinalLineCount) => {
    try {
      return (
        data[2][0] === 'Nimi suomeksi' &&
        data[3][0] === 'Nimi ruotsiksi' &&
        data[4][0] === 'Nimi englanniksi' &&
        data[data.length - (1 + emptyFinalLineCount)].length >=
          data.length - NUM_DATA_HEADER_ROWS
      );
    } catch (error) {
      return false;
    }
  };

  let fileInput;
  try {
    fileInput = await readTextFile(file, true);
  } catch (error) {
    error.code = ErrorCodes.FILE_READ_FAILED;
    throw error;
  }

  let data;
  try {
    ({ data } = Papa.parse(fileInput));
  } catch (error) {
    error.code = ErrorCodes.FILE_PARSING_FAILED;
    throw error;
  }

  let emptyFinalLineCount = 0;
  while (data[data.length - (1 + emptyFinalLineCount)].every(x => !x.trim()))
    ++emptyFinalLineCount;

  if (isValidData(data, emptyFinalLineCount)) {
    const trimmed = data.slice(
      NUM_DATA_HEADER_ROWS,
      emptyFinalLineCount ? -emptyFinalLineCount : undefined,
    );
    const stations = trimmed.map(entry => entry[0]);
    const distances = trimmed.map((entry, index) =>
      entry.slice(1, 1 + index).map(x => (x === '' ? null : +x.replace(',', '.'))),
    );
    return {
      stations,
      distances,
    };
  } else {
    const error = new Error('Invalid file data');
    error.code = ErrorCodes.FILE_DATA_INVALID;
    throw error;
  }
};

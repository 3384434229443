<script>
  import { _ } from 'svelte-i18n';
  import { signIn } from '../../stores/user';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let email = '';
  let password = '';

  let loading = false;

  let error = '';

  let needChanges = false;

  const login = async () => {
    if (loading) return;
    loading = true;
    error = '';
    try {
      await signIn(email, password);
    } catch (e) {
      if (e.status === 403) error = $_('login.invalidCredentials');
      else if (e.newPassword) dispatch('password', e.session);
      else error = e.message || $_('login.error');
      //needChanges = !e.retryable;
      console.error(e);
    } finally {
      loading = false;
    }
  };
</script>

<style>
  .content {
    background: #fff;
    padding: 0 1.5rem 2.5rem 1.5rem;
    margin-top: 3rem;
    display: grid;
  }
  @supports (-ms-ime-align: auto) {
    .content {
      display: flex;
      flex-direction: column;
    }
  }

  input {
    width: 100%;
    height: 2rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  h1 {
    color: #001E61;
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }

  .actions > span {
    color: #001E61;
    margin-left: 1rem;

  }

  .actions > button {
    padding: 0.6em 3.4em;
  }

  .actions {
    min-width: 20rem;
    font-size: 0.8rem;
    padding-top: 1rem;
  }

  label {
    font-size: 0.8rem;
  }

  .error {
    background: #ffbbd9;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 1rem;
  }
</style>

<form
  class="content"
  on:submit|preventDefault={login}
  on:change={() => (needChanges = false)}
>
  <h1 class="mb-6 text-3xl font-bold">{$_('login.title')}</h1>
  <label for="email">{$_('login.email')}</label>
  <input type="email" id="email" bind:value={email} autocomplete="email" />
  <label for="password" style="padding-top: 1rem;">
    {$_('login.password')}
  </label>
  <input
    type="password"
    id="password"
    autocomplete="current-password"
    bind:value={password}
  />
  {#if error}
    <span class="error">{error}</span>
  {/if}
  <div class="actions">
    <button type="submit" disabled={loading || !email || needChanges || !password}>
      {$_('login.action')}
    </button>
    <span on:click={() => dispatch('forgotten')} class="text-mnormal underline">{$_('login.forgotten')}</span>
    <span on:click={() => dispatch('activate')} class="text-mnormal underline">{$_('login.activate')}</span>
  </div>
</form>

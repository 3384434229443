import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import moment from 'moment-timezone';

export const supportRequestLoaded = writable(false);
export const supportRequestLoading = writable(false);
export const supportRequestResults = writable([]);

export async function getSupportRequests(reset = true) {
  if (reset) {
    supportRequestLoaded.set(false);
    supportRequestLoading.set(true);
    supportRequestResults.set([]);
  }
  const res = await getClient().get('/api/support/requests');
  if (res.status === 200) {
    supportRequestLoaded.set(true);
    supportRequestLoading.set(false);
    supportRequestResults.set(
      res.data.map(request => ({
        ...request,
        timestamp: moment.tz(request.timestamp.split('_')[0], 'Europe/Helsinki'),
        id: request.timestamp,
      })),
    );
  } else {
    supportRequestLoaded.set(false);
    supportRequestLoading.set(false);
    supportRequestResults.set([]);
  }
}

export async function getSupportRequest(id) {
  const res = await getClient().get(`/api/support/request/${id}`);
  const { info, actions, latestInfo } = res.data;
  return {
    actions: actions.map(action => ({
      ...action,
      id: action.timestamp,
      commandTimestamp: action.id,
      timestamp: moment.tz(action.timestamp.split('_')[0], 'Europe/Helsinki'),
    })),
    info: {
      ...info,
      id: info.timestamp,
      timestamp: moment.tz(info.timestamp.split('_')[0], 'Europe/Helsinki'),
    },
    latestInfo,
  };
}

export function supportRequestsDirty() {
  supportRequestLoaded.set(false);
}

export async function addNote(id, text) {
  await getClient().post(`/api/support/request/${id}/note`, { text });
}

export async function markAsHandled(id, text) {
  await getClient().post(`/api/support/request/${id}/handled`, { text });
}

export async function sendCommandToThread(id, command, device, args) {
  await getClient().post(`/api/support/request/${id}/command`, {
    device,
    command,
    args,
  });
}

export async function sendCommand(command, device, args) {
  await getClient().post(`/api/support/request/command`, {
    device,
    command,
    args,
  });
}

export async function createRequestAndSendCommand(command, device) {
  await getClient().post(`/api/support/request`, {
    device,
    command,
  });
  await getSupportRequests(false);
}

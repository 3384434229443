<script>
  import { _, number } from 'svelte-i18n';
  import {
    CARD,
    CASH,
    MULTIPLE,
    ONE_TIME_PAYMENT,
    ROUNDING,
    isTravelcard,
  } from '../../stores/search';

  export let results = [];

  function isCancelled(event_type) {
    return event_type === 'CANCELLED_RECEIPT' || event_type === 'CANCELLED_TRANSACTION';
  }

  $: totalSum = results.reduce((acc, { sum: cur }) => acc + (cur || 0), 0);
  $: totalCashSum = results
    .filter(({ payment_method, isAddedRow }) => payment_method === CASH || isAddedRow || payment_method === MULTIPLE)
    .reduce((acc, cur) => acc + ((cur.payment_method === MULTIPLE ? cur.cash_amount : cur.sum) || 0), 0);
  $: totalCardSum = results
    .filter(({ payment_method }) => payment_method === CARD || payment_method === MULTIPLE)
    .reduce((acc, cur) => acc + ((cur.payment_method === MULTIPLE ? cur.card_amount : cur.sum) || 0), 0);
  $: totalOneTimeSum = results
    .filter(
      ({ event_type }) => event_type === ONE_TIME_PAYMENT || event_type === ROUNDING,
    )
    .reduce((acc, { sum: cur }) => acc + (cur || 0), 0);
  $: totalTravelCardSum = results
    .filter(({ event_type }) => isTravelcard(event_type))
    .reduce((acc, { sum: cur }) => acc + (cur || 0), 0);
  $: otherSaleSum = results
    .filter(
      ({ event_type, isAddedRow }) =>
        (!isCancelled(event_type) &&
          !isTravelcard(event_type) &&
          event_type !== ONE_TIME_PAYMENT &&
          event_type !== ROUNDING) ||
        isAddedRow,
    )
    .reduce((acc, { sum: cur }) => acc + (cur || 0), 0);
  $: cancelledSum = results
    .filter(({ event_type }) => isCancelled(event_type))
    .reduce((acc, { sum: cur }) => acc + (cur || 0), 0);
  $: totalCash = new Set(
    results
      .filter(({ payment_method, isAddedRow }) => payment_method === CASH || isAddedRow || payment_method === MULTIPLE)
      .map(({ receipt_number, id }) => receipt_number || id)
      .filter(it => it),
  ).size;
  $: totalCard = new Set(
    results
      .filter(({ payment_method }) => payment_method === CARD || payment_method === MULTIPLE)
      .map(({ receipt_number, id }) => receipt_number || id)
      .filter(it => it),
  ).size;
  $: totalOneTime = results
    .filter(
      ({ event_type }) => event_type === ONE_TIME_PAYMENT || event_type === ROUNDING,
    )
    .reduce(
      (acc, { quantity: cur, event_type }) =>
        acc + (isTravelcard(event_type) ? 1 : cur || 1),
      0,
    );
  $: totalTravelCard = results.filter(({ event_type }) => isTravelcard(event_type))
    .length;
  $: otherSale = results
    .filter(
      ({ event_type, isAddedRow }) =>
        (!isCancelled(event_type) &&
          !isTravelcard(event_type) &&
          event_type !== ONE_TIME_PAYMENT &&
          event_type !== ROUNDING) ||
        isAddedRow,
    )
    .reduce(
      (acc, { quantity: cur, event_type }) =>
        acc + (isTravelcard(event_type) ? 1 : cur || 1),
      0,
    );
  $: cancelled = results
    .filter(({ event_type }) => isCancelled(event_type))
    .reduce((acc, { quantity: cur }) => acc + (cur || 1), 0);
</script>

<style>
  td {
    padding-bottom: 0.2em;
    padding-top: 0.2em;
  }

  td:last-child {
    text-align: right;
  }

  td:first-child,
  th:first-child {
    width: 100%;
    padding-right: 1em;
    text-align: left;
  }
</style>

<table class="mt-4" cellspacing="0">
  <tr>
    <td>{$_('results.cashSum').replace(/\{1\}/, totalCash)}:</td>
    <td>{$number(totalCashSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <td>{$_('results.cardSum').replace(/\{1\}/, totalCard)}:</td>
    <td>{$number(totalCardSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <th class="py-1 border-mdark border-b border-t">
      {$_('results.totalSum')}:
    </th>
    <th class="py-1 border-mdark border-b border-t">
      {$number(totalSum, { format: 'EUR' })}
    </th>
  </tr>
  <tr>
    <td>{$_('results.oneTimeSum').replace(/\{1\}/, totalOneTime)}:</td>
    <td>{$number(totalOneTimeSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <td>{$_('results.travelCardSum').replace(/\{1\}/, totalTravelCard)}:</td>
    <td>{$number(totalTravelCardSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <td>{$_('results.otherSale').replace(/\{1\}/, otherSale)}:</td>
    <td>{$number(otherSaleSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <td>{$_('results.cancelled').replace(/\{1\}/, cancelled)}:</td>
    <td>{$number(cancelledSum, { format: 'EUR' })}</td>
  </tr>
</table>

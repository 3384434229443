<script>
  import Modal from '../Modal.svelte';
  import { showError } from '../../stores/notifications';
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import {
    companiesLoaded,
    companies as companyList,
    getCompanies,
  } from '../../stores/companies';
  import { getMessageImage, deleteMessage, getMessages } from '../../stores/messages';

  export let message;
  const handleError = showError($_('error.title'));

  const dispatch = createEventDispatcher();

  let imageSrc;
  let imageLoading = false;

  const loadImage = async () => {
    imageLoading = true;
    imageSrc = await getMessageImage(message.id).finally(() => (imageLoading = false));
  };

  $: {
    if (message.image) {
      loadImage().catch(handleError);
    }
  }

  if (!$companiesLoaded) {
    getCompanies().catch(handleError);
  }

  let companies = [];

  companyList.subscribe(list => {
    const concernedCompanies = new Set(message.companies);
    companies = list.filter(({ number }) => concernedCompanies.has(number));
  });

  let deleteWarning = false;

  let loading = false;

  const handleDelete = async () => {
    try {
      loading = true;
      await deleteMessage(message.id);
      await getMessages();
      dispatch('delete');
    } catch (e) {
      handleError(e);
    } finally {
      loading = false;
    }
  };

  const now = new Date().getTime();
</script>

<style>
  .container {
    display: flex;
  }

  .left-panel {
    flex-grow: 2;
    max-width: calc(60% - 3em);
    padding-right: 3em;
  }

  .right-panel {
    flex-grow: 1;
    max-width: 40%;
    display: flex;
  }

  .equal-split {
    display: flex;
  }

  .equal-split > * {
    flex-grow: 1;
  }

  .space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .field {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
  }

  .actions {
    display: flex;
    margin-top: 2em;
  }

  .actions > * {
    margin-right: 2em;
  }

  .lines {
    display: flex;
  }

  .lines > div {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
  }

  .label {
    font-size: 14px;
    margin-bottom: 1.5em;
  }

  .label.title {
    margin-bottom: 0.5em;
  }

  .value.title {
    font-weight: 600;
  }

  .value.unpublished {
    color: #001E61;
  }

  img {
    margin-top: 2rem;
    object-fit: contain;
    max-width: 450px;
    max-height: 450px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .buttons > div:first-child {
    display: flex;
  }

  .buttons > div:first-child > button:not(:first-child) {
    margin-left: 2em;
  }

  .companies {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .modal button {
    width: 160px;
  }
</style>

<Modal bind:opened={deleteWarning}>
  <div class="modal px-4 py-5 w-400px">
    <h3>{$_('messages.deleteSure')}</h3>
    <div class="buttons mt-16">
      <button class="small" disabled={loading} on:click={handleDelete}>
        {$_('messages.delete')}
      </button>
      <button class="secondary small" on:click={() => (deleteWarning = false)}>
        {$_('users.editor.cancel')}
      </button>
    </div>
  </div>
</Modal>

{#if message}
  <div class="container">
    <div class="left-panel">
      <div class="equal-split">
        <div class="field">
          <span class="label">{$_('messages.categories.title')}:</span>
          <span class="value">
            {$_(`messages.categories.${message.category}`, {
              default: message.category,
            })}
          </span>
        </div>
        <div class="space-evenly">
          <div class="field">
            <span class="label">{$_('messages.start')}:</span>
            <span
              class="value"
              class:unpublished={message.start.toDate().getTime() > now}
            >
              {message.start.format('D.M.YYYY H:mm')}
            </span>
          </div>
          <div class="field">
            <span class="label">{$_('messages.end')}:</span>
            <span class="value">
              {message.end ? message.end.format('D.M.YYYY H:mm') : ''}
            </span>
          </div>
        </div>
      </div>
      <div class="field">
        <span class="label title">{$_('messages.messageTitle')}:</span>
        <span class="value title">{message.title}</span>
      </div>
      <div class="field">
        <span class="label">{$_('messages.messageContent')}:</span>
        <span class="value">{message.content}</span>
      </div>
      <div class="actions">
        <button class="secondary" disabled={loading} on:click={() => dispatch('edit')}>
          {$_('messages.edit')}
        </button>
        <button
          class="secondary"
          disabled={loading}
          on:click={() => (deleteWarning = true)}
        >
          {$_('messages.delete')}
        </button>
      </div>
    </div>
    <div class="right-panel">
      <div class="field">
        {#if $companiesLoaded}
          <div class="field" style="padding: 0;">
            <span class="label">{$_('messages.companies')}:</span>
            <div class="companies">
              {#each companies as company}
                <span class="value">
                  {`${company.name} (${company.number})`}
                </span>
              {/each}
            </div>
          </div>
        {/if}
        <span class="label">{$_('messages.target.name')}:</span>
        {#if message.lines}
          <div class="lines">
            <span class="value">{$_('messages.selectedLines')}</span>
            <div>
              {#each message.lines as line}
                <span class="value">{line.line}/{line.departure}</span>
              {/each}
            </div>
          </div>
        {:else}
          <span class="value">{$_('messages.allLines')}</span>
        {/if}
        {#if imageSrc}
          <img src={imageSrc} alt="" />
        {/if}
      </div>
    </div>
  </div>
{/if}

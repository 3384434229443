<script>
  import { _, date } from 'svelte-i18n';
  import DatePicker from '../DatePicker.svelte';
  import Expandable from '../Expandable.svelte';
  import Checkbox from '../Checkbox.svelte';
  import Loading from '../Loading.svelte';
  import MultiSelect from '../MultiSelect.svelte';
  import Select from '../Select.svelte';
  import Icon from 'fa-svelte';
  import { faChevronDown, faCalendar } from '@fortawesome/free-solid-svg-icons';
  import { searchResults, startSearch } from '../../stores/search';
  import {
    companies as companyList,
    companiesLoaded,
    getCompanies,
  } from '../../stores/companies';
  import {
    updatePricingData,
    prices,
    currentCompany as currentCompanyStore,
  } from '../../stores/pricing';
  import { showError } from '../../stores/notifications';
  import DropDownArrowSmall from '../icons/DropDownArrowSmall.svelte';
  import { currentUser } from '../../stores/user';
  import { getCompaniesByFeature } from '../../stores/features';

  $: features = ($currentUser && $currentUser.features) || [];

  let expanded = $searchResults.length == 0;

  $: companiesForReporting = getCompaniesByFeature(features, 'REPORTS') || [];
  $: companyListFilteredByFeature = $companyList.filter(company => companiesForReporting.includes(company.number));
  
  
  export let start;
  export let end;
  export let departureId;
  export let lineId;
  export let driverId;
  export let deviceId;
  export let companies;
  export let receiptNumber;
  export let unaccounted;
  export let onlyAdded;
  export let deviceType;
  export let tickets;

  let currentCompany = $currentCompanyStore;
  let ticketFilter = [];
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);

  if (!$companiesLoaded) {
    getCompanies().catch(showError($_('error.title')));
  }
  const getEndDate = date => {
    const end = new Date(date);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(999);
    return end;
  };

  const getStartDate = date => {
    const start = new Date(date);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return start;
  };

  const search = () => {
    startSearch({
      start: getStartDate(start),
      end: getEndDate(end),
      departureId,
      lineId,
      driverId,
      deviceId,
      companies,
      receiptNumber,
      unaccounted,
      onlyAdded,
      tickets,
      deviceType,
    })
      .then(() => (expanded = false))
      .catch(showError($_('error.title')));
  };

  const updateCompanyData = () => {
    if ($companiesLoaded) {
      $companyList
        .filter(company => company && !$prices[company.number])
        .forEach(company => updatePricingData(company.number));
    }
  };

  companyList.subscribe(() => {
    if (!companies.map(({ value }) => value).includes(currentCompany))
      currentCompany = companies[0] && companies[0].value;
    updateCompanyData();
  });

  const handleAdd = company => {
    const ticketProperties = ['basicTickets', 'otherProducts', 'otherTickets'];
    const companyPricing = $prices[company.number];
    ticketProperties.forEach(property => {
      if (!companyPricing || !companyPricing[property]) return;
      companyPricing[property].forEach(
        ticket => (ticketFilter = [...ticketFilter, ticket]),
      );
    });

    if ($prices[company.number] && $prices[company.number].zoneTickets) {
      const zoneTickets = $prices[company.number].zoneTickets;
      const zoneTicketObjects = Object.keys(zoneTickets);
      zoneTicketObjects.forEach(priceList =>
        zoneTickets[priceList].forEach(ticket =>
          ticket.zonePrices.forEach(zonePrice => {
            ticketFilter = [...ticketFilter, { ...ticket, zone: zonePrice.zone }];
          }),
        ),
      );
    }
    companies = [...companies, company.number];
    return companies;
  };

  const handleRemove = company => {
    companies = companies.filter(number => number !== company.number);
    ticketFilter = ticketFilter.filter(ticket => company.number !== ticket['company']);
    return companies;
  };

  const getProductValue = product => {
    const productObj = {
      productName: product.name,
      zone: product.zone,
    };
    return JSON.stringify(productObj);
  };
</script>

<style>
  section {
    transition: padding 250ms ease-in-out;
  }

  section.expanded {
    padding: 0.5em 3em 2em 3em;
  }

  .icon {
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }

  h2 {
    transition: margin-bottom 250ms ease-in-out;
  }

  button {
    min-width: 8em;
  }

  label {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }

  input:placeholder-shown {
    font-style: italic;
  }

  .select-field > :global(.container) {
    height: 1.5rem;
  }

  .select-field > :global(.container) > :global(select) {
    font-size: 0.8rem;
    padding: 0 0.8em;
    background-color: white;
    border-radius: 2px;
    margin: 0 0 0.5em 0;
  }
</style>

<h1 class="mb-6 text-3xl font-bold">{$_('reports.title')}</h1>
<section class:expanded class="bg-mlight text-mdark py-3 px-12">
  <div
    class="flex justify-between items-center cursor-pointer"
    on:click={() => (expanded = !expanded)}
  >
    <h2 class:mb-8={expanded} class="text-xl font-semibold mr-12 my-1">
      {$_('search.define')}:
    </h2>
    <div class="icon" class:expanded>
      <Icon icon={faChevronDown} />
    </div>
  </div>
  <Expandable bind:expanded>
    <div class="flex flex-col">
      <div class="flex space-x-4">
        <div>
          <div class="flex flex-col mb-4">
            <label for="start">{$_('search.start')}: *</label>
            <DatePicker bind:selected={start} end={end} start={new Date(2010,1,1)}>
              <div
                class="input mr-3 w-48 flex justify-between cursor-pointer
                  items-center"
                placeholder={$_('search.choose.date')}
              >
                <span>{$date(start, { format: 'twoDigits' })}</span>
                <Icon icon={faCalendar} />
              </div>
            </DatePicker>
          </div>
          <div class="flex flex-col mb-4">
            <label for="end">{$_('search.end')}: *</label>
            <DatePicker bind:selected={end} end={now} {start}>
              <div
                class="input mr-3 w-48 flex justify-between cursor-pointer
                  items-center"
                placeholder={$_('search.choose.date')}
              >
                <span>{$date(end, { format: 'twoDigits' })}</span>
                <Icon icon={faCalendar} />
              </div>
            </DatePicker>
          </div>
        </div>
        <div class="flex flex-col mb-4" id="companies-div">
          <label for="companies">{$_('search.company')}: *</label>
          <div class="flex flex-row flex-wrap" id="companies">
            {#if $companiesLoaded}
              {#each companyListFilteredByFeature as company}
                <div class="flex w-320px mr-4">
                  <Checkbox
                    checked={companies.includes(company.number)}
                    on:change={({ target: { checked } }) =>
                      (companies = checked ? handleAdd(company) : handleRemove(company))}
                    label={`${company.name} (${company.number})`}
                  />
                </div>
              {/each}
            {:else}
              <Loading />
            {/if}
          </div>
        </div>
      </div>
      <div class="flex flex-wrap space-x-4">
        <div class="flex flex-row">
          <div class="flex flex-col mb-4">
            <label for="line">{$_('search.lineId')}:</label>
            <input
              class="mr-3 w-48"
              type="text"
              id="line"
              placeholder={$_('search.choose.lineId')}
              bind:value={lineId}
            />
          </div>
          <div class="flex flex-col mb-4">
            <label for="departure">{$_('search.departureId')}:</label>
            <input
              class="mr-3 w-48"
              type="text"
              id="departure"
              placeholder={$_('search.choose.departureId')}
              bind:value={departureId}
            />
          </div>
          <div class="flex flex-col mb-4 select-field w-48 mr-3">
            <label>{$_('search.deviceType')}:</label>
            <Select
              border={true}
              bind:value={deviceType}
              customIcon={DropDownArrowSmall}
              entries={[
                {
                  value: null,
                  label: $_('search.deviceTypes.all'),
                },
                {
                  value: 'PRO',
                  label: $_('search.deviceTypes.pro'),
                },
                {
                  value: 'LITE',
                  label: $_('search.deviceTypes.lite'),
                },
              ]}
            />
          </div>
          <div class="flex flex-col mb-4 multi w-64">
            <label for="product_name">{$_('search.product')}:</label>

            <MultiSelect
              placeholder={$_('search.choose.product')}
              id="tickets"
              removeProducts={false}
              bind:value={tickets}
            >
              {#each ticketFilter as product}
                <option value={getProductValue(product)}>
                  {product.zone ? `${product.name} - ${product.zone}` : `${product.name}`}
                </option>
              {/each}
            </MultiSelect>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col mb-4">
          <label for="driver">{$_('search.driverId')}:</label>
          <input
            class="mr-3 w-48"
            type="text"
            id="driver"
            placeholder={$_('search.choose.driverId')}
            bind:value={driverId}
          />
        </div>
        <div class="flex flex-col mb-4">
          <label for="device">{$_('search.deviceId')}:</label>
          <input
            class="mr-3 w-48"
            type="text"
            id="device"
            placeholder={$_('search.choose.deviceId')}
            bind:value={deviceId}
          />
        </div>
        <div class="flex flex-col mb-4">
          <label for="receipt">{$_('search.receiptNumber')}:</label>
          <input
            class="mr-3 w-48"
            type="text"
            id="receipt"
            placeholder={$_('search.choose.receiptNumber')}
            bind:value={receiptNumber}
          />
        </div>
      </div>
    </div>

    <Checkbox
      label={$_('search.showOnlyUnaccounted')}
      bind:checked={unaccounted}
      itemsToStart
    />
    <Checkbox label={$_('search.showOnlyAdded')} bind:checked={onlyAdded} itemsToStart />
    <button class="mt-4" on:click={search} disabled={!companies.length}>
      {$_('search.fetch')}
    </button>
  </Expandable>
</section>

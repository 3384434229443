<script>
  import { date, time } from 'svelte-i18n';

  export let command;
  $: commandDesc = command && command.data && command.data.commands;
  $: commands =
    commandDesc &&
    commandDesc.split('\n').map(it => {
      const [type, status, createdAt] = it.split(':');
      const time = parseInt(createdAt, 10);
      return [type, status, time ? new Date(time) : null];
    });
</script>

<style>
  td {
    padding: 0 0.5em;
  }
</style>

{#if commands && commands.length}
  <table>
    {#each commands as [type, status, createdAt]}
      <tr>
        <td>
          {#if createdAt}{$date(createdAt)} {$time(createdAt)}{:else}-{/if}
        </td>
        <td>{type}</td>
        <td>{status}</td>
      </tr>
    {/each}
  </table>
{/if}

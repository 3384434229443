<script>
  import EventReportSummary from './EventReportSummary.svelte';

  import { _, number } from 'svelte-i18n';
  import { dayLoaded, dayLoading, dayResults, dayInResultError, fetchDayReport } from '../../stores/travel_reports';
  import { INVOICE_PAYMENT, ONE_TIME_PAYMENT, TRAVELCARD } from '../../stores/search';
  import Loading from '../Loading.svelte';
  import LoadingError from '../LoadingError.svelte';
  import { showError } from '../../stores/notifications';
  import { createEventDispatcher } from 'svelte';
  export let day;
  export let companyId;
  export let start;
  export let end;
  export let lineId;
  export let departureId;
  export let tickets;
  export let deviceType;

  const dispatch = createEventDispatcher();

  const loadContent = () => {
    fetchDayReport(new Date(day.day).toISOString(), {
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId,
      departureId,
      companyId,
      tickets,
      deviceType,
    }).catch(showError($_('error.title')));
  };

  const reload = () => {
    loadContent();
  };

  if ($dayLoaded !== day.day) {
    loadContent();
  }

  const getProductId = result => {
    if (
      result.product_id &&
      result.product_id !== result.product_name &&
      result.product_name && 
      result.product_name.startsWith(TRAVELCARD)
    )
      return result.product_id;
    else {
      const alternate_id =
        result.prepurchased_ticket_product_id || result.external_product_id;
      if (alternate_id && alternate_id.startsWith('WALTTI:')) {
        return alternate_id.slice(7);
      } else {
        return alternate_id || '';
      }
    }
  };

  $: totalCount = $dayResults.reduce((acc, { quantity }) => acc + (quantity || 0), 0);
  $: totalSum = $dayResults.reduce((acc, { sum }) => acc + (sum || 0), 0);
  $: totalSumVatFree = $dayResults.reduce(
    (acc, { vat_free_price }) => acc + (vat_free_price || 0),
    0,
  );
</script>

<style>
  table {
    width: 100%;
    color: #333333;
    margin-top: 1em;
  }

  th {
    text-align: start;
    padding: 0 2em 0.3em 0;
    border-bottom: 1px solid #333333;
        font-weight: 600;
  }
  th.text-right {
    text-align: end;
  }

  th:last-child {
    padding-right: 0;
    text-align: end;
  }

  td {
    padding-top: 0.4em;
    padding-bottom: 0.6em;
    padding-right: 2em;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:last-child td {
    border-bottom: 2px solid #333333;
  }

  td:last-child {
    text-align: end;
    padding-right: 0;
  }

  .summary {
    display: flex;
    justify-content: space-between;
  }
</style>

<br />
{#if day.device}<b>Laite {day.device}</b> <br />{/if}
<b>{$_('travel.allTravelEvents')}</b> 
{#if $dayLoading }
  <div class="loading-content">
    <Loading />
  </div>
{:else if $dayInResultError}
  <LoadingError retryAction={reload} />
{:else}
  <table class="results" cellspacing="0">
    <tr>
      <th>{$_('travel.eventHour')}</th>
      <th>{$_('results.driver')}</th>
      <th>{$_('travel.origin')}</th>
      <th>{$_('travel.destination')}</th>
      <th class="text-right">{$_('travel.distance')}</th>
      <th>{$_('travel.ticketProduct')}</th>
      <th>{$_('results.productName')}</th>
      <th>{$_('travel.productId')}</th>
      <th class="text-right">{$_('results.vat')}</th>
      <th class="text-right">{$_('results.vatFreePrice')}</th>
      <th>{$_('results.priceVAT')}</th>
    </tr>
    {#each $dayResults as result}
      {#each { length: result.event_type.startsWith(TRAVELCARD) ? 1 : result.quantity } as _i}
        <tr>
          <td>{result.date.format('H:mm')}</td>
          <td>{result.driver_id || '-'}</td>
          <td
            >{result.departure_stop || '-'}{result.departure_stop_area
              ? ', ' + result.departure_stop_area
              : ''}</td
          >
          <td
            >{result.arrival_stop || '-'}{result.arrival_stop_area
              ? ', ' + result.arrival_stop_area
              : ''}</td
          >
          <td class="text-right">
            {result.travel_length || '-'}
          </td>
          <td>
            {$_(
              `results.productNames.${
                (result.event_type === ONE_TIME_PAYMENT || result.event_type === INVOICE_PAYMENT)
                  ? result.event_type
                  : result.product_name
              }`,
              { default: result.product_name },
            )}
            {#each result.services as service}
              <br />
              {$_(`travel.services.${service.name}`, { default: service })}
            {/each}
          </td>
          <td>
            {`${result.transfer > 0 ? 'VAIHTO, ' : ''}`}
            {`${result.target_group || '-'}${result.has_connection ? ', V' : ''}`}
            {#if result.zone}{' - '}{result.zone}{/if}
            {#if result.travel_card_id}<br /> {result.travel_card_id}{/if}
          </td>
          <td>{getProductId(result)}</td>
          <td class="text-right">{Math.round(result.vat)}</td>
          <td class="text-right">
            {$number(
              result.vat_free_price /
                (result.event_type.startsWith(TRAVELCARD) ? 1 : result.quantity),
              { format: 'EUR' },
            ).replace('€', '')}
          </td>
          <td>
            {$number(
              result.sum /
                (result.event_type.startsWith(TRAVELCARD) ? 1 : result.quantity),
              { format: 'EUR' },
            ).replace('€', '')}
            {#each result.services as service}
              <br />
              {#if service.price !== undefined}
                {$number(service.price, { format: 'EUR' }).replace('€', '')}
              {:else}-{/if}
            {/each}
          </td>
        </tr>
      {/each}
    {/each}
  </table>

  <div class="summary mt-2">
    <div>
      <p>{$_('results.totalCount').replace('%d', totalCount)}</p>
      <button class="mt-8" on:click={() => dispatch('excel')}>
        {$_('results.createExcel')}
      </button>
    </div>
    <EventReportSummary {totalSum} {totalSumVatFree} />
  </div>
{/if}

<script>
  import { afterUpdate } from 'svelte';

  let content;
  export let expanded = true;
  export let containerStyle = '';

  let height = !expanded ? 0 : undefined;

  const verifyHeight = () => {
    const currentHeight = !expanded || !content ? 0 : content.scrollHeight * 1.5;
    if (currentHeight != height) height = currentHeight;
  };

  afterUpdate(verifyHeight);
</script>

<style>
  .container {
    overflow: hidden;
    transition: max-height 250ms ease-in-out;
  }

  .container.expanded {
    overflow: visible;
  }
</style>

<div
  class="container"
  style={`${height !== undefined ? `max-height: ${height}px;` : ''}${containerStyle}`}
  class:expanded
>
  <div class="expandable-content" bind:this={content} on:resize={verifyHeight}>
    <slot />
  </div>
</div>

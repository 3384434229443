<script>
  import { getContext } from 'svelte';
  import {
    copyableTables,
    createBasicPriceTable,
    copyBasicPriceTable,
    createLineBasicPriceTable,
  } from '../../stores/pricing';
  import { companies as companyList } from '../../stores/companies';
  import { _ } from 'svelte-i18n';
  import RadioButton from '../RadioButton.svelte';
  import { currentUser } from '../../stores/user';
  import { getCompaniesByFeature } from '../../stores/features';

  $: features = ($currentUser && $currentUser.features) || [];
  $: companiesForPricing = getCompaniesByFeature(features, 'PRICING') || [];
  $: companyListFilteredByFeature = $companyList.filter(company => companiesForPricing.includes(company.number));
    
  const COPY_SELECTION_VALUE = 'copy';
  const CUSTOM_SELECTION_VALUE = 'custom';

  const { close } = getContext('simple-modal');

  export let companyId;
  export let onSuccess;
  export let existingNames = [];
  export let initial;
  let priceTableName = '';
  let selection;
  let copyLinesAndDeparturesSelection;
  let companySelection;
  let priceTableSelection;
  let copyableCompanies;
  let hasLines; 

  $: createTable = initial ? createBasicPriceTable : createLineBasicPriceTable;

  $: copyableCompanies = Object.keys($copyableTables).map(number =>
    $companyList.find(company => company.number === number),
  ).filter(it => it);

  $: {
    if (copyableCompanies.length === 1) {
      companySelection = copyableCompanies[0].number;
    } else if (!copyableCompanies.length) {
      selection = CUSTOM_SELECTION_VALUE;
    }
  }

  $: copyablePriceTables = companySelection ? $copyableTables[companySelection] : [];

  $: {
    if (selection === CUSTOM_SELECTION_VALUE) {
      companySelection = undefined;
    } else if (selection === COPY_SELECTION_VALUE && copyableCompanies.length === 1) {
      companySelection = copyableCompanies[0].number;
    }
  }

  $: {
    companySelection; //Execute when companySelection changes
    priceTableSelection = undefined;
  }

  $: {
     hasLines = (copyablePriceTables.find(item => item.name === priceTableSelection) || {}).hasLines;
  }
  $: continueEnabled =
    priceTableName.length &&
    !existingNames.includes(priceTableName) &&
    (selection === CUSTOM_SELECTION_VALUE || (companySelection && priceTableSelection)) &&
    (!hasLines || copyLinesAndDeparturesSelection !== undefined);
</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
  }
  p {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
    border-radius: 4px;
  }
  .input-container {
    width: 350px;
  }
  button {
    min-width: 150px;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
  .error {
    color: #d0021b;
    font-size: 0.9rem;
  }
</style>

<div>
  <h2>{$_('pricing.addNewBasicPricing')}</h2>
  <div class="input-container">
    <label for="name">{$_('pricing.name')}: *</label>
    <input type="text" name="name" id="name" bind:value={priceTableName} />
    {#if existingNames.includes(priceTableName)}
      <span class="error">{$_('pricing.errorPriceTableNameAlreadyExists')}</span>
    {/if}
  </div>

  {#if copyableCompanies.length}
    <div>
      <RadioButton
        label={$_('pricing.basicPricingFromExisting')}
        value={COPY_SELECTION_VALUE}
        bind:group={selection}
      />
      <RadioButton
        label={$_('pricing.basicPricingFromScratch')}
        value={CUSTOM_SELECTION_VALUE}
        bind:group={selection}
      />
    </div>
  {/if}

  {#if selection === COPY_SELECTION_VALUE}
    {#if copyableCompanies.length > 1}
      <p>{$_('pricing.basicPricingCopySelectCompany')}</p>
      <div>
        {#each copyableCompanies as copyableCompany}
          <RadioButton
            label={copyableCompany.name}
            value={copyableCompany.number}
            bind:group={companySelection}
          />
        {/each}
      </div>
    {/if}

    {#if companySelection}
      <p>{$_('pricing.basicPricingCopySelectPriceTable')}</p>
      <div>
        {#each copyablePriceTables as copyablePriceTable}
          <RadioButton
            label={copyablePriceTable.default
              ? `${copyablePriceTable.name} ${$_('pricing.basicPricingDefaultTag')}`
              : copyablePriceTable.name}
            value={copyablePriceTable.default ? 'default' : copyablePriceTable.name}
            bind:group={priceTableSelection}
            on:change={() => { copyLinesAndDeparturesSelection = undefined; }}
          />
        {/each}
      </div>
    {/if}

    {#if priceTableSelection && hasLines}
      <p>{$_('pricing.copyLinesAndDepartures')}: *</p>

      <div>
        <RadioButton
          label={$_('pricing.yes')}
          value={true}
          bind:group={copyLinesAndDeparturesSelection}
        />
        <RadioButton
          label={$_('pricing.no')}
          value={false}
          bind:group={copyLinesAndDeparturesSelection}
        />
      </div>
    {/if}
  {/if}

  <div class="button-container">
    <button
      class="small"
      disabled={!continueEnabled}
      on:click={async () => {
        if (selection === CUSTOM_SELECTION_VALUE) {
          await createTable(companyId, priceTableName);
        } else {
          await copyBasicPriceTable(
            companySelection,
            companyId,
            priceTableSelection,
            priceTableName,
            initial,
            copyLinesAndDeparturesSelection
          );
        }
        onSuccess(priceTableName);
        close();
      }}
    >
      {$_('pricing.continue')}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        close();
      }}
    >
      {$_('pricing.cancel')}
    </button>
  </div>
</div>

<script>
  import { number, _ } from 'svelte-i18n';
  import PassengerIcon from '../images/Passenger.svelte';
  import { searchLoaded, searchLoading } from '../../stores/travel_reports';
  export let products = [];
  export let maxShifts;

  let count = {};
  $: totalCount = Object.values(count).length;
  $: totalSum = products && products.reduce((acc, { sum: cur }) => acc + Number(cur), 0);
  $: totalQuantity =
    products && products.reduce((acc, { boarding: cur }) => acc + Number(cur), 0);

  const getCount = products => {
    const count = {};
    products.forEach(product => {
      count[product['line_start_date']] = 1 + (count[product['line_start_date']] || 0);
    });
    return count;
  };
  $: count = getCount(products);
</script>

<style>
  .table-results th {
    font-weight: normal;
        padding-bottom: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
  }

  .table-results td {
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    padding-right: 3rem;
  }

  .final {
    border-color: #cccccc;
  }

  .table-results :not(:first-child) :not(:last-child) {
    border-top: 1px solid #cccccc;
  }

  .table-results span {
    font-weight: normal;
      }

  th,
  td {
    border-color: #cccccc;
  }

  td.text-right {
    padding-right: 3em;
  }
</style>

{#if products.length && !$searchLoading && $searchLoaded}
  <tr class="bg-mlight table-results">
    <th colspan="1" />
    <th class="text-left">
      <div class="inline-flex">
        <span>{$_('travel.ticketProduct')}</span>
      </div>
    </th>

    <th class="text-right">
      <div class="inline-flex">
        <PassengerIcon width="20px" height="20px" />
        <span>{$_('travel.total')}</span>
      </div>
    </th>
    <th>
      <div class="inline-flex">
        <PassengerIcon width="20px" height="20px" />
        <span> {$_('travel.totalSumVatFree')}</span>
      </div>
    </th>
    <th colspan="4" />
  </tr>

  {#each products as product, i}
    {#if product.target_group}
      <tr class="bg-mlight">
        <td colspan="1" />
        <td class="border-t">
          {#if product.target_group}
            {product.target_group}
          {/if}
        </td>
        <td class="text-right border-t">
          {product.boarding || '-'}
        </td>
        <td class="text-right border-t">
          {$number(product.sum, { format: 'EUR' }).replace('€', '') || '-'}
        </td>
        <td colspan="4" />
      </tr>
    {/if}
  {/each}
  <tr class="final table-results text-mdark bg-mlight border-b">
    <td colspan="1" />
    <td class="font-semibold border-t">
      {$_('travel.totalLinesPerDayPerTotal', { values: { totalCount, maxShifts } })}
    </td>
    <td class="text-right font-semibold border-t">{totalQuantity || '0'}</td>
    <td class="text-right font-semibold border-t">
      {$number(totalSum, { format: 'EUR' }).replace('€', '') || '0'}
    </td>
    <td colspan="4" />
  </tr>
{/if}

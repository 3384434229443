<script>
  import { getContext } from 'svelte';
  import {
    prices,
    createSpecialPricing,
    copySpecialPricing,
    SPECIAL_PRICING_TYPE_DISTANCE,
    SPECIAL_PRICING_TYPE_PRICE,
  } from '../../stores/pricing';
  import { showSpinnerOverlay, dismissSpinnerOverlay } from '../../stores/spinner';
  import { _ } from 'svelte-i18n';
  import { readPriceTableFile, ErrorCodes } from '../../common/file';
  import RadioButton from '../RadioButton.svelte';
  import FileInput from '../FileInput.svelte';
  import Select from '../Select.svelte';

  const FILE_SELECT_VALUE = 'browse';

  const PRICING_TYPES = [
    {
      value: SPECIAL_PRICING_TYPE_DISTANCE,
      label: $_('pricing.specialDistancePricingTable'),
    },
    {
      value: SPECIAL_PRICING_TYPE_PRICE,
      label: $_('pricing.specialConnectionPricingTable'),
    },
  ];

  const { close } = getContext('simple-modal');

  export let companyId;
  export let onSuccess;
  let priceTableName = '';
  let selection;
  let files;
  let type;
  let processing = false;
  let fileError;
  let copyLinesAndDeparturesSelection;
  let hasLines; 

  $: pricingTablesExist =
    Object.keys($prices[companyId].specialPricings || {}).length > 0;
  $: existingNames = Object.keys($prices[companyId].specialPricings || {});
  
  $: {
    const currentPriceTable = (Object.values(($prices[companyId].specialPricings || {})).find(item => item.name === selection) || {});
    hasLines = currentPriceTable && currentPriceTable.lines && currentPriceTable.lines.length > 0;
  }
  

  $: proceedEnabled =
    priceTableName.length > 0 &&
    !existingNames.includes(priceTableName) &&
    ((!pricingTablesExist && files) ||
    (selection && (selection !== FILE_SELECT_VALUE || files))) &&
    (!hasLines || copyLinesAndDeparturesSelection !== undefined);


  const parseFile = async file => {
    processing = true;
    try {
      const { stations, distances } = await readPriceTableFile(file);
      await createSpecialPricing(companyId, priceTableName, type, stations, distances);
    } catch (error) {
      switch (error.code) {
        case ErrorCodes.FILE_READ_FAILED:
          fileError = $_('pricing.errorFileReadingFailed');
          break;
        case ErrorCodes.FILE_PARSING_FAILED:
        case ErrorCodes.FILE_DATA_INVALID:
          fileError = $_('pricing.errorFileDataNotValid');
          break;
        default:
          break;
      }
      processing = false;
      throw error;
    }
  };
</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  input {
    width: 100%;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
    border-radius: 4px;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
  }
  .input-container {
    width: 350px;
  }
  button {
    min-width: 135px;
  }
  p.file-title {
    padding: 0;
    margin: 0;
  }
  .file-container {
    padding-bottom: 3rem;
    padding-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .indent-left {
    padding-top: 0;
    padding-bottom: 2.5rem;
    margin-left: 35px;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
  .error {
    color: #d0021b;
    font-size: 0.9rem;
  }
</style>

<div>
  <h2>{$_('pricing.addNewSpecialPriceTable')}</h2>
  <div class="input-container">
    <label for="name">{$_('pricing.name')}: *</label>
    <input type="text" name="name" id="name" bind:value={priceTableName} />
    {#if existingNames.includes(priceTableName) && !processing}
      <span class="error">{$_('pricing.errorPriceTableNameAlreadyExists')}</span>
    {/if}
  </div>
  <div class="input-container">
    <label for="type">{$_('pricing.specialPricingType')}:</label>
    <Select
      id="type"
      entries={PRICING_TYPES}
      border={true}
      bind:value={type}
      disabled={selection && selection !== FILE_SELECT_VALUE}
    />
  </div>
  <div>
    {#if pricingTablesExist}
      <RadioButton
        label={$_('pricing.chooseFileForSpecialPricing')}
        value={FILE_SELECT_VALUE}
        bind:group={selection}
      />
      <div class="indent-left">
        <FileInput
          disabled={selection !== FILE_SELECT_VALUE}
          accept=".xls,.txt,.tsv,.csv"
          bind:files
          bind:error={fileError}
        />
      </div>
      <p>{$_('pricing.chooseOtherTableForSpecialPricing')}</p>
      
      {#each Object.values($prices[companyId].specialPricings) as entry}
        <RadioButton
          label={entry.name}
          value={entry.name}
          bind:group={selection}
          on:change={() => {
            type = entry.specialPricingType;
            copyLinesAndDeparturesSelection = undefined;
          }}
        />
      {/each}
      {#if selection && selection !== FILE_SELECT_VALUE && hasLines}
        <p>{$_('pricing.copyLinesAndDepartures')}</p>

        <div>
          <RadioButton
            label={$_('pricing.yes')}
            value={true}
            bind:group={copyLinesAndDeparturesSelection}
          />
          <RadioButton
            label={$_('pricing.no')}
            value={false}
            bind:group={copyLinesAndDeparturesSelection}
          />
        </div>
      {/if}
    {:else}
      <p class="file-title">{$_('pricing.chooseFileForSpecialPricing')}</p>
      <div class="file-container">
        <FileInput accept=".xls,.txt,.tsv,.csv" bind:files bind:error={fileError} />
      </div>
    {/if}
  </div>
  <div class="button-container">
    <button
      class="small"
      disabled={!proceedEnabled}
      on:click={async () => {
        try {
          showSpinnerOverlay();
          if (selection === FILE_SELECT_VALUE || !pricingTablesExist) {
            await parseFile(files[0]);
          } else {
            await copySpecialPricing(companyId, selection, priceTableName, copyLinesAndDeparturesSelection);
          }
          close();
          onSuccess(priceTableName);
        } catch (error) {
          console.log(error);
        } finally {
          dismissSpinnerOverlay();
        }
      }}
    >
      {$_('pricing.continue')}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        close();
      }}
    >
      {$_('pricing.cancel')}
    </button>
  </div>
</div>

<script>
  import { _ } from 'svelte-i18n';
  import ConfirmableInlineInput from '../../components/ConfirmableInlineInput.svelte';

  export let editingMode;
  export let item;
  export let index;
  export let onChange;
  export let onDeleteRow;
</script>

<style>
  td:not(:nth-child(3)) {
    min-width: 160px;
  }
  td {
    border-bottom: solid 1px #D1D1D2;
    padding: 0.75rem 1rem;
  }
  td:nth-child(3) {
    text-align: right;
  }
  td.editable {
    padding: 0rem calc(0.6rem - 1px);
    background-color: #eef5fb;
  }
  .text-button {
    padding: 0.75rem 1rem;
    color: #001E61;
    cursor: pointer;
  }
  .text-button:hover {
    color: #173043;
  }
</style>

{#if editingMode}
  <td class="editable">
    <ConfirmableInlineInput
      inputId={{ id: index, field: 'line' }}
      type="text"
      value={item.line  === '0000' ? '' : item.line}
      maxlength={4}
      allowEmpty={true}
      on:complete={onChange}
    />
  </td>
  <td class="editable">
    <ConfirmableInlineInput
      inputId={{ id: index, field: 'departure' }}
      type="text"
      value={item.departure === '0000' ? '' : item.departure}
      maxlength={4}
      allowEmpty={true}
      on:complete={onChange}
    />
  </td>
  <td class="editable">
    <ConfirmableInlineInput
      inputId={{ id: index, field: 'name' }}
      type="text"
      value={item.name}
      maxlength={1000}
      allowEmpty={true}
      on:complete={onChange}
    />
  </td>  
  <td
    on:click={() => onDeleteRow(item, index)}
    class="text-button remove"
  >
    {$_('pricing.remove')}
  </td>
{:else}
  <td>{item.line === '0000' ? '' : item.line}</td>
  <td>{item.departure === '0000' ? '' : item.departure}</td>
  <td>{!item.name ? '' : item.name}</td>
{/if}

<script>
  import { flip } from 'svelte/animate';
  import { fade } from 'svelte/transition';

  export let steps;
  export let currentStep;
  export let sticky = false;

</script>

<style>
  div.inner {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  div.step {
    position: relative;
    color: rgb(158, 166, 180);
    padding-left: 24px;
    padding-bottom: 16px;
    width: 100%;
    height: auto;
    margin: 0px;
    background: transparent;
  }
  div.step::before {
    position: absolute;
    top: 4px;
    left: 0px;
    z-index: 2;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: white;
    border-color: rgb(206, 210, 217);
    border-width: 2px;
    border-radius: 99rem;
    border-style: solid;
    box-shadow: 0 0 0 2px white
  }
  div.step::after {
    position: absolute;
    left: 8px;
    top: 4px;
    bottom: -4px;
    margin-left: -1px;
    z-index: 1;
    content: '';
    display: block;
    background: rgb(206, 210, 217);
    width: 2px;
  }
  div.step:last-child::after {
    display: none;
  }
  div.step.current, div.step.completed {
    color: #06324C;
  }
  div.step.current::before {
    border-color: #06324C;
  }
  div.step.completed::before {
    background: #06324C;
    border-color: #06324C;
  }
  div.step.completed::after {
    background: #06324C;
  }
  .sticky-progress {
    position: sticky;
    top: 0
  }
</style>

<div class:sticky-progress={sticky}>
  <div class="inner">
    {#each steps as step, index (step.id || index)}
    <div transition:fade={{ duration: 100 }} animate:flip={{ duration: 300 }} class="step" class:current={index == currentStep} class:completed={index < currentStep}>{step.title}</div>
    {/each}
  </div>
</div>

import Axios from 'axios';
import axiosRetry from 'axios-retry';
import { BASE_URL, API_KEY } from './constants';
import { logout, refreshToken } from '../stores/user';

let _client;

const MAX_RETRIES = 3;

function init() {
  _client = Axios.create({
    baseURL: BASE_URL,
    headers: {
      'x-api-key': API_KEY,
    },
    withCredentials: true,
  });

  axiosRetry(_client, { retries: MAX_RETRIES });
  _client.interceptors.response.use({}, errorHandling);
}

export function getClient() {
  if (!_client) init();
  return _client;
}

const errorHandling = err => {
  if (!err) {
    return;
  }
  const response = err.response;

  if (!response) return Promise.reject(err);

  if (response.status === 401 && err.config.url.endsWith('/auth/refresh')) {
    logout().catch(console.error);
    return Promise.reject(err);
  } else if (response.status === 401 && err.config.url.endsWith('/auth/logout')) {
    return Promise.reject(err);
  } else if (response.status === 401) {
    delete err.config.headers;
    return refreshToken().then(() => getClient().request(err.config));
  }

  if (!response.data) return Promise.reject(err);

  const data = response.data;

  return Promise.reject(
    data.error ? new Error(data.error) : { data, status: response.status },
  );
};

export function setAuthHeader(token) {
  _client = Axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: token,
      'x-api-key': API_KEY,
    },
    withCredentials: true,
  });

  axiosRetry(_client, { retries: MAX_RETRIES });
  _client.interceptors.response.use({}, errorHandling);
}

export function removeAuthHeader() {
  _client = Axios.create({
    baseURL: BASE_URL,
    headers: {
      'x-api-key': API_KEY,
    },
    withCredentials: true,
  });

  axiosRetry(_client, { retries: MAX_RETRIES });
  _client.interceptors.response.use({}, errorHandling);
}

export function getPregeneratedReport(filename, company) {
  return _client.get(`api/reports/monthly/${company}/${filename}`);
}

export function getExcelDownload(params, filter) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.post('api/reports/export', filter || {}, {
    params,
  });
}

export function getAccountingReportDownload(params, filter) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.post('api/reports/accounting/export', filter || {}, {
    params,
  });
}

export function getAccountedReportDownload(params, filter) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.post('api/reports/accounted/export', filter || {}, {
    params,
  });
}

export function getExcelDownloadDay(company, params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/getReportByCompany/${company}/export/daily`, {
    params,
  });
}

export function getExcelDownloadStop(company, params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/getReportByCompany/${company}/export/stop`, {
    params,
  });
}

export function getExcelDownloadDevicesList(params) {
  return _client.post(`api/devices/export`, {
    params
  });
}

// Not currently in used -> if stopOpened report needs generating
export function getExcelDownloadStopOpened(company, params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  return _client.get(`api/travelers/getReportByCompany/${company}/export/stopOpened`, {
    params,
  });
}

export function getExcelDownloadEvent(company, params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/getReportByDay/${company}/export`, {
    params,
  });
}

export function getExcelDownloadLines(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/lines/export`, {
    params,
  });
}

export function getExcelDownloadGeneral(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/export`, {
    params,
  });
}

export function getExcelDownloadProducts(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/products/export`, {
    params,
  });
}

export function getExcelDownloadGroupedLines(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/grouped_lines/export`, {
    params,
  });
}

export function getExcelDownloadGroupedLinesNoSales(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/grouped_nosales/lines/export`, {
    params,
  });
}

export function getExcelDownloadGroupedEventsNoSales(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/grouped_nosales/events/export`, {
    params,
  });
}

export function getExcelDownloadGroupedNoSales(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/grouped_nosales/export`, {
    params,
  });
}

export function getExcelDownloadGeneralByDay(params) {
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  return _client.get(`api/travelers/days/export`, {
    params,
  });
}

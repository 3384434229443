<script>
  import { _ } from 'svelte-i18n';
  import Icon from 'fa-svelte';
  import { faCheck } from '@fortawesome/free-solid-svg-icons';
  import { changePassword, confirmChangePassword, signIn } from '../../stores/user';
  import { showError, showNotification } from '../../stores/notifications';
  import { createEventDispatcher } from 'svelte';
  import {
    getPasswordPolicy,
    passwordPolicy,
    passwordPolicyLoaded,
  } from '../../stores/users';

  const dispatch = createEventDispatcher();

  export let email = '';
  export let needConfirmation = false;
  export let session = '';
  export let confirmationCode = '';
  export let encryptedData = '';
  let password = '';
  let password2 = '';

  let loading = false;

  let error = '';

  let needChanges = false;

  const change = async () => {
    if (loading) return;
    loading = true;
    error = '';
    try {
      if (needConfirmation || encryptedData)
        await confirmChangePassword(email, password, confirmationCode, encryptedData);
      else await changePassword(email, password, session);
      if (email) await signIn(email, password);
      else {
        showNotification({
          title: $_('login.passwordChanged'),
          type: 'success',
        });
        dispatch('changed');
      }
    } catch (e) {
      error = e.message || $_('login.error');
      //needChanges = !e.retryable;
      console.error(e);
    } finally {
      loading = false;
    }
  };

  if (!$passwordPolicyLoaded) {
    getPasswordPolicy().catch(showError($_('error.title')));
  }

  $: minimumLength =
    $passwordPolicy &&
    $passwordPolicy.MinimumLength &&
    password.length < $passwordPolicy.MinimumLength;
  $: requiredLength = $passwordPolicy && $passwordPolicy.MinimumLength;
  $: requiresUppercase =
    $passwordPolicy && $passwordPolicy.RequireUppercase && !/[A-Z]/.test(password);
  $: requiresLowercase =
    $passwordPolicy && $passwordPolicy.RequireLowercase && !/[a-z]/.test(password);
  $: requiresNumbers =
    $passwordPolicy && $passwordPolicy.RequireNumbers && !/\d/.test(password);
  $: requiresSymbols =
    $passwordPolicy &&
    $passwordPolicy.RequireSymbols &&
    !/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);

  $: invalidPassword =
    minimumLength ||
    requiresUppercase ||
    requiresLowercase ||
    requiresNumbers ||
    requiresSymbols ||
    password !== password2;
</script>

<style>
  .content {
    background: #fff;
    padding: 0 1.5rem 2.5rem 1.5rem;
    margin-top: 3rem;
    max-width: 23rem;
    display: grid;
    display: -ms-grid;
  }
  @supports (-ms-ime-align: auto) {
    .content {
      display: flex;
      flex-direction: column;
    }
  }

  input {
    width: 100%;
    height: 2rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  h1 {
    color: #001E61;
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }

  .actions > button {
    padding: 0.6em 3.4em;
  }

  .actions {
    min-width: 20rem;
    font-size: 0.8rem;
    padding-top: 1rem;
  }

  label {
    font-size: 0.8rem;
  }

  span {
    font-size: 0.8rem;
  }

  .password-policy {
    list-style: none;
    padding: 0;
    font-size: 0.8em;
  }

  li {
    display: flex;
    color: #001E61;
    align-items: baseline;
    margin: 0.5em 0;
  }

  .icon {
    margin-right: 1em;
  }

  .icon :global(*) {
    color: #001E61;
  }

  .incorrect .icon :global(*) {
    opacity: 0;
  }

  .incorrect {
    color: #333333;
  }

  .error {
    background: #ffbbd9;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 1rem;
  }
</style>

<form
  class="content"
  on:submit|preventDefault={change}
  on:change={() => (needChanges = false)}
>
  <h1 class="mb-6 text-3xl font-bold">{$_('login.change')}</h1>
  <span>
    {$_(needConfirmation ? 'login.forgotten_instr' : 'login.change_instr')}
  </span>
  {#if needConfirmation}
    <label for="confirmationCode" style="padding-top: 2.8rem;">
      {$_('login.confirmationCode')}
    </label>
    <input
      type="text"
      id="confirmationCode"
      bind:value={confirmationCode}
      autocomplete="off"
    />
  {/if}
  <label for="password" style="padding-top: 1rem;">
    {$_('login.newPassword')}
  </label>
  <input
    type="password"
    id="password"
    autocomplete="current-password"
    bind:value={password}
  />
  <label for="password2" style="padding-top: 1rem;">
    {$_('login.retypeNewPassword')}
  </label>
  <input
    type="password"
    id="password2"
    autocomplete="current-password"
    bind:value={password2}
  />
  <ul class="password-policy">
    <li class:incorrect={minimumLength}>
      <div class="icon">
        <Icon icon={faCheck} />
      </div>
      {$_('users.editor.minimumLength').replace(/\{1\}/, requiredLength || 8)}
    </li>
    <li class:incorrect={requiresUppercase}>
      <div class="icon">
        <Icon icon={faCheck} />
      </div>
      {$_('users.editor.requireUppercase')}
    </li>
    <li class:incorrect={requiresLowercase}>
      <div class="icon">
        <Icon icon={faCheck} />
      </div>
      {$_('users.editor.requireLowercase')}
    </li>
    <li class:incorrect={requiresNumbers}>
      <div class="icon">
        <Icon icon={faCheck} />
      </div>
      {$_('users.editor.requireNumbers')}
    </li>
    <li class:incorrect={requiresSymbols}>
      <div class="icon">
        <Icon icon={faCheck} />
      </div>
      {$_('users.editor.requireSymbols')}
    </li>
  </ul>
  {#if error}
    <span class="error">{error}</span>
  {/if}
  <div class="actions">
    <button
      type="submit"
      disabled={loading ||
        invalidPassword ||
        needChanges ||
        (!confirmationCode && needConfirmation)}
    >
      {$_('login.action')}
    </button>
  </div>
</form>

import { writable, get } from 'svelte/store';
import { _ } from 'svelte-i18n';
import { getClient } from '../common/http';
import { showSpinnerOverlay, dismissSpinnerOverlay } from '../stores/spinner';
import { showNotification } from '../stores/notifications';
import moment from 'moment-timezone';
import QueryString from 'query-string';
import { currentUser } from './user';

export const searchLoaded = writable(false);
export const searchLoading = writable(false);
export const searchResults = writable([]);
export const searchTerms = writable({});

currentUser.subscribe(user => {
  if (!user || !user.loggedIn) {
    searchTerms.set({});
    searchResults.set([]);
    searchLoaded.set(false);
    searchLoading.set(false);
  }
});

export const CASH = 'CASH';
export const CARD = 'CARD';
export const MULTIPLE = 'MULTIPLE';
export const ONE_TIME_PAYMENT = 'TICKET_SALE';
export const INVOICE_PAYMENT = 'INVOICE_TICKET';
export const ROUNDING = 'ROUNDING';
export const TRAVELCARD = 'TRAVEL_CARD';
export const VALUE_CARD = 'VALUE_CARD';
export const WALTT_TRAVEL_CARD = 'WALTTI_TRAVEL_CARD';
export const CREDIT_TICKET = 'CREDIT_TICKET';
export const TRAVELCARD_SALE = 'TRAVEL_CARD_SALE';
export const PRODUCT_SALE = 'PRODUCT_SALE';
export const ONLINE_TICKET = 'ONLINE_TICKET';
export const NO_SALE = 'NO_SALE';

export function isTravelcard(eventType) {
  if (!eventType) return false;
  return (
    eventType.startsWith(TRAVELCARD) ||
    eventType.startsWith(VALUE_CARD) ||
    eventType.startsWith(CREDIT_TICKET) ||
    eventType.startsWith(WALTT_TRAVEL_CARD)
  );
}

export async function startSearch(params) {
  searchLoaded.set(false);
  searchLoading.set(true);
  searchResults.set([]);
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;

  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  const res = await getClient().get('/api/reports/search', { params });
  if (res.status === 200) {
    searchLoaded.set(true);
    searchLoading.set(false);
    searchResults.set(
      res.data.map(({ company, reports }) => ({
        company,
        reports: reports.map(value => ({
          ...value,
          date: moment.tz(value.timeStamp || value.date, 'Europe/Helsinki'),
          refundDate: moment.tz(value.refund_date, 'Europe/Helsinki'),
        })),
      })),
    );
  } else {
    searchLoaded.set(false);
    searchLoading.set(false);
    searchResults.set([]);
  }
}

export async function markRowsAccounted(transactions) {
  showSpinnerOverlay();
  try {
    await getClient().post('/api/reports/accounted/mark', {
      transactionIds: transactions.map(x => x.transaction_id),
    });
    searchResults.update(current => {
      transactions.forEach(transaction => {
        const companyEntries = current.find(
          entry => entry.company === transaction.company_id,
        );
        if (companyEntries && companyEntries.reports) {
          const transactionEntry = companyEntries.reports.find(
            entry => entry.transaction_id === transaction.transaction_id,
          );
          if (transactionEntry) {
            transactionEntry.accounted = true;
          }
        }
      });
      return current;
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

export async function unmarkRowsAccounted(transactions) {
  showSpinnerOverlay();
  try {
    await getClient().post('/api/reports/accounted/unmark', {
      transactionIds: transactions.map(x => x.transaction_id),
    });
    searchResults.update(current => {
      transactions.forEach(transaction => {
        const companyEntries = current.find(
          entry => entry.company === transaction.company_id,
        );
        if (companyEntries && companyEntries.reports) {
          const transactionEntry = companyEntries.reports.find(
            entry => entry.transaction_id === transaction.transaction_id,
          );
          if (transactionEntry) {
            transactionEntry.accounted = false;
          }
        }
      });
      return current;
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

function toReportResult(result) {
  return {
    transaction_id: result.transactionId,
    receipt_number: result.receiptNumber,
    accounted: result.accounted,
    company_id: result.companyId,
    line_id: result.lineId,
    departure_id: result.departureId,
    device_id: result.deviceId,
    driver_id: result.driverId,
    payment_method: result.paymentMethod,
    event_type: result.eventType,
    product_name: result.productName,
    sum: result.sum,
    item_price: result.itemPrice,
    quantity: result.quantity,
    vat: result.vat,
    original_receipt_number: result.originalReceiptNumber,
    original_transaction_id: result.originalTransactionId,
    date: result.date,
    refundDate: result.refundDate,
  };
}

export async function cancelRow(transaction) {
  showSpinnerOverlay();
  try {
    const { data: result } = await getClient().post(
      `/api/reports/cancel?${QueryString.stringify({
        transactionId: transaction.transaction_id,
      })}`,
    );
    if (result) {
      result.date = moment.tz(result.timeStamp, 'Europe/Helsinki');
      result.refundDate = moment.tz(result.refundTimeStamp, 'Europe/Helsinki');
      searchResults.update(current => {
        const companyEntries = current.find(
          entry => entry.company === transaction.company_id,
        );
        if (companyEntries && companyEntries.reports) {
          companyEntries.reports = [
            ...companyEntries.reports,
            toReportResult(result),
          ].sort((a, b) => {
            // sort events so that refund event is after original event
            const dateA = new Date(a.timeStamp || a.date).toISOString();
            const dateB = new Date(b.timeStamp || b.date).toISOString();
            const secondaryA =
              (a.original_receipt_number && a.original_receipt_number + 'x') ||
              '' + a.receipt_number;
            const secondaryB =
              (b.original_receipt_number && b.original_receipt_number + 'x') ||
              '' + b.receipt_number;
            return dateA.localeCompare(dateB) || secondaryA.localeCompare(secondaryB);
          });
        }
        return current;
      });
    }
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

export async function postRow(row, id, params) {
  showSpinnerOverlay();
  try {
    const res = await getClient().post(
      id ? `/api/reports/row/${id}` : '/api/reports/row',
      row,
      { params },
    );
    if (res.status !== 200) {
      dismissSpinnerOverlay();
      return showNotification({
        title: get(_)(id ? 'results.addRowFailed' : 'results.editRowFailed'),
        body: '',
        type: 'error',
      });
    }
    const data = res.data;
    searchResults.update(value => {
      const report = value.find(it => it.company === params.company);
      report.reports = id
        ? report.reports.map(it =>
            it.id === id
              ? {
                  ...row,
                  id,
                  isAddedRow: true,
                  date: moment.tz(params.end, 'Europe/Helsinki'),
                }
              : it,
          )
        : [
            ...report.reports,
            {
              ...row,
              id: data.id,
              isAddedRow: true,
              date: moment.tz(params.end, 'Europe/Helsinki'),
            },
          ];
      return [...value];
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

export async function markCommentsAsAccounted(comments, params) {
  const company = params.company;
  showSpinnerOverlay();
  try {
    await getClient().post(
      '/api/reports/accounted/rows/mark',
      {
        ids: comments.map(x => x.id),
      },
      { params },
    );
    searchResults.update(current => {
      comments.forEach(comment => {
        const companyEntries = current.find(entry => entry.company === company);
        if (companyEntries && companyEntries.reports) {
          const commentEntry = companyEntries.reports.find(
            entry => entry.id === comment.id,
          );
          if (commentEntry) {
            commentEntry.accounted = true;
          }
        }
      });
      return current;
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

export async function unmarkCommentsAsAccounted(comments, params) {
  const company = params.company;
  showSpinnerOverlay();
  try {
    await getClient().post(
      '/api/reports/accounted/rows/unmark',
      {
        ids: comments.map(x => x.id),
      },
      { params },
    );
    searchResults.update(current => {
      comments.forEach(comment => {
        const companyEntries = current.find(entry => entry.company === company);
        if (companyEntries && companyEntries.reports) {
          const commentEntry = companyEntries.reports.find(
            entry => entry.id === comment.id,
          );
          if (commentEntry) {
            commentEntry.accounted = false;
          }
        }
      });
      return current;
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

export async function cancelAddedRow(id, params) {
  showSpinnerOverlay();
  try {
    await getClient().delete(`/api/reports/cancel/row`, {
      params: { ...params, id },
    });
    searchResults.update(value => {
      const report = value.find(it => it.company === params.company);
      report.reports = report.reports.filter(it => it.id !== id);
      return [...value];
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
}

<script>
  import RadioButtonLightBlue from '../RadioButtonLightBlue.svelte';
  import { showError, showNotification } from '../../stores/notifications';
  import { _ } from 'svelte-i18n';
  import {
    sendCommand,
    createRequestAndSendCommand,
  } from '../../stores/support_requests';
  import { showSpinnerOverlay, dismissSpinnerOverlay } from '../../stores/spinner';
  import ConfirmationModal from '../ConfirmationModal.svelte';
  import { getContext } from 'svelte';

  const { open } = getContext('simple-modal');

  const COMMANDS = [
    { id: 'REMOTE_FORCE_LOGOUT_USER_COMMAND', isValid: () => true },
    { id: 'REMOTE_RESET_DEVICE_COMMAND', isValid: () => true },
    { id: 'REMOTE_RESET_AND_DEACTIVATE_DEVICE_COMMAND', isValid: () => true },
    { id: 'REMOTE_LIST_COMMANDS_COMMAND', isValid: () => true },
    { id: 'REMOTE_DUMP_DEBUG_SETTINGS', isValid: () => true },
  ];

  export let command = '';

  export let showHeader = true;
  export let deviceId = '';
  export let commandSelection = {};
  export let onSend = () => {
    handleExecute();
  };

  const getSelectedCommand = (commands, command) =>
    commands.find(({ id }) => id === command);

  const specialCommand = command => {
    switch (command) {
      case 'DELETE_COMMAND_BY_ID_COMMAND': {
        return {
          id: 'DELETE_COMMAND_BY_ID_COMMAND',
          args: { cmdId: (commandSelection.ids || []).join(',') },
          isValid: () => (commandSelection.ids || []).length > 0,
        };
      }
      case 'DELETE_COMMANDS_BY_TYPE_COMMAND': {
        return {
          id: 'DELETE_COMMANDS_BY_TYPE_COMMAND',
          args: { commandType: (commandSelection.commandTypes || []).join(',') },
          isValid: () => (commandSelection.commandTypes || []).length > 0,
        };
      }
    }
  };

  $: selectedCommand = getSelectedCommand(COMMANDS, command) || specialCommand(command);
  $: isValidSelecton = (
    getSelectedCommand(COMMANDS, command) ||
    specialCommand(command) || { isValid: () => false }
  ).isValid();
  export let createRequest = false;

  const handleError = showError($_('error.title'));

  const handleExecute = async () => {
    if (createRequest) {
      showSpinnerOverlay();
      try {
        await createRequestAndSendCommand(selectedCommand.id, deviceId);
      } catch (error) {
        if (error.status === 404) {
          showNotification({
            title: $_('supportRequests.deviceNotFound'),
            body: '',
            type: 'error',
          });
        } else {
          handleError(error);
        }
      } finally {
        dismissSpinnerOverlay();
      }
    } else {
      try {
        await sendCommand(selectedCommand.id, deviceId, selectedCommand.args);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const showCancelConfirmationModal = onConfirmToExecute => {
    open(ConfirmationModal, {
      title: $_('supportRequests.confirmExecuteTitle'),
      body:
        $_('supportRequests.confirmExecuteBody')
          .replace(/\{1\}/, deviceId)
          .replace(/\{2\}/, selectedCommand.id) +
        (selectedCommand.args
          ? '\n' +
            $_('supportRequests.confirmExecuteParams').replace(
              /\{1\}/,
              JSON.stringify(selectedCommand.args),
            )
          : ''),
      onConfirm: () => {
        onConfirmToExecute();
      },
    });
  };
</script>

<style>
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  label[for='device-id'] {
    margin-top: 2em;
    margin-bottom: 0.5em;
  }

  #device-id {
    margin-bottom: 2.5em;
    padding: 1em 0.5em;
    min-width: 12em;
    border-radius: 5px;
    font-size: 16px;
  }

  button {
    margin-top: 2.5em;
    min-width: 11em;
  }
</style>

<div>
  {#if showHeader}
    <h3>{$_('supportRequests.sendCommand')}</h3>
    <label for="device-id">{$_('supportRequests.writeDeviceId')}:</label>
    <input
      type="text"
      id="device-id"
      bind:value={deviceId}
      placeholder={$_('supportRequests.deviceId')}
    />
  {/if}
  <div>
    {#each COMMANDS as it}
      <RadioButtonLightBlue
        label={$_(`supportRequests.commands.${it.name || it.id}`, {
          default: it.name || it.id,
        })}
        value={it.id}
        bind:group={command}
      />
    {/each}

    <RadioButtonLightBlue
      label={$_(`supportRequests.commands.DELETE_COMMAND_BY_ID_COMMAND`, {
        default: 'it.name || it.id',
      }).replace(
        /\{1\}/,
        commandSelection.ids || $_('supportRequests.commands.param_not_available'),
      )}
      value="DELETE_COMMAND_BY_ID_COMMAND"
      bind:group={command}
    />

    <RadioButtonLightBlue
      label={$_(`supportRequests.commands.DELETE_COMMANDS_BY_TYPE_COMMAND`, {
        default: 'it.name || it.id',
      }).replace(
        /\{1\}/,
        commandSelection.commandTypes ||
          $_('supportRequests.commands.param_not_available'),
      )}
      value="DELETE_COMMANDS_BY_TYPE_COMMAND"
      bind:group={command}
    />
  </div>
  <button
    disabled={!command ||
      !selectedCommand ||
      !deviceId ||
      isValidSelecton !== true}
    on:click={() => showCancelConfirmationModal(() => onSend(selectedCommand))}
  >
    {$_('supportRequests.execute')}
  </button>
</div>

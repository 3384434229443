<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import moment from 'moment-timezone';
  import debounce from 'debounce';
  import { showSpinnerOverlay, dismissSpinnerOverlay } from '../../stores/spinner';
  import { fetchDeviceInformation, updateDeviceInformation } from '../../stores/devices';
  import FeatureTransferModal from '../../components/devices/FeatureTransferModal.svelte';
  import { showError } from '../../stores/notifications';
  import Loading from '../../components/Loading.svelte';
  import InputField from '../../components/InputField.svelte';
  import Checkbox from '../../components/Checkbox.svelte';
  import LoadingSpinner from '../../components/icons/LoadingSpinner.svelte';

  const DEVICE_SEARCH_DELAY_MS = 500;

  const ADDITIONAL_FEATURES = Object.freeze([
    'WALTTI',
    'REPORTING',
    'LINES',
    'MESSAGING',
  ]);

  export let activeView;
  export let editDeviceId;

  let displayedMessage = undefined;
  let deviceFetched = false;
  let device = {};
  let deviceId = editDeviceId || undefined;
  let loading = false;
  let fetchError = false;

  const { open } = getContext('simple-modal');

  const editView = editDeviceId !== undefined;

  const fetchDeviceFunction = async (deviceId, onSuccess, onError) => {
    try {
      const result = await fetchDeviceInformation(deviceId);
      onSuccess(result);
    } catch (error) {
      onError(error);
    }
  };

  const debouncedFetch = debounce(fetchDeviceFunction, DEVICE_SEARCH_DELAY_MS);

  const checkIfDeviceAlredyExists = () => {
    if (!editView && device.features.size) {
      displayedMessage = $_('devices.noteDeviceAlreadyExists', { values: { deviceId } } );
    }
  };

  $: {
    deviceFetched = false;
    device = {};
    fetchError = false;
    displayedMessage = undefined;
    if (deviceId && deviceId.toString().length == 8) {
      loading = true;
      debouncedFetch(deviceId, (result) => {
        loading = false;
        deviceFetched = true;
        device = {
          ...result,
          features: new Set(result.features || [])
        };
        checkIfDeviceAlredyExists();
      }, (_error) => {
        fetchError = true;
        loading = false;
      });
    }
  }

  $: isValid = deviceFetched && !fetchError && !loading;

  const showFeatureTransferModal = () => {
    open(FeatureTransferModal, { sourceDevice: editDeviceId, onSuccess: () => {
      activeView = 'list';
    }});
  };

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.title-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  .input-container > :global(.input-field-main-div) {
    margin-bottom: 1.5rem;
    max-width: 640px;
  }
  div.input-container {
    flex-grow: 1;
    flex-basis: 12rem;
  }
  .input-main-container {
    max-width: 800px;
  }
  .button-container > button {
    margin-left: 2rem;
  }
  button {
    min-width: 120px;
  }
  p {
    margin-bottom: 0.25rem;
  }
  .fixed-property p:last-child {
    padding-left: 0.4em;
    padding-bottom: 0.4em;
  }
  .message-box {
    display: flex;
    max-width: 800px;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
    align-items: center;
    background-color: #eef5fb;
    font-weight: 600;
    margin: 0.5rem 0 1.5rem 0;
  }
  .message-box > button {
    min-width: unset;
  }
  .bottom-button-container > button {
    margin-right: 2rem;
  }

</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {$_(`devices.${editView ? 'editPropertiesTitle' : 'addDevice'}`)}
    </h2>
  </div>

  <div class="button-container hidden lg:flex lg:items-center lg:justify-end">
    <button class="secondary" on:click={() => { activeView = 'list'; }}>{$_('devices.buttonCancel')}</button>
    <button disabled={!isValid} on:click={async () => {
      try {
        showSpinnerOverlay();
        await updateDeviceInformation(deviceId, device);
        activeView = 'list';
      } catch (error) {
        showError($_('devices.errorUpdatingDeviceDetails'));
      } finally {
        dismissSpinnerOverlay();
      }
      }}>{$_('devices.buttonFinish')}</button>
  </div>
</div>
{#if displayedMessage}
<div class="message-box">
  <span>{displayedMessage}</span>
  <button on:click={() => {
    displayedMessage = undefined;
  }}>{$_('lines.common.messageDismiss')}</button>
</div>
{/if}

{#if editView && loading}
  <Loading />
{:else}

<div class="flex w-full justify-between">

  <div class="input-main-container flex flex-col lg:flex-row flex-grow">
    <div class="input-container lg:mr-4">
      {#if !editView}
      <InputField
        id="passengerCount"
        label={$_('devices.deviceIdentifier')}
        type="number"
        maxLength={8}
        validator={(input) => input.toString().length == 8}
        required
        inputIcon={loading ? LoadingSpinner : undefined}
        bind:value={deviceId}
        forceError={fetchError}
        errorMessage={$_(fetchError ? 'devices.deviceIdNotFound' : 'devices.deviceIdMustBe8Characters')}
      />
      {:else}
      <div class="fixed-property">
        <p>{`${$_('devices.deviceIdentifier')}:`}</p>
        <p>{device.deviceId}</p>
      </div>
        {/if}
      <div class="fixed-property">
        <p>{`${$_('devices.consortiumIdentifier')}:`}</p>
        <p>{device.consortiumIdentifier || '-'}</p>
      </div>
      <div class="fixed-property">
        <p>{`${$_('devices.companyId')}:`}</p>
        <p>{device.companyId || '-'}</p>
      </div>
      <div class="fixed-property">
        <p>{`${$_('devices.activationDate')}:`}</p>
        <p>{device.activationTime ? moment(device.activationTime).tz('Europe/Helsinki').format('DD.MM.YYYY HH:mm') : '-'}</p>
      </div>
    </div>
    <div class="hidden lg:block flex-grow" />
    <div class="input-container lg:ml-4">
      <p>{$_(`devices.additionalFeatures`)}:</p>
      <div class="flex flex-col items-start">
        {#each ADDITIONAL_FEATURES as feature}
        <Checkbox size="32px" disabled={!deviceFetched} markColor={'#001E61'} checked={(device.features && device.features.has(feature)) || false} label={$_(`devices.features.${feature}`)}
          on:change={(event) => {
            if (event.target.checked) {
              device.features.add(feature);
            }
            else {
              device.features.delete(feature);
            }
          }}
        />
      {/each}
      </div>
    </div>
  </div>
</div>
{#if editView}
<button on:click={showFeatureTransferModal}>{$_('devices.transferFeatures')}</button>
{/if}

<div class="w-full flex justify-start lg:hidden bottom-button-container">
  <button class="secondary" on:click={() => { activeView = 'list'; }}>{$_('devices.buttonCancel')}</button>
  <button disabled={!isValid} on:click={async () => {
    try {
      showSpinnerOverlay();
      await updateDeviceInformation(deviceId, device);
      activeView = 'list';
    } catch (error) {
      showError($_('devices.errorUpdatingDeviceDetails'));
    } finally {
      dismissSpinnerOverlay();
    }
    }}>{$_('devices.buttonFinish')}</button>
</div>

{/if}

<script>
  import { notifications } from '../stores/notifications';
  import { slide } from 'svelte/transition';
</script>

<style>
  .notifications {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    z-index: 9999;
  }

  .notification {
    padding: 0.5em;
    display: flex;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: #fff;
    background-color: #333333;
    align-items: baseline;
  }

  .notification * {
    color: #fff !important;
  }

  .clickable {
    cursor: pointer;
  }

  .notification.error {
    background-color: #e31937;
    font-weight: normal;
  }

  .notification.success {
    background-color: #66cc33;
  }

  .notification.warning {
    background-color: #cc9933;
  }

  .notification h4 {
    margin: 0;
    margin-right: 1em;
  }
</style>

<div class="notifications">
  {#each $notifications as notification, i (notification.uid)}
    <div
      class={`notification ${notification.type || ''}`}
      class:clickable={notification.to}
      transition:slide
    >
      <h4>{notification.title}</h4>
      {#if notification.body}
        <span>{notification.body}</span>
      {/if}
    </div>
  {/each}
</div>

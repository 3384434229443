<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import AddCustomerGroupModal from '../../components/pricing/AddCustomerGroupModal.svelte';
  import BasicTicketTableCreationModal from '../../components/pricing/BasicTicketTableCreationModal.svelte';
  import ConfirmationModal from '../../components/ConfirmationModal.svelte';
  import ModifyCustomerGroupModal from '../../components/pricing/ModifyCustomerGroupModal.svelte';
  import BasicPricingTable from '../../components/pricing/BasicPricingTable.svelte';
  import DateRange from '../../components/pricing/DateRange.svelte';
  import DepartureTable from '../../components/pricing/DepartureTable.svelte';
  import { showNotification } from '../../stores/notifications';
  import {
    prices,
    updateProductIndex,
    updateNestedProductIndex,
    addBasicTicket,
    addBasicTicketLine,
    removeProduct,
    removeBasicTicketDistance,
    modifyBasicTicket,
    modifyBasicTicketPrice,
    modifyBasicTicketDistance,
    modifyBasicTicketLine,
    removeBasicTicketPriceTable,
    removeBasicTicketLine,
    getCopyableBasicPriceTables,
    addBasicTicketDistance,
    makeBasicPricingDefault,
    updatePriceTableValidity,
    VALIDITY_START_COL,
    VALIDITY_END_COL,
  } from '../../stores/pricing';
  import Select from '../../components/Select.svelte';
  import OverlappingError from '../../components/pricing/OverlappingError.svelte';

  const TEMP_TICKET_ROWS = 5;

  const { open } = getContext('simple-modal');

  let tempRowId = 0;

  const showBasicTicketModal = () => {
    open(AddCustomerGroupModal, {
      companyId: currentCompany,
      onSuccess: (company, name, discount) =>
        addBasicTicket(
          company,
          name,
          discount,
          currentPriceTableName,
          currentPriceTable === 'default',
        ),
    });
  };

  const showBasicTicketTableCreationModal = initial => {
    const defaultPriceTableName =
      $prices[currentCompany].basicTickets &&
      $prices[currentCompany].basicTickets[0] &&
      $prices[currentCompany].basicTickets[0].priceTableName;
    open(BasicTicketTableCreationModal, {
      companyId: currentCompany,
      existingNames: [
        ...(defaultPriceTableName ? [defaultPriceTableName] : []),
        ...(($prices[currentCompany].lineBasicTickets &&
          Object.keys($prices[currentCompany].lineBasicTickets)) ||
          []),
      ],
      initial,
      onSuccess: priceTableName => {
        currentPriceTable = priceTableName;
        editingMode = true;
      },
    });
  };

  const showRemovePricingConfirmationModal = () => {
    open(ConfirmationModal, {
      title: $_('pricing.removeBasicPriceTable'),
      body: $_('pricing.removeBasicPriceTableConfirmation'),
      onConfirm: () => {
        removeBasicTicketPriceTable(
          currentCompany,
          currentPriceTable === 'default' ? undefined : currentPriceTable,
        );
      },
    });
  };

  const showMakePricingDefaultConfirmationModal = () => {
    open(ConfirmationModal, {
      body: $_('pricing.pricingAsDefaultConfirmationText'),
      confirmText: $_('pricing.continue'),
      cancelText: $_('pricing.cancel'),
      onConfirm: () => {
        makeBasicPricingDefault(currentCompany, currentPriceTable);
      },
    });
  };

  let editingMode = false;
  let currentPriceTable = 'default';
  let currentPriceTableName;

  $: {
    if (
      !$prices[currentCompany].lineBasicTickets ||
      !$prices[currentCompany].lineBasicTickets[currentPriceTable]
    ) {
      currentPriceTable = 'default';
    }
  }

  $: {
    if (currentPriceTable === 'default') {
      currentPriceTableName =
        $prices[currentCompany] &&
        $prices[currentCompany].basicTickets &&
        $prices[currentCompany].basicTickets[0] &&
        $prices[currentCompany].basicTickets[0].priceTableName;
    } else {
      currentPriceTableName = currentPriceTable;
    }
  }

  $: priceTables = [
    {
      label: `${
        ($prices[currentCompany].basicTickets &&
          $prices[currentCompany].basicTickets[0] &&
          $prices[currentCompany].basicTickets[0].priceTableName) ||
        $_('pricing.basicPricingDefault')
      } ${$_('pricing.basicPricingDefaultTag')}`,
      value: 'default',
    },
    ...(
      ($prices[currentCompany].lineBasicTickets &&
        Object.keys($prices[currentCompany].lineBasicTickets)) ||
      []
    ).map(entry => ({
      value: entry,
      label: entry,
    })),
  ];

  let tempTicketRows = [];

  $: {
    if (!editingMode) {
      tempTicketRows = [];
    } else {
      createTempRows();
    }
  }

  $: lines =
    currentPriceTable === 'default'
      ? []
      : ($prices[currentCompany].lineBasicTickets &&
          $prices[currentCompany].lineBasicTickets[currentPriceTable][0].lines) ||
        [];
  $: lineNames =
    currentPriceTable === 'default'
      ? []
      : ($prices[currentCompany].lineBasicTickets &&
          $prices[currentCompany].lineBasicTickets[currentPriceTable][0].lineNames) ||
        [];
  $: lineIds =
    currentPriceTable === 'default'
      ? []
      : ($prices[currentCompany].lineBasicTickets &&
          $prices[currentCompany].lineBasicTickets[currentPriceTable][0].lineIds) ||
        [];

 
  const createTempRows = () => {
    const ticketRows =
      currentPriceTable === 'default'
        ? $prices[currentCompany].basicTickets
        : $prices[currentCompany].lineBasicTickets[currentPriceTable];
    if (!ticketRows[0].distancePrices.length) {
      tempTicketRows = [];
      for (let i = 0; i < TEMP_TICKET_ROWS; ++i) {
        tempTicketRows.push(createBlankTempEntry());
      }
    }
  };

  const createPricingTable = async () => {
    await getCopyableBasicPriceTables();
    const initialTable = !$prices[currentCompany].basicTickets;
    showBasicTicketTableCreationModal(initialTable);
  };

  const onRowWillBeCreated = entry => {
    const currentEntries =
      currentPriceTable === 'default'
        ? $prices[currentCompany].basicTickets
        : $prices[currentCompany].lineBasicTickets[currentPriceTable];
    const existingDistances =
      currentEntries && currentEntries[0] && currentEntries[0].distancePrices;
    const alreadyExists = existingDistances.some(
      field => field.distance === entry.distance,
    );
    if (!alreadyExists) {
      addBasicTicketDistance(
        currentCompany,
        entry.distance,
        entry.price,
        currentPriceTable === 'default' ? undefined : currentPriceTable,
      );
      return true;
    } else {
      showNotification({
        type: 'warning',
        title: $_('error.title'),
        body: $_('pricing.errorDistanceAlreadyExists'),
      });
      return false;
    }
  };

  const deleteRow = price => {
    removeBasicTicketDistance(
      currentCompany,
      price.distance,
      currentPriceTable === 'default' ? undefined : currentPriceTable,
    );
  };

  const modifyColumn = ticket => {
    open(ModifyCustomerGroupModal, {
      companyId: currentCompany,
      itemId: ticket.ticketIdentifier,
      item: ticket,
      onSuccess: (company, ticketId, name, discount) => {
        if (ticket.name !== name || ticket.discount !== discount) {
          modifyBasicTicket(
            company,
            ticketId,
            name,
            discount,
            currentPriceTable === 'default' ? undefined : currentPriceTable,
          );
        }
      },
      onDelete: removeProduct,
    });
  };

  const distancesModified = (price, index, event) => {
    if (price.distance !== event.detail.value) {
      modifyBasicTicketDistance(
        currentCompany,
        price.distance,
        event.detail.value,
        price.price,
        currentPriceTable === 'default' ? undefined : currentPriceTable,
      );
    }
  };

  const pricesModified = (item, row, column, event) => {
    const distanceEntry = item.distancePrices[row];
    if (distanceEntry.price !== event.detail.value) {
      if (column === 0) {
        modifyBasicTicketDistance(
          currentCompany,
          distanceEntry.distance,
          distanceEntry.distance,
          event.detail.value,
          currentPriceTable === 'default' ? undefined : currentPriceTable,
        );
      } else {
        modifyBasicTicketPrice(
          currentCompany,
          item.ticketIdentifier,
          distanceEntry.distance,
          event.detail.value,
          currentPriceTable === 'default' ? undefined : currentPriceTable,
        );
      }
    }
  };

  const getTicketIdentifier = () => {
    return $prices[currentCompany].lineBasicTickets[currentPriceTable][0]['ticketIdentifier'];
  };

  const startDateChanged = (val) => {
    updatePriceTableValidity(currentCompany, currentPriceTable, getTicketIdentifier(), VALIDITY_START_COL, val);
  };

  const endDateChanged = (val) => {
    updatePriceTableValidity(currentCompany, currentPriceTable, getTicketIdentifier(), VALIDITY_END_COL, val);
  };

  const createBlankTempEntry = () => ({
    distance: null,
    price: null,
    id: tempRowId++,
  });

  const tempRowValid = entry => entry.distance && entry.price;

  export let currentCompany;

  let dateEnd;
  let dateStart;
  let hasDateRange;
  let hasLines = false;
  $: {
    const lineTicket = currentCompany && currentPriceTable && $prices[currentCompany] && $prices[currentCompany].lineBasicTickets && $prices[currentCompany].lineBasicTickets[currentPriceTable] && $prices[currentCompany].lineBasicTickets[currentPriceTable][0];
    dateEnd =  lineTicket && lineTicket[VALIDITY_END_COL];
    dateStart = lineTicket &&  lineTicket[VALIDITY_START_COL];
    hasLines = lineTicket && lineTicket['lines'] && lineTicket['lines'].length > 0;
    hasDateRange = dateEnd || dateStart;
  }

  $: overlappingItems = currentCompany && $prices[currentCompany]['overlapping']['lineBasicTickets'];

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div {
    overflow: auto;
  }
  div.title-container,
  div.subtitle-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.subbutton-container {
    flex: 1 1 0;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
  }
  div.container > * {
    margin-left: 3rem;
  }
  div.select-container {
    display: flex;
    min-width: 250px;
    padding-bottom: 0.25rem;
    min-height: 42px;
  }
  button {
    margin-left: 4rem;
  }

  div.date-container {
    overflow: visible;
    padding-bottom: 3rem;
    padding-top: 1.75rem;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {editingMode ? $_('pricing.modifyBasicPricing') : $_('pricing.sections.basic')}
    </h2>
  </div>
  <div class="button-container">
    {#if editingMode}
      <button
        on:click={() => {
          editingMode = false;
        }}
      >
        {$_('pricing.done')}
      </button>
    {:else}
      <button on:click={createPricingTable}>{$_('pricing.addPricing')}</button>
    {/if}
  </div>
</div>

{#if overlappingItems && overlappingItems.length > 0}
  <OverlappingError overlappingItems={overlappingItems} nameProperty={'priceTableName'} />
{/if}
{#if $prices[currentCompany].basicTickets}
  <div class="subtitle-container">
    <div class="select-container">
      <Select
        flexGrow={true}
        disabled={editingMode}
        entries={priceTables}
        bind:value={currentPriceTable}
      />
    </div>
    <div class="button-container subbutton-container">
      {#if !editingMode}
        <button
          class="tertiary"
          on:click={() => {
            editingMode = true;
          }}
        >
          {$_('pricing.modify')}
        </button>
        <button class="tertiary" on:click={showRemovePricingConfirmationModal}>
          {$_('pricing.remove')}
        </button>
        {#if currentPriceTable !== 'default' && !lines.length && !hasDateRange}
          <button class="tertiary" on:click={showMakePricingDefaultConfirmationModal}>
            {$_('pricing.setAsDefault')}
          </button>
        {/if}
      {/if}
    </div>
  </div>
  <div class="date-container">
    <DateRange
      isDefault={currentPriceTable === 'default'}
      startDateChanged={startDateChanged}
      endDateChanged={endDateChanged}
      defaultInUse={hasLines}
      dateEnd={dateEnd}
      dateStart={dateStart}
      {editingMode}
      />

  </div>
  <div class="container">

    <div class="price-container">
      <BasicPricingTable
        list={currentPriceTable === 'default'
          ? $prices[currentCompany].basicTickets
          : $prices[currentCompany].lineBasicTickets[currentPriceTable]}
        onAddRightClicked={showBasicTicketModal}
        onRemoveRowClicked={deleteRow}
        onModifyColumnClicked={modifyColumn}
        onPrimaryColumnEdited={distancesModified}
        onDataEdited={pricesModified}

        columnData="distancePrices"
        headerColumnField="distance"
        headerColumnTitle={$_('pricing.distanceShort')}
        headerColumnType="number"
        columnField="price"
        columnTitle={$_('pricing.priceShort')}
        columnType="price"
        {onRowWillBeCreated}
        {createBlankTempEntry}
        {tempRowValid}
        bind:tempEntries={tempTicketRows}
        key="ticketIdentifier"
        on:sort={result => {
          currentPriceTable === 'default'
            ? updateProductIndex(
                currentCompany,
                result.detail.item.ticketIdentifier,
                'basicTickets',
                result.detail.from,
                result.detail.to,
                result.detail.item.index,
              )
            : updateNestedProductIndex(
                currentCompany,
                result.detail.item.ticketIdentifier,
                'lineBasicTickets',
                currentPriceTable,
                result.detail.from,
                result.detail.to,
                result.detail.item.index,
              );
        }}
        uneditableColumnTitles={[0]}
        {editingMode}
      />
    </div>
    {#if currentPriceTable !== 'default'}
      <div class="line-container">
        <DepartureTable
          {lines}
          {lineNames}
          {lineIds}
          {editingMode}
          onLineAdded={(line, departure, name) =>
            addBasicTicketLine(currentCompany, currentPriceTable, line, departure, name)}
          onLineModified={(id, line, departure, name) =>
            modifyBasicTicketLine(
              currentCompany,
              currentPriceTable,
              id,
              line,
              departure,
              name,
            )}
          onLineDeleted={id =>
            removeBasicTicketLine(currentCompany, currentPriceTable, id)}
        />
      </div>
    {/if}
  </div>
{:else}
  <p>{$_('pricing.basicPricingMissing')}</p>
{/if}

<script>
  import { _, locale } from 'svelte-i18n';

  $: [lang] = ($locale || 'fi').split('-');

  import Checkbox from '../Checkbox.svelte';
  import Tooltip from '../Tooltip.svelte';
  import FeatureLineAccess from './FeatureLineAccess.svelte';

  export let feature;
  export let features;
  export let valid;
  export let updateFeature;

  const handleChange = (_) => {

    if (checked)
      features = features.filter(
        code => code !== feature.code || code.startsWith(`${feature.code};`),
      );
    else features = [...features, feature.code];
  };

  const isChecked = (features, feature) => {
    return features.some(code => feature.code === code || code.startsWith(`${feature.code};`));
  };

  $: checked = isChecked(features, feature);

  $: reFeature = feature;
</script>

<style>
  .tooltip {
    color: white;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    border: 2px solid #333333;
  }
</style>

<div>
  <div class="flex">
    <Checkbox
      on:change={({ target: { checked } }) => handleChange(checked)}
      label={feature[lang]}
      {checked}
      labelClass='labelLarge'
    />
    {#if feature.code === 'ADMIN'}
      <div class="ml-1">
        <Tooltip>
          <span slot="content" class="info">i</span>
          <span slot="tooltip">
            <span class="tooltip"> {$_('users.editor.adminInfo')} </span>
          </span>
        </Tooltip>
      </div>
    {/if}
  </div>
  {#if ((feature.code.endsWith('$REPORTS') || feature.code.endsWith('$TRAVELREPORTS')) && checked)}
    <FeatureLineAccess labelClass='labelLarge' bind:feature bind:features bind:valid updateFeature={updateFeature} />
  {/if}
</div>

<script>
  import { onMount } from 'svelte';

  export let maxIntegers = '';
  export let value;
  export let type;

  const PRICE_REGEX = new RegExp(`^\\d{0,${maxIntegers}}((,|\\.)\\d{0,2})?$`);
  const NUMBER_REGEX = new RegExp(`^\\d*$`);

  let inputEl;
  let previousValue = '';
  const initialValue = Number.isFinite(value) ? value.toString().replace('.', ',') : '';
  onMount(() => (inputEl.value = initialValue));
  const regex = type === 'price' ? PRICE_REGEX : NUMBER_REGEX;

  const handleInput = e => {
    let success = true;
    if (regex.test(e.target.value)) {
      const parsedValue = e.target.value.length
        ? +e.target.value.replace(',', '.')
        : null;
      value = Number.isFinite(parsedValue) ? parsedValue : null;
    } else {
      success = false;
    }
    console.log(success, value);

    if (success) {
      console.log('SET PREVIOUS');
      previousValue = e.target.value;
    } else {
      console.log('RESET');
      inputEl.value = previousValue;
    }
  };
</script>

<style>
  input {
    width: 100%;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
    border-radius: 4px;
  }
</style>

<input
  {...$$props}
  type="text"
  bind:this={inputEl}
  on:input={handleInput}
  on:change={console.error}
/>

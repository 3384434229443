<script>
  import { _, locale } from 'svelte-i18n';
  import { getContext } from 'svelte';

  $: [lang] = ($locale || 'fi').split('-');
  
  import UserFeature from './UserFeature.svelte';
  const { close } = getContext('simple-modal');

  export let company;
  export let features;
  export let featureList;
  export let confirmText = $_('users.editor.button.save');
  export let cancelText = $_('users.editor.button.cancel');
  export let onConfirm = () => {};
  export let onClose = () => {};

  let validLinesMap = {};
  let selectedCompany = company.number;
  $: validLines = !Object.values(validLinesMap).some(it => it === false);

  const prefixFeature = (originalFeature, company) => {
    const feature = { ...originalFeature };
    if (!feature.code.includes('$')) {
      feature.code = `${company}$${feature.code}`;
    }

    return feature;
  };

  const updateFeature = (feature, newFeatureValue) => {
    const indexToUpdate = features.findIndex(item => item.startsWith(feature.code));
    features[indexToUpdate] = newFeatureValue;
  };

$: prefixedFeatureList = (featureList || []).map(it =>
    prefixFeature(it, selectedCompany),
  );
</script>

<style>
  .tooltip {
    color: white;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    border: 2px solid #333333;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  button {
    min-width: 150px;
    padding: 0.7rem;
    margin-left: 2rem;
  }

  .button-container {
    display: flex;
    flex-flow: row;
    justify-content: right;
    width: 100%;
  }

  p {
    white-space: pre-line;
  }

  .features {
    margin-top: 1rem;
  }

  .capitalize {
    text-transform: capitalize;
  }

  h3 {
    margin-top: 1rem;
  }
</style>

<div>
  <div class="mb-6 flex flex-col items-start">
    <h2 class="text-mnormal text-3xl">{$_('users.editor.seeFeatures')}</h2>
    <h3 class="text-mnormal text-lg capitalize">{company.name.toLowerCase()}</h3>
    
    <div class="features">
      {#each prefixedFeatureList as feature}
        <UserFeature
          bind:feature
          bind:features
          bind:valid={validLinesMap[feature.code]}
          {updateFeature}
        />
      {/each}
    </div>
  </div>

  <div class="button-container">
    <button
      class="small"
      on:click={() => {
        onConfirm(selectedCompany, features.filter(feature => feature.startsWith(`${selectedCompany}$`)));
        close();
      }}
    >
      {confirmText}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        onClose();
        close();
      }}
    >
      {cancelText}
    </button>
  </div>
</div>

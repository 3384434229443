import { writable } from 'svelte/store';
import { showNotification } from './notifications';
import { getClient } from '../common/http';

export const positions = writable({});
export const connection = writable({ status: 'closed' });

export const tripInfo = writable({});
export const workshiftInfo = writable({});

//positions.subscribe(console.log);

let ws = null;

export async function fetchInfo(params, trip_id) {
  const res = await getClient().get(`/api/map/line/${params}`);
  tripInfo.update(value => ({ ...value, [trip_id]: res.data }));
}

export async function fetchWorkshiftInfo(
  workshift,
  stop,
  companyId,
  lineId,
  departureId,
) {
  console.log(companyId, lineId, departureId);
  const res = await getClient().get(`/api/map/getStatsForStop/${workshift}/${stop}`, {
    params: { companyId, lineId, departureId },
  });
  const shipments =
    typeof res.data.shipments === 'number'
      ? res.data.shipments
      : (res.data.shipments[stop] || 0) + (res.data.shipments.noStop || 0);
  const data = { ...res.data, shipments };
  workshiftInfo.update(value => ({ ...value, [workshift]: data }));
}

let lastURL;

export async function initConnection() {
  const res = await getClient().get(`/api/map/ws/url`);
  if (
    res.status !== 200 ||
    !res.data ||
    !res.data.startsWith ||
    !res.data.startsWith('wss://')
  )
    return;
  if (res.data === lastURL) return;
  closeConnection();
  ws = new WebSocket(res.data);
  lastURL = res.data;
  ws.onopen = () => {
    connection.set({ status: 'open' });
  };
  ws.onclose = () => {
    connection.set({ status: 'closed' });
  };
  ws.onerror = e => {
    console.error('Error when connecting to WebSocket', e);
    if (ws === e.target)
      showNotification(
        {
          title: 'Error when connecting to WebSocket',
          type: 'error',
        },
        3000,
      );
    connection.set({ status: 'closed', error: true });
  };
  ws.onmessage = event => {
    const messages = JSON.parse(event.data);
    messages.forEach(addPositionFromMessage);
  };
}

export function closeConnection() {
  if (!ws) return;
  const tempWS = ws;
  ws = null;
  tempWS.close();
}

function addPositionFromMessage(message) {
  const posHeader = {
    lineId: message.header.line_id,
    departureId: message.header.departure_id,
    driverId: message.header.driver_id,
    startStopName: message.header.start_stop_name,
    arrivalStopName: message.header.arrival_stop_name,
    workshiftId: message.header.workshift_id,
    companyId: message.header.agency_id,
    lite: message.header.basic,
  };
  positions.update(value => {
    const vehicles = {};
    message.entity.forEach(entity => {
      const info = entity.vehicle;
      if (!info) return;
      const tripParams = info.trip
        ? `${message.header.agency_id}/${posHeader.departureId}/${posHeader.lineId}/${posHeader.driverId}/${info.trip.start_date}`
        : null;
      const infoTrip = info.trip || {
        trip_id: `${message.header.agency_id}/${posHeader.lineId}/${posHeader.departureId}`,
      };
      const data = {
        ...posHeader,
        ...info.position,
        ...infoTrip,
        id: info.vehicle.id || info.vehicle,
        timestamp: info.timestamp,
        currentStop: info.stop_id,
        tripParams:
          tripParams || `${infoTrip.trip_id}_${info.vehicle.id || info.vehicle}`,
        basic: !tripParams,
      };
      vehicles[data.trip_id] = {
        ...(value[data.trip_id] || {}),
        [data.id]: data,
        __INFO__: {
          ...posHeader,
          ...infoTrip,
          id: '__INFO__',
          tripParams: data.tripParams,
          basic: data.basic,
        },
      };
    });
    const oldValues = {};
    Object.entries(value).forEach(([key, val]) => {
      const validVehicles = Object.entries(val).filter(
        ([_, vehicle]) =>
          !vehicle.workshiftId || vehicle.workshiftId !== posHeader.workshiftId,
      );
      if (validVehicles.length <= 1) return;
      const vehicles = {};
      validVehicles.forEach(([id, vehicle]) => (vehicles[id] = vehicle));
      oldValues[key] = vehicles;
    });
    return {
      ...oldValues,
      ...vehicles,
    };
  });
}

<script>
  import { number, _ } from 'svelte-i18n';
  import PassengerIcon from '../images/Passenger.svelte';
  import { companies as companyList } from '../../stores/companies';

  export let lines = [];
  export let totalColumnWidth = null;

  $: results = lines && getResults(lines, $companyList);

  const getResults = (lines, companies) =>
    lines.map(result => ({
      ...result,
      company: companies.filter(({ number }) => number === result.company)[0] || {
        name: result.company_id,
        number: result.company_id,
      },
    }));

  const total = lines.reduce((acc, num) => acc + num.quantity, 0);
  const totalSum = lines.reduce((acc, num) => acc + num.sum, 0);
  const totalSumVat = lines.reduce((acc, num) => acc + num.sum_vat, 0);
</script>

<style>
  td {
    border-color: #cccccc;
  }

  td.text-right {
    padding-right: 2em;
  }

  th:not(:first-child) {
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  th,
  th span {
      }

  td:not(.products) {
    padding: 0;
    padding-right: 2em;
    padding-bottom: 0.7em;
    padding-top: 1em;
  }

  td:last-child:not(.products) {
    padding: 0;
  }

  tr:not(:first-child) {
    cursor: pointer;
  }

  .bottom-border {
    border-bottom: 1px solid #333333;
  }
</style>

{#if results.length}
  <tr class="pl-16 pt-2 bg-mlight">
    <th />
    <th class="text-left">{$_('results.company')}</th>
    <th class="text-left">{$_('results.line')}</th>
    <th class="text-left">{$_('results.departure')}</th>
    <th class="text-left">{$_('results.trip')}</th>
    <th class="text-right">
      <div class="inline-flex">
        <PassengerIcon width="20px" height="20px" />
        <span>{$_('travel.total')}</span>
      </div>
    </th>
    <th class="text-right">
      <div class="inline-flex">
        <PassengerIcon width="20px" height="20px" />
        <span>{$_('travel.totalSumVatFree')}</span>
      </div>
    </th>
    <th class="text-right">
      <div class="inline-flex">
        <PassengerIcon width="20px" height="20px" />
        <span>{$_('travel.totalSumVatLong')}</span>
      </div>
    </th>
    <th style={totalColumnWidth ? `width: ${totalColumnWidth}px` : ''} />
  </tr>
  {#each results as line, i}
    <tr class="pl-16 bg-mlight row-stuff">
      <td />
      <td class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {(line.company && line.company.name) || '-'}
      </td>
      <td class="text-left" class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {(line.line_id === '0000' ? '-' : line.line_id || '-').split('$')[0]}
      </td>
      <td class="text-left" class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {(line.departure_id || '-').split('$')[0]}
      </td>
      <td class="text-left" class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {line.line_name || '-'}
      </td>
      <td class="text-right" class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {line.quantity || '-'}
      </td>
      <td class="text-right" class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {$number(line.sum, { format: 'EUR' }).replace('€', '') || '-'}
      </td>
      <td class="text-right" class:border-t={i !== 0} class:bottom-border={i === results.length - 1}>
        {$number(line.sum_vat, { format: 'EUR' }).replace('€', '') || '-'}
      </td>
      <td
        class:border-t={i !== 0} class:bottom-border={i === results.length - 1}
        style={totalColumnWidth ? `width: ${totalColumnWidth}px` : ''}
      />
    </tr>
  {/each}
  <tr class="pl-16 pb-4 summary text-mdark bg-mlight">
    <td colspan="5" />

    <td class="text-right font-semibold">
      {total || '0'}
    </td>
    <td class="text-right font-semibold">
      {$number(totalSum, { format: 'EUR' }).replace('€', '') || '0'}
    </td>
    <td class="text-right font-semibold">
      {$number(totalSumVat, { format: 'EUR' }).replace('€', '') || '0'}
    </td>
    <td style={totalColumnWidth ? `width: ${totalColumnWidth}px` : ''} />
  </tr>
{/if}

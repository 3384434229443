<script>
    export let width = "62px";
    export let height = "63px";
</script>
<svg {width} {height} viewBox="0 0 62 63" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-6" transform="translate(0.600000, 0.522533)" fill="#333333" fill-rule="nonzero">
            <path d="M54.326879,47.333297 L31.5428502,56.8079547 L31.4797844,56.7960787 C31.1431297,56.7310071 30.6794988,56.6164366 30.108882,56.4569463 L29.8578401,56.3858565 C28.6667447,56.0443923 27.1173685,55.5444178 25.3158721,54.9261646 L23.53585,54.3053017 L21.9069198,53.7228771 C19.7475848,52.9434425 17.7447405,52.1927329 17.1728183,51.9531293 C15.9901252,51.4576468 14.6289672,52.0130069 14.1325869,53.1935608 C13.6362065,54.3741147 14.192573,55.7328107 15.3752661,56.2282932 L15.8296057,56.4108562 L17.29089,56.9678835 C18.892956,57.5679093 21.137839,58.384421 23.0724048,59.0573568 L25.3413808,59.83583 C29.8781966,61.3688971 31.6709185,61.78117 32.8955938,61.2680994 L56.1141222,51.612765 C58.7055551,50.5336954 60.3946142,48.0088017 60.4,45.2071077 L60.4,16.6143969 C60.4,13.8076815 58.7101411,11.2760072 56.1151372,10.1954443 L32.8902992,0.532840683 C31.1710617,-0.177228569 29.2460486,-0.177228569 27.5323768,0.530533548 L4.30262278,10.1951739 C1.70696924,11.2760072 0.0171103344,13.8076815 0.0171103344,16.6143969 L0.0171103344,31.9243713 C0.0171103344,33.2046879 1.05689266,34.2425895 2.33952917,34.2425895 C3.62216567,34.2425895 4.661948,33.2046879 4.661948,31.9243713 L4.661948,16.6143969 C4.661948,15.6786023 5.22526676,14.8346623 6.09023558,14.4744882 L29.3137742,4.81242539 C29.8847391,4.57662013 30.5323713,4.57662013 31.1089018,4.81473253 L54.3275245,14.4747586 C55.1918436,14.8346623 55.7551623,15.6786023 55.7551623,16.6143969 L55.7551623,45.2026629 C55.7533728,46.1321161 55.1903531,46.9737473 54.326879,47.333297 Z" id="Path"></path>
            <path d="M4.12011459,11.748355 C2.93618465,11.2558245 1.5764214,11.8145784 1.08299837,12.9963669 C0.589575333,14.1781554 1.14934178,15.5354592 2.33327173,16.0279897 L29.3151337,27.2528019 C29.8869129,27.4906696 30.5301975,27.4906696 31.1019766,27.2528019 L58.0838386,16.0279897 C59.2677685,15.5354592 59.827535,14.1781554 59.334112,12.9963669 C58.8406889,11.8145784 57.4809257,11.2558245 56.2969957,11.748355 L30.2085552,22.601492 L4.12011459,11.748355 Z" id="Path"></path>
            <path d="M15.6216471,9.79759387 L44.1641746,21.1058619 C45.3563395,21.5781859 46.7063682,20.9963876 47.179548,19.806379 C47.6527279,18.6163704 47.0698753,17.2687836 45.8777104,16.7964596 L17.335183,5.48819156 C16.143018,5.01586757 14.7929893,5.59766588 14.3198095,6.78767447 C13.8466296,7.97768306 14.4294822,9.32526988 15.6216471,9.79759387 Z" id="Path"></path>
            <path d="M27.8861363,25.1129846 L27.8861363,57.5680384 C27.8861363,58.848355 28.9259187,59.8862566 30.2085552,59.8862566 C31.4911917,59.8862566 32.530974,58.848355 32.530974,57.5680384 L32.530974,25.1129846 C32.530974,23.832668 31.4911917,22.7947664 30.2085552,22.7947664 C28.9259187,22.7947664 27.8861363,23.832668 27.8861363,25.1129846 Z" id="Path"></path>
            <path d="M3.63709063e-13,28.0087175 L3.63709063e-13,49.4462175 C3.63709063e-13,50.2919035 1.05213084,50.9774675 2.35,50.9774675 C3.64786916,50.9774675 4.7,50.2919035 4.7,49.4462175 L4.7,28.0087175 C4.7,27.1630315 3.64786916,26.4774675 2.35,26.4774675 C1.05213084,26.4774675 3.63709063e-13,27.1630315 3.63709063e-13,28.0087175 Z" id="Path"></path>
            <path d="M10.4,42.1717969 L10.4,63.1717969 C10.4,64.000224 11.4521308,64.6717969 12.75,64.6717969 C14.0478692,64.6717969 15.1,64.000224 15.1,63.1717969 L15.1,42.1717969 C15.1,41.3433698 14.0478692,40.6717969 12.75,40.6717969 C11.4521308,40.6717969 10.4,41.3433698 10.4,42.1717969 Z" id="Path" transform="translate(12.750000, 52.671797) rotate(-69.000000) translate(-12.750000, -52.671797) "></path>
        </g>
    </g>
</svg>
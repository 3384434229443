<script>
  import { flip } from 'svelte/animate';
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';

  export let title;
  export let list;
  export let headerColumns;
  export let key;
  export let renderComponent;
  export let componentProps;
  export let onAddClicked;
  export let placeholder = null;
  export let droppableId = 'default'; // If multiple lists on the same page, each needs a unique id if dragging across lists needs to be prevented
  export let editingMode = false;
  export let disableSorting = false;
  export let addButtonText = $_('pricing.addNewProduct');

  let isOver = false;
  const dispatch = createEventDispatcher();

  const getDraggedParent = node =>
    node.dataset && node.dataset.index ? node.dataset : getDraggedParent(node.parentNode);

  const handleDragStart = e => {
    e.dataTransfer.setData('source', e.target.dataset.index);
    e.dataTransfer.setData('sourceZone', e.target.dataset.dropzone);
  };

  const handleDragOver = e => {
    e.preventDefault();
    const dragged = getDraggedParent(e.target);
    if (isOver !== dragged.id) isOver = dragged.id;
  };

  const handleDragLeave = e => {
    const dragged = getDraggedParent(e.target);
    if (isOver === dragged.id) isOver = false;
  };

  const handleDragDrop = e => {
    isOver = false;
    e.preventDefault();
    const dragged = getDraggedParent(e.target);
    const sourceZone = e.dataTransfer.getData('sourceZone');
    if (dragged.dropzone !== sourceZone) {
      return;
    }
    const from = +e.dataTransfer.getData('source');
    const to = +dragged.index;
    reorder({ from, to });
  };

  const reorder = ({ from, to }) => {
    let newList = [...list];
    const [movedItem] = newList.splice(from, 1);
    newList.splice(to, 0, movedItem);
    dispatch('sort', {
      list: newList,
      item: movedItem,
      from,
      to,
    });
  };

  const getKey = item => { return (key ? item[key] : item); };
</script>

<style>
  td:not(.placeholder) {
    border-bottom: solid 1px #D1D1D2;
  }
  th {
    font-weight: normal;
  }
  th,
  td {
    padding: 0.75rem 1rem;
  }
  .over {
    border-color: rgba(48, 12, 200, 0.2);
  }
  tr[draggable='true'] {
    cursor: grab;
  }
  tr[draggable='true']:active {
    cursor: grabbing;
  }
  tr.over {
    filter: brightness(0.95);
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
  }
  .modify-button {
    float: right;
  }
  .text-button {
    padding: 0.75rem 1rem;
    color: #001E61;
    cursor: pointer;
  }
  .text-button:hover {
    color: #173043;
  }
  th {
    padding: 0.75rem 1rem;
    border-bottom: solid 1px #173043;
    color: #5B6670;
    text-align: left;
  }
</style>

<table>
  <tr>
    <th width={headerColumns && headerColumns.length && headerColumns[0].width}>
      {title}
    </th>
    <th colspan={headerColumns ? headerColumns.length - 1 : undefined}>
      {#if $$props.editingMode === undefined}
        <button class="modify-button" on:click={() => (editingMode = !editingMode)}>
          {$_(`pricing.${editingMode ? 'done' : 'modify'}`)}
        </button>
      {/if}
    </th>
  </tr>
  {#if list && list.length}
    {#if headerColumns && headerColumns.length}
      <tr class="subtitle">
        {#each headerColumns as headerColumn}
          {#if editingMode || !headerColumn.editingOnly}
            <td
              width={headerColumn.width}
              style={headerColumn.align ? `text-align: ${headerColumn.align}` : undefined}
            >
              {headerColumn.title ? headerColumn.title : ''}
            </td>
          {/if}
        {/each}
      </tr>
    {/if}
    {#each list as item, index (getKey(item))}
      <tr
        data-index={index}
        data-id={getKey(item)}
        data-dropzone={droppableId}
        draggable={editingMode && !disableSorting}
        on:dragstart={handleDragStart}
        on:dragover={handleDragOver}
        on:dragleave={handleDragLeave}
        on:drop={handleDragDrop}
        animate:flip={{ duration: 300 }}
        class:over={getKey(item) === isOver}
      >
        <svelte:component
          this={renderComponent}
          {item}
          {editingMode}
          {index}
          {...componentProps}
        />
      </tr>
    {/each}
  {:else if placeholder && !editingMode}
    <tr>
      <td class="placeholder" colspan={headerColumns ? headerColumns.length : undefined}>
        {placeholder}
      </td>
    </tr>
  {/if}
</table>
{#if editingMode}
  <div class="text-button" on:click={onAddClicked}>{addButtonText}</div>
{/if}

<script>
    import { _ } from 'svelte-i18n';
    import { showError } from '../../stores/notifications';
    import { preGeneratedReports, fetchPreGeneratedReports, preGeneratedReportsLoaded }  from '../../stores/travel_reports';
    import { getPregeneratedReport } from '../../common/http';
    import Loading from '../Loading.svelte';
    import {
    companies as companyList,
    companiesLoaded,
    getCompanies,
    } from '../../stores/companies';
    import { currentUser } from '../../stores/user';
    import { getCompaniesByFeature } from '../../stores/features';

    let initialReportLoaded = false;
    $: features = ($currentUser && $currentUser.features) || [];
    $: companiesForMonthlyReports = getCompaniesByFeature(features, 'REPORTS_MONTHLY') || [];
    $: companyListFilteredByFeature = $companyList && $companyList.filter(company => companiesForMonthlyReports.includes(company.number));

    let selectedCompany = null;

    const getReports = (reports, company) => {
      return reports && company && reports[company.number] && reports[company.number].monthly || [];
    };

    $: reports = getReports($preGeneratedReports, selectedCompany);
    $: {
      if (!initialReportLoaded && $companiesLoaded) {
        selectedCompany = companyListFilteredByFeature && companyListFilteredByFeature[0];
        if ( selectedCompany && selectedCompany.number ) {
          fetchPreGeneratedReports(selectedCompany.number).catch(showError($_('error.title')));
          initialReportLoaded = true;
        }
      }
    }

    if (!$companiesLoaded) {
      getCompanies().catch(showError($_('error.title')));
    }

    const createExcel = async (report) => {
      const res = await getPregeneratedReport(report.filename, report.company);

      const url = res.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    };

    $: monthsOfYear = [
      $_('calendar.months.long.jan'),
      $_('calendar.months.long.feb'),
      $_('calendar.months.long.mar'),
      $_('calendar.months.long.apr'),
      $_('calendar.months.long.may'),
      $_('calendar.months.long.jun'),
      $_('calendar.months.long.jul'),
      $_('calendar.months.long.aug'),
      $_('calendar.months.long.sep'),
      $_('calendar.months.long.oct'),
      $_('calendar.months.long.nov'),
      $_('calendar.months.long.dec'),
    ];
  </script>

  <style> 
  .report {
    width: 100%;
    border-bottom: solid 1px #000;
    margin-right: 3rem;
  }

  div.report button {
    display: inline-flex;
    margin-left: 10em;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  div.report div.name {
    display: inline-block;
    padding-right: 10em;
    width: 20em;
    margin-left: 0.5rem;
  }

  div.report span {
    width: 20em;
  }

  div.report span.dl {
    width: 10em;
  }
  .content {
    margin-top: 5em;
    margin-left: 2em;
  }

  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  div.subbutton-container {
    flex: 1 1 0;
  }
  
  button {
    margin-left: 0.5rem;
  }

  .reportTitle {
    font-weight: bold;
    min-width: 42rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  </style>
  

<div class="header">
  <h1 class="mb-6 text-3xl font-bold">{$_('travelReports.sections.pregenerated')}</h1>
</div>


<div class="button-container subbutton-container">
    {#if companiesLoaded}
      {#each companyListFilteredByFeature as company}
        <button
          class="primary"
          class:secondary={!selectedCompany || selectedCompany.number !== company.number}
          on:click={() => {
            selectedCompany = company;
            fetchPreGeneratedReports(company.number).catch(showError($_('error.title')));
            }}
        >{company.name}
        </button>
      {/each}
    {/if}
</div>  

<div class="content">
  <div class="reportTitle border-newBlack border-b-[2px] border-solid">Matkustajaraportoinnin tapahtumarivit</div>
    {#if $preGeneratedReportsLoaded}
      {#each reports as report}
        <div class="report">
          <div class="name">{monthsOfYear[report.month]} {report.year}</div>
          <span class="dl">
            <button class="tertiary" on:click={() => {createExcel(report);}}>
              Lataa excel
            </button>
        </span>
        </div>
      {/each}
      {#if reports.length === 0}
        <div>Ei ladattavia raportteja</div>
      {/if}
    {:else}
      <div>
        <Loading />
      </div>
    {/if}
</div>

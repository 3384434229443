import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import { showSpinnerOverlay, dismissSpinnerOverlay } from './spinner';
import { showNotification } from './notifications';

const lineSeriesInternal = writable({});
export const lineSeries = {
  subscribe: lineSeriesInternal.subscribe
};

const fixSeriesData = (data) => {
  data.lines = data.lines.filter(lineEntry => lineEntry.departure).map(lineEntry => `${(lineEntry.line || '').padStart(4, '0')}_${lineEntry.departure.padStart(4, '0')}`);
  data.weekdays = [...data.weekdays];
  return data;
};

export const createLineSeries = async (company, title, lines, weekdays) => {
  showSpinnerOverlay();
  try {
    const seriesData = fixSeriesData({ company, title, lines, weekdays });
    const { data: result } = await getClient().post('api/lineSeries', seriesData);
    await getLineSeries(company);
    return result;
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const updateLineSeries = async (company, id, title, lines, weekdays) => {
  showSpinnerOverlay();
  try {
    const seriesData = fixSeriesData({ company, id, title, lines, weekdays });
    const { data: result } = await getClient().put('api/lineSeries', seriesData);
    await getLineSeries(company);
    return result;
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const getLineSeries = async (company) => {
  const { data: result } = await getClient().get(`api/lineSeries/${company}`);
  lineSeriesInternal.update(current => ({
    ...current,
    [company]: result.map(row => ({
      ...row,
      lines: row.lines.map(lines => {
        const [line, departure] = lines.split('_');
        return {
          line: line === '0000' ? '' : line,
          departure
        };
      })
    }))
  }));
};

export const deleteLineSeries = async (company, id) => {
  showSpinnerOverlay();
  try {
    await getClient().delete(`api/lineSeries/${company}/${id}`);
    await getLineSeries(company);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

<script>
  import { createEventDispatcher, tick } from 'svelte';
  import {
    saveMessage,
    setMessageImage,
    getMessages,
    getMessageImage,
    convertMessageFromAPI,
  } from '../../stores/messages';
  import MultiSelect from '../MultiSelect.svelte';
  import { showError, showNotification } from '../../stores/notifications';
  import {
    companiesLoaded,
    getCompanies,
    companies as companyList,
  } from '../../stores/companies';
  import DatePicker from '../DatePicker.svelte';
  import Icon from 'fa-svelte';
  import { faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
  import { _, date } from 'svelte-i18n';
  import RadioButton from '../RadioButton.svelte';
  import MessageLines from './MessageLines.svelte';
  import DropZone from '../DropZone.svelte';

  import { currentUser } from '../../stores/user';
  import { getCompaniesByFeature } from '../../stores/features';

  $: features = ($currentUser && $currentUser.features) || [];
  $: companiesForMessaging = getCompaniesByFeature(features, 'MESSAGING') || [];
  $: companyListFilteredByFeature = $companyList.filter(company => companiesForMessaging.includes(company.number));


  export let message = {};
  const handleError = showError($_('error.title'));
  const handleImageError = showError($_('messages.imageError'));

  const dispatch = createEventDispatcher();

  let companies = message.companies || companyListFilteredByFeature;

  let target = message.lines ? 'selectedLines' : 'all';
  let lines = message.lines || [];

  $: isNew = !message.id;

  const CATEGORIES = ['GOOD_TO_KNOW', 'INSTRUCTION', 'IMPORTANT', 'DISTURBANCE'];

  $: isValid =
    title &&
    content &&
    startDateChosen &&
    category &&
    companies.length &&
    (target !== 'selectedLines' || lines.length);

  let startDateChosen = !!message.start;
  let endDateChosen = !!message.end;

  let start = (message.start && message.start.toDate()) || new Date();
  let end = (message.end && message.end.toDate()) || new Date();

  let content = message.content;
  let title = message.title;
  let category = message.category;

  let imageFile;
  let imageSrc;

  let imageLoading = false;

  const loadImage = async () => {
    imageLoading = true;
    imageSrc = await getMessageImage(message.id).finally(() => (imageLoading = false));
  };

  if (message.image) {
    loadImage().catch(handleError);
  }

  const emptyFields = () => {
    category = '';
    title = '';
    content = '';
    startDateChosen = false;
    endDateChosen = false;
    companies = companyListFilteredByFeature.map(({ number }) => number);
    target = 'all';
    lines = [];
    imageSrc = null;
  };


  companyList.subscribe(list => {
    if (message.companies) return;
    companies = list.filter(company => companiesForMessaging && companiesForMessaging.includes(company.number)).map(({ number }) => number);
  });


if (!$companiesLoaded) {
    getCompanies().catch(handleError);
  }

  let saving = false;

  const handleCancel = () => {
    dispatch('cancel');
  };

  const handleSubmit = async () => {
    saving = true;
    let newMessage;
    try {
      const now = new Date();
      const isNow =
        now.getDate() === start.getDate() &&
        now.getMonth() === start.getMonth() &&
        now.getFullYear() === start.getFullYear() &&
        start.getMinutes() === 0 &&
        start.getHours() === 0;
      const realStart = isNow ? now : start;
      let realEnd = new Date(end);
      if (endDateChosen) {
        realEnd.setHours(23);
        realEnd.setMinutes(59);
        realEnd.setSeconds(59);
      }
      const messageToSubmit = {
        id: message.id,
        title,
        category,
        content,
        start: realStart,
        end: endDateChosen ? realEnd : null,
        companies,
        lines:
          target === 'selectedLines'
            ? lines.map(({ line, departure }) => `${line}_${departure}`)
            : null,
      };
      const res = await saveMessage(messageToSubmit);
      if (!res) {
        saving = false;
        return;
      }
      newMessage = convertMessageFromAPI(res.data);
    } catch (e) {
      handleError(e);
    }
    try {
      if (imageFile) {
        message.id = newMessage.id;
        await setMessageImage(message.id, imageFile);
        newMessage.image = true;
        await loadImage();
      }
    } catch (e) {
      handleImageError(e);
    }
    if (isNew) {
      emptyFields();
      showNotification({
        title: $_('messages.messagePublished'),
        type: 'success',
      });
    }
    try {
      await getMessages();
      if (!newMessage) {
        saving = false;
        return;
      }
      await tick();
      message = newMessage;
      dispatch('submitted', message);
    } catch (e) {
      handleError(e);
    }
    saving = false;
  };

  let fileDrop;
  const handleFile = ({ detail: file }) => {
    if (!file) return handleRemoveImage();
    imageLoading = true;
    imageSrc = null;
    const reader = new FileReader();
    reader.onload = () => {
      imageFile = file;
      imageSrc = reader.result;
      imageLoading = false;
    };
    reader.onerror = e => {
      handleError(e);
      imageLoading = false;
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    imageSrc = null;
    imageFile = null;
    fileDrop.empty();
  };
</script>

<style>
  .container {
    display: flex;
  }

  .left-panel {
    flex-grow: 2;
    max-width: calc(60% - 3em);
    padding-right: 3em;
  }

  .right-panel {
    flex-grow: 1;
    max-width: 40%;
  }

  .equal-split {
    display: flex;
  }

  .equal-split > * {
    flex-grow: 1;
  }

  .space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .field {
    display: flex;
    flex-direction: column;
    padding: 1em 0;
  }

  textarea {
    resize: none;
    height: 20em;
    border-radius: 3px;
  }

  .input-calendar {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    width: 12em;
  }

  .input,
  input {
    height: 2rem;
    border-radius: 3px;
  }

  label {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }

  *:placeholder-shown,
  option[disabled] {
    font-style: italic;
  }

  .actions {
    display: flex;
  }

  .actions > * {
    margin-right: 2em;
  }

  .multi {
    width: 100%;
    margin-bottom: 1em;
  }

  .lines {
    display: flex;
  }

  .dropzone {
    height: 12rem;
    width: 100%;
    border: 2px dashed #cccccc;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropzone.dragging {
    cursor: copy;
  }

  .image-drop {
    padding-top: 2em;
    display: flex;
  }

  .dropzone > img {
    height: 10rem;
    object-fit: contain;
    max-width: 90%;
  }

  .field button {
    min-width: 9.186em;
    padding: 0.5em 1em;
  }

  .dropped-image {
    max-width: 30em;
    padding-left: 1em;
    position: relative;
  }

  .remove-image {
    position: absolute;
    top: 2em;
    right: 0.5em;
    cursor: pointer;
  }
</style>

{#if message}
  <form class="container" on:submit|preventDefault={handleSubmit}>
    <div class="left-panel">
      <div class="equal-split">
        <div class="field">
          <label for="category">{$_('messages.categories.title')}: *</label>
          <select name="category" id="category" class="input" bind:value={category}>
            <option value="" disabled selected>{$_('messages.choose')}</option>
            {#each CATEGORIES as category}
              <option value={category}>
                {$_(`messages.categories.${category}`, { default: category })}
              </option>
            {/each}
          </select>
        </div>
        <div class="space-evenly">
          <div class="field">
            <label>{$_('messages.start')}: *</label>
            <DatePicker
              bind:selected={start}
              bind:dateChosen={startDateChosen}
              end={end}
              let:dateChosen
            >
              <div
                class="input input-calendar"
                style="margin: 0 1em;"
                placeholder={$_('search.choose.date')}
              >
                <span>{dateChosen ? $date(start) : ''}</span>
                <Icon icon={faCalendar} />
              </div>
            </DatePicker>
          </div>
          <div class="field">
            <label>{$_('messages.end')}:</label>
            <DatePicker
              {start}
              bind:selected={end}
              bind:dateChosen={endDateChosen}
              let:dateChosen
            >
              <div class="input input-calendar" placeholder={$_('search.choose.date')}>
                <span>{dateChosen ? $date(end) : ''}</span>
                <Icon icon={faCalendar} />
              </div>
            </DatePicker>
          </div>
        </div>
      </div>
      <div class="field">
        <label>{$_('messages.messageTitle')}: *</label>
        <input type="text" placeholder={$_('messages.writeTitle')} bind:value={title} />
      </div>
      <div class="field">
        <label>{$_('messages.messageContent')}: *</label>
        <textarea
          type="text"
          placeholder={$_('messages.writeContent')}
          bind:value={content}
        />
      </div>
      <div class="actions">
        <button type="submit" disabled={!isValid || saving}>
          {#if isNew}
            {$_('messages.publishMessage')}
          {:else}{$_('messages.save')}{/if}
        </button>
        {#if isNew}
          <button class="secondary" type="button" on:click={emptyFields}>
            {$_('messages.emptyFields')}
          </button>
        {:else}
          <button class="secondary" type="button" on:click={handleCancel}>
            {$_('messages.cancel')}
          </button>
        {/if}
      </div>
    </div>
    <div class="right-panel">
      <div class="field">
        {#if $companiesLoaded}
          <div class="multi">
            <label>{$_('messages.companies')}: *</label>
            <MultiSelect bind:value={companies} id="companies">
              {#each companyListFilteredByFeature as company}
                <option value={company.number}>
                  {`${company.name} (${company.number})`}
                </option>
              {/each}
            </MultiSelect>
          </div>
        {/if}
        <label>{$_('messages.target.name')}: *</label>
        <RadioButton label={$_('messages.allLines')} value="all" bind:group={target} />
        <div class="lines">
          <RadioButton
            label={$_('messages.selectedLines')}
            value="selectedLines"
            bind:group={target}
          />
          <MessageLines disabled={target !== 'selectedLines'} bind:lines />
        </div>
        <div class="image-drop">
          <div class="field">
            <label for="file">{$_('messages.addImage')}:</label>
            <div>
              <button type="button" on:click={() => fileDrop.click()} class="secondary">
                {$_('messages.chooseFile')}
              </button>
            </div>
          </div>
          <div class="field dropped-image">
            <DropZone
              bind:this={fileDrop}
              on:file={handleFile}
              let:dragging
              accept="image/*"
            >
              <div class="dropzone" class:dragging>
                {#if imageSrc}
                  <div class="remove-image" on:click|stopPropagation={handleRemoveImage}>
                    <Icon icon={faTrash} />
                  </div>
                  <img src={imageSrc} alt="" />
                {/if}
              </div>
            </DropZone>
          </div>
        </div>
      </div>
    </div>
  </form>
{/if}

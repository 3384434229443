<script>
  export let checked = false;
  export let label = '';
  export let noReverse = false;
  export let itemsToStart = false;
  export let disabled = false;
  export let bold = false;
  export let size = '20px';
  export let markColor = '#fff';
</script>

<style>
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    float: right;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .container.no-reverse {
    flex-direction: unset;
    margin-bottom: unset;
  }

  .container.items-to-start {
    justify-content: flex-end;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    top: 0;
    left: 0;
    height: var(--size);
    width: var(--size);
    min-height: var(--size);
    min-width: var(--size);
    max-height: var(--size);
    max-width: var(--size);
    background-color: #001E61;
    border: 1px solid #b2c3cf;
    padding: 1px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkmark.disabled {
    background-color: #fff;
  }

  .bold {
    border-width: 2px;
    padding: 0;
  }

  .checkmark:after {
    content: '';
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    width: calc(0.25 * var(--size));
    height: calc(0.6 * var(--size));
    border: 1px solid var(--markColor);
    border-width: 0 2px 2px 0;
    transform: translateY(-2px) rotate(45deg);
  }

  .label {
    margin-left: 1em;
  }

  .ext-slot {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }

  .mainContainer {
    min-width: 13rem;
  }
</style>
<div class="container">
  <label class="container mainContainer" class:no-reverse={noReverse} class:items-to-start={itemsToStart} style="--size: {size}; --markColor: {markColor};">
    <input type="checkbox" bind:checked on:change />
    <span class="checkmark {disabled ? 'disabled' : ''}" class:bold />
    <span class="label">{label}</span>
  </label>
  <span class="ext-slot"><slot /></span>
</div>
<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import ConfirmationModal from '../../components/ConfirmationModal.svelte';
  import SpecialPriceTableCreationModal from '../../components/pricing/SpecialPriceTableCreationModal.svelte';
  import SpecialPriceTableUpdateModal from '../../components/pricing/SpecialPriceTableUpdateModal.svelte';
  import PyramidTable from '../../components/PyramidTable.svelte';
  import DateRange from '../../components/pricing/DateRange.svelte';
  import DepartureTable from '../../components/pricing/DepartureTable.svelte';
  import {
    prices,
    addSpecialPricingLine,
    modifySpecialPricing,
    modifySpecialPricingLine,
    removeSpecialPricing,
    removeSpecialPricingLine,
    updateSpecialPriceTableValidity,
    SPECIAL_PRICING_TYPE_DISTANCE,
    SPECIAL_PRICING_TYPE_PRICE,
    VALIDITY_START_COL,
    VALIDITY_END_COL,    
  } from '../../stores/pricing';
  import Select from '../../components/Select.svelte';
  
  import OverlappingError from '../../components/pricing/OverlappingError.svelte';

  const { open } = getContext('simple-modal');

  export let currentCompany;

  let editingMode = false;
  let currentPriceTable = undefined;
  let dataFormatter = undefined;

  $: {
    if (
      $prices[currentCompany].specialPricings &&
      !$prices[currentCompany].specialPricings[currentPriceTable]
    ) {
      currentPriceTable = Object.keys($prices[currentCompany].specialPricings)[0];
    }
  }

  $: pricingType =
    $prices[currentCompany].specialPricings &&
    $prices[currentCompany].specialPricings[currentPriceTable] &&
    $prices[currentCompany].specialPricings[currentPriceTable].specialPricingType;

  $: {
    if (pricingType === SPECIAL_PRICING_TYPE_DISTANCE) {
      dataFormatter = value =>
        value !== null
          ? `${value.toLocaleString('fi-FI', { useGrouping: false })} ${$_(
              'pricing.distanceShort',
            )}`
          : '';
    } else if (pricingType === SPECIAL_PRICING_TYPE_PRICE) {
      dataFormatter = value =>
        Number.isFinite(value)
          ? (+value).toLocaleString('fi-FI', {
              style: 'currency',
              currency: 'EUR',
              useGrouping: false,
            })
          : '';
    }
  }

  $: priceTableTitle =
    pricingType === SPECIAL_PRICING_TYPE_DISTANCE
      ? $_('pricing.specialDistancePricingTable')
      : $_('pricing.specialConnectionPricingTable');

  $: lines =
    ($prices[currentCompany].specialPricings &&
      $prices[currentCompany].specialPricings[currentPriceTable].lines) ||
    [];
  $: lineNames =
    ($prices[currentCompany].specialPricings &&
      $prices[currentCompany].specialPricings[currentPriceTable].lineNames) ||
    [];
  $: lineIds =
    ($prices[currentCompany].specialPricings &&
      $prices[currentCompany].specialPricings[currentPriceTable].lineIds) ||
    [];

  const showSpecialPriceTableCreationModal = () => {
    open(SpecialPriceTableCreationModal, {
      companyId: currentCompany,
      existingNames:
        ($prices[currentCompany].lineBasicTickets &&
          Object.keys($prices[currentCompany].lineBasicTickets)) ||
        [],
      onSuccess: priceTableName => {
        editingMode = true;
        currentPriceTable = priceTableName;
      },
    });
  };

  const showSpecialPriceTableUpdateModal = () => {
    open(SpecialPriceTableUpdateModal, {
      companyId: currentCompany,
      priceTableName: currentPriceTable,
      onSuccess: priceTableName => {
        editingMode = true;
        currentPriceTable = priceTableName;
      },
    });
  };

  const showRemovePricingConfirmationModal = () => {
    open(ConfirmationModal, {
      title: $_('pricing.removeSpecialPricing'),
      body: $_('pricing.removeSpecialPricingConfirmation'),
      onConfirm: () => {
        removeSpecialPricing(currentCompany, currentPriceTable);
      },
    });
  };

  const distancesModified = (item, row, column, event) => {
    modifySpecialPricing(
      currentCompany,
      currentPriceTable,
      row,
      column,
      event.detail.value,
    );
  };

  const getTicketIdentifier = () => {
    return $prices[currentCompany].specialPricings[currentPriceTable]['ticketIdentifier'];
  };

  const startDateChanged = (val) => {
    updateSpecialPriceTableValidity(currentCompany, currentPriceTable, getTicketIdentifier(), VALIDITY_START_COL, val);
  };

  const endDateChanged = (val) => {
    updateSpecialPriceTableValidity(currentCompany, currentPriceTable, getTicketIdentifier(), VALIDITY_END_COL, val);
  };


  let dateEnd;
  let dateStart;
  let hasDateRange;
  let hasLines = false;
  $: {
    const specialTicket = currentCompany && currentPriceTable && $prices[currentCompany] && $prices[currentCompany].specialPricings && $prices[currentCompany].specialPricings[currentPriceTable];
    dateEnd = specialTicket && specialTicket[VALIDITY_END_COL];
    dateStart = specialTicket && specialTicket[VALIDITY_START_COL];
    hasLines =  specialTicket && specialTicket['lines'] && specialTicket['lines'].length > 0;

    hasDateRange = dateEnd || dateStart;
  }

  $: overlappingItems = currentCompany && $prices[currentCompany]['overlapping']['specialPricings'];

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div {
    overflow: auto;
  }
  div.title-container,
  div.subtitle-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.subbutton-container {
    flex: 1 1 0;
  }
  div.container {
    padding-top: 1rem;
  }
  div.select-container {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding-bottom: 0.25rem;
    min-height: 42px;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  div.line-container {
    padding-bottom: 3rem;
    max-width: 650px;
  }
  div.date-container {
    overflow: visible;
    padding-bottom: 3rem;
    padding-top: 1.75rem;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {editingMode ? $_('pricing.modifySpecialPricing') : $_('pricing.sections.special')}
    </h2>
  </div>
  <div class="button-container">
    {#if !editingMode}
      <button on:click={showSpecialPriceTableCreationModal}>
        {$_('pricing.addPricing')}
      </button>
    {/if}
  </div>
</div>
{#if overlappingItems && overlappingItems.length > 0}
  <OverlappingError overlappingItems={overlappingItems} nameProperty={'name'} />
{/if}
{#if $prices[currentCompany].specialPricings}
  <div class="subtitle-container">
    <div class="select-container">
      <Select
        flexGrow={true}
        disabled={editingMode}
        entries={Object.keys($prices[currentCompany].specialPricings)}
        bind:value={currentPriceTable}
      />
    </div>
    <div class="button-container subbutton-container">
      {#if !editingMode}
        <button
          on:click={() => {
            editingMode = true;
          }}
        >
          {$_('pricing.modify')}
        </button>
        <button class="secondary" on:click={showRemovePricingConfirmationModal}>
          {$_('pricing.remove')}
        </button>
      {:else}
        <button
          on:click={() => {
            editingMode = false;
          }}
        >
          {$_('pricing.done')}
        </button>
        <button class="secondary" on:click={showSpecialPriceTableUpdateModal}>
          {$_('pricing.loadNewFile')}
        </button>
      {/if}
    </div>
  </div>
  <div class="date-container">
    <DateRange
      isDefault={currentPriceTable === 'default'}
      startDateChanged={startDateChanged}
      endDateChanged={endDateChanged}
      defaultInUse={hasLines}
      dateEnd={dateEnd}
      dateStart={dateStart}
      {editingMode}
      />

  </div>  
  <div class="container">
    <div class="line-container">
      <DepartureTable
        {lines}
        {lineNames}
        {lineIds}
        {editingMode}
        onLineAdded={(line, departure, name) =>
          addSpecialPricingLine(currentCompany, currentPriceTable, line, departure, name)}
        onLineModified={(id, line, departure, name) =>
          modifySpecialPricingLine(
            currentCompany,
            currentPriceTable,
            id,
            line,
            departure,
            name,
          )}
        onLineDeleted={id =>
          removeSpecialPricingLine(currentCompany, currentPriceTable, id)}
      />
    </div>
    <div class="price-container">
      <PyramidTable
        list={$prices[currentCompany].specialPricings[currentPriceTable].specialDistances}
        headerColumnTitles={$prices[currentCompany].specialPricings[currentPriceTable]
          .stations}
        headerRowTitles={$prices[currentCompany].specialPricings[currentPriceTable]
          .stations}
        onDataEdited={distancesModified}
        dataType="decimal"
        tableTitle={priceTableTitle}
        {dataFormatter}
        {editingMode}
      />
    </div>
  </div>
{:else}
  <p>{$_('pricing.specialPricesMissing')}</p>
{/if}

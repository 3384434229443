<script>
  import { number, _ } from 'svelte-i18n';
  import { NO_SALE, ONLINE_TICKET, TRAVELCARD } from '../../stores/search';

  export let products = [];
  export let lastResult = false;
</script>

<style>
  tr.final td {
    border-bottom: 1px solid #cccccc;
  }

  td {
    border-color: #cccccc;
  }

  .final.last-result td {
    border-color: #333333;
  }

  td.text-right {
    padding-right: 2em;
  }

  td.price-vatfree {
    padding-right: 0.75em;
  }
</style>

{#each products as product, i}
  <tr class="bg-mlight">
    <td colspan="3" />
    <td class:border-t={i !== 0}>
      {#if product.product_name.startsWith('WALTTI') || product.product_name.startsWith(TRAVELCARD) || product.product_name === ONLINE_TICKET || ((product.product_name === NO_SALE || product.product_id === NO_SALE) && product.target_group && product.target_group !== 'null')}
        {product.target_group}
      {:else}
        {$_(`results.productNames.${product.product_name}`, {
          default: product.product_name,
        })}
      {/if}
    </td>
    <td class="text-right" class:border-t={i !== 0}>
      {product.quantity || '-'}
    </td>
    <td class="text-right price-vatfree" class:border-t={i !== 0}>
      {$number(product.sum, { format: 'EUR' }).replace('€', '') || '-'}
    </td>
    <td class="text-right" class:border-t={i !== 0}>
      {$number(product.sum_vat, { format: 'EUR' }).replace('€', '') || '-'}
    </td>
    <td colspan="6" />
  </tr>
{/each}
<tr class="final bg-mlight" class:last-result={lastResult}>
  <td colspan="13" class="pt-2" />
</tr>

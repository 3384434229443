<script>
  import { getContext } from 'svelte';
  import InputField from '../InputField.svelte';
  import debounce from 'debounce';
  import { fetchDeviceInformation, transferDeviceInformation } from '../../stores/devices';
  import { showSpinnerOverlay, dismissSpinnerOverlay } from '../../stores/spinner';
  import LoadingSpinner from '../icons/LoadingSpinner.svelte';
  import { _ } from 'svelte-i18n';

  const DEVICE_SEARCH_DELAY_MS = 500;

  const { close } = getContext('simple-modal');

  export let sourceDevice;
  export let onSuccess;

  let deviceId;
  let loading = false;
  let inputError = false;
  let deviceFetched = false;
  let errorMessage = undefined;
  let overwriting = false;

  const fetchDeviceFunction = async (deviceId, onDeviceFetched, onError) => {
    try {
      const result = await fetchDeviceInformation(deviceId);
      onDeviceFetched(result);
    } catch (error) {
      onError(error);
    }
  };

  $: {
    overwriting = false;
    deviceFetched = false;
    inputError = false;
    if (deviceId && deviceId.toString().length == 8) {
      if (deviceId == sourceDevice) {
        inputError = true;
      }
      else {
        loading = true;
        debouncedFetch(deviceId, (result) => {
          if (result && result.features && result.features.length) {
            overwriting = true;
          }
          loading = false;
          deviceFetched = true;
        }, (_error) => {
          inputError = true;
          loading = false;
        });
      }
    }
  }

  $: isValid = deviceFetched && !inputError && !loading;

  $: {
    if (inputError) {
      if (deviceId && deviceId == sourceDevice) {
        errorMessage = $_('devices.deviceIdSameAsSource');
      }
      else {
        errorMessage = $_('devices.deviceIdNotFound');
      }
    }
    else {
      errorMessage = $_('devices.deviceIdMustBe8Characters');
    }
  }

const debouncedFetch = debounce(fetchDeviceFunction, DEVICE_SEARCH_DELAY_MS);

</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  button {
    min-width: 150px;
  }
  .input-container {
    width: 350px;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
</style>

<div>
  <h2>{$_('devices.transferFeatures')}</h2>
  <p>{$_('devices.transferFeaturesDescription')}</p>
  <div class="input-container">
    <InputField
      id="passengerCount"
      label={$_('devices.deviceIdentifier')}
      type="number"
      maxLength={8}
      validator={(input) => input.toString().length == 8}
      required
      inputIcon={loading ? LoadingSpinner : undefined}
      bind:value={deviceId}
      forceError={inputError}
      {errorMessage}
    />
  </div>
  {#if overwriting}
  <p>{$_('devices.overwriteNotice', { values: { deviceId } })}</p>
  {/if}
  <div class="button-container">
    <button
      class="small"
      disabled={!isValid}
      on:click={async () => {
        try {
        showSpinnerOverlay();
        await transferDeviceInformation(sourceDevice, deviceId);
        close();
        onSuccess();
        } catch (error) {
          console.error(error);
        } finally {
          dismissSpinnerOverlay();
        }
      }}
    >
      {$_('pricing.done')}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        close();
      }}
    >
      {$_('pricing.cancel')}
    </button>
  </div>
</div>

<script>
  import { _, date } from 'svelte-i18n';
  import Icon from 'fa-svelte';
  import Form from '../../components/Form.svelte';
  import { faCalendar, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
  import DatePicker from '../../components/DatePicker.svelte';
  import Checkbox from '../../components/Checkbox.svelte';
  import { serviceLineCreateState, createServiceLine, modifyServiceLine } from '../../stores/lines';
  import InputField from '../../components/InputField.svelte';

  export let isValid = false;
  export let inProgress;
  export let onSuccess;

  let endTimeTooEarly = false;
  let endDateTooEarly = false;

  let formValid;
  let fieldValidity;

  const WeekDayNames = Object.freeze([
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun'
  ]);

  const checkDateRangeValidity = () => {
    if ($serviceLineCreateState.validFrom && $serviceLineCreateState.validUntil) {
      return $serviceLineCreateState.validFrom > $serviceLineCreateState.validUntil;
    } else {
      return false;
    }
  };

  const checkTimeRangeValidity = () => {
    if (fieldValidity && $fieldValidity.startingTime && $fieldValidity.endingTime && $serviceLineCreateState.startingTime && $serviceLineCreateState.endingTime) {
      return $serviceLineCreateState.startingTime >= $serviceLineCreateState.endingTime;
    } else {
      return false;
    }
  };

  const validateCrossConstraints = () => {
    endTimeTooEarly = checkTimeRangeValidity();
  };

  $: {
    $serviceLineCreateState.validFrom;
    $serviceLineCreateState.validUntil;
    endDateTooEarly = checkDateRangeValidity();
  }

  $: {
    isValid = formValid && !endTimeTooEarly && !endDateTooEarly && $serviceLineCreateState.selectedWeekDays && $serviceLineCreateState.selectedWeekDays.size;
  }

  const finishLineCreation = async () => {
    try {
      const data = await ($serviceLineCreateState.modification ? modifyServiceLine() : createServiceLine());
      inProgress = false;
      onSuccess(data);
    } catch (error) {
      // api error already caught on store side
    }
  };

  const cancelLineCreation = () => {
    inProgress = false;
  };

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.title-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  label {
    font-size: 1em;
    margin-bottom: 0.25rem;
    display: block;
  }
  .input-div {
    margin-bottom: 1.5rem;
  }
  .input-container > :global(.input-field-main-div) {
    margin-bottom: 1.5rem;
    max-width: 640px;
  }
  .input {
    width: 100%;
    padding: 0.4rem;
    height: unset;
    font-size: 1rem;
    margin: 0;
    max-width: 640px;
  }
  div.input-container {
    flex-grow: 1;
    flex-basis: 12rem;
  }
  .input-main-container {
    max-width: 800px;
  }
  .picker > :global(.datepicker) {
    width: 100%;
  }
  .checkbox > :global(label) > :global(.label) {
    margin-left: 0;
    margin-right: 1rem;
    font-size: 1rem;
  }
  .button-container > button {
    margin-left: 2rem;
  }
  .bottom-button-container > button {
    margin-right: 2rem;
  }
  button {
    min-width: 120px;
  }
  p.input-error {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
    color: #EA5798;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {$_($serviceLineCreateState.modification ? 'lines.service.serviceModificationTitle' : 'lines.service.serviceCreationTitle')}
    </h2>
  </div>

  <div class="button-container hidden lg:flex lg:items-center lg:justify-end">
    <button class="secondary" on:click={() => { cancelLineCreation(); }}>{$_('pricing.cancel')}</button>
    <button disabled={!isValid} on:click={() => { finishLineCreation(); }}>{$_(`lines.service.${$_($serviceLineCreateState.modification ? 'buttonModificationFinish' : 'buttonCreateFinish')}`)}</button>
  </div>
</div>
<div class="flex w-full justify-between">

  <div class="input-main-container flex flex-col lg:flex-row flex-grow">
    <Form bind:isValid={formValid} bind:validity={fieldValidity} on:validated={validateCrossConstraints}>
    <div class="input-container lg:mr-4">
      <InputField
        id="lineName"
        type="text"
        label={$_('lines.service.serviceLineName')}
        bind:value={$serviceLineCreateState.lineName}
        errorMessage={$_('lines.common.inputErrorRequired')}
        required
      />
      <div class="input-div picker">
        <label for="end">{$_('lines.service.validFrom')}: *</label>
        <DatePicker bind:selected={$serviceLineCreateState.validFrom} start={new Date()} end={$serviceLineCreateState.validUntil}>
          <div class="input flex justify-between cursor-pointer
            items-center" placeholder={$_('lines.service.validFrom')}>
            <span>{$date($serviceLineCreateState.validFrom, { format: 'twoDigitsFullYear' })}</span>
            <Icon icon={faCalendar} />
          </div>
        </DatePicker>
      </div>
      <div class="input-div picker">
        <label for="end">{$_('lines.service.validUntil')}:</label>
        <DatePicker bind:selected={$serviceLineCreateState.validUntil} start={$serviceLineCreateState.validFrom} allowEmpty={true}>
          <div class="input flex justify-between cursor-pointer
            items-center" placeholder={$_('lines.service.validUntil')}>
            <span>{$serviceLineCreateState.validUntil ? $date($serviceLineCreateState.validUntil, { format: 'twoDigitsFullYear' }) : '\u00A0'}</span>
            <div class="flex p-1 -mr-1" on:click={(event) => {
              if ($serviceLineCreateState.validUntil) {
                $serviceLineCreateState.validUntil = undefined;
                event.stopPropagation();
              }
            }}><Icon icon={$serviceLineCreateState.validUntil ? faCalendarTimes : faCalendar} /></div>
          </div>
        </DatePicker>
      </div>
      {#if endDateTooEarly}
      <p class="input-error">{$_('lines.service.inputErrorEndingDateTooEarly')}</p>
      {/if}
      <InputField
        id="startingTime"
        type="time"
        label={$_('lines.service.startingTime')}
        placeholder={$_('lines.common.timePlaceholder')}
        bind:value={$serviceLineCreateState.startingTime}
        errorMessage={$_('lines.common.inputErrorTime')}
        required
      />
      <InputField
        id="endingTime"
        type="time"
        label={$_('lines.service.endingTime')}
        placeholder={$_('lines.common.timePlaceholder')}
        bind:value={$serviceLineCreateState.endingTime}
        errorMessage={$_('lines.common.inputErrorTime')}
        required
      />
      {#if endTimeTooEarly}
      <p class="input-error">{$_('lines.service.inputErrorEndingTimeTooEarly')}</p>
      {/if}
      <div class="input-div">
        <p>{$_('lines.service.operatingDays')}: *</p>
        <div class="checkbox flex flex-row flex-wrap">
          {#each WeekDayNames as weekday, index}
            <div class="flex mt-1 w-20">
              <Checkbox size="32px" markColor={'#001E61'} checked={$serviceLineCreateState.selectedWeekDays && $serviceLineCreateState.selectedWeekDays.has(index + 1)} label={$_(`calendar.days.short.${weekday}`)}
                on:change={(event) => {
                  $serviceLineCreateState.selectedWeekDays = new Set($serviceLineCreateState.selectedWeekDays);
                  if (event.target.checked) {
                    $serviceLineCreateState.selectedWeekDays.add(index + 1);
                  } else {
                    $serviceLineCreateState.selectedWeekDays.delete(index + 1);
                  }
                }}
              />
            </div>
          {/each}
        </div>
      </div>
    </div>
    <div class="hidden lg:block flex-grow" />
    <div class="input-container lg:ml-4">
      <InputField
        id="serviceLineArea"
        type="text"
        label={$_('lines.service.serviceLineArea')}
        placeholder={$_('lines.service.serviceLineAreaPlaceholder')}
        bind:value={$serviceLineCreateState.serviceLineArea}
        errorMessage={$_('lines.common.inputErrorRequired')}
        required
      />
      <InputField
        id="capacity"
        label={$_('lines.service.capacity')}
        type="number"
        placeholder={$_('lines.service.capacityPlaceholder')}
        bind:value={$serviceLineCreateState.capacity}
      />
    </div>
  </Form>
  </div>

</div>
<div class="w-full flex justify-start lg:hidden bottom-button-container">
  <button class="secondary" on:click={() => { cancelLineCreation(); }}>{$_('pricing.cancel')}</button>
  <button disabled={!isValid} on:click={() => { finishLineCreation(); }}>{$_(`lines.service.${$_($serviceLineCreateState.modification ? 'buttonModificationFinish' : 'buttonCreateFinish')}`)}</button>
</div>

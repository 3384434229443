<script>
  import { _, locale } from 'svelte-i18n';
  import { getContext } from 'svelte';
  import Loading from '../Loading.svelte';
  import { companies as companyList } from '../../stores/companies';
  import { showError } from '../../stores/notifications';
  import {
    searchLoaded,
    searchLoading,
    searchResults,
    markRowsAccounted,
    unmarkRowsAccounted,
    markCommentsAsAccounted,
    unmarkCommentsAsAccounted,
    cancelRow,
    postRow,
    cancelAddedRow,
    isTravelcard,
    MULTIPLE,
  } from '../../stores/search';
  import {
    getExcelDownload,
    getAccountingReportDownload,
    getAccountedReportDownload,
  } from '../../common/http';
  import ConfirmationModal from '../ConfirmationModal.svelte';
  import Modal from '../Modal.svelte';
  import NewRowForm from './NewRowForm.svelte';
  import SummaryTable from './SummaryTable.svelte';
  import SummaryAccounted from './SummaryAccounted.svelte';
  import ResultTable from './ResultTable.svelte';

  export let start;
  export let end;
  export let departureId;
  export let lineId;
  export let driverId;
  export let deviceId;
  export let companies;
  export let receiptNumber;
  export let unaccounted;
  export let onlyAdded;
  export let tickets;

  const { open } = getContext('simple-modal');

  let firstWidth, secondWidth, thirdWidth;

  let checkedRowIds = [];
  let selectedCompany = undefined;
  let allCheckboxChecked = false;
  let rowFilter = {};
  $: currentCompanyRowFilter = rowFilter[selectedCompany];

  searchResults.subscribe(value => {
    if (!value.find(x => x.company === selectedCompany)) {
      selectedCompany = value[0] && value[0].company;
    }
  });

  const getCurrentCompanyResults = (searchResults, selectedCompany, rowFilter) => {
    const reports =
      (searchResults.find(x => x.company === selectedCompany) || {}).reports || [];
    return rowFilter
      ? reports.filter(({ id, transaction_id }) =>
          rowFilter.includes(transaction_id || id),
        )
      : reports;
  };

  $: currentCompanyResults = getCurrentCompanyResults(
    $searchResults,
    selectedCompany,
    currentCompanyRowFilter,
  );

  $: checkedRows = currentCompanyResults.filter(entry =>
    checkedRowIds.includes(entry.transaction_id || entry.id),
  );

  // Android client always refunds whole receipts (original_transaction_id is missing), reporting allows single row refunds
  $: refundedReceiptNumbers = currentCompanyResults
    .filter(entry => !entry.original_transaction_id && entry.original_receipt_number)
    .map(entry => +entry.original_receipt_number);
  $: refundedTransactions = currentCompanyResults
    .filter(entry => entry.original_transaction_id)
    .map(entry => entry.original_transaction_id);

  $: refundButtonVisible =
    checkedRows &&
    checkedRows.length === 1 &&
    !checkedRows[0].refund &&
    !checkedRows[0].accounted &&
    !checkedRows[0].external_sales_channel &&
    !refundedReceiptNumbers.includes(checkedRows[0].receipt_number) &&
    !refundedTransactions.includes(checkedRows[0].transaction_id);

  $: results = getResults(currentCompanyResults, $companyList);

  $: total = results.reduce(
    (acc, { quantity: cur, event_type, payment_method }) =>
    {
      let quantity = cur;
      if (isTravelcard(event_type)) {
        quantity = 1;
      }
      else if (payment_method === MULTIPLE) {
        quantity = 2;
      }
      return acc + quantity;
    }
      ,
    0,
  );

  const showCancelConfirmationModal = () => {
    open(ConfirmationModal, {
      title: $_('results.cancelEventConfirmationTitle'),
      body: $_('results.cancelEventConfirmationText'),
      onConfirm: () => {
        const row = checkedRows[0];
        if (!row) return;
        if (row.isAddedRow) cancelAddedRow(row.id, commentsHash);
        else cancelRow(row);
      },
    });
  };

  const getResults = (searchResults, companies) =>
    searchResults.map(result => ({
      ...result,
      company: companies.filter(({ number }) => number === result.company_id)[0] || {
        name: result.company,
        number: result.company,
      },
    }));
  const getReportedCompanies = (reports, companies) =>
    companies.filter(
      ({ number }) => (reports || []).filter(({ company }) => company === number).length,
    );

  $: reportedCompanies = getReportedCompanies($searchResults, $companyList);

  let newRowOpened = false;

  const getEndDate = date => {
    const end = new Date(date);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(999);
    return end;
  };

  const getStartDate = date => {
    const start = new Date(date);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return start;
  };

  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const res = await getExcelDownload(
      {
        lang,
        start: getStartDate(start),
        end: getEndDate(end),
        departureId,
        lineId,
        driverId,
        deviceId,
        companies,
        receiptNumber,
        unaccounted,
        onlyAdded,
        tickets,
      },
      rowFilter,
    );
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const createAccountingReport = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const res = await getAccountingReportDownload(
      {
        lang,
        start: getStartDate(start),
        end: getEndDate(end),
        departureId,
        lineId,
        driverId,
        deviceId,
        companies,
        receiptNumber,
        unaccounted,
        onlyAdded,
        tickets,
      },
      rowFilter,
    );
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const createAccountedReport = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const res = await getAccountedReportDownload(
      {
        lang,
        start: getStartDate(start),
        end: getEndDate(end),
        departureId,
        lineId,
        driverId,
        deviceId,
        companies,
        receiptNumber,
        unaccounted,
        onlyAdded,
        tickets,
      },
      rowFilter,
    );
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  $: checkedRow = checkedRows.length === 1 && checkedRows[0];
  $: editingRow = checkedRow && checkedRow.isAddedRow && checkedRow.id;

  const errorNotification = showError($_('error.title'), 5000);

  $: commentsHash = {
    start: getStartDate(start),
    end: getEndDate(end),
    departureId,
    lineId,
    driverId,
    deviceId,
    company: selectedCompany,
    receiptNumber,
  };

  const handleRowSubmitted = async ({ detail: row }) => {
    newRowOpened = false;
    try {
      await postRow(row, editingRow, commentsHash);
    } catch (e) {
      console.error(e);
      errorNotification(e);
    }
  };

  const handleMarkRowsAccounted = async () => {
    const transactions = [];
    const comments = [];
    checkedRows
      .filter(entry => !entry.accounted)
      .forEach(entry => {
        if (entry.isAddedRow) comments.push(entry);
        else transactions.push(entry);
      });
    if (transactions.length) await markRowsAccounted(transactions);
    if (comments.length) markCommentsAsAccounted(comments, commentsHash);
    checkedRowIds = [];
  };

  const handleUnmarkRowsAsAccounted = async () => {
    const transactions = [];
    const comments = [];
    checkedRows
      .filter(entry => entry.accounted)
      .forEach(entry => {
        if (entry.isAddedRow) comments.push(entry);
        else transactions.push(entry);
      });
    if (transactions.length) await unmarkRowsAccounted(transactions);
    if (comments.length) unmarkCommentsAsAccounted(comments, commentsHash);
    checkedRowIds = [];
  };
</script>

<style>
  .tab {
    transition: color 250ms ease-in-out;
  }

  .tab::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0;
    background-color: #fff0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0%;
    transition: width 250ms ease-in-out;
  }

  .tab.active::after {
    background-color: #06324c;
    width: calc((100% - 2em) * 0.8);
  }

  .flex-basis-0 {
    flex-basis: 0;
  }
</style>

{#if $searchLoading}
  <div class="flex items-center justify-center w-full">
    <Loading />
  </div>
{/if}

{#if !$searchLoading && $searchLoaded}
  <div class="mb-8">
    {#each reportedCompanies as company}
      <span
        class="tab text-mnormal text-center cursor-pointer py-1 px-3 relative"
        on:click={() => (selectedCompany = company.number)}
        class:active={selectedCompany === company.number}
        class:text-mdark={selectedCompany === company.number}
      >
        {company.name}
      </span>
    {/each}
  </div>
  {#if results.length}
    <ResultTable
      {results}
      {currentCompanyRowFilter}
      bind:firstWidth
      bind:secondWidth
      bind:thirdWidth
      bind:allCheckboxChecked
      bind:checkedRowIds
    />
    <div class="flex relative">
      <div
        class="flex flex-col"
        style="width: {currentCompanyRowFilter ? firstWidth : secondWidth}px"
      >
        <div class="flex w-full">
          <div class="flex flex-col space-y-4" style="width: {firstWidth}px">
            <p
              class="mt-4 text-mnormal cursor-pointer w-max-content"
              on:click={() => (newRowOpened = true)}
            >
              {editingRow ? $_('results.editAddedRow') : $_('results.addRow')}
            </p>
            <p>{$_('results.totalCount').replace('%d', total)}</p>
            <div class="flex space-x-4">
              <button class="w-max-content px-4" on:click={createExcel}>
                {$_('results.createExcel')}
              </button>
              <button class="w-max-content px-4" on:click={createAccountingReport}>
                {$_('results.createAccountingReport')}
              </button>
              <button class="w-max-content px-4" on:click={createAccountedReport}>
                {$_('results.createAccountedReport')}
              </button>
            </div>
          </div>
          {#if !currentCompanyRowFilter}
            <div class="flex-grow mr-4">
              <SummaryAccounted {results} />
            </div>
          {/if}
        </div>
        <div class="flex space-x-4 mt-4">
          {#if checkedRows.length > 0 && checkedRows.some(entry => !entry.accounted)}
            <button
              class="secondary w-max-content px-4"
              on:click={handleMarkRowsAccounted}
            >
              {$_('results.markAccounted')}
            </button>
          {/if}
          {#if checkedRows.length > 0 && checkedRows.some(entry => entry.accounted)}
            <button
              class="secondary w-max-content px-4"
              on:click={handleUnmarkRowsAsAccounted}
            >
              {$_('results.unmarkAccounted')}
            </button>
          {/if}
          {#if refundButtonVisible}
            <button
              class="secondary w-max-content px-4"
              on:click={showCancelConfirmationModal}
            >
              {$_('results.cancelEvent')}
            </button>
          {/if}
          {#if checkedRowIds.length > 0 && !currentCompanyRowFilter}
            <button
              class="secondary w-max-content px-4"
              on:click={() => {
                rowFilter[selectedCompany] = checkedRowIds;
              }}
            >
              {$_('results.showOnlySelected')}
            </button>
          {/if}
        </div>
      </div>
      <div
        class:bg-mlight={currentCompanyRowFilter}
        class="flex items-end flex-basis-0 flex-grow pb-4"
      >
        {#if currentCompanyRowFilter}
          <div class="flex justify-center" style="width: {secondWidth - firstWidth}px">
            <button
              class="secondary"
              on:click={() => {
                rowFilter[selectedCompany] = null;
              }}
            >
              {$_('results.goBack')}
            </button>
          </div>
        {/if}
        <div class="flex-grow flex-basis-0">
          <SummaryTable {results} />
        </div>
      </div>
    </div>
    <Modal bind:opened={newRowOpened}>
      <NewRowForm
        bind:isOpen={newRowOpened}
        isNew={checkedRow && checkedRow.explanation}
        on:add={handleRowSubmitted}
        sum={(checkedRow && checkedRow.sum) || null}
        explanation={(checkedRow && checkedRow.explanation) || ''}
      />
    </Modal>
  {:else}
    <h3>{$_('results.noResults')}</h3>
  {/if}
{/if}

import './i18n';
import App from './App.svelte';
import { init } from './stores/user';

init();

const app = new App({
  target: document.body,
});

export default app;

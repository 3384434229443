<script>
  import { flip } from 'svelte/animate';
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import ConfirmableInlineInput from '../ConfirmableInlineInput.svelte';

  export let list;
  export let key;
  export let onAddRightClicked;
  export let onRemoveRowClicked;
  export let onModifyColumnClicked;
  export let onPrimaryColumnEdited;
  export let onDurationEdited;
  export let uneditableColumnTitles = [];
  export let editableColumnEntries = null;
  export let onDataEdited;
  export let droppableId = 'default'; // If multiple lists on the same page, each needs a unique id if dragging across lists needs to be prevented
  export let editingMode = false;
  export let onAddRowClicked;
  export let allowEmpty = true;
  export let showTempRows = false;
  export let createBlankTempEntry;
  export let tempRowValid;
  export let onRowWillBeCreated;

  let isOver = false;
  const dispatch = createEventDispatcher();

  const getDraggedParent = node =>
    node.dataset && node.dataset.index ? node.dataset : getDraggedParent(node.parentNode);

  const handleDragStart = e => {
    e.dataTransfer.setData('source', e.target.dataset.index);
    e.dataTransfer.setData('sourceZone', e.target.dataset.dropzone);
  };

  const handleDragOver = e => {
    e.preventDefault();
    const dragged = getDraggedParent(e.target);
    if (!uneditableColumnTitles.includes(+dragged.index) && isOver !== dragged.id)
      isOver = dragged.id;
  };

  const handleDragLeave = e => {
    const dragged = getDraggedParent(e.target);
    if (isOver === dragged.id) isOver = false;
  };

  const handleDragDrop = e => {
    isOver = false;
    e.preventDefault();
    const dragged = getDraggedParent(e.target);
    const sourceZone = e.dataTransfer.getData('sourceZone');
    if (dragged.dropzone !== sourceZone) {
      return;
    }
    const from = +e.dataTransfer.getData('source');
    const to = +dragged.index;
    reorder({ from, to });
  };

  const reorder = ({ from, to }) => {
    if (uneditableColumnTitles.includes(to)) {
      return;
    }
    let newList = [...list];
    const [movedItem] = newList.splice(from, 1);
    newList.splice(to, 0, movedItem);
    dispatch('sort', {
      list: newList,
      item: movedItem,
      from,
      to,
    });
  };

  const getKey = item => (key ? item[key] : item);

  let tempEntries = [];

  const addRow = () => {
    tempEntries = [...tempEntries, createBlankTempEntry()];
  };

  const removeRow = index => {
    tempEntries = [...tempEntries];
    tempEntries.splice(index, 1);
  };

  const onTempRowComplete = (entry, index) => {
    if (tempRowValid(entry)) {
      if (onRowWillBeCreated(entry)) {
        removeRow(index);
      }
    }
  };
</script>

<style>
  td {
    border-bottom: solid 1px #D1D1D2;
  }
  th {
    font-weight: normal;
  }
  th,
  td {
    padding: 0.75rem 1rem;
  }
  td.editable {
    padding: 0rem calc(0.6rem - 1px);
    background-color: #eef5fb;
  }
  th.editable {
    background-color: #eef5fb;
    cursor: pointer;
  }
  th.over {
    filter: brightness(0.95);
  }
  th[draggable='true'] {
    cursor: grab;
  }
  th[draggable='true']:active {
    cursor: grabbing;
  }
  td.right-align {
    text-align: right;
    white-space: nowrap;
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
  }
  .text-button {
    color: #001E61;
    cursor: pointer;
  }
  .text-button:hover {
    color: #173043;
  }
  th {
    padding: 0.75rem 1rem;
    border-bottom: solid 1px #173043;
    color: #5B6670;
  }
  .faux-row {
    padding: 0.75rem 1rem;
  }
  .text-button.add-group-button {
    text-align: left;
  }
</style>

<table>
  <tr>
    <th width="130" />
    <th width="150" />
    {#each list as item, index (getKey(item))}
      <th
        class="text-right"
        width="130"
        class:editable={editingMode && !uneditableColumnTitles.includes(index)}
        on:click={editingMode && !uneditableColumnTitles.includes(index)
          ? () => onModifyColumnClicked(item, index)
          : undefined}
        data-index={index}
        data-id={getKey(item)}
        data-dropzone={droppableId}
        draggable={editingMode && !uneditableColumnTitles.includes(index)}
        on:dragstart={handleDragStart}
        on:dragover={handleDragOver}
        on:dragleave={handleDragLeave}
        on:drop={handleDragDrop}
        animate:flip={{ duration: 300 }}
        class:over={getKey(item) === isOver}
      >
        {`${item.name}${item.discount ? ` -${item.discount}%` : ''}`}
      </th>
    {/each}
    {#if editingMode}
      <th class="text-button add-group-button" on:click={onAddRightClicked}
        >{$_('pricing.addCustomerGroup')}</th
      >
    {/if}
  </tr>
  <tr class="subtitle">
    <td>{$_('pricing.zone')}</td>
    <td>{$_('pricing.validityTimeMinutes')}</td>
    {#each list as item}
      <td class="text-right">{$_('pricing.priceShort')}</td>
    {/each}
    {#if editingMode}
      <td />
    {/if}
  </tr>
  {#if list && list.length}
    {#each list[0].zonePrices as price, index}
      <tr>
        {#if editingMode}
          <td class="editable"
            ><ConfirmableInlineInput
              {allowEmpty}
              type="text"
              value={price.zone}
              on:complete={event => onPrimaryColumnEdited(price, index, event)}
            /></td
          >
          <td class="editable"
            ><ConfirmableInlineInput
              {allowEmpty}
              type="number"
              value={price.duration}
              on:complete={event => onDurationEdited(price, index, event)}
            /></td
          >
        {:else}
          <td>{price.zone}</td>
          <td>{Number.isFinite(price.duration) ? price.duration : ''}</td>
        {/if}
        {#each list as item, itemIndex (getKey(item))}
          <td
            class="text-right"
            class:editable={editingMode &&
              (!editableColumnEntries || editableColumnEntries.includes(itemIndex))}
            animate:flip={{ duration: 300 }}
          >
            {#if editingMode && (!editableColumnEntries || editableColumnEntries.includes(itemIndex))}
              <ConfirmableInlineInput
                textAlignment="right"
                {allowEmpty}
                type="price"
                value={item.zonePrices[index].price}
                on:complete={event => onDataEdited(item, index, itemIndex, event)}
              />
            {:else}
              {item.zonePrices[index] &&
                (+item.zonePrices[index].price).toLocaleString('fi-FI', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: false,
                })}
            {/if}
          </td>
        {/each}
        {#if editingMode}
          <td class="right-align"
            ><span
              class="text-button"
              on:click={editingMode ? () => onRemoveRowClicked(price, index) : undefined}
              >{editingMode ? $_('pricing.remove') : ''}</span
            ></td
          >
        {/if}
      </tr>
    {/each}
  {/if}
  {#if editingMode && showTempRows}
    {#each tempEntries as entry, index (`custom-${entry.id}`)}
      <tr>
        <td class="editable"
          ><ConfirmableInlineInput
            type="text"
            bind:value={entry.zone}
            on:complete={() => {
              onTempRowComplete(entry, index);
            }}
          /></td
        >
        <td class="editable"
          ><ConfirmableInlineInput
            type="number"
            bind:value={entry.duration}
            on:complete={() => {
              onTempRowComplete(entry, index);
            }}
          /></td
        >
        {#each entry.prices as price, priceIndex}
          <td class="editable">
            <ConfirmableInlineInput
              textAlignment="right"
              type="price"
              bind:value={price}
              on:complete={() => {
                onTempRowComplete(entry, index);
              }}
            />
          </td>
        {/each}
        <td class="right-align"
          ><span
            class="text-button"
            on:click={() => {
              removeRow(index);
            }}>{$_('pricing.remove')}</span
          ></td
        >
      </tr>
    {/each}
  {/if}
</table>
{#if editingMode}
  <div class="text-button faux-row" on:click={showTempRows ? addRow : onAddRowClicked}>
    {$_('pricing.addRow')}
  </div>
{/if}

<script>
  import { createEventDispatcher } from 'svelte';
  import { showError } from '../../stores/notifications';
  import Loading from '../Loading.svelte';
  import { _ } from 'svelte-i18n';
  import {
    supportRequestLoading,
    supportRequestLoaded,
    supportRequestResults,
    getSupportRequests,
  } from '../../stores/support_requests';

  if (!$supportRequestLoaded) {
    getSupportRequests().catch(showError($_('error.title')));
  }

  const dispatch = createEventDispatcher();

  const handleRequestClick = request => {
    dispatch('request', request.id);
  };
</script>

<style>
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  table {
    color: #333333;
    flex-grow: 1;
  }

  th {
    padding-right: 8rem;
    border-bottom: 1px solid #333333;
    font-weight: 600;
    text-align: start;
  }

  th:first-child {
    padding-left: 0;
  }

  td:first-child {
    padding-left: 0;
    padding-right: 1rem;
    min-width: 8rem;
  }

  td {
    padding-right: 3rem;
    height: 2.5em;
  }

  tr td {
    border-bottom: 1px solid #cccccc;
  }
  td:first-child,
  td:nth-child(2) {
    color: #001E61;
    cursor: pointer;
  }

  .panels > div {
    padding: 0 5em;
  }

  .unhandled {
    color: #ee77ac;
  }
</style>

{#if $supportRequestLoading}
  <div class="loading-content">
    <Loading />
  </div>
{:else}
  <table cellspacing="0">
    <tr>
      <th>{$_('supportRequests.timestamp')}</th>
      <th>{$_('supportRequests.deviceId')}</th>
      <th style="width: 100%">{$_('supportRequests.category')}</th>
      <th>{$_('supportRequests.status.title')}</th>
    </tr>
    {#each $supportRequestResults as request}
      <tr>
        <td on:click={() => handleRequestClick(request)}>
          {request.timestamp.format('D.M.YYYY H:mm')}
        </td>
        <td on:click={() => handleRequestClick(request)}>
          {request.device || '-'}
        </td>
        <td>
          {$_(`supportRequests.reasons.${request.reason}`, {
            default: request.reason || '-',
          })}
        </td>
        <td class:unhandled={request.status !== 'HANDLED'}>
          {$_(`supportRequests.status.${request.status}`, {
            default: request.status,
          })}
        </td>
      </tr>
    {/each}
  </table>
{/if}

<script>
  import { _, locale, number } from 'svelte-i18n';
  import Loading from '../Loading.svelte';
  import { companies as companyList } from '../../stores/companies';
  import {
    searchLoaded,
    searchLoading,
    searchResults,
  } from '../../stores/travel_reports';
  import { getExcelDownloadGroupedLines } from '../../common/http';
  import PassengerIcon from '../images/Passenger.svelte';
  import ShipmentIcon from '../images/Shipment.svelte';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import Icon from 'fa-svelte';
  import ProductReport from './ProductReport.svelte';

  export let start;
  export let end;

  export let lineId;
  export let departureId;
  export let companies;
  export let weekdays;
  export let shipmentWidth = null;
  export let totalSumWidth = null;
  export let euroPerKmWidth = null;
  export let tickets;
  export let deviceType;

  let selectedLine;

  $: results = getResults($searchResults.lines, $companyList);

  const getResults = (searchResults, companies) =>
    searchResults.map(result => ({
      ...result,
      company: companies.filter(({ number }) => number === result.company_id)[0] || {
        name: result.company_id,
        number: result.company_id,
      },
    }));

  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const excel = getExcelDownloadGroupedLines;
    const res = await excel({
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId,
      departureId,
      companies,
      weekdays,
      lang,
      tickets,
      deviceType,
    });
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
  };
</script>

<style>
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  table.table-results {
    width: 100%;
  }

  .table-results th {
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  .table-results th,
  .table-results th span {
      }

  tr:not(.selected) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:not(.selected).last-result td {
    border-bottom: 1px solid #333333;
  }

  td {
    padding: 0;
    color: inherit;
    padding-right: 2em;
    padding-bottom: 0.7em;
    padding-top: 1em;
  }

  tr:not(:first-child):not(:last-child) {
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: center;
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }
</style>

{#if $searchLoading}
  <div class="loading-content">
    <Loading />
  </div>
{/if}

{#if !$searchLoading && $searchLoaded}
  {#if results.length}
    <table class="table-results" cellspacing="0">
      <tr>
        <th class="text-left">{$_('results.company')}</th>
        <th class="text-left">{$_('results.line')}</th>
        <th class="text-left">{$_('results.departure')}</th>
        <th class="text-left">{$_('results.trip')}</th>
        <th class="text-right">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span>{$_('travel.total')}</span>
          </div>
        </th>
        <th class="text-right" style={totalSumWidth ? `width: ${totalSumWidth}px` : ''}>
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span> {$_('travel.totalSumVatFree')}</span>
          </div>
        </th>
        <th class="text-right" style={euroPerKmWidth ? `width: ${euroPerKmWidth}px` : ''}>
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span>
              {$_('travel.euroPerKm')}</span
            >
          </div>
        </th>
        <th
          class="text-right"
          style={shipmentWidth ? `width: calc(${shipmentWidth}px - 2em)` : ''}
        >
          <div class="inline-flex">
            <ShipmentIcon width="20px" height="20px" />
            <span> {$_('travel.total')}</span>
          </div>
        </th>
        <th />
      </tr>
      {#each results as result, i}
        <tr
          class:last-result={i === results.length - 1}
          class:font-semibold={selectedLine === result.id}
          class:selected={selectedLine === result.id}
          class:bg-mlight={selectedLine === result.id}
        >
          <td>{result.company.name} ({result.company.number})</td>
          <td>{(result.line_id === '0000' ? '-' : result.line_id || '-').split('$')[0]}</td>
          <td>
            {#if result.departures.length > 1}
              {$_('travel.all')}
            {:else}{(result.departure_id || '-').split('$')[0]}{/if}
          </td>
          <td>
            {#if result.departures.length > 1}
              {$_('travel.totalLinesPerDay').replace('%d', result.departures.length)}
            {:else}{result.line_name || '-'}{/if}
          </td>
          <td class="text-right">{result.quantity || '0'}</td>
          <td class="text-right">
            {$number(result.sum, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">
            {$number(result.euro_per_km, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">{result.shipments || '0'}</td>

          <td
            on:click|stopPropagation={() =>
              selectedLine === result.id
                ? (selectedLine = null)
                : (selectedLine = result.id)}
          >
            <div class="icon" class:expanded={selectedLine === result.id}>
              <Icon icon={faChevronDown} />
            </div>
          </td>
        </tr>
        {#if selectedLine === result.id}
          <ProductReport
            products={result.products}
            lastResult={i === results.length - 1}
          />
        {/if}
      {/each}
    </table>
    <div class="mt-4">
      <button on:click={createExcel}>{$_('results.createExcel')}</button>
    </div>
  {:else}
    <h3>{$_('results.noResults')}</h3>
  {/if}
{/if}

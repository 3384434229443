<script>
  import { _ } from 'svelte-i18n';
  import { tick } from 'svelte';
  import ProgressIndicator from '../../components/ProgressIndicator.svelte';
  import { charterLineCreateState, createCharterLine, modifyCharterLine } from '../../stores/lines';
  import CharterCreateDepartureForm from './CharterCreateDepartureForm.svelte';
  import CharterCreatePricingForm from './CharterCreatePricingForm.svelte';
  import CharterCreateReturnForm from './CharterCreateReturnForm.svelte';

  export let inProgress;
  export let onSuccess;
  // export let successMessage;

  const DIRECTION_FORWARD = -1;
  const DIRECTION_BACKWARD = 1;

  let steps = [];
  let currentPage = 0;
  let formValid = false;
  let direction = DIRECTION_FORWARD;
  let titleElement;

  let departureValid = false;
  let returnValid = false;
  let pricingValid = false;

  const populateReturnAdditionalInfo = () => {
    if (!$charterLineCreateState.returnInfo && $charterLineCreateState.additionalInfo) {
      $charterLineCreateState.returnInfo = $charterLineCreateState.additionalInfo;
    }
    if (!$charterLineCreateState.returnDate) {
      $charterLineCreateState.returnDate = $charterLineCreateState.departureDate;
    }
  };

  const scrollToTop = () => {
    titleElement.scrollIntoView(true);
  };

  $: firstPage = currentPage === 0;
  $: lastPage = currentPage === (steps.length - 1);
  $: currentPageId = steps[currentPage].id;


  $: {
    if (currentPageId == 'MAIN') {
      formValid = departureValid;
    } else if (currentPageId == 'RETURN') {
      formValid = returnValid;
    } else if (currentPageId == 'PRICE') {
      formValid = pricingValid;
    }
  }

  $: {
    if (currentPageId == 'RETURN') {
      populateReturnAdditionalInfo();
    }
  }

  const moveBackward = async (scroll) => {
    direction = DIRECTION_BACKWARD;
      await tick();
    --currentPage;
    if (scroll) scrollToTop();
  };

  const moveForward = async (scroll) => {
    direction = DIRECTION_FORWARD;
    await tick();
    ++currentPage;
    if (scroll) scrollToTop();
  };

  const finishLineCreation = async () => {
    try {
      const data = await ($charterLineCreateState.modification ? modifyCharterLine() : createCharterLine());
      inProgress = false;
      onSuccess(data);
    } catch (error) {
      // api error already caught on store side
    }
  };

  const cancelLineCreation = () => {
    inProgress = false;
  };

  $: {
    if ($charterLineCreateState.roundTrip) {
      steps = [
        {
          title: $_('lines.charter.createPageMain'),
          id: 'MAIN'
        },
        {
          title: $_('lines.charter.createPageReturn'),
          id: 'RETURN',
        },
        {
          title: $_('lines.charter.createPagePricing'),
          id: 'PRICE'
        }
      ];
    } else {
      steps = [
        {
          title: $_('lines.charter.createPageMain'),
          id: 'MAIN'
        },
        {
          title: $_('lines.charter.createPagePricing'),
          id: 'PRICE'
        }
      ];
    }
  }

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.title-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  .button-container > button {
    margin-left: 2rem;
  }
  .bottom-button-container > button {
    margin-right: 2rem;
  }
  button {
    min-width: 120px;
  }
</style>

<div bind:this={titleElement} class="title-container">
  <div>
    <h2 class="my-6">
      {#if currentPageId === 'MAIN'}
        {$_($charterLineCreateState.modification ? 'lines.charter.charterModificationMainTitle' : 'lines.charter.charterCreationMainTitle')}
      {:else if currentPageId === 'RETURN'}
        {$_('lines.charter.charterCreationReturnTripTitle')}
      {:else if currentPageId === 'PRICE'}
        {$_('lines.charter.charterCreationPricingTitle')}
      {/if}
    </h2>
  </div>

  <div class="button-container hidden lg:flex lg:items-center lg:justify-end">
    {#if firstPage}
      <button class="secondary" on:click={() => { cancelLineCreation(); }}>{$_('pricing.cancel')}</button>
      <button disabled={!formValid} on:click={() => { moveForward(false); }}>{$_('lines.charter.buttonNext')}</button>
    {:else}
      <button on:click={() => { moveBackward(false); }}>{$_('lines.charter.buttonBack')}</button>
      {#if lastPage}
        <button disabled={!formValid} on:click={() => { finishLineCreation(); }}>{$_(`lines.charter.${$_($charterLineCreateState.modification ? 'buttonModificationFinish' : 'buttonCreateFinish')}`)}</button>
      {:else}
        <button disabled={!formValid} on:click={() => { moveForward(false); }}>{$_('lines.charter.buttonNext')}</button>
      {/if}
    {/if}
  </div>
</div>
<div class="flex w-full justify-between">
  {#if currentPageId === 'MAIN'}
    <CharterCreateDepartureForm bind:isValid={departureValid} {direction} />
  {:else if currentPageId === 'RETURN'}
    <CharterCreateReturnForm bind:isValid={returnValid} {direction} />
  {:else if currentPageId === 'PRICE'}
    <CharterCreatePricingForm bind:isValid={pricingValid} {direction} />
  {/if}
  <div class="ml-8 hidden sm:block">
    <ProgressIndicator {steps} currentStep={currentPage} sticky={true} />
  </div>
</div>
<div class="w-full flex justify-start lg:hidden bottom-button-container">
  {#if firstPage}
    <button class="secondary" on:click={() => { cancelLineCreation(); }}>{$_('pricing.cancel')}</button>
    <button disabled={!formValid} on:click={() => { moveForward(true); }}>{$_('lines.charter.buttonNext')}</button>
  {:else}
    <button on:click={async () => { moveBackward(true); }}>{$_('lines.charter.buttonBack')}</button>
    {#if lastPage}
      <button disabled={!formValid} on:click={() => { finishLineCreation(); }}>{$_(`lines.charter.${$_($charterLineCreateState.modification ? 'buttonModificationFinish' : 'buttonCreateFinish')}`)}</button>
    {:else}
      <button disabled={!formValid} on:click={() => { moveForward(true); }}>{$_('lines.charter.buttonNext')}</button>
    {/if}
  {/if}
</div>

<script>
  export let checked;
</script>

<style>
  input {
    display: none;
  }

  .toggle-switch {
    width: 45px;
    height: 23px;
    border-radius: 3em;
    background-color: #cccccc;
    transition: background-color 0.09s ease-in-out;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }

  input:checked + .toggle-switch {
    background: #001E61;
    cursor: pointer;
  }

  .toggle-switch::after {
    content: '';
    width: 23px;
    height: 23px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    transform: scale(0.75);
    left: 0;
    transition: left 500ms ease-in-out;
    cursor: pointer;
  }

  input:checked + .toggle-switch::after {
    left: 22px;
    cursor: pointer;
  }
</style>

<!--<style>
  .switch {
    --width: 45px;
    --height: 23px;
    --ballSize: 15px;
    --padding: 4px;
    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: var(--ballSize);
    width: var(--ballSize);
    left: var(--padding);
    bottom: var(--padding);
    top: var(--padding);
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #001E61;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #001E61;
  }

  input:checked + .slider:before {
    transform: translateX(calc(var(--width) / 2));
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: var(--height);
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>-->
<label class="switch">
  <input type="checkbox" bind:checked />
  <div class="toggle-switch" />
</label>

<script>
  import moment from 'moment-timezone';
  import { _, date } from 'svelte-i18n';
  import { fly } from 'svelte/transition';
  import Icon from 'fa-svelte';
  import Form from '../../components/Form.svelte';
  import { faCalendar } from '@fortawesome/free-solid-svg-icons';
  import DatePicker from '../../components/DatePicker.svelte';
  import { charterLineCreateState } from '../../stores/lines';
  import InputField from '../../components/InputField.svelte';

  export let isValid = false;
  export let direction;
  let formValid = false;
  let validity;
  let returnDateTooEarly = false;

  const departureDateTime = moment($charterLineCreateState.departureDate).tz('Europe/Helsinki');
  const [depHour, depMin] = $charterLineCreateState.departureTime.split(':');
  departureDateTime.hour(depHour).minute(depMin);

  $: {
    isValid = formValid;
    const returnDateTime = moment($charterLineCreateState.returnDate).tz('Europe/Helsinki');
    if (returnDateTime && validity && $validity.departureTime) {
      const [retHour, retMin] = $charterLineCreateState.returnTime.split(':');
      returnDateTime.hour(retHour).minute(retMin);
      returnDateTooEarly = returnDateTime.isSameOrBefore(departureDateTime);
    } else {
      returnDateTooEarly = false;
    }
    isValid = isValid && !returnDateTooEarly;
  }

</script>

<style>
  label, .label {
    font-size: 1em;
    margin-bottom: 0.25rem;
    display: block;
  }
  .input-div {
    margin-bottom: 1.5rem;
  }
  .input-container > :global(.input-field-main-div) {
    margin-bottom: 1.5rem;
    max-width: 640px;
  }
  .input {
    width: 100%;
    padding: 0.4rem;
    height: unset;
    font-size: 1rem;
    margin: 0;
    max-width: 640px;
  }
  div.input-container {
    flex-grow: 1;
    flex-basis: 12rem;
  }
  .input-main-container {
    max-width: 800px;
  }
  .picker > :global(.datepicker) {
    width: 100%;
  }
  .fake-input {
    padding: 0.4em;
  }
  p.input-error {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
    color: #EA5798;
  }
</style>

<div class="input-main-container flex flex-col lg:flex-row flex-grow"
  in:fly="{{ x: direction * -250, duration: 250, delay: 300 }}"
  out:fly="{{ x: direction * 250, duration: 250 }}">
  <Form bind:isValid={formValid} bind:validity={validity}>
  <div class="input-container lg:mr-4">
    <div class="input-div picker">
      <label for="end">{$_('lines.charter.departureDate')}: *</label>
      <DatePicker bind:selected={$charterLineCreateState.returnDate} start={$charterLineCreateState.departureDate}>
        <div class="input flex justify-between cursor-pointer
          items-center" placeholder={$_('lines.charter.departureDate')}>
          <span>{$date($charterLineCreateState.returnDate, { format: 'twoDigitsFullYear' })}</span>
          <Icon icon={faCalendar} />
        </div>
      </DatePicker>
    </div>
    <InputField
      id="departureTime"
      type="time"
      label={$_('lines.charter.departureTime')}
      placeholder={$_('lines.charter.departureTimePlaceholder')}
      bind:value={$charterLineCreateState.returnTime}
      errorMessage={$_('lines.charter.inputErrorTime')}
      required
    />
    {#if returnDateTooEarly}
    <p class="input-error">{$_('lines.charter.inputErrorReturnTooEarly')}</p>
    {/if}
    <div class="input-div">
      <p class="label">{$_('lines.charter.departureLocation')}:</p>
      <p class="fake-input">{$charterLineCreateState.destinationLocation}</p>
    </div>
    <div class="input-div">
      <p class="label">{$_('lines.charter.departureAddress')}:</p>
      <p class="fake-input">{$charterLineCreateState.destinationAddressInput}</p>
    </div>
    <div class="input-div">
      <p class="label">{$_('lines.charter.destinationLocation')}:</p>
      <p class="fake-input">{$charterLineCreateState.departureLocation}</p>
    </div>
    <div class="input-div">
      <p class="label">{$_('lines.charter.destinationAddress')}:</p>
      <p class="fake-input">{$charterLineCreateState.departureAddressInput}</p>
    </div>
  </div>
  <div class="hidden lg:block flex-grow" />
  <div class="input-container lg:ml-4">
    <InputField
      id="additionalInfo"
      label={$_('lines.charter.additionalInfo')}
      type="textarea"
      placeholder={$_('lines.charter.additionalInfoPlaceholder')}
      rows="3"
      bind:value={$charterLineCreateState.returnInfo}
    />
  </div>
  </Form>
</div>

<script>
  import { _, locale, number } from 'svelte-i18n';
  import Loading from '../Loading.svelte';
  import {
    searchLoaded,
    searchLoading,
    searchResults,
  } from '../../stores/travel_reports';
  import {
    getExcelDownloadGroupedLinesNoSales,
    getExcelDownloadGroupedEventsNoSales,
  } from '../../common/http';
  import PassengerIcon from '../images/Passenger.svelte';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import Icon from 'fa-svelte';
  import LineReport from './LineReport.svelte';
  import RadioButton from '../RadioButton.svelte';

  export let start;
  export let end;

  export let lineId;
  export let departureId;
  export let companies;
  export let weekdays;
  export let tickets;
  export let deviceType;

  let excelType = 'lines';

  let totalColumnWidth = 0;

  let selectedTargetGroup;
  $: results = getResults($searchResults.noSales);

  const getResults = searchResults =>
    searchResults.map(result => ({
      ...result,
    }));

  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const excel =
      excelType === 'lines'
        ? getExcelDownloadGroupedLinesNoSales
        : getExcelDownloadGroupedEventsNoSales;
    const res = await excel({
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId,
      departureId,
      companies,
      weekdays,
      lang,
      tickets,
      deviceType,
    });
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
  };
</script>

<style>
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  table.table-results {
    width: 100%;
    table-layout: auto;
  }

  .table-results th {
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  .table-results th,
  .table-results th span {
      }

  tr:not(.selected) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:not(.selected).last-result td {
    border-bottom: 1px solid #333333;
  }

  td {
    padding: 0;
    color: inherit;
    padding-bottom: 0.7em;
    padding-top: 1em;
    padding-right: 2em;
  }

  tr:not(:first-child):not(:last-child) {
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: center;
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }

  td:last-child {
    padding: 0;
  }
</style>

{#if $searchLoading}
  <div class="loading-content">
    <Loading />
  </div>
{/if}

{#if !$searchLoading && $searchLoaded}
  {#if results.length}
    <table class="table-results" cellspacing="0">
      <tr>
        <th class="text-left w-full" colspan={5}>{$_('travel.ticketProduct')}</th>
        <th class="text-right" style="white-space: nowrap">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span>{$_('travel.total')}</span>
          </div>
        </th>
        <th class="text-right" style="white-space: nowrap">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span> {$_('travel.totalSumVatFree')}</span>
          </div>
        </th>
        <th class="text-right" style="white-space: nowrap">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span> {$_('travel.totalSumVatLong')}</span>
          </div>
        </th>
        <th bind:clientWidth={totalColumnWidth} />
      </tr>
      {#each results as result, i}
        <tr
          class:last-result={i === results.length - 1}
          class:font-semibold={selectedTargetGroup === result.id}
          class:selected={selectedTargetGroup === result.id}
          class:bg-mlight={selectedTargetGroup === result.id}
        >
          <td class="text-left" colspan={5}>{result.target_group}</td>
          <td class="text-right">{result.quantity || '0'}</td>
          <td class="text-right">
            {$number(result.sum, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">
            {$number(result.sum_vat, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td
            on:click|stopPropagation={() =>
              selectedTargetGroup === result.id
                ? (selectedTargetGroup = null)
                : (selectedTargetGroup = result.id)}
          >
            <div class="icon" class:expanded={selectedTargetGroup === result.id}>
              <Icon icon={faChevronDown} />
            </div>
          </td>
        </tr>
        {#if selectedTargetGroup === result.id}
          <LineReport lines={result.lines} bind:totalColumnWidth />
        {/if}
      {/each}
    </table>

    <div class="mt-4">
      <span class="mb-2">{$_('travel.createExcel')}</span>
      <RadioButton
        label={$_('travel.exportResults')}
        value="lines"
        bind:group={excelType}
      />
      <RadioButton
        label={$_('travel.exportEvents')}
        value="events"
        bind:group={excelType}
      />
      <button on:click={createExcel}>{$_('results.createExcel')}</button>
    </div>
  {:else}
    <h3>{$_('results.noResults')}</h3>
  {/if}
{/if}

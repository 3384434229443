export default {
  loading: 'Lataa...',
  back: 'Takaisin',
  header: {
    title: 'Matkassa Pilvi',
    login: 'Kirjaudu sisään',
  },
  login: {
    title: 'Kirjaudu sisään',
    action: 'Kirjaudu',
    forgotten: 'Unohditko salasanasi?',
    email: 'Sähköpostiosoite',
    password: 'Salasana',
    reset_instr:
      'Syötä sähköpostiosoitteesi, niin lähetämme sinulle varmistuskoodin salasanan resetoimiseksi.',
    reset: 'Resetoi salasana',
    change: 'Vaihda salasana',
    signUp: 'Vahvista tili',
    back: 'Takaisin',
    activate: 'Aktivoi tili',
    change_instr:
      'Salasanasi on väliaikainen, ja se tulee vaihtaa.\nSyötä uusi salasana allaoleviin kenttiin.',
    forgotten_instr:
      'Olet saanut sähköpostiisi varmistuskoodin salasanan vaihtamiseksi. Syötä varmistuskoodi sekä uusi salasanasi allaoleviin kenttiin.',
    signup_instr:
      'Olet saanut sähköpostiisi varmistuskoodin tilin varmistamiseksi. Syötä varmistuskoodi sekä uusi salasanasi allaoleviin kenttiin.',
    newPassword: 'Uusi salasana',
    retypeNewPassword: 'Uusi salasana uudelleen',
    alreadyConfirmed: 'Minulla on jo varmistuskoodi',
    confirmationCode: 'Varmistuskoodi',
    passwordMatch: 'Passwords must match',
    invalidCredentials: 'Virhe sähköpostiosoitteessa tai salasanassa.',
    error: 'Tapahtui virhe, yritä uudelleen.',
    userNotFound: 'Käyttäjä ei ole olemassa.',
    passwordChanged: 'Salasanan vaihto onnistui',
    sessionExpired: 'Istunto on vanhentunut',
    signUpCompleted: 'Tili vahvistettu. Sinut ohjataan kirjautumissivulle 5 sekunnissa...',
  },
  reports: {
    title: 'Kassaraportointi',
  },
  calendar: {
    days: {
      long: {
        sun: 'Sunnuntai',
        mon: 'Maanantai',
        tue: 'Tiistai',
        wed: 'Keskiviikko',
        thu: 'Torstai',
        fri: 'Perjantai',
        sat: 'Lauantai',
      },
      short: {
        sun: 'Su',
        mon: 'Ma',
        tue: 'Ti',
        wed: 'Ke',
        thu: 'To',
        fri: 'Pe',
        sat: 'La',
      },
      shortSentenceCase: {
        sun: 'su',
        mon: 'ma',
        tue: 'ti',
        wed: 'ke',
        thu: 'to',
        fri: 'pe',
        sat: 'la',
      },
    },
    months: {
      long: {
        jan: 'Tammikuu',
        feb: 'Helmikuu',
        mar: 'Maaliskuu',
        apr: 'Huhtikuu',
        may: 'Toukokuu',
        jun: 'Kesäkuu',
        jul: 'Heinäkuu',
        aug: 'Elokuu',
        sep: 'Syyskuu',
        oct: 'Lokakuu',
        nov: 'Marraskuu',
        dec: 'Joulukuu',
      },
      short: {
        jan: 'Tammi',
        feb: 'Helmi',
        mar: 'Maalis',
        apr: 'Huhti',
        may: 'Touko',
        jun: 'Kesä',
        jul: 'Heinä',
        aug: 'Elo',
        sep: 'Syys',
        oct: 'Loka',
        nov: 'Marras',
        dec: 'Joulu',
      },
    },
  },
  users: {
    title: 'Käyttäjähallinta',
    users: 'Käyttäjät',
    create: 'Luo käyttäjä',
    name: 'Nimi',
    username: 'Sähköposti / Käyttäjätunnus',
    company: 'Yritys',
    admin: 'Admin',
    edit: 'Muokkaa',
    editor: {
      button: {
        save: 'Hyväksy',
        cancel: 'Hylkää muutokset',
      },
      title: 'Muokkaa käyttäjän tietoja',
      firstname: 'Etunimi',
      lastname: 'Sukunimi',
      email: 'Sähköpostiosoite',
      password: 'Salasana',
      seeCompanies: 'Käyttäjä näkee seuraavat yritykset',
      seeFeatures: 'Käyttäjä näkee valitulle yritykselle seuraavat toiminnot:',
      done: 'Valmis',
      cancel: 'Peruuta',
      create: 'Luo käyttäjä',
      delete: 'Poista käyttäjä',
      unresettable:
        'Mikäli käyttäjä ei saa salasanaansa itse resetoitua, poista käyttäjä ja luo se uudelleen.',
      deleteSure: 'Haluatko varmasti poistaa käyttäjän?',
      deleteSub: 'Käyttäjä: {1}, {2}',
      admin: 'Käyttäjällä on admin-oikeus käyttäjänhallintaan valituilla yrityksillä',
      adminInfo:
        'Mikäli annat käyttäjille admin-oikeudet, hän saa lisätä ja muokata käyttäjiä valituissa yrityksissä.',
      features: 'Uusi käyttäjä näkee valituille yrityksille seuraavat toiminnot',
      userCreated: 'Käyttäjä luotu',
      userUpdated: 'Käyttäjä päivitetty',
      userDeleted: 'Käyttäjä poistettu',
      minimumLength: 'Salasanassa on oltava vähintään {1} merkkiä.',
      requireUppercase: 'Salasanassa on oltava vähintään yksi iso kirjain.',
      requireLowercase: 'Salasanassa on oltava vähintään yksi pieni kirjain.',
      requireNumbers: 'Salasanassa on oltava vähintään yksi numero.',
      requireSymbols: 'Salasanassa on oltava vähintään yksi erikoismerkki.',
      allLines: 'Salli kaikkien linjojen raportointi',
      selectedLines: 'Salli vain määrättyjen linjojen/vuorojen raportointi',
      lines: {
        line: 'Linja',
        departure: 'Vuoro',
        remove: 'Poista',
        add: 'Lisää rivi',
        invalid: 'Anna vähintään yksi linja',
      },
    },
  },
  map: {
    title: 'Kartta',
    search: 'Hae vuoro- tai linjanumerolla',
    filter: 'Suodata hakua',
    details: 'Katso lisätietoja',
    showOtherLines: 'Näytä muut linjat',
    onRoad: 'Busseja linjoilla',
    late: 'Myöhässä',
    travelers: 'Matkustajia',
    shipments: 'Paketteja',
    basic: 'Basic',
    lite: 'Lite',
  },
  travelReports: {
    title: 'Matkustajaraportointi',
    sections: {
      search: 'Hakukohtainen raportointi',
      pregenerated: 'Valmiit kuukausiraportit',
    }
  },
  pricingMaintenance: {
    title: 'Hinnastot',
  },
  error: {
    title: 'Virhe',
  },
  search: {
    define: 'Määritä hakuehdot',
    fetch: 'Hae',
    start: 'Alkaen',
    end: 'Päättyen',
    departureId: 'Vuoron ID',
    lineId: 'Linjan ID',
    driverId: 'Kuljettajatunnus',
    deviceId: 'Laitetunniste',
    deviceType: 'Laitetyyppi',
    receiptNumber: 'Kuittinumero',
    company: 'Yritys',
    choose: {
      date: 'Valitse päivämäärä',
      departureId: 'Kirjoita vuoron ID',
      lineId: 'Kirjoita linjan ID',
      driverId: 'Kirjoita kuljettajatunnus',
      deviceId: 'Kirjoita laitetunniste',
      receiptNumber: 'Kirjoita kuittinumero',
      weekdayFilter: 'Rajaa viikonpäivät',
      product: 'Valitse tuote',
      weekdays: {
        Monday: 'Maanantai',
        Tuesday: 'Tiistai',
        Wednesday: 'Keskiviikko',
        Thursday: 'Torstai',
        Friday: 'Perjantai',
        Saturday: 'Lauantai',
        Sunday: 'Sunnuntai',
      },
    },
    showOnlyUnaccounted: 'Näytä vain tilittämättömät',
    showOnlyAdded: 'Näytä vain lisätyt rivit',
    product: 'Tuotenimi',
    deviceTypes: {
      all: 'Kaikki',
      pro: 'Pro',
      lite: 'Lite',
    },
  },
  results: {
    noResults: 'Ei tuloksia',
    date: 'Aikaleima',
    departure: 'Vuoro',
    line: 'Linja',
    totalDepartures: 'yhteensä {1} lähtöä',
    driver: 'Kuljettaja',
    device: 'Laite',
    company: 'Yritys',
    productName: 'Tuotenimi',
    productNames: {
      TICKET_SALE: 'Käteislippu',
      CASH_PURCHASE: 'Käteislippu',
      NO_SALE: 'Kirjaus ilman myyntiä',
      ONLINE_TICKET: 'Nettilippu',
      TRAVEL_CARD_SERIES: 'Sarjakortti',
      TRAVEL_CARD_SEASON: 'Kausikortti',
      TRAVEL_CARD_VALUE: 'Arvokortti',
      TRAVEL_CARD_CREDIT: 'Luottolippu',
      ROUNDING: 'Pyöristys',
      ADDITIONAL_SERVICE: 'Lisäpalvelu',
      BIKE_TICKET: 'Polkupyörälippu',
      MATKASSA_PRO_CONNECTION: 'Vaihtolippu',
      WALTTI_MOBILE_TICKET: 'Waltti Mobiililippu',
      WALTTI_TRAVEL_CARD: 'Waltti Kausi',
      WALTTI_VALUE_TICKET: 'Waltti Arvolippu',
      SEAT_RESERVATION: 'Paikkavaraus',
      CHARTER_TICKET: 'Tilausajo',
      INVOICE_TICKET: 'Laskutettu lippu',
      RL_TICKET: 'RL-Mobiililippu',
    },
    receiptNumber: 'Kuittinro',
    paymentMethod: 'Maksutapa',
    vat: 'Alv. %',
    vatFreePrice: 'Veroton hinta',
    priceVAT: 'Hinta sis. alv.',
    totalCount: 'Tuloksia yhteensä %d riviä',
    totalSum: 'Yhteensä',
    cashSum: 'Käteismaksut ({1} kpl)',
    cardSum: 'Maksukorttimaksut ({1} kpl)',
    oneTimeSum: 'Kertaliput ({1} kpl)',
    travelCardSum: 'Matkakorttilataukset ({1} kpl)',
    otherSale: 'Muut myynnit ({1} kpl)',
    cancelled: 'Peruutukset ({1} kpl)',
    accountedSum: 'Tilitetyt ({1} kpl)',
    unaccountedSum: 'Tilittämättömät ({1} kpl)',
    createExcel: 'Luo Excel',
    report: 'Raportti',
    trip: 'Reitti',
    markAccounted: 'Merkitse tilitetyksi',
    unmarkAccounted: 'Palauta tilittämättömäksi',
    cancelEvent: 'Peruuta myynti',
    cancelEventConfirmationTitle: 'Tapahtuman peruutus',
    cancelEventConfirmationText: 'Haluatko varmasti peruuttaa myynnin?',
    cancelledSale: 'Peruttu',
    showOnlySelected: 'Näytä yhteenveto valituille',
    goBack: 'Palaa takaisin',
    addRow: 'Lisää rivi',
    editRow: 'Tallenna',
    editAddedRow: 'Muokkaa lisättyä riviä',
    addRowFailed: 'Rivin lisääminen epäonnistui',
    editRowFailed: 'Rivin muokkaus epäonnistui',
    add: 'Lisää',
    explanation: 'Selite',
    explanationLong: 'Antamasi selite tulee näkyville raporttiin.',
    sum: 'Summa',
    sumLong: 'Summa voi olla positiivinen tai negatiivinen.',
    createAccountingReport: 'Lataa kirjanpitoraportti',
    createAccountedReport: 'Lataa tilitysten seurantaraportti',
  },
  travel: {
    line_length: 'Ajetut kilometrit',
    daily: 'Päiväkohtainen yhteenveto',
    stop: 'Pysäkkikohtainen yhteenveto',
    dayDate: 'Pvm',
    eventHour: 'Aikaleima',
    nextDayEvent: '* seuraavan päivän tapahtuma',
    origin: 'Lähtöpysäkki',
    destination: 'Määräpysäkki',
    distance: 'Matkan pituus (km)',
    customerGroup: 'Asiakasryhmä',
    price: 'Hinta € alv. 0%',
    prepurchase: 'Nettiliput kpl',
    prepurchaseSold: 'Nettiliput myydyt kpl',
    prepurchaseValidated: 'Nettiliput kyydissä kpl',
    prepurchaseSum: 'Nettiliput myydyt €',
    cash: 'Käteisliput kpl',
    cashSum: 'Käteisliput €',
    travelcard: 'Tunniste&shy;liput kpl',
    travelcardSum: 'Tunniste&shy;liput €',
    noSale: 'Kirjattu ilman myyntiä kpl',
    noSaleSum: 'Kirjattu ilman myyntiä €',
    total: 'Yht. kpl',
    totalSum: 'Yht. €',
    totalSumVatFree: 'Yht. € alv 0%',
    totalSumVat: 'sis. alv',
    totalSumVatLong: 'Yht. € sis. alv',
    vatFreeSummary: 'Yhteensä (alv 0%)',
    travelerAvg: 'Matkustajat ka.',
    routeChangedNotice:
      'HUOM! Reitti on muuttunut valitulla aikavälillä (muuttunut {dates}), yhteenveto saattaa näyttää väärin.',
    stopName: 'Pysäkki',
    ticketProduct: 'Lipputuote',
    max: 'Matkustajat max.',
    boarding: 'Nousijat ka. ',
    leaving: 'Poistujat ka.',
    allTravelEvents: 'Kaikki matkustustapahtumat',
    errorLoadingContent: 'Virhe ladattaessa sisältöä.',
    clickHere: 'Lataa uudelleen',
    services: {
      BIKE_TICKET: 'Polkupyörälippu',
    },
    euroPerKm: '€/km',
    byLineMax: 'max.',
    avg: 'ka.',
    totalLinesPerDay: 'Yhteensä %d vuoroa',
    totalLinesPerDayPerTotal: 'Yhteensä {totalCount}/{maxShifts} vuoroa',
    reportByLine: 'Kaikki valitulta ajanjaksolta',
    reportGroupedByLine: 'Linjakooste',
    reportGroupedByNoSales: 'Kirjaukset ilman lipunmyyntiä',
    reportByDay: 'Päiväkohtainen erittely',
    searchGroupedLines: 'Hae linjakoosteet omalle välilehdelle',
    searchGroupedNoSales: 'Hae kirjaukset ilman lipunmyyntiä omalle välilehdelle',
    all: 'Kaikki',
    createExcel: 'Luo Exceliin:',
    exportResults: 'hakutulokset',
    exportEvents: 'hakutulosten tapahtumarivit',
    exportProducts: 'hakutulokset tuotekohtaisesti',
    productId: 'Tuotenumero',
  },
  payment: {
    methods: {
      CASH: 'Käteinen',
      CARD: 'Maksukortti',
      MULTIPLE: 'Käteinen/Maksukortti',
      ONE_TIME_PAYMENT: 'Kertalippu',
      TRAVELCARD: 'Matkakortti',
    },
  },
  pricing: {
    copyLinesAndDepartures: 'Kopioidaanko vuorotiedot?',
    dateRange: {
      title: 'Voimassaolo',
      notInUse: 'Hinnasto ei ole käytössä',
      defaultNotValid: 'Oletushinnastolla ei voi olla voimassaoloaikaa',
      alwaysInUse: 'Voimassaoloaikaa ei määritelty, hinnasto käytössä',
      chooseStartDate: 'Alkaen pvm.',
      chooseEndDate: 'Loppuen pvm.'
    },
    overlaps: {
      title: 'Voimassaoloajat ja linjat/vuorot menevät päällekkäin seuraavilla hinnastoilla',
      notDefined: 'ei määritelty'
    },
    zonePricing: 'Vyöhykelippuhinnastot',
    customerGroup: 'Asiakasryhmä',
    zone: 'Vyöhyke',
    sections: {
      basic: 'Perushinnasto',
      special: 'Erikoishinnastot',
      other: 'Muu myynti',
      noSale: 'Kirjaus ilman myyntiä',
      zone: 'Vyöhykeliput',
    },
    modify: 'Muokkaa',
    done: 'Valmis',
    addNewProduct: 'Lisää uusi',
    add: 'Lisää',
    addPricing: 'Lisää hinnasto',
    addRow: 'Lisää rivi',
    remove: 'Poista',
    setAsDefault: 'Vaihda oletushinnastoksi',
    name: 'Nimi',
    text: 'Teksti',
    priceShort: '€',
    price: 'Hinta',
    vat: 'Alv. %',
    otherTicketProducts: 'Muut lipputuotteet, alv 10%',
    otherProducts: 'Muut tuotteet, alv 0/10/14/25.5%',
    noProducts: 'Ei tuotteita',
    travelRightDescription: 'Matkustusoikeutta kuvaavat tekstit',
    ready: 'Valmis',
    zoneName: 'Vyöhykehinnaston nimi:',
    basicPricingDefault: 'Perushinnasto',
    basicPricingDefaultTag: '(oletus)',
    basicPricingMissing: 'Tälle yritykselle ei ole vielä määritelty perushinnastoa.',
    specialPricesMissing: 'Tälle yritykselle ei ole vielä määritelty erikoishinnastoja.',
    otherTicketsMissing:
      'Tälle yritykselle ei ole vielä määritelty muita lipputuotteita.',
    otherProductsMissing: 'Tälle yritykselle ei ole vielä määritelty muita tuotteita.',
    travelRightDescriptionsMissing:
      'Tälle yritykselle ei ole vielä määritelty matkustusoikeutta kuvaavia tekstejä.',
    zoneTicketsMissing: 'Tälle yritykselle ei ole vielä määritelty vyöhykehinnastoja.',
    otherTicketsObservation:
      'Lisätessäsi tuotteita huomaathan, että Muut lipputuotteet kerryttävät matkustajamäärää, Muut tuotteet eivät kerrytä.',
    addCustomerGroup: 'Lisää asiakasryhmä',
    modifyCustomerGroup: 'Muokkaa asiakasryhmää',
    removeCustomerGroup: 'Poista asiakasryhmä',
    departuresMissing: 'Vuoroja ei ole vielä määritelty',
    addDistance: 'Lisää etäisyys',
    addOtherProduct: 'Lisää muu tuote',
    addOtherTicket: 'Lisää muu lipputuote',
    addTravelRightDescription: 'Lisää matkustusoikeutta kuvaava teksti',
    addZoneTicket: 'Lisää uusi vyöhykehinnasto',
    addNewBasicPricing: 'Lisää uusi perushinnasto',
    addNewSpecialPriceTable: 'Lisää uusi erikoishinnasto',
    removeBasicPriceTable: 'Perushinnaston poisto',
    removeBasicPriceTableConfirmation: 'Oletko varma että haluat poistaa perushinnaston?',
    removeSpecialPricing: 'Erikoishinnaston poisto',
    removeSpecialPricingConfirmation:
      'Oletko varma että haluat poistaa erikoishinnaston?',
    removeZoneTickets: 'Vyöhykehinnaston poisto',
    removeZoneTicketsConfirmation: 'Oletko varma että haluat poistaa vyöhykehinnaston?',
    basicPricingCreationTitle:
      'Haluatko kopioida olemassa olevan hinnaston, vai luoda kokonaan uuden?',
    basicPricingCopySelectCompany: 'Valitse yritys, jonka hinnasto kopioidaan: *',
    basicPricingCopySelectPriceTable: 'Valitse hinnasto: *',
    basicPricingFromScratch: 'En halua kopioida, luon kokonaan uuden hinnaston',
    basicPricingFromDefault: 'Haluan kopioida oletusperushinnaston',
    basicPricingFromExisting: 'Haluan kopioida olemassa olevasta hinnastosta',
    modifyBasicPricing: 'Muokkaa perushinnastoa',
    modifyZoneTickets: 'Muokkaa vyöhykehinnastoa',
    distance: 'Etäisyys',
    distanceShort: 'km',
    continue: 'Jatka',
    cancel: 'Peruuta',
    discount: 'Alennusprosentti',
    yes: 'Kyllä',
    no: 'Ei',
    errorDistanceAlreadyExists: 'Etäisyydelle on jo määritelty hinta',
    errorZoneAlreadyExists: 'Vyöhyke ja voimassaoloaika on jo olemassa',
    errorLineAlreadyExists: 'Vuoro on jo määritelty',
    errorPriceTableNameAlreadyExists: 'Samanniminen hintataulukko on jo olemassa',
    appliesToLines: 'Koskee vuoroja:',
    errorCannotDeleteLastZone: 'Viimeistä vyöhykettä ei voi poistaa',
    errorCannotDeleteLastDeparture: 'Viimeistä vuoroa ei voi poistaa',
    chooseFileForSpecialPricing: 'Valitse tiedosto, jonka haluat tuoda taulukon pohjaksi',
    chooseOtherTableForSpecialPricing:
      'Tai valitse erikoishinnasto, jonka haluat kopioida taulukon pohjaksi:',
    chooseFile: 'Valitse tiedosto',
    modifySpecialPricing: 'Muokkaa erikoishinnastoa',
    loadNewFile: 'Lataa uusi tiedosto',
    updatePricingWithFile: 'Päivitä hinnasto lataamalla uusi tiedosto',
    updatePricingNotification:
      'Huomaathan, että hinnaston sisältö korvataan valitsemasi uuden tiedoston sisällöllä!',
    specialPricingType: 'Hinnaston tyyppi',
    specialDistancePricingTable: 'Kilometripohjainen taksataulukko',
    specialConnectionPricingTable: 'Hintapohjainen taksataulukko',
    errorFileReadingFailed: 'Tiedoston lukeminen epäonnistui',
    errorFileDataNotValid: 'Tiedoston sisältö ei kelpaa',
    validityTimeMinutes: 'Voimassa (min)',
    pricingAsDefaultConfirmationText:
      'Olet vaihtamassa valittua hinnastoa oletusarvoiseksi hinnastoksi kaikille vuoroille, joille ei ole erikseen määritelty vuorokohtaista hinnastoa.\n\nHaluatko jatkaa?',
    errorPricingModified:
      'Hinnastot ovat muuttuneet palvelimella, hinnastot haetaan uudelleen',
    modifyNoSalePricing: 'Muokkaa kirjauksia ilman myyntiä',
    removeNoSalePriceTable: 'Kirjaus ilman myyntiä -hinnaston poisto',
    removeNoSalePriceTableConfirmation: 'Oletko varma että haluat poistaa kirjaus ilman myyntiä -hinnaston?',
    addNewNoSalePriceTable: 'Lisää uusi kirjaus ilman myyntiä -hinnasto',
    noSalesMissing: 'Kirjaus ilman myyntiä -hinnastoja ei ole',
    modifyOtherSalePricing: 'Muokkaa muu myynti -hinnastoa',
    removeOtherSalePriceTable: 'Muu myynti -hinnaston poisto',
    removeOtherSalePriceTableConfirmation: 'Oletko varma että haluat poistaa muu myynti -hinnaston?',
    addNewOtherSalePriceTable: 'Lisää uusi muu myynti -hinnasto',
    otherSalesMissing: 'Muu myynti -hinnastoja ei ole'
  },
  lines: {
    title: 'Linjojen hallinta',
    sections: {
      charter: 'Tilausajot',
      serviceLines: 'Palvelulinjat',
      lineSeries: 'Ajosarjat',
      rlTickets: 'RL Lipputuotteet',
      kilometers: 'Kilometrikysely',
      waltti: 'Waltti-liput'
    },
    companiesFetchFailed: 'Yhtiöiden hakeminen epäonnistui.',
    common: {
      timePlaceholder: 'hh:mm',
      inputErrorTime: 'Syötä aika muodossa hh:mm',
      inputErrorRequired: 'Kenttä on pakollinen',
      messageDismiss: 'Ok!',
      lineId: 'Linjatunnus',
      departureId: 'Vuorotunnus',
    },
    charter: {
      title: 'Tilausajot',
      searchStart: 'Alkaen',
      searchEnd: 'Päättyen',
      noLinesFound: 'Valitulle aikavälille ei löytynyt yhtään tilausajoa.',
      lineFetchError: 'Tilausajojen haussa tapahtui virhe.',
      createNew: 'Luo uusi tilausajo',
      departureDate: 'Lähtöpäivä',
      departureTime: 'Lähtöaika',
      departureTimePlaceholder: 'hh:mm',
      departureLocation: 'Lähtöpaikka',
      departureLocationPlaceholder: 'Syötä paikan nimi',
      departureAddress: 'Lähtöosoite',
      departureAddressPlaceholder: 'Syötä osoite',
      destinationLocation: 'Määräpaikka',
      destinationLocationPlaceholder: 'Syötä paikan nimi',
      destinationAddress: 'Määräosoite',
      destinationAddressPlaceholder: 'Syötä osoite',
      customer: 'Tilaaja',
      customerPlaceholder: 'Syötä tilaajan nimi tai yhdistys',
      contactPersonInfo: 'Yhteyshenkilön tiedot',
      contactPersonInfoPlaceholder: 'Esim. nimi ja puhelinnumero',
      additionalInfo: 'Lisätiedot',
      additionalInfoPlaceholder: 'Viesti välitetään bussikuskille',
      passengerCount: 'Matkustajien lkm',
      roundTrip: 'Menopaluu',
      totalPrice: 'Kokonaishinta',
      pricingBasis: 'Hinnan määräytyminen',
      charterModificationMainTitle: 'Tilausajon muokkaus',
      charterCreationMainTitle: 'Tilausajon luonti',
      charterCreationReturnTripTitle: 'Paluumatkan tiedot',
      charterCreationPricingTitle: 'Hinnoittelu',
      buttonNext: 'Jatka',
      buttonBack: 'Edellinen',
      buttonCreateFinish: 'Luo tilausajo',
      buttonModificationFinish: 'Valmis',
      createPageMain: 'Lähdön tiedot',
      createPageReturn: 'Paluun tiedot',
      createPagePricing: 'Hinnoittelu',
      inputErrorTime: 'Syötä aika muodossa hh:mm',
      inputErrorRequired: 'Kenttä on pakollinen',
      inputErrorChooseAddress: 'Valitse osoite paikannusta varten',
      inputErrorAddressSearchFailed: 'Osoitteenhaku epäonnistui',
      inputErrorNoAddressResults: 'Ei hakutuloksia',
      inputErrorReturnTooEarly: 'Paluuajankohta on ennen lähtöajankohtaa',
      errorCreatingCharter: 'Tilausajon luonti epäonnistui',
      errorModifyingCharter: 'Tilausajon muokkaus epäonnistui',
      pricingBases: {
        EACH: 'Matkustajat maksavat oman osuutensa',
        ONE: 'Yksi matkustaja maksaa kaiken',
        INVOICE: 'Lasku maksetaan erikseen',
      },
      returnTripYes: 'Kyllä',
      returnTripNo: 'Ei',
      modify: 'Muokkaa',
      copy: 'Kopioi',
      charterLineCreatedSuccessMessage:
        'Tilausajo luotu! Tilausajon linjatunnus: {lineId} {departureId}',
      charterLineModificationSuccessMessage: 'Tilausajon muokkaaminen onnistui',
    },
    service: {
      title: 'Palvelulinjat',
      lineFetchError: 'Palvelulinjojen haussa tapahtui virhe.',
      noLinesFound: 'Yhtiölle ei ole vielä luotu yhtään palvelulinjaa.',
      createNew: 'Luo uusi palvelulinja',
      serviceModificationTitle: 'Palvelulinjan muokkaus',
      serviceCreationTitle: 'Palvelulinjan luonti',
      serviceLineName: 'Palvelulinjan nimi',
      validFrom: 'Voimassa alkaen',
      validUntil: 'Voimassa asti',
      startingTime: 'Aloitusaika',
      endingTime: 'Päätösaika',
      operatingDays: 'Toistuu viikonpäivinä',
      serviceLineArea: 'Palvelulinjan alue',
      serviceLineAreaPlaceholder: 'Esim. Turun keskusta-alue',
      capacity: 'Kapasiteetti',
      capacityPlaceholder: 'Maksimi henkilömäärä',
      buttonCreateFinish: 'Luo palvelulinja',
      buttonModificationFinish: 'Valmis',
      inputErrorEndingTimeTooEarly: 'Päätosäika on ennen aloitusaikaa',
      inputErrorEndingDateTooEarly: 'Päätöspäivä on ennen aloituspäivää',
      errorCreatingService: 'Palvelulinjan luonti epäonnistui',
      errorModifyingService: 'Palvelulinjan muokkaus epäonnistui',
      modify: 'Muokkaa',
      serviceLineCreatedSuccessMessage:
        'Palvelulinja {lineId} {departureId} on luotu. Palvelulinjalle on nyt voimassa perushinnasto. Voit luoda uuden hinnaston hinnastojen hallinnasta.',
      serviceLineModificationSuccessMessage: 'Palvelulinjan muokkaaminen onnistui',
    },
  },
  lineSeries: {
    title: 'Ajosarjat',
    noLineSeriesExist: 'Tälle yritykselle ei ole vielä luotu ajosarjoja.',
    addLineSeries: 'Lisää ajosarja',
    createNewLineSeries: 'Lisää uusi ajosarja',
    modifyLineSeries: 'Muokkaa ajosarjaa',
    lineSeriesName: 'Ajosarjan nimi:',
    lineSeriesOrder: 'Ajojärjestys:',
    validity: 'Voimassa:',
    line: 'Linja',
    departure: 'Vuoro',
    removeLineSeries: 'Ajosarjan poisto',
    removeLineSeriesConfirmation: 'Oletko varma että haluat poistaa ajosarjan?',
    atleastOneWeekdayRequired: 'Valitse vähintään yksi viikonpäivä',
    atleastOneLineCombinationRequired:
      'Vähintään yksi linja/vuoro -pari on syötettävä taulukkoon',
  },
  kilometers: {
    title: 'Kilometrit',
    linesAndDepartures: 'Linjat ja vuorot',
    modifyKilometers: 'Kilometrit kysytään',
    line: 'Linja',
    departure: 'Vuoro',
    atleastOneLineCombinationRequired:
      'Vähintään yksi linja/vuoro -pari on syötettävä taulukkoon',
    typeDescNone: 'Kilometrejä ei kysytä miltään linjalta tai vuorolta.',
    typeDescAll: 'Kilometrit kysytään kaikilta yrityksen vuoroilta.',
    typeDescSpecific: 'Kilometrit kysytään taulukon mukaisilla linja ja tai vuoro yhdistelmiltä.',
    typeSelectNone: 'Ei miltään vuorolta',
    typeSelectAll: 'Kaikilta yrityksen vuoroilta',
    typeSelectSpecific: 'Vain seuraavilta vuoroilta',
    noLinesDefined: 'Tälle yritykselle ei ole määritelty yhtään linjaa/vuoroa.'
  },
  waltti: {
    modify: 'Walttilippujen käyttö sallitaan',
    name: 'Waltti-nimi',
    title: 'Waltti-liput',
    linesAndDepartures: 'Linjat ja vuorot',
    desc: 'Mikäli haluat rajata Waltti-alueella ajettaessa Waltti-liput vain tiettyjen Minfo-vuorojen käyttöön, voit listata kyseiset vuorot täällä.',
    desc2: 'Mikäli yhtään vuoroa ei ole listattu, Waltti-liput hyväksytään kaikilla yrityksen Minfo-vuoroilla.',
    desc3: 'Mikäli yksikin vuoro listataan, Waltti-liput hyväksytään vain kyseisellä vuorolla.',
    line: 'Linja',
    departure: 'Vuoro',
    atleastOneLineCombinationRequired:
      'Vähintään yksi linja/vuoro -pari on syötettävä taulukkoon',
    typeDescAll: 'Waltti-liput hyväksytään kaikilla yrityksen vuoroilta.',
    typeDescSpecific: 'Waltti-liput hyväksytään taulukon mukaisilla linja- ja vuoro -yhdistelmillä.',
    typeSelectAll: 'Kaikilla yrityksen vuoroilla',
    typeSelectSpecific: 'Vain seuraavilla vuoroilla',
    noLinesDefined: 'Tälle yritykselle ei ole määritelty yhtään linjaa/vuoroa.',
    noRights: 'Ei Waltti oikeuksia.'
  },  
  messages: {
    title: 'Viestintä',
    create: 'Luo uusi viesti',
    categories: {
      title: 'Kategoria',
      INSTRUCTION: 'Työohje',
      GOOD_TO_KNOW: 'Hyvä tietää',
      IMPORTANT: 'Tärkeää',
      DISTURBANCE: 'Häiriö',
    },
    choose: 'Valitse',
    start: 'Voimassaolo alkaen',
    end: 'Voimassaolo päättyen',
    target: { name: 'Kohde', all: 'Kaikki', selectedLines: 'Vuorokohtainen' },
    companies: 'Yritykset',
    allLines: 'Koskee kaikkia vuoroja',
    selectedLines: 'Koskee seuraavia vuoroja:',
    line: 'Linja',
    departure: 'Vuoro',
    addImage: 'Lisää kuva',
    chooseFile: 'Valitse tiedosto',
    messageTitle: 'Viestin otsikko',
    messageContent: 'Viestin sisältö',
    writeTitle: 'Kirjoita otsikko',
    writeContent: 'Kirjoita viestin sisältö',
    publishMessage: 'Julkaise viesti',
    emptyFields: 'Tyhjennä kentät',
    published: 'Julkaistu',
    titleColumn: 'Otsikko',
    until: 'Poistuu',
    messageInfo: 'Viestin tiedot',
    editMessage: 'Muokkaa viestiä',
    edit: 'Muokkaa',
    delete: 'Poista viesti',
    deleteSure: 'Haluatko varmasti poistaa viestin?',
    back: 'Takaisin',
    save: 'Tallenna',
    cancel: 'Peruuta',
    fieldRequired: 'Tieto on pakollinen',
    imageError: 'Kuvan liittäminen epäonnistui',
    messagePublished: 'Julkaisu onnistui',
  },
  supportRequests: {
    title: 'Tukipyynnöt',
    timestamp: 'Aikaleima',
    deviceId: 'Laitetunnus',
    device: 'Laite',
    category: 'Kategoria',
    status: {
      title: 'Status',
      HANDLED: 'Käsitelty',
      NEW: 'Uusi',
      IN_PROGRESS: 'Kesken',
    },
    sendCommand: 'Suorita etäkomento',
    writeDeviceId: 'Anna laitetunnus',
    confirmExecuteTitle: 'Haluatko varmasti suorittaa komennon?',
    confirmExecuteBody: 'Kohde: {1}  \n  Komento: {2}',
    confirmExecuteParams: 'Parameterit: {1}',
    commands: {
      REMOTE_FORCE_LOGOUT_USER_COMMAND: 'Kirjaa käyttäjä ulos',
      REMOTE_RESET_DEVICE_COMMAND: 'Resetoi laite',
      REMOTE_SEND_DEVICE_INFO_COMMAND: 'Päivitä tiedot',
      REMOTE_RESET_AND_DEACTIVATE_DEVICE_COMMAND: 'Resetoi ja deactivoi laite',
      DELETE_UPDATE_LINE_COMMANDS: `Poista kaikki "UPDATE_LINE" komennot `,
      REMOTE_LIST_COMMANDS_COMMAND: 'Komentojen listaus',
      REMOTE_DUMP_DEBUG_SETTINGS: 'Hae asetukset',
      DELETE_COMMAND_BY_ID_COMMAND: 'Poista valitut komennot ({1})',
      DELETE_COMMANDS_BY_TYPE_COMMAND: 'Poista komennot tyypin mukaan ({1})',
      param_not_available: 'Ei valituja komentoja',
    },
    execute: 'Suorita',
    driver: 'Kuljettaja',
    company: 'Yritys',
    writeNote: 'Kirjoita halutessasi muistiinpano',
    saveNote: 'Tallenna muistiinpano',
    writeComment:
      'Kirjoita halutessasi kommentti. Huomaathan, että käsitellyksi kuittaaminen sulkee tukipyynnön, eikä komentoja enää voi suorittaa.',
    markHandled: 'Kuittaa käsitellyksi',
    updateInfo: 'Päivitä tiedot',
    waitingForResponse: 'Odottaa vastausta',
    actionType: {
      NOTE: 'Muistiinpano',
      RESPONSE: 'Vastaus',
      HANDLED: 'Käsitelty',
    },
    reasons: {
      DEVICE_BLOCKED: 'Laite jumissa',
      OTHER: 'Muu',
      SUPPORT_INITED: 'Tuen aloittama tukipyyntö',
    },
    commandTable: {
      commandAmount: 'Komentoja yhteensä: {1}',
      shortenText: 'Lyhennä tekstiä',
      '!shortenText': 'Älä lyhennä tekstiä',
      showDefaultAmount: 'Näytä ensimmäiset 10',
      showMore: 'Näytä 50 lisää',
      showAll: 'Näytä kaikki ({1})',
      hideCommands: 'Piilota komentolista',
      showCommands: 'Näytä komentolista',
    },
    deviceNotFound: 'Laitetta ei löytynyt annetulla tunnuksella',
  },
  devices: {
    title: 'Laitehallinta',
    deviceIdentifier: 'Laitetunnus',
    consortiumIdentifier: 'Pääliikennöitsijätunnus',
    companyId: 'Yritystunnus',
    edit: 'Muokkaa',
    allConsortia: 'Kaikki',
    editPropertiesTitle: 'Muokkaa laitteen lisätoiminnallisuuksia',
    activationDate: 'Käyttöönottopäivä ja -aika',
    noDevicesAvailable: 'Hallittavia laitteita ei ole vielä määritelty',
    addDevice: 'Lisää laite',
    deviceIdMustBe8Characters: 'Laitetunnuksen tulee olla 8 merkkiä',
    deviceIdNotFound: 'Laitetunnusta ei löytynyt, tarkista tunnus',
    deviceIdSameAsSource: 'Laitetunnus on sama kun nykyisen laitteen laitetunnus',
    additionalFeatures: 'Lisätoiminnallisuudet',
    buttonFinish: 'Valmis',
    buttonCancel: 'Peruuta',
    errorUpdatingDeviceDetails: 'Laitetietojen päivitys epäonnistui',
    noteDeviceAlreadyExists:
      'Laitteelle {deviceId} on jo määritelty lisätoiminnallisuuksia. Voit kuitenkin muokata niitä tässä.',
    transferFeatures: 'Siirrä lisätoiminnallisuudet',
    transferFeaturesDescription:
      'Voit siirtää nykyisen laitteen lisätoiminnalisuudet toiselle laitetunnukselle. Nykyisen laitteen lisätoiminnallisuudet poistetaan.',
    hideEmpty: 'Piilota tyhjät',
    overwriteNotice:
      'HUOM! Laitteella {deviceId} on jo lisätoiminnallisuuksia. Jos jatkat, vanhat lisätoiminnallisuudet ylikirjoitetaan.',
    features: {
      REPORTING: 'Pilven raportointi',
      LINES: 'Linjojen hallinta',
      MESSAGING: 'Viestintä',
      WALTTI: 'Waltti-toiminnot',
    },
    featureSymbols: {
      REPORTING: 'Rap.',
      LINES: 'Linj.',
      MESSAGING: 'Vies.',
      WALTTI: 'Wal.',
    },
  },
  rlTickets: {
    add: 'Lisää linja',
    remove: 'Poista',
    line: 'Linjan ID',
    departure: 'Vuoron ID',
  },
};

<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  let dragging = false;

  let input;

  const handleDrop = event => {
    const file = event.dataTransfer.files[0];
    dispatch('file', file);
    dragging = false;
  };

  export function click() {
    input.click();
  }

  export function empty() {
    input.value = null;
  }

  export let accept = '';
</script>

<style>
  input {
    visibility: hidden;
  }
</style>

<div
  on:click={() => input.click()}
  on:drop|preventDefault={handleDrop}
  on:dragover|preventDefault={() => (dragging = true)}
  on:dragleave|preventDefault={() => (dragging = false)}
  class:dragging
>
  <slot {dragging} />
</div>

<input
  bind:this={input}
  type="file"
  {accept}
  on:change={event => dispatch('file', event.target.files[0])}
/>

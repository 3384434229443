<script>
  import { _, number } from 'svelte-i18n';

  export let totalSum;
  export let totalSumVatFree;
</script>

<table>
  <tr>
    <td class="font-bold pr-4">{$_('results.totalSum')}:</td>
    <td class="font-bold">{$number(totalSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <td class="pr-4">{$_('travel.vatFreeSummary')}:</td>
    <td>{$number(totalSumVatFree, { format: 'EUR' })}</td>
  </tr>
</table>

<script>
  import { _ } from 'svelte-i18n';
  import { supportRequestsDirty } from '../stores/support_requests';
  import SendCommand from '../components/support_requests/SendCommand.svelte';
  import SupportRequestList from '../components/support_requests/SupportRequestList.svelte';
  import SupportRequestThread from '../components/support_requests/SupportRequestThread.svelte';
  import Back from '../components/Back.svelte';

  let selectedRequest = '';
</script>

<style>
  h1 {
    margin: 0 0 1em 0;
    display: flex;
    align-items: baseline;
  }

  .content {
    padding: 2em 4em 1em 4em;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: calc(100% - 8em);
  }

  .panels {
    display: flex;
    width: 100%;
  }

  .send-command {
    padding: 0 3em;
  }
</style>

<div class="content">
  {#if selectedRequest}
    <Back
      on:click={() => {
        supportRequestsDirty();
        selectedRequest = null;
      }}
    />
  {/if}
  <h1 class="mb-6 text-3xl font-bold">{$_('supportRequests.title')}</h1>
  {#if selectedRequest}
    <SupportRequestThread requestId={selectedRequest} />
  {:else}
    <div class="panels">
      <SupportRequestList on:request={({ detail }) => (selectedRequest = detail)} />
      <div class="send-command">
        <SendCommand createRequest={true} />
      </div>
    </div>
  {/if}
</div>

import { getClient } from '../common/http';
import { writable } from 'svelte/store';

export const devices = writable([]);
export const activeDevice = writable({});
export const consortiumIdentifiers = writable([]);

export const fetchBasicDevices = async () => {
  const { data: result } = await getClient().get('/api/devices');
  devices.set(result);
  consortiumIdentifiers.set([...new Set(result.map((device) => device.consortiumIdentifier))]);
};

export const fetchDeviceInformation = async (deviceId) => {
  const { data: result } = await getClient().get(`/api/devices/${deviceId}`);
  activeDevice.set(result);
  return result;
};

export const transferDeviceInformation = async (sourceId, destinationId) => {
  await getClient().post(`/api/devices/transfer/${sourceId}/${destinationId}`);
};

export const updateDeviceInformation = (deviceId, deviceInfo) => {
  return getClient().post(`/api/devices/${deviceId}`, {
    ...deviceInfo,
    features: [...deviceInfo.features],
  });
};

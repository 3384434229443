<script>
  import { getContext } from 'svelte';
  import NumberInput from '../NumberInput.svelte';
  import { _ } from 'svelte-i18n';

  const { close } = getContext('simple-modal');

  export let onDelete;
  export let onSuccess;
  export let companyId;
  export let item;
  export let itemId;
  export let hideDiscount = false;
  export let disableDelete = false;

  let name = item.name;
  let discount = item.discount;
</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  input:not(.discount) {
    min-width: 350px;
  }
  input {
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
    border-radius: 4px;
  }
  .discount-container {
    display: flex;
    align-items: baseline;
    padding: 0;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
  }
  .discount-input-container {
    width: 75px;
    padding: 0;
  }
  .discount-symbol {
    padding-left: 0.5rem;
  }
  button {
    min-width: 150px;
  }
  .button-main-container {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-between;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
</style>

<div>
  <h2>{$_('pricing.modifyCustomerGroup')}</h2>
  <div class="flex flex-col items-start">
    <label for="name">{$_('pricing.name')}: *</label>
    <input type="text" name="name" id="name" bind:value={name} />
  </div>
  {#if !hideDiscount}
    <div>
      <label for="discount">{$_('pricing.discount')}:</label>
      <div class="discount-container">
        <div class="discount-input-container">
          <NumberInput
            type="number"
            class="discount"
            bind:value={discount}
            name="discount"
            id="discount"
            maxlength="2"
          />
        </div>
        <span class="discount-symbol">%</span>
      </div>
    </div>
  {/if}
  <div class="button-main-container">
    <div class="button-container">
      <button
        class="small"
        disabled={!name.length}
        on:click={() => {
          onSuccess(companyId, itemId, name, discount);
          close();
        }}
      >
        {$_('pricing.done')}
      </button>
      <button
        class="small secondary"
        on:click={() => {
          close();
        }}
      >
        {$_('pricing.cancel')}
      </button>
    </div>
    <div>
      <button
        class="small"
        disabled={disableDelete}
        on:click={() => {
          onDelete(companyId, itemId);
          close();
        }}
      >
        {$_('pricing.removeCustomerGroup')}
      </button>
    </div>
  </div>
</div>

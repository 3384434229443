<script>
  import { _, locale, number } from 'svelte-i18n';
  import Loading from '../Loading.svelte';
  import { companies as companyList } from '../../stores/companies';
  import {
    searchLoaded,
    searchLoading,
    searchResults,
  } from '../../stores/travel_reports';
  import {
    getExcelDownloadGeneral,
    getExcelDownloadProducts,
    getExcelDownloadLines,
  } from '../../common/http';
  import { createEventDispatcher } from 'svelte';
  import PassengerIcon from '../images/Passenger.svelte';
  import ShipmentIcon from '../images/Shipment.svelte';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import Icon from 'fa-svelte';
  import ProductReportVat from './ProductReportVat.svelte';
  import RadioButton from '../RadioButton.svelte';

  const dispatch = createEventDispatcher();

  export let start;
  export let end;
  export let lineId;
  export let departureId;
  export let companies;
  export let weekdays;
  export let shipmentWidth = null;
  export let totalSumWidth = null;
  export let euroPerKmWidth = null;
  export let tickets;
  export let deviceType;

  let selectedLine;

  $: results = getResults($searchResults.products, $companyList);

  let excelType = 'lines';

  const getResults = (searchResults, companies) =>
    searchResults.map(result => ({
      ...result,
      company: companies.filter(({ number }) => number === result.company_id)[0] || {
        name: result.company_id,
        number: result.company_id,
      },
    }));

  $: totalCount = results && results.length;
  $: totalSum = results && results.reduce((acc, { sum: cur }) => acc + cur, 0);
  $: totalSumVat = results && results.reduce((acc, { sum_vat: cur }) => acc + cur, 0);
  $: totalLineLength =
    results &&
    results.reduce(
      (acc, { line_length, shifts }) => acc + (line_length || 0) * shifts,
      0,
    );
  $: totalEuroPerKm = totalSum && totalLineLength ? totalSum / totalLineLength : 0;
  $: totalShipments =
    results && results.reduce((acc, { shipments: cur }) => acc + cur, 0);
  $: totalQuantity = results && results.reduce((acc, { quantity: cur }) => acc + cur, 0);

  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const excel =
      excelType === 'lines'
        ? getExcelDownloadLines
        : excelType === 'events'
        ? getExcelDownloadGeneral
        : getExcelDownloadProducts;
    const res = await excel({
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId,
      departureId,
      companies,
      weekdays,
      lang,
      tickets,
      deviceType,
    });
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
</script>

<style>
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  table.table-results {
    width: 100%;
  }

  .table-results th {
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  .table-results th,
  .table-results th span {
      }

  tr:not(.selected):not(.summary) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:not(.selected):not(.summary).last-result td {
    border-bottom: 1px solid #333333;
  }

  td {
    padding: 0;
    color: inherit;
    padding-right: 2em;
    padding-bottom: 0.7em;
    padding-top: 1em;
  }

  .table-results th.price-vatfree,
  td.price-vatfree {
    padding-right: 0.75em;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  td:last-child {
    padding: 0;
  }

  tr:not(:first-child):not(:last-child) {
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: center;
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }
</style>

{#if $searchLoading}
  <div class="loading-content">
    <Loading />
  </div>
{/if}

{#if !$searchLoading && $searchLoaded}
  {#if results.length}
    <table class="table-results" cellspacing="0">
      <tr>
        <th class="text-left">{$_('results.company')}</th>
        <th class="text-left">{$_('results.line')}</th>
        <th class="text-left">{$_('results.departure')}</th>
        <th class="text-left">{$_('results.trip')}</th>
        <th class="text-right">
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span>{$_('travel.total')}</span>
          </div>
        </th>
        <th
          class="text-right price-vatfree"
          style={totalSumWidth ? `width: ${totalSumWidth}px` : ''}
        >
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" />
            <span> {$_('travel.totalSumVatFree')}</span>
          </div>
        </th>
        <th class="text-right" style={totalSumWidth ? `width: ${totalSumWidth}px` : ''}>
          <div class="inline-flex">
            <span> {$_('travel.totalSumVat')}</span>
          </div>
        </th>
        <th class="text-right" style={euroPerKmWidth ? `width: ${euroPerKmWidth}px` : ''}>
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span>
              {$_('travel.euroPerKm')}</span
            >
          </div>
        </th>
        <th class="text-right" style={euroPerKmWidth ? `width: ${euroPerKmWidth}px` : ''}>
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span>
              {$_('travel.byLineMax')}</span
            >
          </div>
        </th>
        <th class="text-right" style={euroPerKmWidth ? `width: ${euroPerKmWidth}px` : ''}>
          <div class="inline-flex">
            <PassengerIcon width="20px" height="20px" /><span> {$_('travel.avg')}</span>
          </div>
        </th>
        <th
          class="text-right"
          style={shipmentWidth ? `width: calc(${shipmentWidth}px - 2em)` : ''}
        >
          <div class="inline-flex">
            <ShipmentIcon width="20px" height="20px" />
            <span> {$_('travel.total')}</span>
          </div>
        </th>

        <th />
      </tr>
      {#each results as result, i}
        <tr
          class="text-mnormal"
          on:click={() => dispatch('line', result)}
          class:last-result={i === results.length - 1}
          class:font-semibold={selectedLine === result.id}
          class:selected={selectedLine === result.id}
          class:bg-mlight={selectedLine === result.id}
        >
          <td>{result.company.name} ({result.company.number})</td>
          <td
            >{(result.line_id === '0000' ? '-' : result.line_id || '-').split('$')[0]}</td
          >
          <td>{(result.departure_id || '-').split('$')[0]}</td>
          <td>{result.line_name || '-'}</td>
          <td class="text-right">{result.quantity || '0'}</td>
          <td class="text-right price-vatfree">
            {$number(result.sum, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">
            {$number(result.sum_vat, { format: 'EUR' }).replace('€', '') || '0'}
          </td>
          <td class="text-right">
            {result.euro_per_km
              ? $number(result.euro_per_km, { format: 'EUR' }).replace('€', '')
              : '0'}
          </td>
          <td class="text-right">
            {$number(result.maxPassengers)}
          </td>
          <td class="text-right">
            {$number(result.travelerAvg, { format: 'EUR' }).replace('€', '')}
          </td>

          <td class="text-right">{result.shipments || '0'}</td>

          <td
            on:click|stopPropagation={() =>
              selectedLine === result.id
                ? (selectedLine = null)
                : (selectedLine = result.id)}
          >
            <div class="icon" class:expanded={selectedLine === result.id}>
              <Icon icon={faChevronDown} />
            </div>
          </td>
        </tr>
        {#if selectedLine === result.id}
          <ProductReportVat
            products={result.products}
            lastResult={i === results.length - 1}
          />
        {/if}
      {/each}
      <tr class="summary text-mdark">
        <td colspan="4">
          {$_('travel.totalLinesPerDay').replace('%d', totalCount)}
        </td>
        <td class="text-right font-semibold">{totalQuantity || '0'}</td>
        <td class="text-right font-semibold price-vatfree">
          {$number(totalSum, { format: 'EUR' }).replace('€', '') || '0'}
        </td>
        <td class="text-right font-semibold">
          {$number(totalSumVat, { format: 'EUR' }).replace('€', '') || '0'}
        </td>
        <td class="text-right font-semibold">
          {$number(totalEuroPerKm, { format: 'EUR' }).replace('€', '') || '0'}
        </td>
        <td colspan="3" />

        <td class="text-right font-semibold">{totalShipments || '0'}</td>

        <td />
      </tr>
    </table>

    <div class="mt-4">
      <span class="mb-2">{$_('travel.createExcel')}</span>
      <RadioButton
        label={$_('travel.exportResults')}
        value="lines"
        bind:group={excelType}
      />
      <RadioButton
        label={$_('travel.exportEvents')}
        value="events"
        bind:group={excelType}
      />
      <RadioButton
        label={$_('travel.exportProducts')}
        value="products"
        bind:group={excelType}
      />
      <button on:click={createExcel}>{$_('results.createExcel')}</button>
    </div>
  {:else}
    <h3>{$_('results.noResults')}</h3>
  {/if}
{/if}

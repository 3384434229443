<script>
  import { createEventDispatcher } from 'svelte';
  import Loading from '../Loading.svelte';
  import { showError } from '../../stores/notifications';
  import { _ } from 'svelte-i18n';
  import { messagesLoaded, messages, getMessages } from '../../stores/messages';

  if (!$messagesLoaded) {
    getMessages().catch(showError($_('error.title')));
  }

  const dispatch = createEventDispatcher();
  const handleClick = message => {
    dispatch('click', message);
  };

  const now = new Date().getTime();
</script>

<style>
  table {
    width: 100%;
    color: #333333;
    margin-top: 1em;
  }

  th {
    text-align: start;
    padding: 0 3em 0.3em 0;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  td {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    padding-right: 1em;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
  }

  .unpublished {
    color: #001E61;
  }
</style>

{#if !$messagesLoaded}
  <div class="loading-content">
    <Loading />
  </div>
{:else}
  <table cellspacing="0">
    <tr>
      <th>{$_('messages.published')}</th>
      <th>{$_('messages.categories.title')}</th>
      <th>{$_('messages.titleColumn')}</th>
      <th>{$_('messages.target.name')}</th>
      <th>{$_('messages.until')}</th>
    </tr>
    {#each $messages as message}
      <tr>
        <td
          on:click={() => handleClick(message)}
          class:unpublished={message.start.toDate().getTime() > now}
        >
          {message.start.format('D.M.YYYY H:mm')}
        </td>
        <td on:click={() => handleClick(message)}>
          {$_(`messages.categories.${message.category}`, {
            default: message.category,
          })}
        </td>
        <td on:click={() => handleClick(message)}>{message.title}</td>
        <td on:click={() => handleClick(message)}>
          {message.lines
            ? $_('messages.target.selectedLines')
            : $_('messages.target.all')}
        </td>
        <td on:click={() => handleClick(message)}>
          {message.end ? message.end.format('D.M.YYYY') : '-'}
        </td>
      </tr>
    {/each}
  </table>
{/if}

<script>
  import { _, number } from 'svelte-i18n';
  import { companies as companyList } from '../../stores/companies';

  import { createEventDispatcher } from 'svelte';
  import PassengerIcon from '../images/Passenger.svelte';
  import ShipmentIcon from '../images/Shipment.svelte';

  const dispatch = createEventDispatcher();

  export let data;

  $: results = getResults(data, $companyList);

  const getResults = (searchResults, companies) =>
    searchResults.map(result => ({
      ...result,
      company: companies.filter(({ number }) => number === result.company_id)[0] || {
        name: result.company_id,
        number: result.company_id,
      },
    }));

  $: totalCount = results && results.length;
  $: totalSum = results && results.reduce((acc, { sum: cur }) => acc + cur, 0);
  $: totalSumVat = results && results.reduce((acc, { sum_vat: cur }) => acc + cur, 0);
  $: totalLineLength =
    results && results.reduce((acc, { line_length: cur }) => acc + (cur || 0), 0);
  $: totalEuroPerKm = totalSum && totalLineLength ? totalSum / totalLineLength : 0;
  $: totalShipments =
    results && results.reduce((acc, { shipments: cur }) => acc + cur, 0);
  $: totalQuantity = results && results.reduce((acc, { quantity: cur }) => acc + cur, 0);
</script>

<style>
  th {
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
  }

  tr:not(.selected):not(.summary) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:not(.selected):not(.summary).last-result td {
    border-bottom: 1px solid #333333;
  }

  td {
    padding: 0;
    color: inherit;
    padding-right: 2em;
    padding-bottom: 0.7em;
    padding-top: 1em;
  }

  th.price-vatfree,
  td.price-vatfree {
    padding-right: 0.75em;
  }

  td:first-child,
  td:last-child,
  th:first-child,
  th:last-child {
    border-bottom: none !important;
  }

  tr:not(:first-child):not(:last-child) {
    cursor: pointer;
  }
</style>

<tr class="bg-mlight pl-16 pt-2">
  <th />
  <th class="text-left">{$_('results.company')}</th>
  <th class="text-left">{$_('results.line')}</th>
  <th class="text-left">{$_('results.departure')}</th>
  <th class="text-left">{$_('results.trip')}</th>
  <th class="text-right">
    <div class="inline-flex">
      <PassengerIcon width="20px" height="20px" />
      <span>{$_('travel.total')}</span>
    </div>
  </th>
  <th class="text-right price-vatfree">
    <div class="inline-flex">
      <PassengerIcon width="20px" height="20px" />
      <span> {$_('travel.totalSumVatFree')}</span>
    </div>
  </th>
  <th class="text-right">
    <div class="inline-flex">
      <span> {$_('travel.totalSumVat')}</span>
    </div>
  </th>
  <th class="text-right">
    <div class="inline-flex">
      <PassengerIcon width="20px" height="20px" /><span> {$_('travel.euroPerKm')}</span>
    </div>
  </th>

  <th class="text-right">
    <div class="inline-flex">
      <PassengerIcon width="20px" height="20px" /><span> {$_('travel.byLineMax')}</span>
    </div>
  </th>
  <th class="text-right">
    <div class="inline-flex">
      <PassengerIcon width="20px" height="20px" /><span> {$_('travel.avg')}</span>
    </div>
  </th>
  <th class="text-right">
    <div class="inline-flex">
      <span>{$_('travel.line_length')}</span>
    </div>
  </th>
  <th class="text-right">
    <div class="inline-flex">
      <ShipmentIcon width="20px" height="20px" />
      <span> {$_('travel.total')}</span>
    </div>
  </th>

  <th />
</tr>
{#each results as result, i}
  <tr
    class="text-mnormal bg-mlight pl-16"
    on:click={() => dispatch('line', result)}
    class:last-result={i === results.length - 1}
  >
    <td />
    <td>{result.company.name} ({result.company.number})</td>
    <td>{(result.line_id === '0000' ? '-' : result.line_id || '-').split('$')[0]}</td>
    <td>{(result.departure_id || '-').split('$')[0]}</td>
    <td>{result.line_name || '-'}</td>
    <td class="text-right">{result.quantity || '0'}</td>
    <td class="text-right price-vatfree">
      {$number(result.sum, { format: 'EUR' }).replace('€', '') || '0'}
    </td>
    <td class="text-right">
      {$number(result.sum_vat, { format: 'EUR' }).replace('€', '') || '0'}
    </td>
    <td class="text-right">
      {result.line_length
        ? $number(result.sum / result.line_length, {
            format: 'EUR',
          }).replace('€', '')
        : '-'}
    </td>

    <td class="text-right">
      {$number(result.maxPassengers)}
    </td>
    <td class="text-right">
      {$number(result.travelerAvg, { format: 'EUR' }).replace('€', '')}
    </td>
    <td class="text-right">
      {$number(result.line_length, 0)}
    </td>
    <td class="text-right">{result.shipments || '0'}</td>
    <td />
  </tr>
{/each}
<tr class="summary text-mdark bg-mlight pl-16 pb-4">
  <td />
  <td colspan="4">
    {$_('travel.totalLinesPerDay').replace('%d', totalCount)}
  </td>
  <td class="text-right font-semibold">{totalQuantity || '0'}</td>
  <td class="text-right font-semibold price-vatfree">
    {$number(totalSum, { format: 'EUR' }).replace('€', '') || '0'}
  </td>
  <td class="text-right font-semibold">
    {$number(totalSumVat, { format: 'EUR' }).replace('€', '') || '0'}
  </td>
  <td class="text-right font-semibold">
    {$number(totalEuroPerKm, { format: 'EUR' }).replace('€', '') || '0'}
  </td>
  <td colspan="3" />
  <td class="text-right font-semibold">{totalShipments || '0'}</td>
  <td />
</tr>

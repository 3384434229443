<script>
  export let count = null;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 40.548229 40.548229"
  height="40.548229"
  width="40.548229"
>
  <path
    d="m 25.285787,10.274114 c 1.650408,0 2.988327,1.343146 2.988327,3 v 11.75 c
    0,1.39779 -0.952238,2.572305 -2.240998,2.905423 l -2.47e-4,1.344577 c
    0,0.552285 -0.445973,1 -0.996109,1 -0.51084,0 -0.931867,-0.38604
    -0.989407,-0.883379 l -0.0067,-0.116621 -5.37e-4,-1.25 h -8.093 l
    1.52e-4,1.25 c 0,0.512836 -0.384538,0.935507 -0.879942,0.993272 l
    -0.116167,0.0067 c -0.51084,0 -0.931867,-0.38604 -0.989407,-0.883379 l
    -0.0067,-0.116621 -8.84e-4,-1.55202 c -0.994723,-0.487035 -1.68005,-1.512161
    -1.68005,-2.69798 v -11.75 c 0,-1.656854 1.33792,-3 2.988327,-3 z m
    0.995327,9.875 h -12.015 l 2.18e-4,4.875 c 0,0.552285 0.445973,1 0.996109,1
    h 10.023346 c 0.550136,0 0.996109,-0.447715 0.996109,-1 z m
    -9.929179,1.65625 0.141666,0.0064 c 0.746538,0.06762 1.341036,0.664442
    1.408394,1.413896 l 0.0061,0.135454 c 2.41e-4,0.821754 -0.621317,1.491017
    -1.414513,1.562865 l -0.141666,0.0064 c -0.811832,0 -1.478492,-0.623986
    -1.550059,-1.420281 l -0.0064,-0.142219 0.0064,-0.142219 c 0.07157,-0.79631
    0.738227,-1.420296 1.550059,-1.420296 z m 7.844358,0 0.141666,0.0064 c
    0.746538,0.06762 1.341036,0.664442 1.408394,1.413896 l 0.0058,0.135454 c
    5.99e-4,0.821754 -0.620959,1.491017 -1.414155,1.562865 l -0.141666,0.0064 c
    -0.859587,0 -1.55642,-0.699555 -1.55642,-1.5625 l 1.8e-5,-0.0061 c
    0.0033,-0.860108 0.698833,-1.556383 1.556402,-1.556383 z m 1.089494,-9.53125
    H 15.262441 c -0.550136,0 -0.996109,0.447715 -0.996109,1 l -2.18e-4,4.875 h
    12.015 l 7.82e-4,-4.875 c 0,-0.552285 -0.445973,-1 -0.996109,-1 z"
    fill="#06324C"
  />
  {#if count !== null && count !== undefined}
    <circle cx="30px" cy="8px" r="8px" fill="#333333" />
    <text
      x="30px"
      y="8px"
      text-anchor="middle"
      fill="white"
      dy=".3em"
      style="font-family: 'Arial'; font-size: 12px;"
    >
      {count}
    </text>
  {/if}
</svg>

<script>
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import { createEventDispatcher } from 'svelte';
  import Icon from 'fa-svelte';

  const dispatch = createEventDispatcher();

  const onChange = e => {
    dispatch('change', e);
  };

  export let entries = [];
  export let value;
  export let flexGrow = false;
  export let disabled = false;
  export let border = false;
  export let id = undefined;
  export let textAlignment = undefined;
  export let customIcon = undefined;
  export let primaryText = false;
</script>

<style>
  .container {
    position: relative;
  }
  select {
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    padding-right: 1.5rem;
    padding-left: 0;
    height: 100%;
    width: 100%;
    border: 0;
    font-size: 1rem;
  }
  select:not(:disabled) {
    cursor: pointer;
  }
  .caret {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    height: 100%;
    padding-right: 0.25rem;
  }
  .flex-grow {
    flex-grow: 1;
  }
  select.border {
    border: 1px solid #b2c3cf;
    border-radius: 4px;
  }
</style>

<div class="container" class:flex-grow={flexGrow}>
  <select
    {id}
    class:text-mnormal={primaryText}
    style={textAlignment ? `text-align: ${textAlignment}` : undefined}
    bind:value
    on:change={onChange}
    {disabled}
    class:border
  >
    {#each entries as entry}
      <option value={entry.value !== undefined ? entry.value : entry}>{entry.label !== undefined ? entry.label : entry}</option>
    {/each}
  </select>
  {#if !disabled}
    <div class="caret">
      {#if customIcon}
      <svelte:component this={customIcon} />
      {:else}
      <Icon icon={faChevronDown} />
      {/if}
    </div>
  {/if}
</div>

<script>
  import { _ } from 'svelte-i18n';
  import SortableRowList from '../SortableRowList.svelte';
  import PricingLineRow from './PricingLineRow.svelte';
  import { showNotification } from '../../stores/notifications';

  export let lines;
  export let lineNames;
  export let lineIds;
  export let editingMode;
  export let onLineDeleted;
  export let onLineModified;
  export let onLineAdded;
  export let disallowEmpty = false;
  export let placeholder = $_('pricing.departuresMissing');

  const TEMP_LINE_ROWS = 3;
  const EMPTY_LINE_ROW = Object.freeze({
    line: '',
    departure: '',
    name: '',
    id: undefined,
  });

  let tempLineRows = [];

  $: {
    if (!editingMode) {
      tempLineRows = [];
    } else {
      createTempRows();
    }
  }

  $: data = [
    ...lines.map((x, index) => {
      const [departure, line] = x.split('-');
      return {
        line: line || '',
        departure: departure || '',
        name: lineNames[index] || '',
        id: lineIds[index],
      };
    }),
    ...tempLineRows,
  ];

  const createTempRows = () => {
    tempLineRows = [];
    if (!lines.length) {
      for (let i = 0; i < TEMP_LINE_ROWS; ++i) {
        tempLineRows.push({ ...EMPTY_LINE_ROW });
      }
    }
  };

  const addTempRow = () => {
    tempLineRows = [...tempLineRows, { ...EMPTY_LINE_ROW }];
  };

  const deleteLineRow = (row, index) => {
    if (!row.id) {
      const tempIndex = index - data.length;
      tempLineRows = [...tempLineRows];
      tempLineRows.splice(tempIndex, 1);
    } else {
      if (disallowEmpty && data.length <= 1) {
        showNotification({
          type: 'warning',
          title: $_('error.title'),
          body: $_('pricing.errorCannotDeleteLastDeparture'),
        });
      } else {
        onLineDeleted(data[index].id, index);
      }
    }
  };

  const onLineRowChange = (event, item, index) => {
    if (!item.id) {
      item[event.detail.id] = event.detail.value;
      if (item.departure || item.line) {
        const deleteIndex = tempLineRows.indexOf(item);
        tempLineRows = [...tempLineRows];
        tempLineRows.splice(deleteIndex, 1);
        onLineAdded(item.line, item.departure, item.name);
      }
    } else if (item[event.detail.id] !== event.detail.value) {
      const modifiedItem = {
        ...item,
        [event.detail.id]: event.detail.value,
      };
      if (!modifiedItem.line && !modifiedItem.departure && !modifiedItem.name) {
        deleteLineRow(item, index);
        addTempRow();
      } else {
        onLineModified(
          modifiedItem.id,
          modifiedItem.line,
          modifiedItem.departure,
          modifiedItem.name,
          index,
        );
      }
    }
  };
</script>

<SortableRowList
  title={$_('pricing.appliesToLines')}
  list={data}
  {editingMode}
  onAddClicked={() => {
    tempLineRows = [...tempLineRows, { ...EMPTY_LINE_ROW }];
  }}
  renderComponent={PricingLineRow}
  {placeholder}
  componentProps={{
    onDeleteRow: deleteLineRow,
    onChange: onLineRowChange,
  }}
  headerColumns={[
    {
      title: $_('results.line'),
      width: '160px',
    },
    {
      title: $_('results.departure'),
      width: '160px',
    },
    {
      title: $_('pricing.name'),
      width: '160px',
    },
    {},
  ]}
  disableSorting={true}
  addButtonText={$_('pricing.addRow')}
  }
/>

<script>
  import Back from '../components/Back.svelte';

  import MessageInfo from '../components/messages/MessageInfo.svelte';

  import Expandable from '../components/Expandable.svelte';
  import { _ } from 'svelte-i18n';
  import MessageList from '../components/messages/MessageList.svelte';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import Icon from 'fa-svelte';
  import MessageEditor from '../components/messages/MessageEditor.svelte';
  import { tick } from 'svelte';

  let expanded = true;
  let editing = false;

  let selectedMessage = {};

  $: hasSelectedMessage = selectedMessage && selectedMessage.id;

  const handleUnselect = () => {
    selectedMessage = {};
    editing = false;
  };

  const closeEditor = async () => {
    await tick();
    editing = false;
  };
</script>

<style>
  h1 {
    font-size: 2em;
    margin: 1em 0;
  }

  .content {
    padding: 0 4em;
    width: calc(100% - 8em);
  }

  section {
    background-color: #E5E8EF;
    color: #333333;
    padding: 0.5em 3em;
    transition: padding 250ms ease-in-out;
  }

  section.expanded {
    padding: 0.5em 3em 2em 3em;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }

  .padding {
    padding-top: 2rem;
  }
</style>

<div class="content" class:padding={hasSelectedMessage && !editing}>
  {#if hasSelectedMessage}
    {#if editing}
      <h1 class="mb-6 text-3xl font-bold">{$_('messages.editMessage')}</h1>
      <MessageEditor
        on:submitted={closeEditor}
        bind:message={selectedMessage}
        on:cancel={closeEditor}
      />
    {:else}
      <Back on:click={handleUnselect} />
      <h1 class="mb-6 text-3xl font-bold">{$_('messages.messageInfo')}</h1>
      <MessageInfo
        message={selectedMessage}
        on:edit={() => (editing = true)}
        on:delete={handleUnselect}
      />
    {/if}
  {:else}
    <h1 class="mb-6 text-3xl font-bold">{$_('messages.title')}</h1>

    <section class:expanded>
      <div class="header" on:click={() => (expanded = !expanded)}>
        <h2>{$_('messages.create')}:</h2>
        <div class="icon" class:expanded>
          <Icon icon={faChevronDown} />
        </div>
      </div>
      <Expandable bind:expanded>
        <MessageEditor
          on:submitted={({ detail: message }) => (selectedMessage = message)}
        />
      </Expandable>
    </section>

    <MessageList on:click={({ detail: message }) => (selectedMessage = message)} />
  {/if}
</div>

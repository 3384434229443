<script>
  import { _ } from 'svelte-i18n';

  const COUNT = 6;

  const TABLE = new Array(COUNT).fill(null).map(_ => ({ line: '', departure: '' }));

  export let disabled = false;
  export let lines = [];

  let needInit = true;

  const initLines = lines => {
    if (needInit)
      TABLE.forEach((it, i) => (TABLE[i] = lines[i] || { line: '', departure: '' }));
    needInit = true;
  };

  $: initLines(lines);

  const handleChange = async () => {
    lines = TABLE.filter(({ line, departure }) => line || departure);
    needInit = false;
  };
</script>

<style>
  input {
    height: 2rem;
    border-radius: 3px;
  }

  .lines {
    padding: 0 1em;
  }

  .field {
    display: flex;
  }

  input:first-child {
    margin-right: 1em;
  }
</style>

<div class="lines">
  {#each TABLE as __, i}
    <div class="field">
      <input
        type="text"
        {disabled}
        bind:value={TABLE[i].line}
        on:change={handleChange}
        on:keypress={handleChange}
        on:keyup={handleChange}
        on:keydown={handleChange}
        placeholder={$_('messages.line')}
      />
      <input
        type="text"
        {disabled}
        bind:value={TABLE[i].departure}
        on:change={handleChange}
        on:keypress={handleChange}
        on:keyup={handleChange}
        on:keydown={handleChange}
        placeholder={$_('messages.departure')}
      />
    </div>
  {/each}
</div>

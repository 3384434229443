<script>
  import { _, date } from 'svelte-i18n';
  import moment from 'moment-timezone';
  import { getCharterLines, charterLines, initializeModifyCharterLineData, initializeNewCharterLineData, charterLineCreateState, initializeCopyCharterLineData } from '../../stores/lines';
  import Loading from '../../components/Loading.svelte';
  import { tick, onMount } from 'svelte';
  import Icon from 'fa-svelte';
  import { faCalendar } from '@fortawesome/free-solid-svg-icons';
  import CharterCreate from './CharterCreate.svelte';
  import DatePicker from '../../components/DatePicker.svelte';

  export let currentCompany;
  let creatingLine = false;
  let displayedMessage = undefined;

  let tableElement;
  let messageElement;

  let searchStart = moment().tz('Europe/Helsinki').subtract(7, 'days').startOf('month').toDate();
  let searchEnd = moment().tz('Europe/Helsinki').add(30, 'days').endOf('month').endOf('day').toDate();

  // Date picker is buggy and fires off multiple reactive changes... workaround
  let prevStart = searchStart;
  let prevEnd = searchEnd;
  let prevCompany = currentCompany;

  let isLoading = true;
  let isError = false;

  $: {
    currentCompany;
    creatingLine = false;
    displayedMessage = undefined;
  }

  $: {
    if (currentCompany && (prevCompany != currentCompany || datesHaveChanged(searchStart, searchEnd))) {
      prevStart = searchStart;
      prevEnd = searchEnd;
      prevCompany = currentCompany;
      fetchCharterLines();
    }
  }

  const fetchCharterLines = async () => {
    isLoading = true;
    isError = false;
    try {
      await getCharterLines(currentCompany, searchStart, moment(searchEnd).tz('Europe/Helsinki').endOf('day').toDate());
      updateDimensions();
    } catch (error) {
      isError = true;
    } finally {
      isLoading = false;
    }
  };

  const datesHaveChanged = (newStart, newEnd) => newStart.toISOString() != prevStart.toISOString() || newEnd.toISOString() != prevEnd.toISOString();

  onMount(() => {
    if (currentCompany)
      fetchCharterLines();
  });

  const onCharterLineCreated = (entry) => {
    if ($charterLineCreateState.modification) {
      displayedMessage = $_('lines.charter.charterLineModificationSuccessMessage');
    } else {
      displayedMessage = $_(
          'lines.charter.charterLineCreatedSuccessMessage',
          {
            values:
              {
                lineId: entry.lineId,
                departureId: entry.departureId,
              }
          }
        );
    }
    const departureDateTime = moment(entry.departureDateTime).tz('Europe/Helsinki');
    if (departureDateTime.isBefore(searchStart)) {
      searchStart = departureDateTime.startOf('day').toDate();
    }
    if (departureDateTime.isAfter(searchEnd)) {
      searchEnd = departureDateTime.endOf('day').toDate();
    }
    fetchCharterLines();
  };

  $: {
    $charterLines;
    if (!creatingLine) {
      updateDimensions();
    }
  }

  const updateDimensions = async () => {
    await tick();
    const dimensions = tableElement && tableElement.getBoundingClientRect();
    if (dimensions && messageElement) {
      messageElement.style.width = `${dimensions.width}px`;
    }
  };

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.title-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  table {
    border-collapse: collapse;
  }
  td, th {
    padding: 0.75rem 1rem;
    border-bottom: solid 1px #D1D1D2;
  }
  th {
    color: #5B6670;
    text-align: left;
    font-weight: normal;
  }
  .text-button {
    color: #001E61;
    cursor: pointer;
    user-select: none;
  }
  .text-button:hover {
    color: #173043;
  }
  .message-box {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
    align-items: center;
    background-color: #eef5fb;
    font-weight: 600;
    margin: 0.5rem 0 1.5rem 0;
  }
  .message-box > button {
    min-width: unset;
  }
  label {
    font-size: 1em;
    margin-bottom: 0.25rem;
    display: block;
  }
  .input-div {
    margin-bottom: 1.5rem;
  }
  .input {
    width: 100%;
    padding: 0.4rem;
    height: unset;
    font-size: 1rem;
    margin: 0;
  }
  .picker > :global(.datepicker) {
    width: 100%;
  }
</style>

<svelte:window on:resize={updateDimensions} />

{#if !currentCompany}
  <!-- Show nothing -->
{:else if !$charterLines[currentCompany]}
  <Loading />
{:else if creatingLine}
  <CharterCreate bind:inProgress={creatingLine} onSuccess={onCharterLineCreated} />
{:else}
  <div class="title-container">
    <div>
      <h2 class="my-6">
        {$_('lines.charter.title')}
      </h2>
    </div>

    <div class="button-container">
      <button on:click={() => {
        const company = currentCompany;
        if (!company) return;
        initializeNewCharterLineData(company);
        creatingLine = true;
      }}>{$_('lines.charter.createNew')}</button>
    </div>
  </div>
  <div class="flex flex-row max-w-md">
    <div class="flex-grow input-div picker mr-5">
      <label for="end">{$_('lines.charter.searchStart')}: *</label>
      <DatePicker bind:selected={searchStart} end={searchEnd}>
        <div class="input flex justify-between cursor-pointer
          items-center" placeholder={$_('lines.charter.departureDate')}>
          <span>{$date(searchStart, { format: 'twoDigitsFullYear' })}</span>
          <Icon icon={faCalendar} />
        </div>
      </DatePicker>
    </div>
    <div class="flex-grow input-div picker mr-5">
      <label for="end">{$_('lines.charter.searchEnd')}: *</label>
      <DatePicker bind:selected={searchEnd} start={searchStart}>
        <div class="input flex justify-between cursor-pointer
          items-center" placeholder={$_('lines.charter.departureDate')}>
          <span>{$date(searchEnd, { format: 'twoDigitsFullYear' })}</span>
          <Icon icon={faCalendar} />
        </div>
      </DatePicker>
    </div>
  </div>
  {#if displayedMessage}
  <div class="message-box" bind:this={messageElement}>
    <span>{displayedMessage}</span>
    <button on:click={() => {
      displayedMessage = undefined;
    }}>{$_('lines.common.messageDismiss')}</button>
  </div>
  {/if}
  {#if isLoading}
    <Loading />
  {:else if isError}
  <p>{$_('lines.charter.lineFetchError')}</p>
  {:else if $charterLines[currentCompany] && $charterLines[currentCompany].length}
    <div class="container">
      <table bind:this={tableElement}>
        <tr>
          <th>{$_('lines.charter.departureDate')}</th>
          <th>{$_('lines.charter.departureTime')}</th>
          <th>{$_('lines.charter.departureLocation')}</th>
          <th>{$_('lines.charter.destinationLocation')}</th>
          <th>{$_('lines.charter.passengerCount')}</th>
          <th>{$_('lines.charter.roundTrip')}</th>
          <th>{$_('lines.common.lineId')}</th>
          <th>{$_('lines.common.departureId')}</th>
          <th></th>
        </tr>
        {#each $charterLines[currentCompany] as charterLine}
          <tr>
            <td>{moment(charterLine.departureDateTime).tz('Europe/Helsinki').format('D.M.YYYY')}</td>
            <td>{moment(charterLine.departureDateTime).tz('Europe/Helsinki').format('HH:mm')}</td>
            <td>{charterLine.departureLocation}</td>
            <td>{charterLine.destinationLocation}</td>
            <td>{charterLine.passengerCount}</td>
            <td>{$_(`lines.charter.returnTrip${charterLine.roundTrip ? 'Yes' : 'No'}`)}</td>
            <td>{charterLine.lineId}</td>
            <td>{charterLine.departureId}</td>
            {#if charterLine.outboundDistance || charterLine.inboundDistance}
              <td class="text-button" on:click={() => {
                initializeCopyCharterLineData(charterLine);
                creatingLine = true;
              }
              }>{$_('lines.charter.copy')}</td>
            {:else}
              <td class="text-button" on:click={() => {
                initializeModifyCharterLineData(charterLine);
                creatingLine = true;
              }
              }>{$_('lines.charter.modify')}</td>
            {/if}
          </tr>
        {/each}
      </table>
    </div>
  {:else}
    <p>{$_('lines.charter.noLinesFound')}</p>
  {/if}
{/if}

<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { v4 as uuid } from 'uuid';
  import AddCustomerGroupModal from '../../components/pricing/AddCustomerGroupModal.svelte';
  import ModifyCustomerGroupModal from '../../components/pricing/ModifyCustomerGroupModal.svelte';
  import ZoneTicketTable from '../../components/pricing/ZoneTicketTable.svelte';
  import DepartureTable from '../../components/pricing/DepartureTable.svelte';
  import {
    prices,
    zoneSort,
    createZonePriceTable,
    createLineEntry,
  } from '../../stores/pricing';

  const { open } = getContext('simple-modal');

  export let activeView;
  export let currentCompany;
  export let currentZone;

  $: existingZoneNames =
    ($prices[currentCompany].zoneTickets &&
      Object.keys($prices[currentCompany].zoneTickets)) ||
    [];

  let zoneName = '';
  let zoneData = [
    {
      name: 'Aikuinen',
      zonePrices: [
        {
          zone: '',
          duration: null,
          price: null,
        },
        {
          zone: '',
          duration: null,
          price: null,
        },
        {
          zone: '',
          duration: null,
          price: null,
        },
      ],
    },
  ];

  let lines = [];
  let lineNames = [];
  let lineIds = [];

  let editingMode = true;

  const showAddCustomerGroupModal = () => {
    open(AddCustomerGroupModal, {
      companyId: currentCompany,
      hideDiscount: true,
      onSuccess: createCustomerGroup,
    });
  };

  const onAddRowClicked = () => {
    zoneData = [...zoneData];
    zoneData.forEach(data =>
      data.zonePrices.push({
        zone: '',
        duration: null,
        price: null,
      }),
    );
  };

  const createCustomerGroup = (companyId, name) => {
    const groupHeadPrices = zoneData[0].zonePrices;
    zoneData = [...zoneData];
    const newEntry = {
      name,
      zonePrices: groupHeadPrices.map(priceEntry => ({
        ...priceEntry,
        price: null,
      })),
    };
    zoneData.push(newEntry);
  };

  const deleteRow = entry => {
    zoneData = [...zoneData];
    const index = zoneData[0].zonePrices.indexOf(entry);
    if (index !== -1) {
      zoneData.forEach(zoneEntry => {
        zoneEntry.zonePrices = [...zoneEntry.zonePrices];
        zoneEntry.zonePrices.splice(index, 1);
      });
    }
  };

  const deleteColumn = index => {
    if (zoneData.length) {
      zoneData = [...zoneData];
      zoneData.splice(index, 1);
    }
  };

  const modifyColumnName = (modifyIndex, name) => {
    zoneData = zoneData.map((existing, index) => {
      if (modifyIndex === index) {
        return {
          ...existing,
          name,
        };
      } else {
        return {
          ...existing,
        };
      }
    });
  };

  const modifyColumn = (item, index) => {
    open(ModifyCustomerGroupModal, {
      companyId: currentCompany,
      itemId: index,
      item,
      hideDiscount: true,
      disableDelete: zoneData.length <= 1,
      onSuccess: (companyId, index, name) => modifyColumnName(index, name),
      onDelete: (companyId, index) => deleteColumn(index),
    });
  };

  const zoneModified = (zone, index, event) => {
    if (zone.zone !== event.detail.value) {
      zoneData = [...zoneData];
      zoneData.forEach(zoneEntry => {
        zoneEntry.zonePrices.forEach((zonePrice, i) => {
          if (index === i) zonePrice.zone = event.detail.value;
          zoneEntry.zonePrices.sort(zoneSort);
        });
      });
    }
  };

  const durationModified = (zone, index, event) => {
    if (zone.duration !== event.detail.value) {
      zoneData = [...zoneData];
      zoneData.forEach(zoneEntry => {
        zoneEntry.zonePrices.forEach((zonePrice, i) => {
          if (index === i) zonePrice.duration = event.detail.value;
          zoneEntry.zonePrices.sort(zoneSort);
        });
      });
    }
  };

  const pricesModified = (item, row, column, event) => {
    const zoneEntry = item.zonePrices[row];
    if (zoneEntry.price !== event.detail.value) {
      zoneData = [...zoneData];
      zoneData[column].zonePrices[row].price = event.detail.value;
    }
  };

  const confirmTable = async () => {
    const cleanedZoneData = zoneData
      .filter(entry => entry.name && entry.zonePrices.length)
      .map(entry => ({
        ...entry,
        zonePrices: entry.zonePrices.filter(
          x =>
            x.zone &&
            x.price !== null &&
            x.price !== undefined &&
            x.duration !== null &&
            x.duration !== undefined,
        ),
      }));
    const lineData = lines.map((entry, index) => {
      const [departure, line] = entry.split('-');
      return {
        departure,
        line,
        name: lineNames[index],
        id: lineIds[index],
      };
    });
    const cleanedLineData = lineData.filter(entry => entry.departure || entry.line);
    const cleanedName = zoneName.trim();
    await createZonePriceTable(
      currentCompany,
      cleanedName,
      cleanedZoneData,
      cleanedLineData,
    );
    activeView = 'list';
    currentZone = cleanedName;
  };
</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div {
    overflow: auto;
  }
  label {
    padding-bottom: 0.25rem;
  }
  input {
    width: 300px;
    margin-bottom: 1rem;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
  }
  div.title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
  }
  div.container > * {
    margin-left: 3rem;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  .error-container {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }
  .error {
    color: #d0021b;
    font-size: 0.9rem;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">{$_('pricing.addZoneTicket')}</h2>
  </div>
  <div class="button-container">
    <button
      class="secondary"
      on:click={() => {
        activeView = 'list';
      }}
    >
      {$_('pricing.cancel')}
    </button>
    <button
      disabled={!zoneName.length ||
        !zoneData.length ||
        existingZoneNames.includes(zoneName) ||
        zoneData.some(
          data =>
            !data.zonePrices.length ||
            data.zonePrices.some(
              price => price.zone && (price.price === null || price.price === undefined),
            ) ||
            data.zonePrices.some(
              price =>
                price.zone && (price.duration === null || price.duration === undefined),
            ) ||
            !data.zonePrices.some(
              price =>
                price.zone &&
                (price.price !== null || price.price !== undefined) &&
                (price.duration !== null || price.duration !== undefined),
            ),
        )}
      on:click={confirmTable}
    >
      {$_('pricing.done')}
    </button>
  </div>
</div>

<div class="flex flex-col">
  <label for="name">{$_('pricing.zoneName')}</label>
  <input name="name" id="name" bind:value={zoneName} width="300px" />
</div>
{#if existingZoneNames.includes(zoneName)}
  <div class="error-container">
    <span class="error">{$_('pricing.errorPriceTableNameAlreadyExists')}</span>
  </div>
{/if}

<div class="container">
  <div class="price-container">
    <ZoneTicketTable
      list={zoneData}
      onAddRightClicked={showAddCustomerGroupModal}
      onRemoveRowClicked={deleteRow}
      onModifyColumnClicked={modifyColumn}
      onPrimaryColumnEdited={zoneModified}
      onDataEdited={pricesModified}
      onDurationEdited={durationModified}
      {onAddRowClicked}
      key="name"
      on:sort={event => {
        zoneData = event.detail.list;
      }}
      {editingMode}
    />
  </div>
  <div class="line-container">
    <DepartureTable
      {lines}
      {lineNames}
      {lineIds}
      {editingMode}
      onLineAdded={(line, departure, name) => {
        const entryId = uuid();
        const lineEntry = createLineEntry(line, departure);
        lines = [...lines, lineEntry];
        lineNames = [...lineNames, name];
        lineIds = [...lineIds, entryId];
      }}
      onLineModified={(id, line, departure, name) => {
        const index = lineIds.indexOf(id);
        if (index !== -1) {
          const lineEntry = createLineEntry(line, departure);
          lines = [...lines];
          lineNames = [...lineNames];
          lines[index] = lineEntry;
          lineNames[index] = name;
        }
      }}
      onLineDeleted={id => {
        const index = lineIds.indexOf(id);
        if (index !== -1) {
          lines = [...lines];
          lineNames = [...lineNames];
          lineIds = [...lineIds];
          lines.splice(index, 1);
          lineNames.splice(index, 1);
          lineIds.splice(index, 1);
        }
      }}
    />
  </div>
</div>

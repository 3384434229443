<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path
    fill="none"
    stroke="#06324C"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    d="M9.75,6.265 L9.75,0.265 M9.75,13.765 L9.75,10.765 M12,8.515
    C12,9.75764069 10.9926407,10.765 9.75,10.765 C8.50735931,10.765
    7.5,9.75764069 7.5,8.515 C7.5,7.27235931 8.50735931,6.265 9.75,6.265
    C10.9926407,6.265 12,7.27235931 12,8.515 Z M2.25,4.765 L2.25,13.765
    M4.5,2.515 C4.5,1.27235931 3.49264069,0.265 2.25,0.265 C1.00735931,0.265
    -1.15463195e-14,1.27235931 -1.15463195e-14,2.515 C-1.15463195e-14,3.75764069
    1.00735931,4.765 2.25,4.765 C2.8467371,4.765 3.41903341,4.52794711
    3.84099026,4.10599026 C4.26294711,3.68403341 4.5,3.1117371 4.5,2.515
    L4.5,2.515 Z"
    transform="translate(6 5)"
  />
</svg>

<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import AddNoSaleDescriptionModal from '../../components/pricing/AddNoSaleDescriptionModal.svelte';
  import SortableRowList from '../../components/SortableRowList.svelte';
  import PricingNoSaleRow from '../../components/pricing/PricingNoSaleRow.svelte';
  import {
    prices,
    updateNoSaleIndex,
    removeNoSaleLine,
    updateNoSaleTableValidity,
    VALIDITY_START_COL,
    VALIDITY_END_COL,
    removeNoSalePriceTable,
    addNoSaleLine,
    modifyNoSaleTableLine,
    makeNoSaleDefault,
    getCopyableNoSalePriceTables
  } from '../../stores/pricing';
  import Select from '../../components/Select.svelte';
  import DateRange from '../../components/pricing/DateRange.svelte';
  import DepartureTable from '../../components/pricing/DepartureTable.svelte';
  import NoSaleTableCreationModal from '../../components/pricing/NoSaleTableCreationModal.svelte';
  import ConfirmationModal from '../../components/ConfirmationModal.svelte';

  import OverlappingError from '../../components/pricing/OverlappingError.svelte';
  const { open } = getContext('simple-modal');


  export let currentCompany;

  let editingMode = false;
  let currentPriceTable = undefined;

  const showNoSaleDescriptionModal = () => {
    open(AddNoSaleDescriptionModal, { companyId: currentCompany, priceTable: currentPriceTable });
  };


  $: {
    if (
      $prices[currentCompany].noSales &&
      !$prices[currentCompany].noSales[currentPriceTable]
    ) {
      currentPriceTable = Object.keys($prices[currentCompany].noSales)[0];
    }
  }

  $: lines =
    ($prices[currentCompany].noSales &&
      $prices[currentCompany].noSales[currentPriceTable].lines) ||
    [];
  $: lineNames =
    ($prices[currentCompany].noSales &&
      $prices[currentCompany].noSales[currentPriceTable].lineNames) ||
    [];
  $: lineIds =
    ($prices[currentCompany].noSales &&
      $prices[currentCompany].noSales[currentPriceTable].lineIds) ||
    [];

  const showNoSaleCreationModal = async () => {
    await getCopyableNoSalePriceTables();

    open(NoSaleTableCreationModal, {
      companyId: currentCompany,
      existingNames:
        ($prices[currentCompany].lineBasicTickets &&
          Object.keys($prices[currentCompany].lineBasicTickets)) ||
        [],
      onSuccess: priceTableName => {
        editingMode = true;
        currentPriceTable = priceTableName;
      },
    });
  };

  const showNoSaleConfirmationModal = () => {
    open(ConfirmationModal, {
      title: $_('pricing.removeNoSalePriceTable'),
      body: $_('pricing.removeNoSalePriceTableConfirmation'),
      onConfirm: () => {
        removeNoSalePriceTable(currentCompany, currentPriceTable);
      },
    });
  };


  const getTicketIdentifier = () => {
    return $prices[currentCompany].noSales[currentPriceTable]['ticketIdentifier'];
  };

  const startDateChanged = val => {
    updateNoSaleTableValidity(
      currentCompany,
      currentPriceTable,
      getTicketIdentifier(),
      VALIDITY_START_COL,
      val,
    );
  };

  const endDateChanged = val => {
    updateNoSaleTableValidity(
      currentCompany,
      currentPriceTable,
      getTicketIdentifier(),
      VALIDITY_END_COL,
      val,
    );
  };

  const showMakePricingDefaultConfirmationModal = () => {
    open(ConfirmationModal, {
      body: $_('pricing.pricingAsDefaultConfirmationText'),
      confirmText: $_('pricing.continue'),
      cancelText: $_('pricing.cancel'),
      onConfirm: () => {
        makeNoSaleDefault(currentCompany, currentPriceTable);
      },
    });
  };

  let dateEnd;
  let dateStart;
  let hasDateRange;
  let hasLines = false;

  let isDefault = false;

  $: {
    const noSaleTicket =
      currentCompany &&
      currentPriceTable &&
      $prices[currentCompany] &&
      $prices[currentCompany].noSales &&
      $prices[currentCompany].noSales[currentPriceTable];
    dateEnd = noSaleTicket && noSaleTicket[VALIDITY_END_COL];
    dateStart = noSaleTicket && noSaleTicket[VALIDITY_START_COL];
    hasLines =
      noSaleTicket && noSaleTicket['lines'] && noSaleTicket['lines'].length > 0;

    hasDateRange = dateEnd || dateStart;

    isDefault = noSaleTicket && noSaleTicket.default;
}

  $: overlappingItems =
    currentCompany && $prices[currentCompany]['overlapping']['noSales'];
</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
    padding-top: 1rem;
  }
  div.table-container {
    margin-left: 3rem;
  }

  div {
    overflow: auto;
  }

  div.title-container,
  div.subtitle-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.subbutton-container {
    flex: 1 1 0;
  }
  div.select-container {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding-bottom: 0.25rem;
    min-height: 42px;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  div.line-container {
    padding-bottom: 3rem;
    max-width: 650px;
    margin-left: 3rem;
  }
  div.date-container {
    overflow: visible;
    padding-bottom: 3rem;
    padding-top: 1.75rem;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {editingMode ? $_('pricing.modifyNoSalePricing') : $_('pricing.sections.noSale')}
    </h2>
  </div>
  <div class="button-container">
    {#if !editingMode}
      <button on:click={showNoSaleCreationModal}>
        {$_('pricing.addPricing')}
      </button>
    {/if}
  </div>  
</div>
{#if overlappingItems && overlappingItems.length > 0}
  <OverlappingError {overlappingItems} nameProperty={'name'} />
{/if}

{#if $prices[currentCompany].noSales}
  <div class="subtitle-container">
    <div class="select-container">
      <Select
        flexGrow={true}
        disabled={editingMode}
        entries={Object.keys($prices[currentCompany].noSales)}
        bind:value={currentPriceTable}
      />
    </div>
    <div class="button-container subbutton-container">
      {#if !editingMode}
        <button
          on:click={() => {
            editingMode = true;
          }}
        >
          {$_('pricing.modify')}
        </button>

        <button class="secondary" on:click={showNoSaleConfirmationModal} disabled={isDefault === true}>
          {$_('pricing.remove')}
        </button>
        {#if isDefault !== true && !lines.length && !hasDateRange}
          <button class="tertiary" on:click={showMakePricingDefaultConfirmationModal}>
            {$_('pricing.setAsDefault')}
          </button>
        {/if}        
      {:else}
        <button
          on:click={() => {
            editingMode = false;
          }}
        >
          {$_('pricing.done')}
        </button>
      {/if}
    </div>
  </div>
  <div class="date-container">
    <DateRange
      isDefault={isDefault === true}
      startDateChanged={startDateChanged}
      endDateChanged={endDateChanged}
      defaultInUse={hasLines}
      dateEnd={dateEnd}
      dateStart={dateStart}
      {editingMode}
      />

  </div>  


<div class="container">
  <div class="table-container">
    <SortableRowList
      list={$prices[currentCompany].noSales[currentPriceTable] && $prices[currentCompany].noSales[currentPriceTable].items}
      key={'ticketIdentifier'}
      renderComponent={PricingNoSaleRow}
      componentProps={{ companyId: currentCompany, priceTable: currentPriceTable }}
      placeholder={$_('pricing.travelRightDescriptionsMissing')}
      title={$_('pricing.travelRightDescription')}
      onAddClicked={showNoSaleDescriptionModal}
      editingMode={editingMode}
      on:sort={result => {
        updateNoSaleIndex(
          currentCompany,
          currentPriceTable,
          result.detail.item.ticketIdentifier,
          result.detail.to,
        );
      }}
      headerColumns={[
        {
          title: $_('pricing.text'),
        },
        {
          title: $_('pricing.priceShort'),
          align: 'right',
        },
      ]}
    />
  </div>  
    {#if !isDefault}
      <div class="line-container">
        <DepartureTable
          {lines}
          {lineNames}
          {lineIds}
          editingMode={editingMode}
          onLineAdded={(line, departure, name) =>
            addNoSaleLine(currentCompany, currentPriceTable, line, departure, name)}
          onLineModified={(id, line, departure, name) =>
            modifyNoSaleTableLine(
              currentCompany,
              currentPriceTable,
              id,
              line,
              departure,
              name,
            )}
          onLineDeleted={id =>
            removeNoSaleLine(currentCompany, currentPriceTable, id)}
        />
      </div>
    {/if}
</div>
{:else}
  <p>{$_('pricing.noSalesMissing')}</p>
{/if}

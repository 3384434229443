<script>
  import { getContext } from 'svelte';
  import { replaceSpecialPricing } from '../../stores/pricing';
  import { showSpinnerOverlay, dismissSpinnerOverlay } from '../../stores/spinner';
  import { _ } from 'svelte-i18n';
  import { readPriceTableFile, ErrorCodes } from '../../common/file';
  import FileInput from '../FileInput.svelte';

  const { close } = getContext('simple-modal');

  export let companyId;
  export let priceTableName;
  let files;
  let fileError;

  const parseFile = async file => {
    try {
      const { stations, distances } = await readPriceTableFile(file);
      await replaceSpecialPricing(companyId, priceTableName, stations, distances);
    } catch (error) {
      switch (error.code) {
        case ErrorCodes.FILE_READ_FAILED:
          fileError = $_('pricing.errorFileReadingFailed');
          break;
        case ErrorCodes.FILE_PARSING_FAILED:
        case ErrorCodes.FILE_DATA_INVALID:
          fileError = $_('pricing.errorFileDataNotValid');
          break;
        default:
          break;
      }
      throw error;
    }
  };
</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  button {
    min-width: 135px;
  }
  div.note {
    font-style: italic;
    background-color: #E5E8EF;
    padding: 0.75rem;
    margin-bottom: 2rem;
  }
  p {
    padding: 0;
    margin: 0;
  }
  .file-container {
    padding-bottom: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
</style>

<div>
  <h2>{$_('pricing.updatePricingWithFile')}</h2>
  <div class="note">{$_('pricing.updatePricingNotification')}</div>
  <p>{$_('pricing.chooseFileForSpecialPricing')}</p>
  <div class="file-container">
    <FileInput accept=".xls,.txt,.tsv,.csv" bind:files bind:error={fileError} />
  </div>
  <div class="button-container">
    <button
      class="small"
      disabled={!files || !files[0]}
      on:click={async () => {
        try {
          showSpinnerOverlay();
          await parseFile(files[0]);
          close();
        } catch (error) {
          console.log(error);
        } finally {
          dismissSpinnerOverlay();
        }
      }}
    >
      {$_('pricing.continue')}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        close();
      }}
    >
      {$_('pricing.cancel')}
    </button>
  </div>
</div>

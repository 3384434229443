<script>
  import { _ } from 'svelte-i18n';
  import ConfirmableInlineInput from '../../components/ConfirmableInlineInput.svelte';

  export let editingMode;
  export let item;
  export let onChange;
  export let onDeleteRow;
  export let index;
</script>

<style>
  td {
    border-bottom: solid 1px #D1D1D2;
    padding: 0.75rem 1rem;
  }
  td:last-child {
    text-align: right;
  }
  td.editable {
    padding: 0rem calc(0.6rem - 1px);
    background-color: #eef5fb;
  }
  .text-button {
    padding: 0.75rem 1rem;
    color: #001E61;
    cursor: pointer;
  }
  .text-button:hover {
    color: #173043;
  }
</style>

{#if editingMode}
  <td class="editable">
    <ConfirmableInlineInput
      allowEmpty={true}
      inputId="line"
      type="text"
      maxlength="4"
      value={item.line}
      on:complete={event => onChange(event, item, index)}
    />
  </td>
  <td class="editable">
    <ConfirmableInlineInput
      allowEmpty={true}
      inputId="departure"
      type="text"
      maxlength="4"
      value={item.departure}
      on:complete={event => onChange(event, item, index)}
    />
  </td>
  <td class="editable">
    <ConfirmableInlineInput
      allowEmpty={true}
      inputId="name"
      type="text"
      value={item.name}
      on:complete={event => onChange(event, item, index)}
    />
  </td>
{:else}
  <td>{item.line}</td>
  <td>{item.departure}</td>
  <td>{item.name}</td>
{/if}
<td
  on:click={editingMode ? () => onDeleteRow(item, index) : undefined}
  class="text-button remove"
>
  {editingMode ? $_('pricing.remove') : ''}
</td>

<script>
  import { setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import { formKey } from './form';
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

  export let isValid;
  export let validity = writable({});

  $: {
    isValid = Object.values($validity).every((x) => x);
    dispatch('validated');
  }

  setContext(formKey, validity);
</script>

<slot></slot>

import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import { showSpinnerOverlay, dismissSpinnerOverlay } from './spinner';
import { showNotification } from './notifications';

const walttiLinesInternal = writable({});
export const walttiLines = {
  subscribe: walttiLinesInternal.subscribe
};
// "4805_1615","4803_0820"
//const result = {"company":"86","lines":[],"type":"specific"};

const fixSeriesData = (data) => {
  data.lines = data.lines.filter(lineEntry => lineEntry.departure || lineEntry.line).map(lineEntry => `${(lineEntry.line || '').padStart(4, '0')}_${(lineEntry.departure || '').padStart(4, '0')}___${lineEntry.name ? lineEntry.name : ''}`);
  return data;
};

export const updateWalttiLines = async (company, type, lines) => {
  showSpinnerOverlay();
  try {
    const seriesData = fixSeriesData({ company, type, lines });
    await getClient().put('api/walttiLineSeries', seriesData);
    await getWalttiLines(company);
    return true;
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    return false;
  } finally {
    dismissSpinnerOverlay();
  }
};

export const getWalttiLines = async (company) => {
  const { data: result } = await getClient().get(`api/walttiLineSeries/${company}`);

  walttiLinesInternal.update(current => ({
    ...current,
    [company]: {
      ...result,
      type: result.walttiType || 'all',
      lines: (result.lines || []).map(lines => {
        const [line, departure] = lines.line.split('_');

        return {
          line: line === '0000' ? '' : line,
          departure,
          name: lines.name
        };
      })
    }
  }));
};


import { _ } from 'svelte-i18n';
import { writable, get } from 'svelte/store';
import { getClient } from '../common/http';
import moment from 'moment-timezone';
import { showSpinnerOverlay, dismissSpinnerOverlay } from './spinner';
import { showErrorMessage, showNotification } from './notifications';

export const currentCompany = writable(null);

const charterLinesInternal = writable({});
export const charterLines = {
  subscribe: charterLinesInternal.subscribe
};
export const charterLineCreateState = writable({});

const serviceLinesInternal = writable({});
export const serviceLines = {
  subscribe: serviceLinesInternal.subscribe
};
export const serviceLineCreateState = writable({});

const createDateTimeFromDateAndTime = (date, time) => {
  const dateTime = moment(date).tz('Europe/Helsinki');
  const [hour, minute] = time.split(':');
  return dateTime.hour(hour).minute(minute).toISOString();
};

export const getCharterLines = async (company, start, end) => {
  try {
    const { data: result } = await getClient().get(`api/lines/charter/${company}`, {
      params: {
        from: start.toISOString(),
        until: end.toISOString(),
      },
    });
    charterLinesInternal.update(current => ({
      ...current,
      [company]: result
    }));
  } catch (error) {
    const i18n = get(_);
    showNotification({
      type: 'error',
      title: i18n('error.title'),
      body: i18n('lines.charter.lineFetchError'),
    });
    throw error;
  }
};

export const initializeNewCharterLineData = (company) => {
  charterLineCreateState.set({
    modification: false,
    company,
    departureDate: undefined,
    departureTime: '',
    departureLocation: '',
    departureAddressInput: '',
    departureStreetAddress: '',
    departureMunicipality: '',
    departureLongitude: undefined,
    departureLatitude: undefined,
    destinationLocation: '',
    destinationAddressInput: '',
    destinationStreetAddress: '',
    destinationMunicipality: '',
    destinationLongitude: undefined,
    destinationLatitude: undefined,
    customer: '',
    contactPersonInfo: '',
    additionalInfo: '',
    passengerCount: null,
    roundTrip: false,
    returnDate: undefined,
    returnTime: '',
    returnInfo: '',
    totalPrice: null,
    pricingBasis: null,
    stops: [undefined, undefined],
    lineId: undefined,
    departureId: undefined,
    oldDepartureDateTime: undefined,
    oldReturnDateTime: undefined,
  });
};

export const initializeModifyCharterLineData = (entry) => {
  charterLineCreateState.set({
    modification: true,
    company: entry.company,
    departureDate: moment(entry.departureDateTime).tz('Europe/Helsinki').startOf('day').toDate(),
    departureTime: moment(entry.departureDateTime).tz('Europe/Helsinki').format('HH:mm'),
    departureLocation: entry.departureLocation,
    departureAddressInput: `${entry.departureStreetAddress}, ${entry.departureMunicipality}`,
    departureStreetAddress: entry.departureStreetAddress,
    departureMunicipality: entry.departureMunicipality,
    departureLongitude: entry.departureLongitude,
    departureLatitude: entry.departureLatitude,
    destinationLocation: entry.destinationLocation,
    destinationAddressInput: `${entry.destinationStreetAddress}, ${entry.destinationMunicipality}`,
    destinationStreetAddress: entry.destinationStreetAddress,
    destinationMunicipality: entry.destinationMunicipality,
    destinationLongitude: entry.destinationLongitude,
    destinationLatitude: entry.destinationLatitude,
    customer: entry.customer,
    contactPersonInfo: entry.contactPersonInfo,
    additionalInfo: entry.additionalInfo,
    passengerCount: entry.passengerCount,
    roundTrip: entry.roundTrip,
    returnDate: entry.returnDateTime ? moment(entry.returnDateTime).tz('Europe/Helsinki').startOf('day').toDate() : undefined,
    returnTime: entry.returnDateTime ? moment(entry.returnDateTime).tz('Europe/Helsinki').format('HH:mm') : '',
    returnInfo: entry.returnInfo || '',
    totalPrice: entry.totalPrice,
    pricingBasis: entry.pricingBasis,
    stops: entry.stops,
    lineId: entry.lineId,
    departureId: entry.departureId,
    oldDepartureDateTime: entry.departureDateTime, //For deleting old entries
    oldReturnDateTime: entry.returnDateTime, //For deleting old entries
  });
};

export const initializeCopyCharterLineData = (entry) => {
  charterLineCreateState.set({
    modification: false,
    company: entry.company,
    departureDate: undefined,
    departureTime: moment(entry.departureDateTime).tz('Europe/Helsinki').format('HH:mm'),
    departureLocation: entry.departureLocation,
    departureAddressInput: `${entry.departureStreetAddress}, ${entry.departureMunicipality}`,
    departureStreetAddress: entry.departureStreetAddress,
    departureMunicipality: entry.departureMunicipality,
    departureLongitude: entry.departureLongitude,
    departureLatitude: entry.departureLatitude,
    destinationLocation: entry.destinationLocation,
    destinationAddressInput: `${entry.destinationStreetAddress}, ${entry.destinationMunicipality}`,
    destinationStreetAddress: entry.destinationStreetAddress,
    destinationMunicipality: entry.destinationMunicipality,
    destinationLongitude: entry.destinationLongitude,
    destinationLatitude: entry.destinationLatitude,
    customer: entry.customer,
    contactPersonInfo: entry.contactPersonInfo,
    additionalInfo: entry.additionalInfo,
    passengerCount: entry.passengerCount,
    roundTrip: entry.roundTrip,
    returnDate: undefined,
    returnTime: entry.returnDateTime ? moment(entry.returnDateTime).tz('Europe/Helsinki').format('HH:mm') : '',
    returnInfo: entry.returnInfo || '',
    totalPrice: entry.totalPrice,
    pricingBasis: entry.pricingBasis,
    stops: entry.stops,
    lineId: undefined,
    departureId: undefined,
    oldDepartureDateTime: undefined,
    oldReturnDateTime: undefined,
  });
};

export const createCharterLine = async () => {
  showSpinnerOverlay();
  try {
    const state = get(charterLineCreateState);
    const { data: result } = await getClient().post(`api/lines/charter`, {
      ...state,
      departureDateTime: createDateTimeFromDateAndTime(state.departureDate, state.departureTime),
      departureAddressInput: undefined,
      destinationAddressInput: undefined,
      additionalInfo: state.additionalInfo || undefined,
      contactPersonInfo: state.contactPersonInfo || undefined,
      departureDate: undefined,
      departureTime: undefined,
      returnDate: undefined,
      returnTime: undefined,
      ...(!state.roundTrip ? {
        returnInfo: undefined,
      } : {
        returnDateTime: createDateTimeFromDateAndTime(state.returnDate, state.returnTime),
        returnInfo: state.returnInfo || undefined,
      })
    });
    return result;
  } catch (error) {
    showErrorMessage(get(_)('lines.charter.errorCreatingCharter'));
    throw error;
  } finally {
    dismissSpinnerOverlay();
  }
};

export const modifyCharterLine = async () => {
  showSpinnerOverlay();
  try {
    const state = get(charterLineCreateState);
    const { data: result } = await getClient().put(`api/lines/charter`, {
      ...state,
      modification: undefined,
      departureDateTime: createDateTimeFromDateAndTime(state.departureDate, state.departureTime),
      departureAddressInput: undefined,
      destinationAddressInput: undefined,
      additionalInfo: state.additionalInfo || undefined,
      contactPersonInfo: state.contactPersonInfo || undefined,
      departureDate: undefined,
      departureTime: undefined,
      returnDate: undefined,
      returnTime: undefined,
      ...(!state.roundTrip ? {
        returnInfo: undefined,
      } : {
        returnDateTime: createDateTimeFromDateAndTime(state.returnDate, state.returnTime),
        returnInfo: state.returnInfo || undefined,
      })
    });
    return result;
  } catch (error) {
    showErrorMessage(get(_)('lines.charter.errorModifyingCharter'));
    throw error;
  } finally {
    dismissSpinnerOverlay();
  }
};

export const getNthStation = async (position, index) => {
  const { data: result } = await getClient().get(`api/stops/closest/${position.lat}/${position.lng}`);
  charterLineCreateState.update((state) => {
    state.stops[index] = result;
    return {
      ...state,
    };
  });
};

export const getServiceLines = async (company, start, end) => {
  try {
    const { data: result } = await getClient().get(`api/lines/service/${company}`, {
      params: {
        from: start ? start.toISOString() : undefined,
        until: end ? end.toISOString() : undefined,
      },
    });
    serviceLinesInternal.update(current => ({
      ...current,
      [company]: result
    }));
  } catch (error) {
    const i18n = get(_);
    showNotification({
      type: 'error',
      title: i18n('error.title'),
      body: i18n('lines.service.lineFetchError'),
    });
    throw error;
  }
};

export const initializeNewServiceLineData = (company) => {
  serviceLineCreateState.set({
    modification: false,
    company,
    lineName: '',
    validFrom: undefined,
    validUntil: undefined,
    startingTime: '',
    endingTime: '',
    selectedWeekDays: undefined,
    serviceLineArea: undefined,
    capacity: undefined,
  });
};

export const initializeModifyServiceLineData = (entry) => {
  serviceLineCreateState.set({
    modification: true,
    ...entry,
    validFrom: moment(entry.validFrom).toDate(),
    validUntil: entry.validUntil ? moment(entry.validUntil).toDate() : undefined,
    operatingDays: undefined,
    selectedWeekDays: new Set(entry.operatingDays),
    oldValidUntil: entry.validUntil || 'ETERNITY', //For deleting old entries
  });
};

export const createServiceLine = async () => {
  showSpinnerOverlay();
  try {
    const state = get(serviceLineCreateState);
    const { data: result } = await getClient().post(`api/lines/service`, {
      ...state,
      modification: undefined,
      capacity: state.capacity === null ? undefined : state.capacity,
      selectedWeekDays: undefined,
      operatingDays: [...state.selectedWeekDays].sort(),
      validFrom: state.validFrom.toISOString(),
      validUntil: state.validUntil ? state.validUntil.toISOString() : undefined,
    });
    return result;
  } catch (error) {
    showErrorMessage(get(_)('lines.service.errorCreatingService'));
    throw error;
  } finally {
    dismissSpinnerOverlay();
  }
};

export const modifyServiceLine = async () => {
  showSpinnerOverlay();
  try {
    const state = get(serviceLineCreateState);
    const { data: result } = await getClient().put(`api/lines/service`, {
      ...state,
      modification: undefined,
      capacity: state.capacity === null ? undefined : state.capacity,
      selectedWeekDays: undefined,
      operatingDays: [...state.selectedWeekDays].sort(),
    });
    return result;
  } catch (error) {
    showErrorMessage(get(_)('lines.service.errorModifyingService'));
    throw error;
  } finally {
    dismissSpinnerOverlay();
  }
};

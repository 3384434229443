<script>
    export let width = "52px";
    export let height = "64px";
</script>
<svg width={width} height={height} viewBox="0 0 52 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-3" fill="#333333" fill-rule="nonzero">
            <path d="M2.57643339,63.833534 C1.1576774,63.833534 -1.27897692e-13,62.6779506 -1.27897692e-13,61.2617608 C0.0103057336,47.0827176 11.576774,35.5440283 25.7815102,35.5440283 C28.5056591,35.5440283 31.1817145,35.9692282 33.7547127,36.8059117 C34.4074092,37.0185117 34.9398721,37.4711438 35.2524793,38.0849403 C35.5650866,38.6953078 35.6200505,39.3948301 35.4070653,40.046346 C35.0601056,41.1093456 34.0776257,41.8225841 32.957736,41.8225841 L32.957736,41.8225841 C32.6863516,41.8225841 32.4184026,41.7814357 32.1607592,41.6957099 C30.106483,41.0270489 27.9628904,40.6875748 25.7986864,40.6875748 C14.4177213,40.6875748 5.16317252,49.9185263 5.15630203,61.2651898 C5.15630203,62.6813796 3.99862463,63.833534 2.57643339,63.833534 C2.57986864,63.833534 2.57986864,63.833534 2.57643339,63.833534 Z" id="Path"></path>
            <path d="M18.4374437,63.833534 C17.0186877,63.833534 15.8610103,62.6779506 15.8610103,61.2617608 C15.871316,47.0827176 27.4377843,35.5440283 41.6425204,35.5440283 C44.3666694,35.5440283 47.0427248,35.9692282 49.615723,36.8059117 C50.2684194,37.0185117 50.8008824,37.4711438 51.1134896,38.0849403 C51.4260969,38.6953078 51.4810608,39.3948301 51.2680756,40.046346 C50.9211159,41.1093456 49.938636,41.8225841 48.8187463,41.8225841 L48.8187463,41.8225841 C48.5473619,41.8225841 48.2794129,41.7814357 48.0217695,41.6957099 C45.9674933,41.0270489 43.8239007,40.6875748 41.6596967,40.6875748 C30.2787316,40.6875748 21.0241828,49.9185263 21.0173123,61.2651898 C21.0173123,62.6813796 19.8596349,63.833534 18.4374437,63.833534 C18.4408789,63.833534 18.4408789,63.833534 18.4374437,63.833534 Z" id="Path" transform="translate(33.628356, 49.688781) scale(-1, 1) translate(-33.628356, -49.688781) "></path>
            <path d="M25.7677692,33.4330521 C16.5338319,33.4330521 9.02095212,25.9337614 9.02095212,16.7165261 C9.02095212,13.8395691 9.78014116,10.9934734 11.2160734,8.47313557 C11.2744725,8.35311949 11.3363069,8.26396468 11.3740946,8.20910019 C14.3799336,3.15127948 19.8969363,4.26325641e-14 25.7677692,4.26325641e-14 C33.9711331,4.26325641e-14 41.0683483,6.0968171 42.3050363,14.1927593 C42.3496945,14.3710689 42.3771765,14.5425204 42.3806117,14.7105429 C42.4699281,15.4580717 42.5145862,16.0958715 42.5145862,16.7165261 C42.5145862,25.9337614 35.0017065,33.4330521 25.7677692,33.4330521 Z M14.5345196,13.8429981 C14.2940525,14.7791236 14.1738189,15.7392522 14.1738189,16.7165261 C14.1738189,23.0979527 19.3747791,28.2895057 25.7677692,28.2895057 C31.5870734,28.2895057 36.4204624,23.9860718 37.2380506,18.4036093 C35.0429294,18.9934026 32.7859737,19.2882993 30.5049714,19.2882993 C30.5049714,19.2882993 30.4912304,19.2882993 30.4843599,19.2882993 C24.6994081,19.2882993 19.10683,17.368042 14.5345196,13.8429981 Z M30.5084066,14.1447528 C32.6554344,14.1447528 34.7681098,13.8189949 36.8017746,13.1777661 C35.276526,8.46284848 30.8038376,5.14354648 25.7677692,5.14354648 C22.3771828,5.14354648 19.1652292,6.64203303 16.9701079,9.20351917 C20.721395,12.3547987 25.5788307,14.1413238 30.4774894,14.1447528 C30.4843599,14.1447528 30.4877952,14.1447528 30.4946656,14.1447528 C30.4946656,14.1447528 30.5015361,14.1447528 30.5084066,14.1447528 Z" id="Shape"></path>
        </g>
    </g>
</svg>
<script>
    import { _ } from 'svelte-i18n';
    import {
      updateKilometers,
      getKilometers,
      kilometers,
    } from '../../stores/kilometers';
    import SortableRowList from '../../components/SortableRowList.svelte';
    import Loading from '../../components/Loading.svelte';
    import KilometersRow from '../../components/pricing/KilometersRow.svelte';
    import { showNotification } from '../../stores/notifications';
    import RadioButtonLightBlue from '../../components/RadioButtonLightBlue.svelte';
  
    const ViewMode = Object.freeze({
      DEFAULT: 'DEFAULT',
      EDIT: 'EDIT',
    });
  
    const TypeSelectVal = Object.freeze({
      ALL: 'all',
      NONE: '',
      SPECIFIC: 'specific'
    });
    export let currentCompany;
    let typeSelect;
    let typeSelectUIVal = '';
    let currentMode = ViewMode.DEFAULT;
  
    let lines = [{}, {}, {}];
    let loading = true;
  

    
    const fetchKilometers = async company => {
      loading = true;
      try {
        await getKilometers(company);
        const currentEntry = $kilometers[currentCompany];
        lines = currentEntry.lines;

        typeSelect = currentEntry.type;
        typeSelectUIVal = typeSelect;
      } finally {
        loading = false;
      }
    };
  
    $: {
      fetchKilometers(currentCompany);
    }
  </script>
  
  <style>
    h2 {
      font-size: 1.75rem;
      font-weight: bold;
    }
    div {
      overflow: auto;
    }
    div.title-container,
    div.subtitle-container {
      display: flex;
    }
    div.title-container > div {
      flex: 1 1 0;
    }
    div.button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    div.subbutton-container {
      flex: 1 1 0;
    }
    div.container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3rem;
    }
    div.container > * {
      margin-left: 3rem;
    }
    button {
      margin-left: 2rem;
      min-width: 120px;
    }
    .type-select {
      margin-left: 1rem;
    }

  </style>
  {#if loading}
    <Loading />
  {:else}
    <div class="title-container">
      <div>
        <h2 class="my-6">
          {#if currentMode === ViewMode.EDIT}
            {$_('kilometers.modifyKilometers')}
          {:else}
            {$_('kilometers.title')}
          {/if}
        </h2>
      </div>
  
      <div class="button-container">
        {#if currentMode !== ViewMode.DEFAULT}
          <button
            class="secondary"
            on:click={() => {
              currentMode = ViewMode.DEFAULT;
            }}
          >
            {$_('pricing.cancel')}
          </button>
          <button
            disabled={typeSelect === TypeSelectVal.SPECIFIC && !lines.some(x => x.departure || x.line)}
            on:click={async () => {
              const result = await updateKilometers(
                    currentCompany,
                    typeSelectUIVal,
                    lines,
                  );
               
              if ( result ) {
                currentMode = ViewMode.DEFAULT;
                typeSelect = typeSelectUIVal;
              }
            }}
          >
            {$_('pricing.done')}
          </button>
        {/if}
      </div>
    </div>
    {#if currentMode === ViewMode.DEFAULT}
      <div class="subtitle-container">
        {#if typeSelect ===TypeSelectVal.ALL}
          {$_('kilometers.typeDescAll')}            
        {:else if typeSelect === TypeSelectVal.SPECIFIC}
          {$_('kilometers.typeDescSpecific')}
        {:else}
          {$_('kilometers.typeDescNone')}   
        {/if}
        <div class="button-container subbutton-container">
          {#if currentMode === ViewMode.DEFAULT}
            <button
              on:click={() => { currentMode = ViewMode.EDIT; }}
            >
              {$_('pricing.modify')}
            </button>
          {/if}
        </div>
      </div>
      {#if typeSelect===TypeSelectVal.SPECIFIC}
      <div class="container">
        <div class="price-container">
          <SortableRowList
            editingMode={false}
            list={$kilometers[currentCompany].lines}
            renderComponent={KilometersRow}
            placeholder={$_('kilometers.noLinesDefined')}
            title={$_('kilometers.linesAndDepartures')}
            headerColumns={[
              {
                title: $_('kilometers.line'),
              },
              {
                title: $_('kilometers.departure'),
              },
              {
                editingOnly: true,
              },
            ]}
          />
        </div>
      </div>
    {/if}
    {:else}
      <div class="select-type flex">
        <span>
          <RadioButtonLightBlue
            label={$_('kilometers.typeSelectNone', {
              default: 'Ei miltään vuorolta'
            })}
            value={TypeSelectVal.NONE}
            bind:group={typeSelectUIVal}
          />
       </span>
       
       <span class="type-select">
          <RadioButtonLightBlue
            label={$_('kilometers.typeSelectAll', {
            default: 'Kaikilta yrityksen vuoroilta'
            })}
            value={TypeSelectVal.ALL}
            bind:group={typeSelectUIVal}
        />
      </span>
      <span class="type-select">
        <RadioButtonLightBlue
            label={$_('kilometers.typeSelectSpecific', {
            default: 'Vain seuraavilta vuoroilta'
            })}
            value={TypeSelectVal.SPECIFIC}
            bind:group={typeSelectUIVal}
          />
      </span>
    </div>

      {#if typeSelectUIVal === TypeSelectVal.SPECIFIC}
      <div class="container">
        <div class="price-container">
          <SortableRowList
            editingMode={true}
            list={lines}
            renderComponent={KilometersRow}
            componentProps={{
              companyId: currentCompany,
              onDeleteRow: (__, index) => {
                if (lines.length > 1) {
                  lines.splice(index, 1);
                  lines = [...lines];
                } else {
                  showNotification({
                    title: '',
                    body: $_('kilometers.atleastOneLineCombinationRequired'),
                    type: 'warning',
                  });
                }
              },
              onChange: item => {
                lines[item.detail.id.id][item.detail.id.field] = item.detail.value;
                lines = [...lines];
              },
            }}
            placeholder={$_('kilometers.noLinesDefined')}
            title={$_('kilometers.linesAndDepartures')}
            onAddClicked={() => {
              lines = [...lines, {}];
            }}
            on:sort={event => {
              lines = event.detail.list;
            }}
            headerColumns={[
              {
                title: $_('kilometers.line'),
              },
              {
                title: $_('kilometers.departure'),
              },
              {
                editingOnly: true,
              },
            ]}
          />
        </div>
      </div>
      {/if}
    {/if}
  {/if}
  
<script>
  import { _ } from 'svelte-i18n';
  import Icon from 'fa-svelte';
  import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
</script>

<style>
  .back {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  span {
    padding-left: 1em;
  }
</style>

<div class="back" on:click>
  <Icon icon={faArrowLeft} />
  <span>{$_('back')}</span>
</div>

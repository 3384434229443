<script>
  import { createEventDispatcher } from 'svelte';
  import CloseIcon from '../images/Close.svelte';

  import { _ } from 'svelte-i18n';

  export let isNew = false;
  export let isOpen = false;
  export let sum = null;
  export let explanation;

  const dispatch = createEventDispatcher();

  const handleSubmit = () => {
    dispatch('add', { sum, explanation });
  };

  $: isValid = explanation && sum !== null;

  export const clear = () => {
    sum = null;
    explanation = null;
  };
</script>

<style>
  h1 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  input {
    padding: 0.4em;
    height: unset;
  }
  input[id='explanation'] {
    width: 95%;
  }
  input[type='number'] {
    width: 180px;
  }
  form {
    min-width: 50vw;
  }
  button {
    min-width: 150px;
  }
  p {
    font-size: 0.8rem;
  }
  label {
    margin-top: 1.5rem;
    display: block;
    font-size: 0.8rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
  }
</style>

<form class="p-4" on:submit|preventDefault={handleSubmit}>
  <h1 class="flex justify-between">
    <span>{$_(isNew ? 'results.editAddedRow' : 'results.addRow')}</span>
    <div
      class="text-lg cursor-pointer"
      on:click={() => {
        isOpen = false; // eslint-disable-line no-unused-vars
      }}
    >
      <CloseIcon width="24px" height="24px" />
    </div>
  </h1>
  <label for="explanation">{$_('results.explanation')}: *</label>
  <input name="explanation" id="explanation" bind:value={explanation} required />
  <p>{$_('results.explanationLong')}</p>
  <label for="sum">{$_('results.sum')}: *</label>
  <input type="number" bind:value={sum} step=".01" required />
  <p>{$_('results.sumLong')}</p>
  <button class="small mt-8" disabled={!isValid}
    >{$_(isNew ? 'results.editRow' : 'results.add')}</button
  >
</form>

<style bind:this={style}>
  .tooltip {
    position: relative;
    display: flex;
    align-items: center;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    left: calc(100% + 10px);
    opacity: 0;
    transition: opacity 0.3s;
    background: #333333;
    padding: 1em;
    min-width: 15em;
    min-height: 4em;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: #333333 transparent transparent transparent;
    transform: rotate(90deg);
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>

<div class="tooltip">
  <slot name="content" />
  <div class="tooltiptext">
    <slot name="tooltip" />
  </div>
</div>

<script>
  import Map from '../components/map/Map.svelte';
  import Expandable from '../components/Expandable.svelte';
  import BusList from '../components/map/BusList.svelte';
  import FilterIcon from '../components/icons/Filter.svelte';
  import SearchIcon from '../components/icons/Search.svelte';
  import Icon from 'fa-svelte';
  import { showError, showNotification } from '../stores/notifications';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import {
    positions,
    fetchInfo,
    tripInfo,
    connection,
    initConnection,
    closeConnection,
  } from '../stores/map_tracking';
  import { FEATURE_BY_FEATURE, currentUser } from '../stores/user';
  import { _ } from 'svelte-i18n';
  import { onMount, onDestroy } from 'svelte';

  let search = '';
  let filtersOpened = false;
  let selectedLine = '';
  let selectedLineTrip = '';
  let showOtherLines = false;

  $: filteredLines = [];

  onMount(() => {
    if ($connection.status === 'closed') {
      initConnection();
    }
  });

  onDestroy(() => {
    if ($connection.status === 'open') {
      closeConnection();
    }
  });

  let lastUserCompanies;
  currentUser.subscribe(user => {
    if (
      !user ||
      !user.loggedIn ||
      !user.companies ||
      !user.features ||
      !Object.keys(user.features[FEATURE_BY_FEATURE]).includes('MAP')
    )
      return closeConnection();

    if (lastUserCompanies === user.companies.join(', ')) return;
    lastUserCompanies = user.companies.join(', ');
    initConnection().catch(error => {
      showNotification(
        {
          title: 'Error when connecting to WebSocket',
          body: error.message,
          type: 'error',
        },
        3000,
      );
    });
  });

  const convertToMapInfo = line => ({
    vehicles: Object.values(line).filter(({ id }) => id !== '__INFO__'),
    ...line.__INFO__,
  });

  const getFilteredLines = (positions, search) =>
    Object.entries(positions)
      .filter(
        ([_tripId, { __INFO__: trip }]) =>
          (!selectedLineTrip || showOtherLines || trip.trip_id === selectedLine) &&
            ((trip.lineId && trip.lineId.includes(search)) ||
            (trip.departureId && trip.departureId.includes(search)) ||
            (trip.startStopName && trip.startStopName.toLocaleLowerCase().includes(search)) ||
            (trip.arrivalStopName && trip.arrivalStopName.toLocaleLowerCase().includes(search))),
      )
      .map(([_, trip]) => convertToMapInfo(trip))
      .map(trip => ({
        ...trip,
        sorting: `${trip.startStopName} ${trip.arrivalStopName} ${trip.lineId} ${trip.departureId}`,
      }))
      .sort((a, b) => a.sorting.localeCompare(b.sorting));

  $: lowerCaseSearch = search.toLocaleLowerCase();
  $: filteredLines = getFilteredLines($positions, lowerCaseSearch);

  const clickedOnLine = line => {
    if (selectedLine === line.trip_id) {
      selectedLine = '';
      return;
    }

    if (!$tripInfo[line.trip_id] && !line.basic)
      fetchInfo(line.tripParams, line.trip_id).catch(showError($_('error.title')));
    selectedLine = line.trip_id;
  };
</script>

<style>
  .content {
    position: absolute;
    top: 9rem;
    max-height: calc(100vh - 13rem);
    left: 4rem;
    padding: 1rem;
    min-width: 20vw;
    width: 350px;
    max-width: 35vw;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    .content {
      max-width: calc(100vw - 8rem);
    }
  }

  .input-field {
    background-color: #f5f6f8;
    padding: 0.5em;
    align-items: center;
    display: flex;
  }

  input {
    border: none;
    background: none;
    margin: 0;
    margin-right: 0.5em;
    flex-grow: 1;
  }

  .lines {
    flex-grow: 1;
    overflow-y: auto;
  }

  .content :global(*)::-webkit-scrollbar {
    width: 12px;
  }

  .content :global(*)::-webkit-scrollbar-thumb {
    background: #587280;
    width: 2px;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .content :global(*)::-webkit-scrollbar-track {
    background: #f5f6f8;
  }

  .filters-title {
    display: flex;
    padding: 1em 0;
    align-items: center;
    cursor: pointer;
  }

  .filters-title span {
    flex-grow: 1;
    padding: 0 0.25em;
    font-weight: 600;
  }

  .icon {
    transition: transform 200ms ease-in-out;
  }
  .icon.expanded {
    transform: rotate(-180deg);
  }

  .space {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .on-road {
    font-weight: bold;
    color: #06324c;
    padding-top: 1.8em;
    display: inline-block;
  }

  .filters {
    padding-bottom: 1.8em;
  }
</style>

<Map
  positions={filteredLines}
  on:lineSelected={({ detail }) => clickedOnLine(detail)}
  {selectedLine}
  {selectedLineTrip}
/>

<div class="content">
  <div class="input-field">
    <input type="text" placeholder={$_('map.search')} bind:value={search} />
    <SearchIcon />
  </div>
  {#if !selectedLineTrip}
    <div class="search-filters">
      <div class="filters-title" on:click={() => (filtersOpened = !filtersOpened)}>
        <FilterIcon />
        <span type="text">{$_('map.filter')}</span>
        <div class="icon" class:expanded={filtersOpened}>
          <Icon icon={faChevronDown} />
        </div>
      </div>
    </div>
    <Expandable bind:expanded={filtersOpened}>
      <div class="filters">TEST</div>
    </Expandable>
  {/if}
  <div class="lines" class:space={selectedLineTrip}>
    <BusList
      lines={filteredLines}
      {selectedLine}
      on:select={({ detail }) => clickedOnLine(detail)}
      bind:selectedLineTrip
      bind:showOtherLines
    />
    {#if !selectedLineTrip}
      <span class="on-road">{$_('map.onRoad')}: {filteredLines.length}</span>
    {/if}
  </div>
</div>

<script>
  import { onDestroy } from 'svelte';
  import { showError } from '../../stores/notifications';
  import Loading from '../Loading.svelte';
  import { _ } from 'svelte-i18n';
  import {
    getSupportRequest,
    addNote,
    markAsHandled,
    sendCommandToThread,
  } from '../../stores/support_requests';
  import SupportRequestAction from './SupportRequestAction.svelte';
  import SendCommand from './SendCommand.svelte';
  import CommandsTable from './CommandsTable.svelte';
  import moment from 'moment-timezone';

  export let requestId;

  let thread;
  let threadInfo;
  let threadId;
  let threadStatus;
  let loading = true;
  $: commandsData = threadInfo && threadInfo.table;
  $: commandSelection = {};

  let placeholder = null;

  let commandsTableRef;

  const handleError = showError($_('error.title'));

  const getSorting = action =>
    action.actionType === 'RESPONSE' ? `${action.commandTimestamp}1` : action.id;

  const getThread = async () => {
    try {
      const res = await getSupportRequest(requestId);
      threadInfo = res.latestInfo;

      thread = [res.info, ...res.actions].sort((a, b) =>
        getSorting(a).localeCompare(getSorting(b)),
      );
      threadId = res.info.id;
      threadStatus = res.info.status;
      loading = false;
    } catch (e) {
      handleError(e);
    }
  };

  getThread().catch(console.error);

  let command = '';
  let comment = '';
  let note = '';

  const REFRESH_RATE = 2500;

  $: needRefreshing = thread && thread.some(it => it.waitingForResponse);

  const interval = setInterval(() => {
    if (!needRefreshing) return;
    getThread().catch(console.error);
  }, REFRESH_RATE);

  onDestroy(() => {
    clearInterval(interval);
  });

  const handleSendNote = async () => {
    placeholder = {
      timestamp: moment(),
      actionType: 'NOTE',
      text: note,
      placeholder: true,
    };
    try {
      await addNote(threadId, note, threadInfo.device);
      note = '';
      await getThread();
    } catch (e) {
      handleError(e);
    }
    placeholder = null;
  };

  const handleResolve = async () => {
    placeholder = {
      timestamp: moment(),
      actionType: 'HANDLED',
      text: comment,
      placeholder: true,
    };
    try {
      await markAsHandled(threadId, comment);
      comment = '';
      await getThread();
    } catch (e) {
      handleError(e);
    }
    placeholder = null;
  };

  const handleSendCommand = async command => {
    placeholder = {
      timestamp: moment(),
      actionType: 'COMMAND',
      waitingForResponse: true,
      placeholder: true,
      command: command.id,
      args: command.args ? JSON.stringify(command.args) : null,
    };
    try {
      await sendCommandToThread(threadId, command.id, threadInfo.device, command.args);
      command = '';
      await getThread();

      commandsTableRef && commandsTableRef.clearSelection();
    } catch (e) {
      handleError(e);
    }
    placeholder = null;
  };

  const handleUpdateInfo = async () => {
    placeholder = {
      timestamp: moment(),
      actionType: 'COMMAND',
      waitingForResponse: true,
      placeholder: true,
      command: 'REMOTE_SEND_DEVICE_INFO_COMMAND',
    };
    try {
      await sendCommandToThread(
        threadId,
        'REMOTE_SEND_DEVICE_INFO_COMMAND',
        threadInfo.device,
      );
      command = '';
      await getThread();
    } catch (e) {
      handleError(e);
    }
    placeholder = null;
  };
</script>

<style>
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .content {
    display: block;
    width: 100%;
    margin-bottom: 1em;
  }

  .actions > *:last-child {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 1em;
  }

  .actions {
    margin-bottom: 2em;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .notes > * {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  textarea {
    margin-bottom: 2em;
    width: 50em;
    height: 7em;
    resize: none;
  }

  button {
    margin-bottom: 2em;
  }

  button.secondary {
    margin-top: 2em;
    font-weight: bold;
  }

  h3 {
    font-size: 16px;
    margin-top: 0;
  }

  .info-content h3 {
    display: inline;
  }
  .info-content button {
    display: inline;
  }

  .info-content span {
    margin-right: 0.5em;
  }

  .info-commands {
  }
</style>

{#if loading || !threadInfo}
  <div class="loading-content">
    <Loading />
  </div>
{:else}
  <div class="content">
    <div class="info">
      <div class="info-content">
        <span><h3>{$_('supportRequests.deviceId')}&nbsp;{threadInfo.device}</h3></span>
        {#if threadInfo.driver}
          <span>{$_('supportRequests.driver')}&nbsp;{threadInfo.driver} </span>
        {/if}
        {#if threadInfo.company}
          <span>{$_('supportRequests.company')}&nbsp;{threadInfo.company} abc abc</span>
        {/if}

        {#if threadStatus !== 'HANDLED'}
          <span>
            <button class="secondary small" on:click={handleUpdateInfo}>
              {$_('supportRequests.updateInfo')}
            </button>
          </span>
        {/if}
      </div>

      <div class="info-commands">
        <CommandsTable
          action={commandsData}
          bind:this={commandsTableRef}
          on:commandsSelectionChanged={event => {
            commandSelection = event.detail;
            command = '';
          }}
        />
      </div>
    </div>
    <div class="actions">
      {#each thread as action}
        <div>
          <SupportRequestAction {action} />
        </div>
      {/each}
      {#if placeholder}
        <div>
          <SupportRequestAction action={placeholder} />
        </div>
      {/if}
    </div>
  </div>
  {#if threadStatus !== 'HANDLED'}
    <div class="footer">
      <SendCommand
        bind:command
        showHeader={false}
        deviceId={threadInfo.device}
        onSend={handleSendCommand}
        {commandSelection}
      />
      <div class="notes">
        <div class="note">
          <textarea bind:value={note} placeholder={$_('supportRequests.writeNote')} />
          <button disabled={!note} on:click={handleSendNote}>
            {$_('supportRequests.saveNote')}
          </button>
        </div>
        <div class="handled">
          <textarea
            bind:value={comment}
            placeholder={$_('supportRequests.writeComment')}
          />
          <button on:click={handleResolve}>
            {$_('supportRequests.markHandled')}
          </button>
        </div>
      </div>
    </div>
  {/if}
{/if}

<script>
  import { _, number } from 'svelte-i18n';
  import { TRAVELCARD } from '../../stores/search';

  export let results = [];

  $: accountedSum = results
    .filter(({ accounted }) => accounted)
    .reduce((acc, { sum }) => acc + (sum || 0), 0);
  $: unaccountedSum = results
    .filter(({ accounted }) => !accounted)
    .reduce((acc, { sum }) => acc + (sum || 0), 0);
  $: totalAccounted = results
    .filter(({ accounted }) => accounted)
    .reduce(
      (acc, { quantity: cur, event_type }) =>
        acc + ((event_type || '').startsWith(TRAVELCARD) ? 1 : cur || 1),
      0,
    );
  $: totalUnaccounted = results
    .filter(({ accounted }) => !accounted)
    .reduce(
      (acc, { quantity: cur, event_type }) =>
        acc + ((event_type || '').startsWith(TRAVELCARD) ? 1 : cur || 1),
      0,
    );
</script>

<style>
  td:last-child {
    text-align: right;
  }
</style>

<table class="mt-4 w-full" cellspacing="0">
  <tr>
    <td>{$_('results.accountedSum').replace(/\{1\}/, totalAccounted)}:</td>
    <td>{$number(accountedSum, { format: 'EUR' })}</td>
  </tr>
  <tr>
    <td>{$_('results.unaccountedSum').replace(/\{1\}/, totalUnaccounted)}:</td>
    <td>{$number(unaccountedSum, { format: 'EUR' })}</td>
  </tr>
</table>

<script>
  import { number } from 'svelte-i18n';

  import Checkbox from '../Checkbox.svelte';

  export let result;
  export let checked;
  export let explanationWidth;
  export let currentCompanyRowFilter = false;

  function getTextWidth(content) {
    const text = document.createElement('span');
    document.body.appendChild(text);

    text.style.height = 'auto';
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.innerHTML = content;

    const width = Math.ceil(text.clientWidth);

    document.body.removeChild(text);

    return width;
  }

  $: textWidth = getTextWidth((result && result.explanation) || '-');

  $: expanded = textWidth > explanationWidth;
</script>

<tr
  class:bg-mlight={currentCompanyRowFilter}
  class:bg-mgray={result.accounted && !currentCompanyRowFilter}
>
  <td>
    <Checkbox noReverse={true} on:change {checked} />
  </td>
  <td class="whitespace-no-wrap">{result.date.format('D.M.YYYY')}</td>
  {#if !expanded}
    <td colspan="5" />
  {/if}
  <td colspan={expanded ? 9 : 4} class:text-center={expanded}>
    {result.explanation || '-'}
  </td>
  <td class="text-right">
    {$number(result.sum, { format: 'EUR' }).replace('€', '')}
  </td>
</tr>

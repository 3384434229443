<script>
  export let value;
  export let group;
  export let label = '';
</script>

<style>
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    --size: 20px;
    position: absolute;
    top: 0;
    left: 0;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    background-color: #ffffff;
    border: 2px solid #a9b0bc;
    transition: background-color 250ms ease-in-out;
  }

  .container input:checked ~ .checkmark {
    border: 2px solid #001E61;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    --check-size: 12px;
    top: calc(var(--size) / 2 - var(--check-size) / 2 - 2px);
    left: calc(var(--size) / 2 - var(--check-size) / 2 - 2px);
    width: var(--check-size);
    height: var(--check-size);
    border-radius: 50%;
    background: #001E61;
  }
</style>

<label class="container">
  <span class="label">{label}</span>
  <input type="radio" bind:group {value} on:change />
  <span class="checkmark" />
</label>

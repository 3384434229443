<script>
  import { _ } from 'svelte-i18n';
  import Logo from './Logo.svelte';
  import { FEATURE_BY_FEATURE, currentUser, isSuperUser, logout } from '../stores/user';
  import { currentTab, setCurrentTab } from '../stores/tabs';

  let features;
  let userIsSuperUser;

  $: { 
    features = ($currentUser && $currentUser.features) || [];
    userIsSuperUser = isSuperUser(features);
  }

  $: tabs = filterTabs(
    [
      { name: 'map.title', path: 'map', code: 'MAP' },
      { name: 'reports.title', path: 'reports', code: 'REPORTS' },
      {
        name: 'travelReports.title',
        path: 'travel-reports',
        code: 'TRAVELREPORTS',
      },
      {
        name: 'pricingMaintenance.title',
        path: 'pricing-maintenance',
        code: 'PRICING',
      },
      {
        name: 'lines.title',
        path: 'lines',
        code: 'LINES',
      },
      { name: 'supportRequests.title', path: 'support', code: 'SUPPORT' },
      { name: 'messages.title', path: 'messages', code: 'MESSAGING' },
      { name: 'users.title', path: 'users', code: 'ADMIN' },
      { name: 'devices.title', path: 'devices', code: 'DEVICES' },
    ],
    features,
  );

  const filterTabs = (tabs, features) => {
    return tabs.filter(({ code }) => isSuperUser(features) ||
      Object.keys(features && features[FEATURE_BY_FEATURE] || {}).some(it => it === code || it.startsWith(`${code};`)),
    );
  };

  $: otherTabs = tabs.length >= 2 ? getOtherTabs(tabs) : [];

  const getOtherTabs = tabs => {
    const [, ...others] = tabs;
    return others;
  };
</script>

<style>
  h1.active {
    position: relative;
  }

  h1::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: -0.7rem;
    background-color: #fff0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0%;
    transition: all 250ms;
  }

  h1.active::after {
    background-color: #307FE2;
    width: 80%;
  }

  h2 {
    border-radius: 0 0 5px 5px;
    font-size: 11px;
    transform: translateX(-14px);
    line-height: 24px;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }
</style>

<header class="flex justify-between items-center w-full h-20 bg-mnormal p-2">
  <div class="pl-4 xl:pl-6 flex items-center">
    <Logo width="96px" height="100%" class="min-w-[96px]" />
    <div class="h-20 relative flex items-center ml-4 xl:ml-8 overflow-visible">
      <h2 class="absolute m-none top-0 bg-msecondary text-white px-3 font-semibold uppercase">
        {$_('header.title')}
      </h2>
      {#if tabs.length}
        <h1
          class="cursor-pointer text-white text-sm xl:text-base"
          on:click={() => setCurrentTab(tabs[0].path)}
          class:active={$currentTab === tabs[0].path}
        >
          {$_(tabs[0].name)}
        </h1>
      {/if}
    </div>
    {#each otherTabs as tab}
      <h1
        class="cursor-pointer ml-4 xl:ml-8 text-white text-center text-sm xl:text-base"
        on:click={() => setCurrentTab(tab.path)}
        class:active={$currentTab === tab.path}
      >
        {$_(tab.name)}
      </h1>
    {/each}
  </div>
  <div class="pl-4 xl:pl-6">
    {#if $currentUser && $currentUser.loggedIn}
      <h1
        class="cursor-pointer ml-4 xl:ml-8 text-white text-sm xl:text-base"
        on:click={logout}
      >
        {#if userIsSuperUser}
          (S)
        {/if}
        {#if $currentUser.firstname && $currentUser.lastname}
          {$currentUser.firstname}
          {$currentUser.lastname}
        {:else}{$currentUser.name || $currentUser.username}{/if}
      </h1>
    {/if}
  </div>
</header>

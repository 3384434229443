<script>
  export let width = "96px";
  export let height = "63px";
  let clazz = "";
  export {clazz as class};
</script>
<svg {width} {height} style={'margin-bottom: 0.1rem'} class={clazz} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 711.61 436.9">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }
    </style>
  </defs>
  <g>
    <path class="cls-1" d="m242.34,172.1h-13.93c-.69,0-1.25.56-1.25,1.25v84.35c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-25.26c0-.39.5-.57.74-.26l20.82,26.14c.32.4.8.63,1.3.63h18.7c.72,0,1.1-.85.62-1.39l-27.27-30.59c-.21-.24-.21-.59,0-.83l27.06-30.83c.47-.54.09-1.38-.63-1.38h-18.71c-.51,0-.99.23-1.31.63l-20.58,26.01c-.25.31-.74.14-.74-.26v-46.96c0-.69-.56-1.25-1.25-1.25Z"/>
    <path class="cls-1" d="m405.93,258.94h13.93c.69,0,1.25-.56,1.25-1.25v-36.89c0-5.87-1.08-10.89-3.23-15.08-2.15-4.18-5.15-7.35-8.98-9.51-3.83-2.15-8.21-3.23-13.14-3.23-4.3,0-8.21.8-11.74,2.41-3.13,1.43-5.7,3.33-7.72,5.71-.25.3-.73.14-.73-.26v-27.5c0-.69-.56-1.25-1.25-1.25h-13.93c-.69,0-1.25.56-1.25,1.25v84.35c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-34.66c0-5.16,1.31-9.13,3.93-11.91,2.62-2.78,6.2-4.17,10.74-4.17,4.46,0,7.98,1.39,10.56,4.17,2.58,2.78,3.87,6.75,3.87,11.91v34.66c0,.69.56,1.25,1.25,1.25Z"/>
    <path class="cls-1" d="m476.37,258.94h14.05c.69,0,1.25-.56,1.25-1.25v-62.52c0-.69-.56-1.25-1.25-1.25h-14.05c-.69,0-1.25.56-1.25,1.25v34.54c0,5.16-1.29,9.14-3.87,11.91-2.58,2.78-6.14,4.17-10.68,4.17-4.46,0-7.98-1.39-10.56-4.17-2.58-2.78-3.87-6.75-3.87-11.91v-34.54c0-.69-.56-1.25-1.25-1.25h-13.93c-.69,0-1.25.56-1.25,1.25v36.89c0,5.79,1.12,10.78,3.34,14.96,2.23,4.19,5.3,7.35,9.21,9.51,3.91,2.15,8.41,3.23,13.5,3.23,3.99,0,7.69-.8,11.09-2.41,3.02-1.42,5.54-3.33,7.54-5.71.25-.3.73-.14.73.26v5.79c0,.69.56,1.25,1.25,1.25Z"/>
    <path class="cls-1" d="m533.67,192.68c-18.66,0-33.84,15.18-33.84,33.84,0,18.66,15.18,33.84,33.84,33.84,18.66,0,33.84-15.18,33.84-33.84,0-18.66-15.18-33.84-33.84-33.84Zm0,52.28c-10.17,0-18.44-8.27-18.44-18.44,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.27,18.44,18.44,0,10.17-8.27,18.44-18.44,18.44Z"/>
    <path class="cls-1" d="m676.18,192.68c-18.66,0-33.84,15.18-33.84,33.84,0,18.66,15.18,33.84,33.84,33.84,18.66,0,33.84-15.18,33.84-33.84,0-18.66-15.18-33.84-33.84-33.84Zm0,52.28c-10.17,0-18.44-8.27-18.44-18.44,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.27,18.44,18.44,0,10.17-8.27,18.44-18.44,18.44Z"/>
    <path class="cls-1" d="m89.41,177.03h-16.47c-.5,0-.95.3-1.15.76l-25.35,59.26c-.14.34-.62.34-.77,0l-25.35-59.26c-.2-.46-.65-.76-1.15-.76H2.6c-.69,0-1.25.56-1.25,1.25v79.42c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-49.94c0-.46.63-.58.8-.16l20.94,50.57c.19.47.65.77,1.15.77h10.77c.51,0,.96-.3,1.16-.77l20.83-50.56c.17-.42.8-.3.8.16v49.93c0,.69.56,1.25,1.25,1.25h13.93c.69,0,1.25-.56,1.25-1.25v-79.42c0-.69-.56-1.25-1.25-1.25Z"/>
    <path class="cls-1" d="m639.62,193.93h-12.89c-.23,0-.42-.19-.42-.42v-14.41c0-.69-.56-1.25-1.25-1.25h-14.05c-.69,0-1.25.56-1.25,1.25v14.41c0,.23-.19.42-.42.42h-6.08c-.69,0-1.25.56-1.25,1.25v11c0,.69.56,1.25,1.25,1.25h6.08c.23,0,.42.19.42.42v30.92c0,13.46,6.92,20.19,20.77,20.19h9.08c.69,0,1.25-.56,1.25-1.25v-12.82c0-.69-.56-1.25-1.25-1.25h-12.89c-.23,0-.42-.19-.42-.42v-35.37c0-.23.19-.42.42-.42h12.89c.69,0,1.25-.56,1.25-1.25v-11c0-.69-.56-1.25-1.25-1.25Z"/>
    <path class="cls-1" d="m600.77,243.53l-9.45.09c-.23,0-.42-.18-.42-.42l-.11-69.86c0-.69-.56-1.25-1.25-1.25h-13.93c-.69,0-1.25.56-1.25,1.25v65.41c0,13.46,6.92,20.19,20.77,20.19l5.68-.08c.68-.01,1.23-.57,1.23-1.25v-12.82c0-.69-.57-1.26-1.26-1.25Z"/>
    <path class="cls-1" d="m167.97,257.59v-63.31c0-.69-.56-1.25-1.25-1.25h-12.9c-.69,0-1.25.56-1.25,1.25v5.32c0,.38-.46.57-.71.29-1.99-2.13-8.07-7.67-17.73-7.67h0s-.08,0-.12,0h0c-17.63,0-33.71,14.4-33.71,33.58,0,19.61,16.08,33.84,33.71,33.84h0s.08,0,.12,0c0,0,0,0,0,0,9.66,0,15.74-5.54,17.73-7.67.26-.28.71-.09.71.29v5.32c0,.69.56,1.25,1.25,1.25h12.9c.69,0,1.25-.56,1.25-1.25Zm-33.84-13.09c-10.17,0-18.44-8.1-18.44-18.69,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.52,18.44,18.69,0,10.12-8.27,18.44-18.44,18.44Z"/>
    <path class="cls-1" d="m334.94,258.84h12.9c.69,0,1.25-.56,1.25-1.25v-63.31c0-.69-.56-1.25-1.25-1.25h-12.9c-.69,0-1.25.56-1.25,1.25v5.32c0,.38-.46.57-.71.29-1.99-2.13-8.07-7.67-17.73-7.67h0s-.08,0-.12,0h0c-17.63,0-33.71,14.4-33.71,33.58,0,19.61,16.08,33.84,33.71,33.84h0s.08,0,.12,0c0,0,0,0,0,0,9.66,0,15.74-5.54,17.73-7.67.26-.28.71-.09.71.29v5.32c0,.69.56,1.25,1.25,1.25Zm-19.69-14.34c-10.17,0-18.44-8.1-18.44-18.69,0-10.17,8.27-18.44,18.44-18.44,10.17,0,18.44,8.52,18.44,18.69,0,10.12-8.27,18.44-18.44,18.44Z"/>
    <path class="cls-1" d="m201.92,207.42h12.89c.69,0,1.25-.56,1.25-1.25v-11c0-.69-.56-1.25-1.25-1.25h-12.89c-.23,0-.42-.19-.42-.42v-14.41c0-.69-.56-1.25-1.25-1.25h-14.05c-.69,0-1.25.56-1.25,1.25v14.41c0,.23-.19.42-.42.42h-6.08c-.69,0-1.25.56-1.25,1.25v11c0,.69.56,1.25,1.25,1.25h6.08c.23,0,.42.19.42.42v30.92c0,13.46,6.92,20.19,20.77,20.19h9.08c.69,0,1.25-.56,1.25-1.25v-12.82c0-.69-.56-1.25-1.25-1.25h-12.89c-.23,0-.42-.19-.42-.42v-35.37c0-.23.19-.42.42-.42Z"/>
  </g>
  <g>
    <path class="cls-1" d="m149.83,146.49h72.41c1.48,0,2.83-.79,3.58-2.06,25.18-42.44,71.45-70.9,124.39-70.9,52.92,0,99.21,28.44,124.41,70.9.75,1.27,2.1,2.06,3.58,2.06h72.43c1.73,0,2.96-1.73,2.36-3.36C522.52,60.71,443.24,1.96,350.22,1.96c-93.02,0-172.3,58.75-202.75,141.18-.6,1.63.62,3.36,2.36,3.36Z"/>
    <path class="cls-1" d="m550.62,289.6h-72.43c-1.48,0-2.83.79-3.58,2.06-25.18,42.44-71.45,70.9-124.39,70.9-52.94,0-99.21-28.46-124.39-70.9-.75-1.27-2.1-2.06-3.58-2.06h-72.43c-1.73,0-2.96,1.73-2.36,3.36,30.48,82.42,109.76,141.17,202.75,141.17,93.02,0,172.3-58.75,202.75-141.18.6-1.63-.62-3.36-2.36-3.36Z"/>
  </g>
</svg>
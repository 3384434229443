<script>
    import { _ } from 'svelte-i18n';
    import {
      updateWalttiLines,
      getWalttiLines,
      walttiLines,
    } from '../../stores/waltti';
    import SortableRowList from '../../components/SortableRowList.svelte';
    import Loading from '../../components/Loading.svelte';
    import WalttiRow from '../../components/pricing/WalttiRow.svelte';
    import { showNotification } from '../../stores/notifications';
    import RadioButtonLightBlue from '../../components/RadioButtonLightBlue.svelte';
    import {
    companies as companyList,
  } from '../../stores/companies';
    const ViewMode = Object.freeze({
      DEFAULT: 'DEFAULT',
      EDIT: 'EDIT',
    });
  
    const TypeSelectVal = Object.freeze({
      ALL: 'all',
      SPECIFIC: 'specific'
    });

    export let currentCompany;
    const isWalttiEnabledForCompany = () => {
      const matchedCompany = $companyList.find(entry => entry.number === currentCompany);
      return matchedCompany && matchedCompany.walttiFeaturesEnabled;
    };

    let walttiFeaturesEnabled = isWalttiEnabledForCompany();

    let typeSelect;
    let typeSelectUIVal = '';
    let currentMode = ViewMode.DEFAULT;
  
    let lines = [{}, {}, {}];
    let loading = true;
  
    const fetchWalttiLines = async company => {
      loading = true;
      try {
        await getWalttiLines(company);
        const currentEntry = $walttiLines[currentCompany];
        lines = currentEntry.lines;

        typeSelect = currentEntry.type;
        typeSelectUIVal = typeSelect;
        walttiFeaturesEnabled = isWalttiEnabledForCompany();
      } finally {
        loading = false;
      }
    };
  
    $: {
      fetchWalttiLines(currentCompany);
    }
  </script>
  
  <style>
    h2 {
      font-size: 1.75rem;
      font-weight: bold;
    }
    div {
      overflow: auto;
    }
    div.title-container,
    div.subtitle-container {
      display: flex;
    }
    div.title-container > div {
      flex: 1 1 0;
    }
    div.button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    div.subbutton-container {
      flex: 1 1 0;
    }
    div.container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3rem;
    }
    div.container > * {
      margin-left: 3rem;
    }
    button {
      margin-left: 2rem;
      min-width: 120px;
    }
    .type-select {
      margin-left: 1rem;
    }
  </style>

  {#if loading}
    <Loading />
  {:else if !walttiFeaturesEnabled}
    {$_('waltti.noRights')}
  {:else}
    <div class="title-container">
      <div>
        <h2 class="my-8">
          {#if currentMode === ViewMode.EDIT}
            {$_('waltti.modify')}
          {:else}
            {$_('waltti.title')}          
          {/if}
        </h2>
      </div>
    
      <div class="button-container">
        {#if currentMode !== ViewMode.DEFAULT}
          <button
            class="secondary"
            on:click={() => {
              currentMode = ViewMode.DEFAULT;
            }}
          >
            {$_('pricing.cancel')}
          </button>
          <button
            disabled={typeSelect === TypeSelectVal.SPECIFIC && !lines.some(x => x.departure || x.line)}
            on:click={async () => {
              const result = await updateWalttiLines(
                    currentCompany,
                    typeSelectUIVal,
                    lines,
                  );
               
              if ( result ) {
                currentMode = ViewMode.DEFAULT;
                typeSelect = typeSelectUIVal;
              }
            }}
          >
            {$_('pricing.done')}
          </button>
        {/if}
          {#if currentMode === ViewMode.DEFAULT}
        <div class="button-container subbutton-container">
        
            <button
              on:click={() => { currentMode = ViewMode.EDIT; }}
            >
              {$_('pricing.modify')}
            </button>
        </div>
          {/if}
        
      </div>
    </div>
    {#if currentMode === ViewMode.DEFAULT}
      <div class="subtitle-container">
        {$_('waltti.desc')}  <br />
        {$_('waltti.desc2')} <br />
        {$_('waltti.desc3')} <br /> <br />
        {#if typeSelect ===TypeSelectVal.ALL}
          {$_('waltti.typeDescAll')}            
        {:else if typeSelect === TypeSelectVal.SPECIFIC}
          {$_('waltti.typeDescSpecific')}
        {:else}
          {$_('waltti.typeDescNone')}   
        {/if}
      </div>
      {#if typeSelect===TypeSelectVal.SPECIFIC}
      <div class="container">
        <div class="price-container">
          <SortableRowList
            editingMode={false}
            list={$walttiLines[currentCompany].lines}
            renderComponent={WalttiRow}
            placeholder={$_('waltti.noLinesDefined')}
            title={$_('waltti.linesAndDepartures')}
            headerColumns={[
              {
                title: $_('waltti.line'),
              },
              {
                title: $_('waltti.departure'),
              },
              {
                title: $_('waltti.name'),
              },              
              {
                editingOnly: true,
              },
            ]}
          />
        </div>
      </div>
    {/if}
    {:else}
      <div class="select-type flex">
       <span class="type-select">
          <RadioButtonLightBlue
            label={$_('waltti.typeSelectAll', {
            default: 'Kaikilta yrityksen vuoroilta'
            })}
            value={TypeSelectVal.ALL}
            bind:group={typeSelectUIVal}
        />
      </span>
      <span class="type-select">
        <RadioButtonLightBlue
            label={$_('waltti.typeSelectSpecific', {
            default: 'Seuraavilta yrityksen linjoilta'
            })}
            value={TypeSelectVal.SPECIFIC}
            bind:group={typeSelectUIVal}
          />
      </span>
    </div>

      {#if typeSelectUIVal === TypeSelectVal.SPECIFIC}
      <div class="container">
        <div class="price-container">
          <SortableRowList
            editingMode={true}
            list={lines}
            renderComponent={WalttiRow}
            componentProps={{
              companyId: currentCompany,
              onDeleteRow: (__, index) => {
                if (lines.length > 1) {
                  lines.splice(index, 1);
                  lines = [...lines];
                } else {
                  showNotification({
                    title: '',
                    body: $_('waltti.atleastOneLineCombinationRequired'),
                    type: 'warning',
                  });
                }
              },
              onChange: item => {
                lines[item.detail.id.id][item.detail.id.field] = item.detail.value;
                lines = [...lines];
              },
            }}
            placeholder={$_('waltti.noLinesDefined')}
            title={$_('waltti.linesAndDepartures')}
            onAddClicked={() => {
              lines = [...lines, {}];
            }}
            on:sort={event => {
              lines = event.detail.list;
            }}
            headerColumns={[
              {
                title: $_('waltti.line'),
              },
              {
                title: $_('waltti.departure'),
              },
              {
                title: $_('waltti.name'),
              },              
              {
                editingOnly: true,
              },
            ]}
          />
        </div>
      </div>
      {/if}
    {/if}
  {/if}
  
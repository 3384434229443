<script>
  import { _, locale, date } from 'svelte-i18n';
  import StopBasedReport from './StopBasedReport.svelte';
  import DailyReport from './DailyReport.svelte';
  import EventReport from './EventReport.svelte';
  import Loading from '../Loading.svelte';
  import Back from '../Back.svelte';
  import { reportLoaded, reportInError, reportResults, fetchReport } from '../../stores/travel_reports';
  import { showError } from '../../stores/notifications';
  import {
    getExcelDownloadDay,
    getExcelDownloadStop,
    getExcelDownloadEvent,
  } from '../../common/http';
  import LoadingError from '../LoadingError.svelte';

  export let start;
  export let end;

  export let line;
  export let weekdays;
  export let fromFirstLevel;
  export let tickets;
  export let deviceType;

  export let reportType = 'daily';

  export let selectedDay = {};

  const loadContent = () => {
    fetchReport(line.company_id, {
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId: line.line_id,
      departureId: line.departure_id,
      weekdays,
      tickets,
      deviceType,
    }).catch(showError($_('error.title')));
  };

  if ($reportLoaded !== `${line.company_id}-${line.line_id}-${line.departure_id}`) { 
    loadContent(); 
  }
   
  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    let getExcelDownload = getExcelDownloadDay;
    if (selectedDay.day) getExcelDownload = getExcelDownloadEvent;
    else if (reportType === 'stop') getExcelDownload = getExcelDownloadStop;
    const res = await getExcelDownload(
      selectedDay.day ? selectedDay.day : line.company_id,
      {
        start,
        end: new Date(new Date(end).setDate(end.getDate() + 1)),
        lineId: line.line_id,
        departureId: line.departure_id,
        companyId: line.company_id,
        lineName: line.line_name,
        weekdays,
        lang,
        tickets,
        deviceType,
      },
    );
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const handleBack = () => {
    if (selectedDay.day) {
      selectedDay = {};
      if (fromFirstLevel) line = {};
      fromFirstLevel = false;
    } else line = {};
  };

  $: reportWeekdays = line && line.days_of_operation;
</script>

<style>
  .tabs {
    margin-top: 2em;
    margin-bottom: 3em;
  }

  .tab {
    color: #06324c;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    padding: 0.5em 1em;
    transition: color 250ms ease-in-out;
  }

  .tab.active {
    color: #001E61;
  }

  .tab.active {
    position: relative;
  }

  .tab::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0;
    background-color: #fff0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0%;
    transition: width 250ms ease-in-out;
  }

  .tab.active::after {
    background-color: #001E61;
    width: calc((100% - 2em) * 0.8);
  }

  .back > *:not(:first-child) {
    margin-left: 0.3em;
  }

  :global(.back *) {
    color: #001E61 !important;
    cursor: pointer;
  }

  b.smaller {
    font-size: 0.9em;
    font-weight: 600;
  }
</style>

<Back on:click={handleBack} />

{#if selectedDay.day}
  <b>{$date(selectedDay.day, { format: 'twoDigitsFullYear' })}</b>
  <br />
{:else if start && end}
  <b>
    {$date(start, { format: 'twoDigitsFullYear' })}
    -
    {$date(end, { format: 'twoDigitsFullYear' })}
  </b>
  <br />
{/if}
{#if line.company_id}
  <b class="smaller">
    {$_('results.company')}
    {line.company_id}
  </b>
  <br />
{/if}
<b class="smaller">
  {$_('results.line')}
  {(line.line_id === '0000' ? '-' : line.line_id || '-').split('$')[0]}
  {$_('results.departure')}
  {(line.departure_id || '-').split('$')[0]},
  {$_('results.totalDepartures').replace(/\{1\}/, $reportResults.daily?.length)}
  {#if line.line_name}<br />{line.line_name}{/if}
</b>
{#if reportWeekdays && reportWeekdays.length}
  <br />
  <b class="smaller">
    {reportWeekdays}
  </b>
{/if}
{#if !$reportLoaded}
  {#if $reportInError}
    <LoadingError retryAction={loadContent}/>  
  {:else}
    <div class="loading-content">
      <Loading />
    </div>
  {/if}
{:else if !selectedDay.day}
  <div class="tabs">
    <span
      class="tab"
      on:click={() => (reportType = 'daily')}
      class:active={reportType === 'daily'}
    >
      {$_('travel.daily')}
    </span>
    <span
      class="tab"
      on:click={() => (reportType = 'stop')}
      class:active={reportType === 'stop'}
    >
      {$_('travel.stop')}
    </span>
  </div>

  {#if reportType === 'daily'}
    <DailyReport
      on:excel={createExcel}
      daily={$reportResults.daily}
      on:day={({ detail: day }) => (selectedDay = day)}
    />
  {:else if reportType === 'stop' && (line.line_id !== 'PALI' || $reportResults.daily.length === 1)}
    <StopBasedReport
      on:excel={createExcel}
      stops={$reportResults.stop}
      changes={$reportResults.stopChanges}
      data={$reportResults.stopsPassengers}
    />
  {/if}
{:else}
  <EventReport
    on:excel={createExcel}
    bind:day={selectedDay}
    companyId={line.company_id}
    {start}
    {end}
    lineId={line.line_id}
    departureId={line.departure_id}
    {tickets}
    {deviceType}
  />
{/if}

<script>
  import { _ } from 'svelte-i18n';
  import { removeNoSaleProduct, updateNoSaleItem } from '../../stores/pricing';
  import ConfirmableInlineInput from '../../components/ConfirmableInlineInput.svelte';

  const onTicketChanged = ticketChange => {
    const newValue = ticketChange.detail.value;
    if ( item[ticketChange.detail.id.field] === newValue ) {
      return;
    }

    if ( ticketChange.detail.id.field === 'fixedPrice' ) {
      item.fixedPrice = ticketChange.detail.value;
    } else {
      item.name = ticketChange.detail.value;
    }
    updateNoSaleItem(companyId, priceTable, item);
  };

  export let editingMode;
  export let item;
  export let companyId;
  export let priceTable;
  export let index;
</script>

<style>
  td {
    border-bottom: solid 1px #D1D1D2;
    padding: 0.75rem 1rem;
  }
  td:last-child {
    text-align: right;
  }
  td.editable {
    padding: 0rem calc(0.6rem - 1px);
    background-color: #eef5fb;
  }
  .text-button {
    padding: 0.75rem 1rem;
    color: #001E61;
    cursor: pointer;
  }
  .text-button:hover {
    color: #173043;
  }
</style>

{#if editingMode}
  <td class="editable">
    <div />
    <ConfirmableInlineInput
      inputId={{ id: item.ticketIdentifier, field: 'name' }}
      type="text"
      value={item.name}
      on:complete={onTicketChanged}
    />
  </td>
  <td class="editable">
    <ConfirmableInlineInput
      textAlignment="right"
      inputId={{ id: item.ticketIdentifier, field: 'fixedPrice' }}
      type="price"
      value={item.fixedPrice}
      allowEmpty={true}
      on:complete={onTicketChanged}
    />
  </td>
  <td
    on:click={removeNoSaleProduct(companyId, priceTable, index)}
    class="text-button remove"
  >
    {$_('pricing.remove')}
  </td>
{:else}
  <td>{item.name}</td>
  <td class="text-right">
    {Number.isFinite(item.fixedPrice)
      ? (+item.fixedPrice).toLocaleString('fi-FI', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        })
      : ''}
  </td>
{/if}

<script>
  import { _ } from 'svelte-i18n';
  import CommandList from './commands/CommandList.svelte';

  const commands = {
    REMOTE_LIST_COMMANDS_COMMAND: CommandList,
  };

  export let action;

  // strip out table data from response
  let dataToShow = { ...action, data: { ...action.data } };
  dataToShow.data.table = undefined;
</script>

<style>
  .header {
    width: 100%;
    font-weight: 600;
    border-top: 1px solid #cccccc;
    display: flex;
    padding: 1em 0;
  }

  .header > span:last-child {
    font-weight: initial;
  }

  .title {
    flex-grow: 1;
    padding: 0 1em;
  }

  .no-border {
    border-top: none;
  }

  .timestamp {
    width: 7em;
  }

  .content {
    padding: 0em 0 1em 8em;
  }

  .content small {
    margin-top: 0.5em;
    display: block;
  }

  .placeholder,
  .placeholder > * {
    font-style: italic;
  }
</style>

<div
  class="header"
  class:no-border={action.actionType === 'RESPONSE'}
  class:placeholder={action.placeholder}
>
  <span class="timestamp">{action.timestamp.format('D.M.YYYY H:mm')}</span>
  <span class="title">
    {#if action.type === 'INFO'}
      {$_(`supportRequests.reasons.${action.reason}`, {
        default: action.reason || '-',
      })}
    {:else if action.actionType === 'COMMAND'}
      {$_(`supportRequests.commands.${action.command}`, {
        default: action.command,
      })}
    {:else}
      {$_(`supportRequests.actionType.${action.actionType}`, {
        default: action.actionType,
      })}

      {#if action.actionType === 'RESPONSE'}
        {action.response}
      {/if}
    {/if}
  </span>
  <span>
    {#if action.driver}
      {$_('supportRequests.driver')} {action.driver}
    {:else if action.user}
      {action.user}
    {:else}{$_('supportRequests.device')} {action.device}{/if}
  </span>
</div>
{#if action.actionType === 'HANDLED' || action.actionType === 'NOTE' || action.actionType === 'RESPONSE' || (action.actionType === 'COMMAND' && action.waitingForResponse) || action.args}
  <div class="content">
    {#if action.actionType === 'HANDLED' || action.actionType === 'NOTE'}
      {action.text || ''}
    {:else if action.actionType === 'COMMAND' && action.waitingForResponse}
      <b>{$_('supportRequests.waitingForResponse')}</b>
    {:else if action.actionType === 'RESPONSE'}
      {#if action.data && !commands[action.command]}
        <small>{JSON.stringify(dataToShow)}</small>
      {:else if commands[action.command]}
        <svelte:component this={commands[action.command]} command={action} />
      {/if}
    {/if}
    {#if action.actionType === 'COMMAND' && action.args}
      <small>{action.args}</small>
    {/if}
  </div>
{/if}

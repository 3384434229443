<script>
  import { _ } from 'svelte-i18n';

  export let files;
  export let disabled = false;
  export let buttonClass = 'small';
  export let accept;
  export let error = undefined;
  let fileInput;
</script>

<style>
  input[type='file'] {
    display: none;
  }
  input {
    width: 100%;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
    border-radius: 4px;
  }
  button {
    min-width: 135px;
  }
  .file-name {
    padding: 0;
    padding-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .browse-button-container {
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 0.9em;
  }
  .error {
    color: #d0021b;
    font-size: 0.9rem;
  }
</style>

<div>
  <div class="browse-button-container">
    <button
      class={buttonClass}
      {disabled}
      on:click={() => {
        fileInput.click();
      }}
    >
      {$_('pricing.chooseFile')}
    </button>
    <div class="file-name">
      {(files && files[0] && files[0].name) || ''}
    </div>
    <input
      {accept}
      bind:this={fileInput}
      type="file"
      id="file"
      name="file"
      bind:files
      on:change={() => {
        error = undefined;
      }}
    />
  </div>
  {#if error}
    <span class="error">{error}</span>
  {/if}
</div>

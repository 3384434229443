import { writable, get } from 'svelte/store';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';
import QueryString from 'query-string';
import { getClient } from '../common/http';
import { showSpinnerOverlay, dismissSpinnerOverlay } from '../stores/spinner';
import { showNotification } from '../stores/notifications';
import { currentUser } from './user';
import { _ } from 'svelte-i18n';
import moment from 'moment';

const BASIC_TICKET_TYPE = 'BASIC_TICKET';
const LINE_BASIC_TICKET_TYPE = 'LINE_BASIC_TICKET';
const OTHER_TICKET_TYPE = 'OTHER_TICKET';
const OTHER_PRODUCT_TYPE = 'OTHER_PRODUCT';
const NO_SALE_DESCRIPTION = 'NO_SALE_DESCRIPTION';
const ZONE_TICKET_TYPE = 'ZONE_TICKET';
const SPECIAL_PRICING = 'SPECIAL_PRICING';
const OVERLAPPING = "overlapping";
const REFERENCE_SUFFIX = 'REFERENCE';
export const NO_SALE_TYPE = 'NO_SALE';
export const OTHER_SALE_TYPE = 'OTHER_SALE';


//Item that has main price table other basic ticket prices are based on, BASIC_TICKET_TYPE must be the prefix
const BASIC_TICKET_REFERENCE_IDENTIFIER = `${BASIC_TICKET_TYPE}_${REFERENCE_SUFFIX}`;

const TICKET_GROUP_MAP = Object.freeze({
  [BASIC_TICKET_TYPE]: 'basicTickets',
  [LINE_BASIC_TICKET_TYPE]: 'lineBasicTickets',
  [OTHER_TICKET_TYPE]: 'otherTickets',
  [OTHER_PRODUCT_TYPE]: 'otherProducts',
  [NO_SALE_DESCRIPTION]: 'noSaleDescriptions',
  [ZONE_TICKET_TYPE]: 'zoneTickets',
  [SPECIAL_PRICING]: 'specialPricings',
  [NO_SALE_TYPE]: 'noSales',
  [OTHER_SALE_TYPE]: 'otherSales',
});

const OVERWRITE_DEFAULT_ITEMS = 'toProperty';
const OTHER_SALE_TICKETS = 'otherTickets';
const OTHER_SALE_PRODUCTS = 'otherProducts';
const DEFAULT_ITEMS_PROPERTY = 'items';

export const SPECIAL_PRICING_TYPE_DISTANCE = 'DISTANCE';
export const SPECIAL_PRICING_TYPE_PRICE = 'PRICE';

export const prices = writable({});
export const copyableTables = writable([]);
export const currentCompany = writable(null);

export const copyablePriceTables = writable({});


export const VALIDITY_START_COL = 'validityStart';
export const VALIDITY_END_COL = 'validityEnd';

currentUser.subscribe(user => {
  if (!user || !user.loggedIn) {
    prices.set({});
    copyableTables.set([]);
    copyablePriceTables.set({});
    currentCompany.set(null);
  }
});

export const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
  const multiplier = Big(100 - discountPercentage).div(100);
  return +Big(originalPrice).mul(multiplier).toFixed(2);
};

export const createLineEntry = (line, departure) => {
  return `${departure ? departure.padStart(4, '0') : ''}${
    line ? `-${line.padStart(4, '0')}` : ''
  }`;
};

export const zoneSort = (a, b) => {
  const durationSort = () => {
    if (a.duration === null) {
      return 1;
    } else if (b.duration === null) {
      return -1;
    } else {
      return a.duration - b.duration;
    }
  };

  if (!a.zone) {
    if (a.duration === null) {
      return 1;
    } else {
      return durationSort(a.duration, b.duration);
    }
  } else if (!b.zone) {
    if (b.duration === null) {
      return -1;
    } else {
      return durationSort(a.duration, b.duration);
    }
  } else {
    return a.zone.localeCompare(b.zone) || durationSort(a.duration, b.duration);
  }
};

const getBasicReferenceTicketIdentifier = priceTableName => {
  return priceTableName
    ? `${LINE_BASIC_TICKET_TYPE}_${priceTableName}$_${REFERENCE_SUFFIX}`
    : BASIC_TICKET_REFERENCE_IDENTIFIER;
};

const groupTickets = ticketArray => {
  const groupBy = (array, groupingFunc) => {
    return array.reduce((acc, obj) => {
      const key = groupingFunc(obj);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const grouped = groupBy(ticketArray, obj => TICKET_GROUP_MAP[obj.ticketType]);
  Object.values(grouped).forEach(tickets => tickets.sort((a, b) => a.index - b.index));

  const lineTicketGroupName = TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE];
  if (grouped[lineTicketGroupName]) {
    const groupedBasicTickets = groupBy(
      grouped[lineTicketGroupName],
      obj => obj.priceTableName,
    );
    Object.values(groupedBasicTickets).forEach(tickets =>
      tickets.sort((a, b) => a.index - b.index),
    );
    grouped[lineTicketGroupName] = groupedBasicTickets;
  }

  const zoneGroupName = TICKET_GROUP_MAP[ZONE_TICKET_TYPE];
  if (grouped[zoneGroupName]) {
    const groupedZones = groupBy(grouped[zoneGroupName], obj => obj.zoneName);
    Object.values(groupedZones).forEach(tickets =>
      tickets.sort((a, b) => a.index - b.index),
    );
    grouped[zoneGroupName] = groupedZones;
  }

  const specialPricingGroupName = TICKET_GROUP_MAP[SPECIAL_PRICING];
  if (grouped[specialPricingGroupName]) {
    const groupedDistances = {};
    grouped[specialPricingGroupName].forEach(entry => {
      groupedDistances[entry.name] = entry;
    });
    grouped[specialPricingGroupName] = groupedDistances;
  }


  const noSalesGroupName = TICKET_GROUP_MAP[NO_SALE_TYPE];
  if (grouped[noSalesGroupName]) {
    const groupedDistances = {};
    grouped[noSalesGroupName].forEach(entry => {
      groupedDistances[entry.name] = entry;
    });
    grouped[noSalesGroupName] = groupedDistances;
  }  

  const otherSalesGroupName = TICKET_GROUP_MAP[OTHER_SALE_TYPE];
  if (grouped[otherSalesGroupName]) {
    const groupedDistances = {};
    grouped[otherSalesGroupName].forEach(entry => {
      groupedDistances[entry.name] = entry;
    });
    grouped[otherSalesGroupName] = groupedDistances;
  }  

  grouped[OVERLAPPING] = {};
  grouped[OVERLAPPING][TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE]] = grouped.lineBasicTickets && findOverlapping(Object.values(grouped.lineBasicTickets));
  grouped[OVERLAPPING][specialPricingGroupName] = grouped[specialPricingGroupName] && findOverlapping(Object.values(grouped[specialPricingGroupName]));
  grouped[OVERLAPPING][zoneGroupName] = grouped[zoneGroupName] && findOverlapping(Object.values(grouped[zoneGroupName]));
  grouped[OVERLAPPING][noSalesGroupName] = grouped[noSalesGroupName] && findOverlapping(Object.values(grouped[noSalesGroupName]));
  grouped[OVERLAPPING][otherSalesGroupName] = grouped[otherSalesGroupName] && findOverlapping(Object.values(grouped[otherSalesGroupName]));

  return grouped;
};

const fetchProducts = async company => {
  const result = await getClient().get(`api/pricing/${company}`);
  return groupTickets(result.data);
};

const updateDataWithProduct = (product, productType) => {
  prices.update(current => {
    const newProducts = [...(current[product.company][productType] || [])];
    newProducts.splice(product.index, 0, product);
 
    return {
      ...current,
      [product.company]: {
        ...current[product.company],
        [productType]: newProducts,
      },
    };
  });
};

const updateDictionaryDataWithProduct = (
  company,
  productMainType,
  productSubType,
  product,
) => {
  prices.update(current => {
    const dictionary = { ...current[company][productMainType] };
    dictionary[productSubType] = product;

    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [productMainType]: dictionary,
      },
    };

    if (productMainType === TICKET_GROUP_MAP[NO_SALE_TYPE]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[NO_SALE_TYPE]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[NO_SALE_TYPE]]));
    } else if (productMainType === TICKET_GROUP_MAP[OTHER_SALE_TYPE]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[OTHER_SALE_TYPE]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[OTHER_SALE_TYPE]]));
    }

    return ret;
  });

};

const updateNestedDataWithProduct = (product, productMainType, productSubType) => {
  prices.update(current => {
    const oldProducts =
      current[product.company][productMainType] &&
      current[product.company][productMainType][productSubType];
    const newProducts = [...(oldProducts || [])];
    newProducts.splice(product.index, 0, product);

    return {
      ...current,
      [product.company]: {
        ...current[product.company],
        [productMainType]: {
          ...current[product.company][productMainType],
          [productSubType]: newProducts,
        },
      },
    };
  });
};

const deleteProduct = (company, productId) => {
  prices.update(current => {
    const allProducts = Object.values(current[company])
      .filter(x => x)
      .flatMap(value => {
        if (!Array.isArray(value)) {
          return Object.values(value);
        } else {
          return value;
        }
      })
      .flat()
      .filter(x => x);
    const product = allProducts.find(product => product.ticketIdentifier === productId);
    if (product) {
      const productType = TICKET_GROUP_MAP[product.ticketType];
      let result;
      if (
        product.ticketType !== LINE_BASIC_TICKET_TYPE &&
        product.ticketType !== ZONE_TICKET_TYPE
      ) {
        result = deleteMainProduct(current, product, productType);
      } else {
        result = deleteNestedProduct(
          current,
          product,
          productType,
          product.ticketType === LINE_BASIC_TICKET_TYPE
            ? product.priceTableName
            : product.zoneName,
        );
      }
      if (result) return result;
    }
    return current;
  });
};

const deleteMainProduct = (current, product, productType) => {
  const newProducts = [...(current[product.company][productType] || [])];
  const deleteIndex = newProducts.indexOf(product);
  if (deleteIndex !== -1) {
    newProducts.splice(deleteIndex, 1);

    // regenerate all indexes
    newProducts.sort((a, b) => a.index - b.index);
    newProducts.forEach((item, index) => {
      item.index = index;
    });

    return {
      ...current,
      [product.company]: {
        ...current[product.company],
        [productType]: newProducts.length ? newProducts : undefined,
      },
    };
  } else {
    return undefined;
  }
};

const deleteDictionaryEntry = (company, dictionaryName, entryName) => {
  prices.update(current => {
    let dictionary = { ...current[company][dictionaryName] };
    delete dictionary[entryName];

    const dictionaryUndefined = !Object.keys(dictionary).length;
    if (dictionaryUndefined) {
      dictionary = undefined;
    }

    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [dictionaryName]: dictionary,
      },
    };

    if (dictionaryName === TICKET_GROUP_MAP[SPECIAL_PRICING]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[SPECIAL_PRICING]] = dictionaryUndefined ? undefined : findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[SPECIAL_PRICING]]));
    } else if (dictionaryName === TICKET_GROUP_MAP[NO_SALE_TYPE]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[NO_SALE_TYPE]] = dictionaryUndefined ? undefined : findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[NO_SALE_TYPE]]));    
    } else if (dictionaryName === TICKET_GROUP_MAP[OTHER_SALE_TYPE]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[OTHER_SALE_TYPE]] = dictionaryUndefined ? undefined : findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[OTHER_SALE_TYPE]]));    
    }


    return ret;
  });
};

const deleteNestedProduct = (current, product, productMainType, productSubType) => {
  const newProducts = [
    ...((current[product.company][productMainType] &&
      current[product.company][productMainType][productSubType]) ||
      []),
  ];
  const deleteIndex = newProducts.indexOf(product);
  if (deleteIndex !== -1) {
    newProducts.splice(deleteIndex, 1);

    // regenerate all indexes
    newProducts.sort((a, b) => a.index - b.index);
    newProducts.forEach((item, index) => {
      item.index = index;
    });

    return {
      ...current,
      [product.company]: {
        ...current[product.company],
        [productMainType]: {
          ...current[product.company][productMainType],
          [productSubType]: newProducts.length ? newProducts : undefined,
        },
      },
    };
  } else {
    return undefined;
  }
};

const updateBasicTicketData = (
  current,
  company,
  basicTickets,
  priceTableName,
  makeDefault,
) => {
  if (makeDefault || !priceTableName) {
    return {
      ...current,
      [company]: {
        ...current[company],
        basicTickets,
      },
    };
  } else {
    const ret = {
      ...current,
      [company]: {
        ...current[company],
        lineBasicTickets: {
          ...(current[company] && current[company].lineBasicTickets),
          [priceTableName]: basicTickets,
        },
      },
      
    };


    ret[company][OVERLAPPING][TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE]] = findOverlapping(Object.values(ret[company].lineBasicTickets));
    return ret;
  }
};

const appendBasicTicketDistance = (company, distance, price, priceTableName) => {
  prices.update(current => {
    const basicTickets = priceTableName
      ? [...(current[company].lineBasicTickets[priceTableName] || [])]
      : [...(current[company].basicTickets || [])];
    if (basicTickets && basicTickets.length) {
      basicTickets.forEach(basicTicket => {
        basicTicket.distancePrices.push({
          price: basicTicket.discount
            ? calculateDiscountedPrice(price, basicTicket.discount)
            : price,
          distance,
        });
        basicTicket.distancePrices.sort((a, b) => a.distance - b.distance);
      });
    }
    return updateBasicTicketData(current, company, basicTickets, priceTableName);
  });
};

const appendZoneTicketPriceZone = (
  company,
  zoneName,
  priceZoneName,
  duration,
  priceTable,
) => {
  prices.update(current => {
    const zoneTickets = [...(current[company].zoneTickets[zoneName] || [])];
    zoneTickets.forEach((ticket, index) => {
      ticket.zonePrices.push({
        zone: priceZoneName,
        duration,
        price: priceTable[index],
      });
      ticket.zonePrices.sort(zoneSort);
    });
    return {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets: {
          ...current[company].zoneTickets,
          [zoneName]: zoneTickets,
        },
      },
    };
  });
};

const eraseBasicTicketDistance = (company, distance, priceTableName) => {
  prices.update(current => {
    const basicTickets = priceTableName
      ? [...(current[company].lineBasicTickets[priceTableName] || [])]
      : [...(current[company].basicTickets || [])];
    if (basicTickets && basicTickets.length) {
      const index = basicTickets[0].distancePrices.findIndex(
        entry => entry.distance === distance,
      );
      if (index !== -1) {
        basicTickets.forEach(basicTicket => {
          basicTicket.distancePrices.splice(index, 1);
        });
      }
    }
    return updateBasicTicketData(current, company, basicTickets, priceTableName);
  });
};

const eraseZoneTicketPriceZone = (company, zoneName, priceZoneName, duration) => {
  prices.update(current => {
    const zoneTickets = [...(current[company].zoneTickets[zoneName] || [])];
    if (zoneTickets && zoneTickets.length) {
      const index = zoneTickets[0].zonePrices.findIndex(
        entry => entry.zone === priceZoneName && entry.duration === duration,
      );
      if (index !== -1) {
        zoneTickets.forEach(zoneTicket => {
          zoneTicket.zonePrices.splice(index, 1);
        });
      }
    }
    return {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets: {
          ...current[company].zoneTickets,
          [zoneName]: zoneTickets,
        },
      },
    };
  });
};

const updateBasicTicketsWithTable = (
  company,
  basicTickets,
  priceTableName,
  makeDefault,
) => {
  basicTickets.sort((a, b) => a.index - b.index);
  prices.update(current =>
    updateBasicTicketData(current, company, basicTickets, priceTableName, makeDefault),
  );
};

const updateBasicTicket = (company, ticketId, name, discount, priceTableName) => {
  prices.update(current => {
    const referenceId = getBasicReferenceTicketIdentifier(priceTableName);
    const basicTickets = priceTableName
      ? [...(current[company].lineBasicTickets[priceTableName] || [])]
      : [...(current[company].basicTickets || [])];
    if (basicTickets && basicTickets.length) {
      const referenceTicket = basicTickets.find(
        entry => entry.ticketIdentifier === referenceId,
      );
      const currentIndex = basicTickets.findIndex(
        entry => entry.ticketIdentifier === ticketId,
      );
      if (referenceTicket && currentIndex !== -1) {
        const currentTicket = { ...basicTickets[currentIndex] };
        currentTicket.name = name;
        if (currentTicket.discount !== discount) {
          currentTicket.discount = discount;
          currentTicket.distancePrices = referenceTicket.distancePrices.map(
            priceEntry => ({
              ...priceEntry,
              price: discount
                ? calculateDiscountedPrice(priceEntry.price, discount)
                : priceEntry.price,
            }),
          );
        }
        basicTickets[currentIndex] = currentTicket;
      }
    }
    return updateBasicTicketData(current, company, basicTickets, priceTableName);
  });
};

const updateBasicTicketPrice = (company, ticketId, distance, price, priceTableName) => {
  prices.update(current => {
    const basicTickets = priceTableName
      ? [...(current[company].lineBasicTickets[priceTableName] || [])]
      : [...(current[company].basicTickets || [])];
    if (basicTickets && basicTickets.length) {
      const ticketIndex = basicTickets.findIndex(
        entry => entry.ticketIdentifier === ticketId,
      );
      if (ticketIndex) {
        const newTicket = {
          ...basicTickets[ticketIndex],
          distancePrices: [...basicTickets[ticketIndex].distancePrices],
        };
        const distanceIndex = newTicket.distancePrices.findIndex(
          entry => entry.distance === distance,
        );
        if (distanceIndex !== -1) {
          newTicket.distancePrices[distanceIndex].price = price;
          basicTickets[ticketIndex] = newTicket;
        }
      }
    }
    return updateBasicTicketData(current, company, basicTickets, priceTableName);
  });
};

export const addPriceTableItem = async (company, type, priceTableName, item ) => {
  return execAddPriceTableItem(company, type, priceTableName, item, (addedItem) => {
    try {
      return addPriceTableItemToStore(company, type, priceTableName, addedItem);
    } catch (err) {
      console.log(err);
      showNotification({ title: err.message, body: '', type: 'error' });
    }
  });
};


export const updateOtherSaleTicketIndex = async (
  company,
  priceTable,
  ticketIdentifier,
  toIndex,
 ) => {
  return updateOtherSaleIndex(company,
    priceTable,
    ticketIdentifier,
    toIndex,
    OTHER_SALE_TICKETS);
};

export const updateOtherSaleProductIndex = async (
  company,
  priceTable,
  ticketIdentifier,
  toIndex,
 ) => {
  return updateOtherSaleIndex(company,
    priceTable,
    ticketIdentifier,
    toIndex,
    OTHER_SALE_PRODUCTS);
};

export const updateOtherSaleIndex = async (
  company,
  priceTable,
  ticketIdentifier,
  toIndex,
  itemType,
 ) => {
    return updatePriceTableItemIndex(company, OTHER_SALE_TYPE, priceTable, ticketIdentifier, toIndex, itemType);
};

export const updateNoSaleIndex = async (
  company,
  priceTable,
  ticketIdentifier,
  toIndex
 ) => {
    return updatePriceTableItemIndex(company, NO_SALE_TYPE, priceTable, ticketIdentifier, toIndex);
};

export const updatePriceTableItemIndex = async(company, type, priceTableName, itemIdentifier, newIndex, property) => {
  return execUpdatePriceTableItemIndex(company, type, priceTableName, itemIdentifier, newIndex, property, (updatedPriceTable) => {
    try {
      return updateDictionaryDataWithProduct(company, TICKET_GROUP_MAP[type], priceTableName, updatedPriceTable);
    } catch (err) {
      console.log(err);
      showNotification({ title: err.message, body: '', type: 'error' });
    }
  });
};


export const updateNoSaleTableValidity = async (company, priceTableName, ticketIdentifier, type, value) => {
  return updatePriceTableValidityGeneral(company, priceTableName, ticketIdentifier, type, value, NO_SALE_TYPE);
};

export const updateOtherSaleTableValidity = async (company, priceTableName, ticketIdentifier, type, value) => {
  return updatePriceTableValidityGeneral(company, priceTableName, ticketIdentifier, type, value, OTHER_SALE_TYPE);
};

const updatePriceTableValidityGeneral = async (company, priceTableName, ticketIdentifier, type, value, groupingType) => {
  return execUpdatePriceTableValidity(company, ticketIdentifier, type, value, () => {
    try {
      return updatePricingValidity(company, TICKET_GROUP_MAP[groupingType], priceTableName, type, value);
    } catch (err) {
      console.log(err);
      showNotification({ title: err.message, body: '', type: 'error' });
    }
  });
};

export const updateSpecialPriceTableValidity = async (company, priceTableName, ticketIdentifier, type, value) => {
  return execUpdatePriceTableValidity(company, ticketIdentifier, type, value, () => {
    try {
      return updatePricingValidity(company, TICKET_GROUP_MAP[SPECIAL_PRICING], priceTableName, type, value);
    } catch (err) {
      console.log(err);
      showNotification({ title: err.message, body: '', type: 'error' });
    }
  });
};

export const updateZonePriceTableValidity = async (company, priceTableName, ticketIdentifier, type, value) => {
  return execUpdatePriceTableValidity(company, ticketIdentifier, type, value, () => {
    try {
      return updatePricingValidity(company, TICKET_GROUP_MAP[ZONE_TICKET_TYPE],priceTableName, type, value);
    } catch (err) {
      console.log(err);
      showNotification({ title: err.message, body: '', type: 'error' });
    }
  });
};

export const updatePriceTableValidity = async (company, priceTableName, ticketIdentifier, type, value) => {
  return execUpdatePriceTableValidity(company, ticketIdentifier, type, value, () => {
    prices.update(current => {
      const priceTables = [...current[company].lineBasicTickets[priceTableName]];
      const refTicket = priceTables.find(ticket => ticket.ticketIdentifier.endsWith('_REFERENCE'));
      refTicket[type] = value;
      return updateBasicTicketData(current, company, priceTables, priceTableName);
    });
  });
};

export const execUpdatePriceTableValidity = async (company, ticketIdentifier, type, value, onSuccess = () => {}) => {
  try {
      await getClient().patch('api/pricing/modifyPriceTableValidity', {
        company,
        type,
        value,
        ticketIdentifier,
      });

      onSuccess();
  } catch (error) {
    showNotification(error.message);
  }
};

export const execAddPriceTableItem = async (company, type, priceTable, item, onSuccess = () => {}) => {
  try {
      const { data } = await getClient().post('api/pricing/addPriceTableItem', {
        company,
        type,
        priceTable,
        item,
      });

      onSuccess(data.product);
  } catch (error) {
    showNotification(error.message);
  }
};

export const execUpdatePriceTableItemIndex = async (company, type, priceTable, itemIdentifier, newIndex, property, onSuccess = () => {}) => {
  try {
    const { data } = await getClient().patch('api/pricing/updatePriceTableItemIndex', {
      company,
      type,
      priceTable,
      itemIdentifier,
      newIndex,
      property,
    });

    onSuccess(data.priceTable);
} catch (error) {
  showNotification(error.message);
}  
};
export const execPutPriceTableItem = async (company, type, priceTable, item, onSuccess = () => { dismissSpinnerOverlay(); }) => {
  try {
      const { data } = await getClient().patch('api/pricing/updatePriceTableItem', {
        company,
        type,
        priceTable,
        item,
      });

      onSuccess(data.product);
  } catch (error) {
    showNotification(error.message);
  } 
};


const updateBasicTicketDistance = (
  company,
  originalDistance,
  newDistance,
  price,
  priceTableName,
) => {
  prices.update(current => {
    const basicTickets = priceTableName
      ? [...(current[company].lineBasicTickets[priceTableName] || [])]
      : [...(current[company].basicTickets || [])];
    if (basicTickets && basicTickets.length) {
      const index = basicTickets[0].distancePrices.findIndex(
        entry => entry.distance === originalDistance,
      );
      if (index !== -1) {
        basicTickets.forEach(basicTicket => {
          basicTicket.distancePrices[index].price = basicTicket.discount
            ? calculateDiscountedPrice(price, basicTicket.discount)
            : price;
          if (originalDistance !== newDistance) {
            basicTicket.distancePrices[index].distance = newDistance;
            basicTicket.distancePrices.sort((a, b) => a.distance - b.distance);
          }
        });
      }
    }
    return updateBasicTicketData(current, company, basicTickets, priceTableName);
  });
};

const updatePricingValidity = (company, pricingType, pricingName, validityCol, validityValue) => {
  prices.update(current => {
    const target = current[company][pricingType][pricingName];
    const isArray = Array.isArray(target);

    const pricingToUpdate = isArray ? [...current[company][pricingType][pricingName]] : { ...current[company][pricingType][pricingName] };

    if ( isArray ) {
      pricingToUpdate[0][validityCol] = validityValue;
    } else {
      pricingToUpdate[validityCol] = validityValue;
    }
    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [pricingType]: {
          ...current[company][pricingType],
          [pricingName]: pricingToUpdate,
        },
      },
    };

    ret[company][OVERLAPPING][pricingType] = findOverlapping(Object.values(ret[company][pricingType], isArray));
    return ret;
  });
};


const updatePriceTableItemToStore = (company, type, priceTableName, item) => {
  prices.update(current => {
    const property = item[OVERWRITE_DEFAULT_ITEMS] || DEFAULT_ITEMS_PROPERTY;
    const items = [...current[company][type][priceTableName][property]];
    const itemIndex = items.findIndex(originalItem => originalItem.ticketIdentifier === item.ticketIdentifier);

    items[itemIndex] = item;

    return {
      ...current,
      [company]: {
        ...current[company],
        [type]: {
          ...current[company][type],
          [priceTableName]: {
            ...current[company][type][priceTableName],
            [property]: items,
          }
        },
      },      
    };
  });
};

const overwritePriceTableToStore = (company, type, priceTableName, priceTable) => {
  prices.update(current => {
    return {
      ...current,
      [company]: {
        ...current[company],
        [type]: {
          ...current[company][type],
          [priceTableName]: priceTable
        },
      },      
    };
  });
};


const addPriceTableItemToStore = (company, type, priceTableName, item) => {
  prices.update(current => {
    const groupingType = TICKET_GROUP_MAP[type];
    
    const toProperty = item[OVERWRITE_DEFAULT_ITEMS] || 'items';
    const items = current[company][groupingType][priceTableName] && current[company][groupingType][priceTableName][toProperty] && [...current[company][groupingType][priceTableName][toProperty]] || [];
    items.push(item);

    return {
      ...current,
      [company]: {
        ...current[company],
        [groupingType]: {
          ...current[company][groupingType],
          [priceTableName]: {
            ...current[company][groupingType][priceTableName],
            [toProperty]: items,
          }
        },
      },      
    };
  });
};

const updateSpecialPricing = (company, pricingName, row, column, value) => {
  prices.update(current => {
    const newPricing = { ...current[company].specialPricings[pricingName] };
    newPricing.specialDistances[row][column] = value;
    const ret = {
      ...current,
      [company]: {
        ...current[company],
        specialPricings: {
          ...current[company].specialPricings,
          [pricingName]: newPricing,
        },
      },
    };

    ret[company][OVERLAPPING][TICKET_GROUP_MAP[SPECIAL_PRICING]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[SPECIAL_PRICING]]));
    return ret;
  });
};

const updateZoneTicketName = (company, zoneName, ticketIdentifier, name) => {
  prices.update(current => {
    const zoneTickets = [...current[company].zoneTickets[zoneName]];
    const ticket = zoneTickets.find(
      ticket => ticket.ticketIdentifier === ticketIdentifier,
    );
    ticket.name = name;
    return {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets: {
          ...current[company].zoneTickets,
          [zoneName]: zoneTickets,
        },
      },
    };
  });
};

const updateZoneTicketPrice = (company, updatedTicket) => {
  prices.update(current => {
    const zoneTickets = [...current[company].zoneTickets[updatedTicket.zoneName]];
    const updatedTicketIndex = zoneTickets.findIndex(
      ticket => ticket.ticketIdentifier === updatedTicket.ticketIdentifier,
    );
    zoneTickets[updatedTicketIndex] = updatedTicket;
    return {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets: {
          ...current[company].zoneTickets,
          [updatedTicket.zoneName]: zoneTickets,
        },
      },
    };
  });
};

const updateZoneTicketPriceZoneName = (company, zoneName, oldName, newName, duration) => {
  prices.update(current => {
    const zoneTickets = [...current[company].zoneTickets[zoneName]];
    if (zoneTickets && zoneTickets.length) {
      const index = zoneTickets[0].zonePrices.findIndex(
        entry => entry.zone === oldName && entry.duration === duration,
      );
      zoneTickets.forEach(ticket => {
        ticket.zonePrices[index].zone = newName;
        ticket.zonePrices.sort(zoneSort);
      });
    }
    return {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets: {
          ...current[company].zoneTickets,
          [zoneName]: zoneTickets,
        },
      },
    };
  });
};

const updateZoneTicketDistance = (
  company,
  zoneName,
  priceZoneName,
  oldDuration,
  newDuration,
) => {
  prices.update(current => {
    const zoneTickets = [...current[company].zoneTickets[zoneName]];
    if (zoneTickets && zoneTickets.length) {
      const index = zoneTickets[0].zonePrices.findIndex(
        entry => entry.zone === priceZoneName && entry.duration === oldDuration,
      );
      zoneTickets.forEach(ticket => {
        ticket.zonePrices[index].duration = newDuration;
        ticket.zonePrices.sort(zoneSort);
      });
    }
    return {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets: {
          ...current[company].zoneTickets,
          [zoneName]: zoneTickets,
        },
      },
    };
  });
};

const updateDictionaryTicketLines = (
  company,
  productMainType,
  productSubType,
  entryId,
  line,
  departure,
  name,
) => {
  prices.update(current => {
    const oldTicket =
      current[company][productMainType] &&
      current[company][productMainType][productSubType];
    const ticket = { ...oldTicket };
    if (ticket) {
      const lineEntry = createLineEntry(line, departure);
      const index = ticket.lineIds.indexOf(entryId);
      if (index !== -1) {
        ticket.lines[index] = lineEntry;
        ticket.lineNames[index] = name;
      } else {
        ticket.lines.push(lineEntry);
        ticket.lineNames.push(name);
        ticket.lineIds.push(entryId);
      }
    }
    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [productMainType]: {
          ...current[company][productMainType],
          [productSubType]: ticket,
        },
      },
    };

    if (productMainType === TICKET_GROUP_MAP[SPECIAL_PRICING]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[SPECIAL_PRICING]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[SPECIAL_PRICING]]));
    } else if (productMainType === TICKET_GROUP_MAP[NO_SALE_TYPE]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[NO_SALE_TYPE]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[NO_SALE_TYPE]]));
    } else if (productMainType === TICKET_GROUP_MAP[OTHER_SALE_TYPE]) {
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[OTHER_SALE_TYPE]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[OTHER_SALE_TYPE]]));
    }

    return ret;
  });
};

const updateNestedTicketLines = (
  company,
  productMainType,
  productSubType,
  entryId,
  line,
  departure,
  name,
) => {
  prices.update(current => {
    const oldTickets =
      current[company][productMainType] &&
      current[company][productMainType][productSubType];
    const tickets = [...(oldTickets || [])];
    const newLineEntry = createLineEntry(line, departure);
    if (tickets && tickets.length) {
      const previousIndex = tickets[0].lineIds.indexOf(entryId);
      if (previousIndex !== -1) {
        tickets.forEach(ticket => {
          ticket.lines[previousIndex] = newLineEntry;
          ticket.lineNames[previousIndex] = name;
          ticket.lineIds[previousIndex] = entryId;
        });
      } else {
        tickets.forEach(ticket => {
          ticket.lines.push(newLineEntry);
          ticket.lineNames.push(name);
          ticket.lineIds.push(entryId);
        });
      }
    }
    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [productMainType]: {
          ...current[company][productMainType],
          [productSubType]: tickets,
        },
      },
    };

    if (productMainType === TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE]) {
        ret[company][OVERLAPPING][productMainType] = findOverlapping(Object.values(ret[company].lineBasicTickets));
    } else if ( productMainType === TICKET_GROUP_MAP[ZONE_TICKET_TYPE]) {
      ret[company][OVERLAPPING][productMainType] = findOverlapping(Object.values(ret[company].zoneTickets));
    }

    return ret;
  });
};

const deleteNestedTicketLine = (company, productMainType, productSubType, entryId) => {
  prices.update(current => {
    const oldTickets =
      current[company][productMainType] &&
      current[company][productMainType][productSubType];
    const tickets = [...(oldTickets || [])];
    if (tickets && tickets.length) {
      const index = tickets[0].lineIds.indexOf(entryId);
      if (index !== -1) {
        tickets.forEach(ticket => {
          ticket.lines.splice(index, 1);
          ticket.lineNames.splice(index, 1);
          ticket.lineIds.splice(index, 1);
        });
      }
    }

    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [productMainType]: {
          ...current[company][productMainType],
          [productSubType]: tickets,
        },
      },
    };

    if ( productMainType === TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE] ) {
      ret[company][OVERLAPPING][productMainType] = findOverlapping(Object.values(ret[company].lineBasicTickets));
    }

    return ret;
  });
};

const deleteDictionaryTicketLine = (
  company,
  productMainType,
  productSubType,
  entryId,
) => {
  prices.update(current => {
    const oldTicket =
      current[company][productMainType] &&
      current[company][productMainType][productSubType];
    const ticket = { ...oldTicket };
    if (ticket) {
      const index = ticket.lineIds.indexOf(entryId);
      if (index !== -1) {
        ticket.lines.splice(index, 1);
        ticket.lineNames.splice(index, 1);
        ticket.lineIds.splice(index, 1);
      }
    }

    const ret = {
      ...current,
      [company]: {
        ...current[company],
        [productMainType]: {
          ...current[company][productMainType],
          [productSubType]: ticket,
        },
      },
    };

    if (productMainType === TICKET_GROUP_MAP[SPECIAL_PRICING]) {
      ret[company][OVERLAPPING][productMainType] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[SPECIAL_PRICING]]));
    } else if (productMainType === TICKET_GROUP_MAP[NO_SALE_TYPE]) {
      ret[company][OVERLAPPING][productMainType] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[NO_SALE_TYPE]]));
    } else if (productMainType === TICKET_GROUP_MAP[OTHER_SALE_TYPE]) {
      ret[company][OVERLAPPING][productMainType] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[OTHER_SALE_TYPE]]));
    }

    return ret;
  });
};

const deleteBasicPriceTable = (company, priceTableName) => {
  prices.update(current => {
    if (priceTableName) {
      const priceTables = { ...current[company].lineBasicTickets };
      delete priceTables[priceTableName];
      const ret = {
        ...current,
        [company]: {
          ...current[company],
          lineBasicTickets: Object.keys(priceTables).length ? priceTables : undefined,
        },
      };
      
      ret[company][OVERLAPPING][TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE]] = findOverlapping(Object.values(ret[company].lineBasicTickets || {}));
      return ret;
    } else {
      return {
        ...current,
        [company]: {
          ...current[company],
          basicTickets: undefined,
          [OVERLAPPING]: {
            ...current[company][OVERLAPPING][TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE]],
            [TICKET_GROUP_MAP[LINE_BASIC_TICKET_TYPE]]: undefined
          }
        },
      };
    }
  });
};

const deleteZonePriceTable = (company, zoneName) => {
  prices.update(current => {
    const zoneTickets = { ...current[company].zoneTickets };
    delete zoneTickets[zoneName];

    const ret = {
      ...current,
      [company]: {
        ...current[company],
        zoneTickets,
      },
    };

    ret[company][OVERLAPPING][TICKET_GROUP_MAP[ZONE_TICKET_TYPE]] = findOverlapping(Object.values(ret[company][TICKET_GROUP_MAP[ZONE_TICKET_TYPE]]));
    return ret;
  });
};

export const getCopyableBasicPriceTables = async () => {
  showSpinnerOverlay();
  try {
    const { data: copyableCompanies } = await getClient().get(
      'api/pricing/basicTicket/copyable',
    );
    copyableTables.set(copyableCompanies);
    return copyableCompanies;
  } catch (error) {
    copyableTables.set([]);
    showNotification({ title: error.message, body: '', type: 'error' });
    return [];
  } finally {
    dismissSpinnerOverlay();
  }
};

export const getCopyableNoSalePriceTables = async () => {
  return getCopyablePriceTables(NO_SALE_TYPE);
};

export const getCopyableOtherSalePriceTables = async () => {
  return getCopyablePriceTables(OTHER_SALE_TYPE);
};

export const getCopyablePriceTables = async (type) => {
  showSpinnerOverlay();
  try {
    const { data } = await getClient().get(
      `api/pricing/pricetable/copyable/${type}`,
    );

    copyablePriceTables.update((current) => {
      current[type] = data;

      return current;
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    copyablePriceTables.update((current) => {
      current[type] = {};

      return current;
    });
    console.log('err', error);
    return [];
  } finally {
    dismissSpinnerOverlay();
  }
};

export const modifyProduct = async (company, ticket) => {
  try {
    await getClient().put(`api/pricing/${company}`, ticket);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const removeProduct = async (company, ticketId) => {
  try {
    deleteProduct(company, ticketId);
    await getClient().delete(`api/pricing/${company}/${ticketId}`);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    console.log(error, error.stack);
  }
};

export const updateOtherSaleTicketItem = (company, priceTable, item) => {
  return updatePriceTableItem(company, OTHER_SALE_TYPE, priceTable, { ...item, [OVERWRITE_DEFAULT_ITEMS]: OTHER_SALE_TICKETS });
};

export const updateOtherSaleProductItem = (company, priceTable, item) => {
  return updatePriceTableItem(company, OTHER_SALE_TYPE, priceTable, { ...item, [OVERWRITE_DEFAULT_ITEMS]: OTHER_SALE_PRODUCTS });
};

export const updateNoSaleItem = (company, priceTable, item) => {
  return updatePriceTableItem(company, NO_SALE_TYPE, priceTable, item);
};

export const updatePriceTableItem = async (company, type, priceTableName, item) => {
  return execPutPriceTableItem(company, type, priceTableName, item, () => {
    try {
      return updatePriceTableItemToStore(company, TICKET_GROUP_MAP[type], priceTableName, item);
    } catch (err) {
      console.log(err);
      showNotification({ title: err.message, body: '', type: 'error' });
    }
  });
};

export const removeOtherSaleProduct = async (company, priceTableName, itemIndex, itemType) => {
  try {
    const { data } = await getClient().delete(`api/pricing/${TICKET_GROUP_MAP[OTHER_SALE_TYPE]}/${company}/${priceTableName}/${itemType}/${itemIndex}`);
    overwritePriceTableToStore(company, TICKET_GROUP_MAP[OTHER_SALE_TYPE], priceTableName, data.product);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    console.log(error, error.stack);
  }
};

export const removeOtherSaleTicketProduct = async (company, priceTableName, itemIndex) => {
  return removeOtherSaleProduct(company, priceTableName, itemIndex, OTHER_SALE_TICKETS);
};

export const removeOtherSaleProductProduct = async (company, priceTableName, itemIndex) => {
  return removeOtherSaleProduct(company, priceTableName, itemIndex, OTHER_SALE_PRODUCTS);
};

export const removeNoSaleProduct = async (company, priceTableName, itemIndex) => {
  try {
    const { data } = await getClient().delete(`api/pricing/${TICKET_GROUP_MAP[NO_SALE_TYPE]}/${company}/${priceTableName}/${itemIndex}`);
    overwritePriceTableToStore(company, TICKET_GROUP_MAP[NO_SALE_TYPE], priceTableName, data.product);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    console.log(error, error.stack);
  }
};

export const removeNoSalePriceTable = async (company, priceTableName) => {
  return removePriceTable(company, NO_SALE_TYPE, priceTableName);
};

export const removeOtherSalePriceTable = async (company, priceTableName) => {
  return removePriceTable(company, OTHER_SALE_TYPE, priceTableName);
};

const removePriceTable = async (company, type, pricingName) => {
  try {
    await getClient().delete(`api/pricing/${company}/${type}/${pricingName}`);
    deleteDictionaryEntry(company, TICKET_GROUP_MAP[type], pricingName);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const updatePricingData = async company => {
  const data = await fetchProducts(company);
  prices.update(current => ({
    ...current,
    [company]: data,
  }));
};

export const createBasicPriceTable = async (company, priceTableName) => {
  showSpinnerOverlay();
  try {
    const { data: product } = await getClient().post('api/pricing/basicTicket', {
      company,
      name: 'Aikuinen',
      priceTableName,
      defaultTable: true,
    });
    updateDataWithProduct(product, 'basicTickets');
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};


export const createNoSalePriceTable = async (company, name, priceTableName) => {
  showSpinnerOverlay();
  try {
    const { data: product } = await getClient().post('api/pricing/noSaleTicket', {
      company,
      name,
      priceTableName,
    });

    updateDictionaryDataWithProduct(company, TICKET_GROUP_MAP[NO_SALE_TYPE], name, product);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};


export const createOtherSalePriceTable = async (company, name, priceTableName) => {
  showSpinnerOverlay();
  try {
    const { data: product } = await getClient().post('api/pricing/otherSaleTicket', {
      company,
      name,
      priceTableName,
    });

    updateDictionaryDataWithProduct(company, TICKET_GROUP_MAP[OTHER_SALE_TYPE], name, product);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const createSpecialPricing = async (company, name, type, stations, distances) => {
  showSpinnerOverlay();
  try {
    const { data } = await getClient().post('api/pricing/specialPricing', {
      company,
      name,
      stations,
      type,
      distances,
    });
    updateDictionaryDataWithProduct(company, 'specialPricings', name, data);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const createLineBasicPriceTable = async (company, priceTableName) => {
  showSpinnerOverlay();
  try {
    const { data: product } = await getClient().post('api/pricing/basicTicket', {
      company,
      name: 'Aikuinen',
      priceTableName,
    });
    updateNestedDataWithProduct(product, 'lineBasicTickets', priceTableName);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const createZonePriceTable = async (company, name, priceTable, lines) => {
  showSpinnerOverlay();
  try {
    const { data: result } = await getClient().post('api/pricing/zonePriceTable', {
      company,
      name,
      priceTable,
      lines,
    });
    updateDictionaryDataWithProduct(company, 'zoneTickets', name, result);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const createZoneTicket = async (company, zoneName, ticketName) => {
  showSpinnerOverlay();
  try {
    const { data: result } = await getClient().post('api/pricing/zoneTicket', {
      company,
      zoneName,
      name: ticketName,
    });
    updateNestedDataWithProduct(result, 'zoneTickets', zoneName);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const copyBasicPriceTable = async (
  sourceCompany,
  destinationCompany,
  sourcePriceTableName,
  destinationPriceTableName,
  makeDefault,
  copyLinesAndDepartures,
) => {
  showSpinnerOverlay();
  try {
    const { data: result } = await getClient().post(
      `api/pricing/basicTicket/copy/${destinationCompany}/${sourceCompany}`,
      {
        sourcePriceTableName,
        destinationPriceTableName,
        makeDefault,
        copyLinesAndDepartures,
      },
    );
    updateBasicTicketsWithTable(
      destinationCompany,
      result,
      destinationPriceTableName,
      makeDefault,
    );
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const copyNoSalePriceTable = async (sourceCompany,
  destinationCompany,
  ticketIdentifier,
  destinationPriceTableName,
  makeDefault,
  copyLinesAndDeparturesSelection) => {
    return copyPriceTable(sourceCompany, destinationCompany, ticketIdentifier, destinationPriceTableName, NO_SALE_TYPE, makeDefault, copyLinesAndDeparturesSelection);
};

export const copyOtherSalePriceTable = async (sourceCompany,
  destinationCompany,
  ticketIdentifier,
  destinationPriceTableName,
  makeDefault,
  copyLinesAndDeparturesSelection,
  ) => {
    return copyPriceTable(sourceCompany, destinationCompany, ticketIdentifier, destinationPriceTableName, OTHER_SALE_TYPE, makeDefault, copyLinesAndDeparturesSelection);
};

const copyPriceTable = async (
  sourceCompany,
  destinationCompany,
  ticketIdentifier,
  destinationPriceTableName,
  type,
  makeDefault,
  copyLinesAndDeparturesSelection,
) => {
  showSpinnerOverlay();
  try {
    const { data: result } = await getClient().post(
      `api/pricing/priceTable/copy/${type}/${destinationCompany}/${sourceCompany}`,
      {
        ticketIdentifier,
        destinationPriceTableName,
        makeDefault,
        copyLinesAndDeparturesSelection,
      },
    );

    updateDictionaryDataWithProduct(destinationCompany, TICKET_GROUP_MAP[type], destinationPriceTableName, result);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};



export const copySpecialPricing = async (
  company,
  sourcePricingName,
  destinationPricingName,
  copyLinesAndDeparturesSelection,
) => {
  showSpinnerOverlay();
  try {
    const { data: product } = await getClient().post(
      `api/pricing/specialPricing/copy/${company}/${destinationPricingName}/${sourcePricingName}`, { copyLinesAndDeparturesSelection }
    );
    updateDictionaryDataWithProduct(
      company,
      'specialPricings',
      destinationPricingName,
      product,
    );
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    throw error;
  } finally {
    dismissSpinnerOverlay();
  }
};

export const addBasicTicket = async (
  company,
  name,
  discount,
  priceTableName,
  defaultTable,
) => {
  showSpinnerOverlay();
  try {
    const { data: product } = await getClient().post('api/pricing/basicTicket', {
      company,
      name,
      discount,
      priceTableName,
      defaultTable,
    });
    if (defaultTable) {
      updateDataWithProduct(product, 'basicTickets');
    } else {
      updateNestedDataWithProduct(product, 'lineBasicTickets', priceTableName);
    }
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const addBasicTicketDistance = async (
  company,
  distance,
  price,
  priceTableName,
) => {
  try {
    appendBasicTicketDistance(company, distance, price, priceTableName);
    await getClient().post('api/pricing/basicTicketDistance', {
      company,
      distance,
      price,
      priceTableName,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const addBasicTicketLine = async (
  company,
  priceTableName,
  line,
  departure,
  name,
) => {
  const entryId = uuid();
  try {
    updateNestedTicketLines(
      company,
      'lineBasicTickets',
      priceTableName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().post('api/pricing/basicTicketLine', {
      company,
      priceTableName,
      line,
      departure,
      name,
      entryId,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const addNoSaleLine = async(company, priceTableName, line, departure, name) => {
  const entryId = uuid();
  try {
    updateDictionaryTicketLines(
      company,
      TICKET_GROUP_MAP[NO_SALE_TYPE],
      priceTableName,
      entryId,
      line,
      departure,
      name,
    );

    await getClient().post('api/pricing/priceTableLine', {
      company,
      type: NO_SALE_TYPE,
      priceTableName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const addOtherSaleLine = async(company, priceTableName, line, departure, name) => {
  const entryId = uuid();
  try {
    updateDictionaryTicketLines(
      company,
      TICKET_GROUP_MAP[OTHER_SALE_TYPE],
      priceTableName,
      entryId,
      line,
      departure,
      name,
    );

    await getClient().post('api/pricing/priceTableLine', {
      company,
      type: OTHER_SALE_TYPE,
      priceTableName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const addSpecialPricingLine = async (
  company,
  pricingName,
  line,
  departure,
  name,
) => {
  const entryId = uuid();
  try {
    updateDictionaryTicketLines(
      company,
      'specialPricings',
      pricingName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().post('api/pricing/specialPricingLine', {
      company,
      pricingName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const addZoneTicketLine = async (company, zoneName, line, departure, name) => {
  const entryId = uuid();
  try {
    updateNestedTicketLines(
      company,
      'zoneTickets',
      zoneName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().post('api/pricing/zoneTicketLine', {
      company,
      zoneName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const addZoneTicketPriceZone = async (
  company,
  zoneName,
  priceZoneName,
  duration,
  prices,
) => {
  try {
    appendZoneTicketPriceZone(company, zoneName, priceZoneName, duration, prices);
    await getClient().post('api/pricing/zoneTicketPriceZone', {
      company,
      zoneName,
      priceZoneName,
      duration,
      prices,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyBasicTicket = async (
  company,
  ticket,
  name,
  discount,
  priceTableName,
) => {
  try {
    updateBasicTicket(company, ticket, name, discount, priceTableName);
    await getClient().patch('api/pricing/basicTicket', {
      company,
      ticket,
      name,
      discount,
      priceTableName,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyBasicTicketPrice = async (
  company,
  ticket,
  distance,
  price,
  priceTableName,
) => {
  try {
    updateBasicTicketPrice(company, ticket, distance, price, priceTableName);
    await getClient().patch('api/pricing/basicTicketPrice', {
      company,
      ticket,
      distance,
      price,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyBasicTicketDistance = async (
  company,
  oldDistance,
  newDistance,
  price,
  priceTableName,
) => {
  try {
    updateBasicTicketDistance(company, oldDistance, newDistance, price, priceTableName);
    await getClient().patch('api/pricing/basicTicketDistance', {
      company,
      oldDistance,
      newDistance,
      price,
      priceTableName,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyBasicTicketLine = async (
  company,
  priceTableName,
  entryId,
  line,
  departure,
  name,
) => {
  try {
    updateNestedTicketLines(
      company,
      'lineBasicTickets',
      priceTableName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().patch('api/pricing/basicTicketLine', {
      company,
      priceTableName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifySpecialPricing = async (company, pricingName, row, column, value) => {
  try {
    updateSpecialPricing(company, pricingName, row, column, value);
    await getClient().patch('api/pricing/specialPricing', {
      company,
      pricingName,
      row,
      column,
      value,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifySpecialPricingLine = async (
  company,
  pricingName,
  entryId,
  line,
  departure,
  name,
) => {
  try {
    updateDictionaryTicketLines(
      company,
      'specialPricings',
      pricingName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().patch('api/pricing/specialPricingLine', {
      company,
      pricingName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const makeNoSaleDefault = async (company, priceTable) => {
  return makePriceTableDefault(company, NO_SALE_TYPE, priceTable);
};

export const makeOtherSaleDefault = async (company, priceTable) => {
  return makePriceTableDefault(company, OTHER_SALE_TYPE, priceTable);
};

const makePriceTableDefault = async(company, type, priceTableName) => {
  showSpinnerOverlay();
  try {
    await getClient().post('api/pricing/priceTable/default', {
      company,
      type,
      priceTableName,
    });
    await updatePricingData(company);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const modifyNoSaleTableLine = async (  company,
  pricingName,
  entryId,
  line,
  departure,
  name) => {
    return modifyPriceTableLine(company,
      NO_SALE_TYPE,
      pricingName,
      entryId,
      line,
      departure,
      name);
  };


export const modifyOtherSaleTableLine = async (  company,
    pricingName,
    entryId,
    line,
    departure,
    name) => {
      return modifyPriceTableLine(company,
        OTHER_SALE_TYPE,
        pricingName,
        entryId,
        line,
        departure,
        name);
    };
  

export const modifyPriceTableLine = async (
  company,
  type,
  pricingName,
  entryId,
  line,
  departure,
  name,
) => {
  try {
    updateDictionaryTicketLines(
      company,
      TICKET_GROUP_MAP[type],
      pricingName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().patch('api/pricing/priceTableLine', {
      company,
      type,
      pricingName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyZoneTicketName = async (
  company,
  zoneName,
  ticketIdentifier,
  ticketName,
) => {
  try {
    updateZoneTicketName(company, zoneName, ticketIdentifier, ticketName);
    await getClient().patch('api/pricing/zoneTicket', {
      company,
      ticket: ticketIdentifier,
      name: ticketName,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyZoneTicketPrice = async (
  company,
  ticket,
  priceZone,
  duration,
  newPrice,
) => {
  try {
    const updatedTicket = {
      ...ticket,
      zonePrices: [...ticket.zonePrices],
    };
    const updatePriceIndex = updatedTicket.zonePrices.findIndex(
      entry => entry.zone === priceZone && entry.duration === duration,
    );
    updatedTicket.zonePrices[updatePriceIndex] = {
      ...updatedTicket.zonePrices[updatePriceIndex],
    };
    updatedTicket.zonePrices[updatePriceIndex].price = newPrice;
    updateZoneTicketPrice(company, updatedTicket);
    await getClient().patch('api/pricing/zoneTicketPrice', {
      company,
      ticket: ticket.ticketIdentifier,
      priceZone,
      duration,
      newPrice,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyZoneTicketPriceZoneName = async (
  company,
  zoneName,
  oldName,
  newName,
  duration,
) => {
  try {
    updateZoneTicketPriceZoneName(company, zoneName, oldName, newName, duration);
    await getClient().patch('api/pricing/zoneTicketPriceZoneName', {
      company,
      zoneName,
      oldName,
      newName,
      duration,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyZoneTicketDuration = async (
  company,
  zoneName,
  priceZoneName,
  oldDuration,
  newDuration,
) => {
  try {
    updateZoneTicketDistance(company, zoneName, priceZoneName, oldDuration, newDuration);
    await getClient().patch('api/pricing/zoneTicketDuration', {
      company,
      zoneName,
      priceZoneName,
      oldDuration,
      newDuration,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const modifyZoneTicketLine = async (
  company,
  zoneName,
  entryId,
  line,
  departure,
  name,
) => {
  try {
    updateNestedTicketLines(
      company,
      'zoneTickets',
      zoneName,
      entryId,
      line,
      departure,
      name,
    );
    await getClient().patch('api/pricing/zoneTicketLine', {
      company,
      zoneName,
      entryId,
      line,
      departure,
      name,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const removeBasicTicketDistance = async (company, distance, priceTableName) => {
  try {
    showSpinnerOverlay();
    await getClient().delete(
      `api/pricing/basicTicketDistance/${company}/${distance}/${priceTableName || ''}`,
    );
    eraseBasicTicketDistance(company, distance, priceTableName);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const removeBasicTicketLine = async (company, priceTableName, entryId) => {
  try {
    showSpinnerOverlay();
    await getClient().delete(
      `api/pricing/basicTicketLine/?${QueryString.stringify({
        company,
        priceTableName,
        entryId,
      })}`,
    );
    deleteNestedTicketLine(company, 'lineBasicTickets', priceTableName, entryId);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const removeBasicTicketPriceTable = async (company, priceTableName) => {
  try {
    deleteBasicPriceTable(company, priceTableName);
    await getClient().delete(
      `api/pricing/basicTicket/${company}/${priceTableName || ''}`,
    );
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    console.log(error);
  }
};

export const removeSpecialPricing = async (company, pricingName) => {
  try {
    deleteDictionaryEntry(company, 'specialPricings', pricingName);
    await getClient().delete(`api/pricing/specialPricing/${company}/${pricingName}`);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const removeNoSaleLine = async (company, pricingName, entryId) => {
  return removePriceTableLine(company, NO_SALE_TYPE, pricingName, entryId);
};

export const removeOtherSaleLine = async (company, pricingName, entryId) => {
  return removePriceTableLine(company, OTHER_SALE_TYPE, pricingName, entryId);
};

const removePriceTableLine = async (company, type, pricingName, entryId) => {
  try {
    showSpinnerOverlay();
    await getClient().delete(
      `api/pricing/priceTableLine/?${QueryString.stringify({
        company,
        type,
        pricingName,
        entryId,
      })}`,
    );
    deleteDictionaryTicketLine(company, TICKET_GROUP_MAP[type], pricingName, entryId);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }  
};

export const removeSpecialPricingLine = async (company, pricingName, entryId) => {
  try {
    showSpinnerOverlay();
    await getClient().delete(
      `api/pricing/specialPricingLine/?${QueryString.stringify({
        company,
        pricingName,
        entryId,
      })}`,
    );
    deleteDictionaryTicketLine(company, 'specialPricings', pricingName, entryId);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const removeZoneTicketPriceZone = async (
  company,
  zoneName,
  priceZoneName,
  duration,
) => {
  try {
    showSpinnerOverlay();
    await getClient().delete(
      `api/pricing/zoneTicketPriceZone/${company}/${zoneName}/${priceZoneName}/${duration}`,
    );
    eraseZoneTicketPriceZone(company, zoneName, priceZoneName, duration);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const removeZoneTicketLine = async (company, zoneName, entryId) => {
  try {
    showSpinnerOverlay();
    await getClient().delete(
      `api/pricing/zoneTicketLine/?${QueryString.stringify({
        company,
        zoneName,
        entryId,
      })}`,
    );
    deleteNestedTicketLine(company, 'zoneTickets', zoneName, entryId);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const removeZonePriceTable = async (company, zoneName) => {
  showSpinnerOverlay();
  try {
    await getClient().delete(`api/pricing/zonePriceTable/${company}/${zoneName}`);
    deleteZonePriceTable(company, zoneName);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

export const addOtherTicket = async (company, name, price, priceTable) => {
  showSpinnerOverlay();
  try {
    addPriceTableItem(company, OTHER_SALE_TYPE, priceTable, { name, fixedPrice: price, [OVERWRITE_DEFAULT_ITEMS]: OTHER_SALE_TICKETS });
  } catch (error) {
    showNotification(error.message);
  } finally {
    dismissSpinnerOverlay();
  }
};

export const addOtherProduct = async (company, name, price, vat, priceTable) => {
  showSpinnerOverlay();
  try {
    addPriceTableItem(company, OTHER_SALE_TYPE, priceTable, { vat, name, fixedPrice: price, [OVERWRITE_DEFAULT_ITEMS]: OTHER_SALE_PRODUCTS });
  } catch (error) {
    showNotification(error.message);
  } finally {
    dismissSpinnerOverlay();
  }
};

export const addNoSaleDescription = async (company, description, price, priceTable) => {
  showSpinnerOverlay();
  try {
    addPriceTableItem(company, NO_SALE_TYPE, priceTable, { name: description, fixedPrice: price });
  } catch (error) {
    showNotification(error.message);
  } finally {
    dismissSpinnerOverlay();
  }
};


export const replaceSpecialPricing = async (company, name, stations, distances) => {
  try {
    const currentPrices = get(prices);
    const oldEntry =
      currentPrices[company].specialPricings &&
      currentPrices[company].specialPricings[name];
    const newEntry = { ...(oldEntry || {}) };
    newEntry.stations = stations;
    newEntry.specialDistances = distances;
    updateDictionaryDataWithProduct(company, 'specialPricings', name, newEntry);

    await getClient().put('api/pricing/specialPricing', {
      company,
      name,
      stations,
      type: newEntry.specialPricingType,
      distances,
      lines: newEntry.lines,
      lineNames: newEntry.lineNames,
      lineIds: newEntry.lineIds,
    });
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  }
};

export const makeBasicPricingDefault = async (company, priceTableName) => {
  showSpinnerOverlay();
  try {
    await getClient().post('api/pricing/basicTicket/default', {
      company,
      priceTableName,
    });
    await updatePricingData(company);
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
  } finally {
    dismissSpinnerOverlay();
  }
};

// regenerate all indexes each time
const updateIndex = (products, productIdentifier, oldIndex, newIndex) => {
  const newProducts = [...products]
    .filter(product => product.ticketIdentifier !== productIdentifier)
    .sort((a, b) => a.index - b.index);
  const movedProduct = products[oldIndex];
  movedProduct.index = newIndex;

  newProducts.forEach((item, index) => {
    item.index = index;
    if (index >= newIndex) {
      ++item.index;
    }
  });

  newProducts.push(movedProduct);

  return newProducts.sort((a, b) => a.index - b.index);
};

const postIndexUpdate = async (company, product, oldIndex, newIndex, originalIndex) => {
  try {
    await getClient().post('api/pricing/move', {
      company,
      product,
      oldIndex,
      newIndex,
      originalIndex,
    });
  } catch (error) {
    if (error && error.response && error.response.status === 409) {
      const i18n = get(_);
      showNotification({
        title: i18n('error.title'),
        body: i18n('pricing.errorPricingModified'),
        type: 'error',
      });
      updatePricingData(company);
    } else {
      showNotification({ title: error.message, body: '', type: 'error' });
    }
  }
};

export const updateNestedProductIndex = async (
  company,
  product,
  productMainType,
  productSubType,
  oldIndex,
  newIndex,
  originalIndex,
) => {
  if (oldIndex !== newIndex) {
    prices.update(current => {
      const newProducts = updateIndex(
        current[company][productMainType][productSubType],
        product,
        oldIndex,
        newIndex,
      );
      return {
        ...current,
        [company]: {
          ...current[company],
          [productMainType]: {
            [productSubType]: newProducts,
          },
        },
      };
    });
    postIndexUpdate(company, product, oldIndex, newIndex, originalIndex);
  }
};

export const updateProductIndex = async (
  company,
  product,
  productType,
  oldIndex,
  newIndex,
  originalIndex,
) => {
  if (oldIndex !== newIndex) {
    prices.update(current => {
      const newProducts = updateIndex(
        current[company][productType],
        product,
        oldIndex,
        newIndex,
      );
      return {
        ...current,
        [company]: {
          ...current[company],
          [productType]: newProducts,
        },
      };
    });
    postIndexUpdate(company, product, oldIndex, newIndex, originalIndex);
  }
};

const DEFAULT_START = '2000-01-01';
const DEFAULT_END = '9999-01-01';

const itemsOverlapByTime = (item1, item2) => {
  const s1 = moment(item1.validityStart || DEFAULT_START);
  const s2 = moment(item2.validityStart || DEFAULT_START);
  const e1 = moment(item1.validityEnd || DEFAULT_END);
  const e2 = moment(item2.validityEnd || DEFAULT_END);

  return s1.isSameOrBefore(e2, 'days') && e1.isSameOrAfter(s2, 'days');
};

// this could be moved to backend as flow from user selection to saving to backend is immediate
const findOverlapping = (tickets) => {
  const isActive = item => (item && item.validityStart) || (item && item.validityEnd);
  const isReference = item => item.ticketIdentifier.endsWith('_REFERENCE');
  const hasDepartureOrLine = item => item.lines && item.lines.length > 0;
  const getGroupingKey = item => hasDepartureOrLine(item) ? LINE_AND_DEPARTURE_LIMITED : NO_LINE_OR_DEPARTURE_LIMITATION;

  const LINE_AND_DEPARTURE_LIMITED = 'lineAndDepartureLimited';
  const NO_LINE_OR_DEPARTURE_LIMITATION = 'noLineOrDepartureLimitation';

  const groupedTickets = [...tickets]
    .map(item => Array.isArray(item) ? item.find(isReference) || item[0] : item)
    .filter(isActive)
    .sort(ticket => ticket && ticket.validityStart)
    .reduce((acc, item) => {
      acc[getGroupingKey(item)].push(item);
      return acc;
    }, {
      [LINE_AND_DEPARTURE_LIMITED]: [],
      [NO_LINE_OR_DEPARTURE_LIMITATION]: []
    });
  

  const findOverlaps = (sourceItems, item) => {
    const checkOverlap = itemToCheck => itemsOverlapByTime(item, itemToCheck);

    const checkOverlapByLines = itemToCheck => {
      const lines = itemToCheck.lines; 
      const linesToCompare = item.lines; 

      if (lines.length === 0 || linesToCompare.length === 0) {
        return true;
      }

      const DEFAULT_LINE = '0000';
      const ALL_DEPARTURES = '0000';

      const departureAndLineStrToArr = str => {
        const [departure, line] = str.split('-');
        return {
          departure: departure || ALL_DEPARTURES,
          line: line || DEFAULT_LINE,
        };
      };

      const lineAndDepartureFound = ({ departure, line }) => {
        const matchingLines = lines
          .map(departureAndLineStrToArr)
          .filter(item => item.line === line);

        if (departure === ALL_DEPARTURES && matchingLines.length > 0) {
          return true;
        }

        return (
          matchingLines.filter(
            item => item.departure === ALL_DEPARTURES || item.departure === departure,
          ).length > 0
        );
      };

      return (
        linesToCompare.filter(i => lineAndDepartureFound(departureAndLineStrToArr(i)))
          .length > 0
      );
    };

    return {
      sourceItem: item,
      overlappingItems: sourceItems
        .filter(filterItem => filterItem.ticketIdentifier !== item.ticketIdentifier)
        .filter(checkOverlap)
        .filter(checkOverlapByLines),
    };
  };

  
  const findOverlapsAndConcatResults = (acc, tickets) => acc.concat(tickets.map(item => findOverlaps(tickets, item)).filter(item => item.overlappingItems.length > 0));
  return [groupedTickets[NO_LINE_OR_DEPARTURE_LIMITATION], groupedTickets[LINE_AND_DEPARTURE_LIMITED]].reduce(findOverlapsAndConcatResults, []);
};
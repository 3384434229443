// We can use anything as a key — we could do setContext('mapbox', ...) for
// example. The downside of using a string is that different component
// libraries might accidentally use the same one; using an object literal
// means the keys are guaranteed not to conflict in any circumstance (since
// an object only has referential equality to itself, i.e. {} !== {} whereas
// "x" === "x"), even when you have multiple different contexts operating
// across many component layers.

const formKey = {};
export { formKey };


<script>
    import moment from 'moment-timezone';
    import { _ } from 'svelte-i18n';

    export let overlappingItems;
    export let nameProperty;

    const FORMAT = 'DD.MM.YY';
    const itemToDateRange = (item) => {
        const start = item.validityStart && moment(item.validityStart).format(FORMAT) || $_('pricing.overlaps.notDefined');
        const end = item.validityEnd && moment(item.validityEnd).format(FORMAT) || $_('pricing.overlaps.notDefined');

        return `${start} - ${end}`;
    };
    
    $: items = overlappingItems && overlappingItems.map(item => {
        return {
            date: itemToDateRange(item.sourceItem),
            name: item.sourceItem[nameProperty],
        };
    });
  </script>
  
  <style>
    .overlappingErrors {
      color: red;
      padding: 1em;
      margin-bottom: 0.5em;
      border: solid 1px red;
      display: inline-block;
      border-radius: 0.3em;
    }

    .overlappingErrors div {
        padding-top: 0.5em;
    }

    .overlappingErrors span {
        margin-left: 0.5em;
    }

    span.date {
        min-width: 12em;
        display: inline-block;
    }
  </style>
  
  <div class="overlappingErrors">
    {$_('pricing.overlaps.title')}
    <div>
    {#each items as item}
        <span class="overlappingError">
            <span class="date">{item.date}</span><span>{item.name}</span>
        </span>
        <br />
    {/each} 
    </div>
  </div>
  
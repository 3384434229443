import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import { currentUser } from './user';

export const companiesLoaded = writable(false);
export const companiesLoading = writable(false);
export const companies = writable([]);

currentUser.subscribe(user => {
  if (!user || !user.loggedIn) {
    companies.set([]);
    companiesLoaded.set(false);
    companiesLoading.set(false);
  }
});

export async function getCompanies() {
  companiesLoaded.set(false);
  companiesLoading.set(true);
  companies.set([]);
  const res = await getClient().get('/api/companies');
  if (res.status === 200) {
    companiesLoaded.set(true);
    companiesLoading.set(false);
    companies.set(res.data);
  } else {
    companiesLoaded.set(false);
    companiesLoading.set(false);
    companies.set([]);
  }
}

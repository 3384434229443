<script>
  import { _, date } from 'svelte-i18n';
  import debounce from 'debounce';
  import { fly } from 'svelte/transition';
  import Icon from 'fa-svelte';
  import Form from '../../components/Form.svelte';
  import { faCalendar } from '@fortawesome/free-solid-svg-icons';
  import DatePicker from '../../components/DatePicker.svelte';
  import Checkbox from '../../components/Checkbox.svelte';
  import { charterLineCreateState, getNthStation } from '../../stores/lines';
  import AutoCompleteInputField from '../../components/AutoCompleteInputField.svelte';
  import InputField from '../../components/InputField.svelte';

  export let isValid = false;
  export let direction;

  let formValid;

  $: {
    isValid = formValid && $charterLineCreateState.destinationStreetAddress && $charterLineCreateState.departureStreetAddress && $charterLineCreateState.stops[0] && $charterLineCreateState.stops[1];
  }

  const ADDRESS_SEARCH_DELAY_MS = 500;

  let loadingDepartureAddress = false;
  let loadingDestinationAddress = false;
  let departureAddressResults = [];
  let destinationAddressResults = [];
  let departureAddressError = '';
  let destinationAddressError = '';

  const platform = new H.service.Platform({
    apikey: process.env.HERE_API_KEY,
  });
  const addressSearch = platform.getSearchService();

  const formatHereResultAddress = (result) => {
    return `${result.address.street}${result.address.houseNumber ? ` ${result.address.houseNumber}` : ''}, ${result.address.city}`;
  };

  const queryAddress = (input, onSuccess, onError) => {
    addressSearch.geocode(
      {
        q: input,
        in: 'countryCode:FIN',
        lang: 'fi',
      }, (result) => {
        const items = (result.items || []).filter((x) => x.address && x.address.street && x.position); // Don't allow just a city as an address
        onSuccess(items);
      }, onError);
  };

  const queryAddressDebounced = debounce(queryAddress, ADDRESS_SEARCH_DELAY_MS);

</script>

<style>
  label {
    font-size: 1em;
    margin-bottom: 0.25rem;
    display: block;
  }
  .input-div {
    margin-bottom: 1.5rem;
  }
  .input-container > :global(.input-field-main-div) {
    margin-bottom: 1.5rem;
    max-width: 640px;
  }
  .input {
    width: 100%;
    padding: 0.4rem;
    height: unset;
    font-size: 1rem;
    margin: 0;
    max-width: 640px;
  }
  div.input-container {
    flex-grow: 1;
    flex-basis: 12rem;
  }
  .input-main-container {
    max-width: 800px;
  }
  .picker > :global(.datepicker) {
    width: 100%;
  }
  .checkbox > :global(label) > :global(.label) {
    margin-left: 0;
    margin-right: 1rem;
    font-size: 1rem;
  }
</style>

<div class="input-main-container flex flex-col lg:flex-row flex-grow"
  in:fly="{{ x: direction * -250, duration: 250, delay: 300 }}"
  out:fly="{{ x: direction * 250, duration: 250 }}">
  <Form bind:isValid={formValid}>
  <div class="input-container lg:mr-4">
    <div class="input-div picker">
      <label for="end">{$_('lines.charter.departureDate')}: *</label>
      <DatePicker bind:selected={$charterLineCreateState.departureDate} start={new Date()}>
        <div class="input flex justify-between cursor-pointer
          items-center" placeholder={$_('lines.charter.departureDate')}>
          <span>{$date($charterLineCreateState.departureDate, { format: 'twoDigitsFullYear' })}</span>
          <Icon icon={faCalendar} />
        </div>
      </DatePicker>
    </div>
    <InputField
      id="departureTime"
      type="time"
      label={$_('lines.charter.departureTime')}
      placeholder={$_('lines.charter.departureTimePlaceholder')}
      bind:value={$charterLineCreateState.departureTime}
      errorMessage={$_('lines.charter.inputErrorTime')}
      required
    />
    <InputField
      id="departureLocation"
      type="text"
      label={$_('lines.charter.departureLocation')}
      placeholder={$_('lines.charter.departureLocationPlaceholder')}
      bind:value={$charterLineCreateState.departureLocation}
      errorMessage={$_('lines.charter.inputErrorRequired')}
      required
    />
    <AutoCompleteInputField
      label={$_('lines.charter.departureAddress')}
      id="departureAddress"
      required
      placeholder={$_('lines.charter.departureAddressPlaceholder')}
      loading={loadingDepartureAddress}
      inputValue={$charterLineCreateState.departureAddressInput || ''}
      options={departureAddressResults.map(result => ({ value: result.id, name: formatHereResultAddress(result) }))}
      errorMessage={departureAddressError}
      onChange={(event) => {
        const input = event.target.value;
        departureAddressError = '';
        $charterLineCreateState.departureMunicipality = '';
        $charterLineCreateState.departureStreetAddress = '';
        $charterLineCreateState.departureAddressInput = input;
        if (input.length >= 2) {
          loadingDepartureAddress = true;
          queryAddressDebounced(
            input,
            (results) => {
              departureAddressResults = results;
              if (!results.length) {
                departureAddressError = $_('lines.charter.inputErrorNoAddressResults');
              }
              loadingDepartureAddress = false;
            },
            (error) => {
              loadingDepartureAddress = false;
              departureAddressError = $_('lines.charter.inputErrorAddressSearchFailed');
              console.error(error);
            }
          );
        }
      }}
      onSelection={(id) => {
        const entry = departureAddressResults.find((entry) => entry.id === id);
        if (entry) {
          departureAddressError = '';
          $charterLineCreateState.departureLongitude = entry.position.lng;
          $charterLineCreateState.departureLatitude = entry.position.lat;
          $charterLineCreateState.departureAddressInput = formatHereResultAddress(entry);
          $charterLineCreateState.departureMunicipality = entry.address.city;
          $charterLineCreateState.departureStreetAddress = `${entry.address.street}${entry.address.houseNumber ? ` ${entry.address.houseNumber}` : ''}`;
          getNthStation(entry.position, 0);
        }
      }}
      onBlur={() => {
        if (!$charterLineCreateState.departureAddressInput || !$charterLineCreateState.departureAddressInput.length) {
          departureAddressError = $_('lines.charter.inputErrorRequired');
        } else if (!$charterLineCreateState.departureMunicipality || ! $charterLineCreateState.departureStreetAddress) {
          departureAddressError = $_('lines.charter.inputErrorChooseAddress');
        }
      }} />
    <InputField
      id="destinationLocation"
      type="text"
      label={$_('lines.charter.destinationLocation')}
      placeholder={$_('lines.charter.destinationLocationPlaceholder')}
      bind:value={$charterLineCreateState.destinationLocation}
      errorMessage={$_('lines.charter.inputErrorRequired')}
      required
    />
    <AutoCompleteInputField
      label={$_('lines.charter.destinationAddress')}
      id="destinationAddress"
      required
      placeholder={$_('lines.charter.destinationAddressPlaceholder')}
      loading={loadingDestinationAddress}
      inputValue={$charterLineCreateState.destinationAddressInput || ''}
      options={destinationAddressResults.map(result => ({ value: result.id, name: formatHereResultAddress(result) }))}
      errorMessage={destinationAddressError}
      onChange={(event) => {
        const input = event.target.value;
        destinationAddressError = '';
        $charterLineCreateState.destinationMunicipality = '';
        $charterLineCreateState.destinationStreetAddress = '';
        $charterLineCreateState.destinationAddressInput = input;
        if (input.length >= 2) {
          loadingDestinationAddress = true;
          queryAddressDebounced(
            input,
            (results) => {
              destinationAddressResults = results;
              if (!results.length) {
                destinationAddressError = $_('lines.charter.inputErrorNoAddressResults');
              }
              loadingDestinationAddress = false;
            },
            (error) => {
              loadingDestinationAddress = false;
              destinationAddressError = $_('lines.charter.inputErrorAddressSearchFailed');
              console.error(error);
            }
          );
        }
      }}
      onSelection={(id) => {
        const entry = destinationAddressResults.find((entry) => entry.id === id);
        if (entry) {
          destinationAddressError = '';
          $charterLineCreateState.destinationLongitude = entry.position.lng;
          $charterLineCreateState.destinationLatitude = entry.position.lat;
          $charterLineCreateState.destinationAddressInput = formatHereResultAddress(entry);
          $charterLineCreateState.destinationMunicipality = entry.address.city;
          $charterLineCreateState.destinationStreetAddress = `${entry.address.street}${entry.address.houseNumber ? ` ${entry.address.houseNumber}` : ''}`;
          getNthStation(entry.position, 1);
        }
      }}
      onBlur={() => {
        if (!$charterLineCreateState.destinationAddressInput || !$charterLineCreateState.destinationAddressInput.length) {
          destinationAddressError = $_('lines.charter.inputErrorRequired');
        } else if (!$charterLineCreateState.departureMunicipality || ! $charterLineCreateState.destinationStreetAddress) {
          destinationAddressError = $_('lines.charter.inputErrorChooseAddress');
        }
      }} />
  </div>
  <div class="hidden lg:block flex-grow" />
  <div class="input-container lg:ml-4">
    <InputField
      id="customer"
      type="text"
      label={$_('lines.charter.customer')}
      placeholder={$_('lines.charter.customerPlaceholder')}
      bind:value={$charterLineCreateState.customer}
      errorMessage={$_('lines.charter.inputErrorRequired')}
      required
    />
    <InputField
      id="contactPersonInfo"
      label={$_('lines.charter.contactPersonInfo')}
      type="textarea"
      placeholder={$_('lines.charter.contactPersonInfoPlaceholder')}
      rows="3"
      bind:value={$charterLineCreateState.contactPersonInfo}
    />
    <InputField
      id="additionalInfo"
      label={$_('lines.charter.additionalInfo')}
      type="textarea"
      placeholder={$_('lines.charter.additionalInfoPlaceholder')}
      rows="3"
      bind:value={$charterLineCreateState.additionalInfo}
    />
    <InputField
      id="passengerCount"
      label={$_('lines.charter.passengerCount')}
      type="number"
      required
      bind:value={$charterLineCreateState.passengerCount}
      errorMessage={$_('lines.charter.inputErrorRequired')}
    />
    <div class="input-div checkbox">
      <Checkbox size="32px" markColor={'#001E61'} noReverse={true} bind:checked={$charterLineCreateState.roundTrip} label={$_('lines.charter.roundTrip')} />
    </div>
  </div>
</Form>
</div>

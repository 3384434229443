import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import moment from 'moment-timezone';
import axios from 'axios';

export const messagesLoaded = writable(false);
export const messagesLoading = writable(false);
export const messages = writable([]);

export function convertMessageFromAPI(message) {
  return {
    ...message,
    start: moment.tz(message.start, 'Europe/Helsinki'),
    end: message.end ? moment.tz(message.end, 'Europe/Helsinki') : null,
    lines: message.lines
      ? message.lines.map(line => {
          const parts = line.split('_');
          return { line: parts[0] || '', departure: parts[1] || '' };
        })
      : null,
  };
}

export async function getMessages() {
  messagesLoaded.set(false);
  messagesLoading.set(true);
  messages.set([]);
  const res = await getClient().get('/api/messages');
  if (res.status === 200) {
    messagesLoaded.set(true);
    messagesLoading.set(false);
    messages.set(res.data.map(convertMessageFromAPI));
  } else {
    messagesLoaded.set(false);
    messagesLoading.set(false);
    messages.set([]);
  }
}

export async function saveMessage(message) {
  return getClient().post('/api/message', message);
}

export async function setMessageImage(id, file) {
  const upload = await getClient()
    .post(`/api/message/image/${id}/upload`, { type: file.type })
    .then(({ data }) => data);
  await axios.put(upload, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  await getClient().post(`/api/message/image/${id}/refresh`);
}

export async function getMessageImage(id) {
  return getClient()
    .get(`/api/message/image/${id}`)
    .then(({ data }) => data);
}

export async function deleteMessage(id) {
  return getClient().delete(`/api/message/${id}`);
}

import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import { showSpinnerOverlay, dismissSpinnerOverlay } from './spinner';
import { showNotification } from './notifications';

const kilometersInternal = writable({});
export const kilometers = {
  subscribe: kilometersInternal.subscribe
};
// "4805_1615","4803_0820"
//const result = {"company":"86","lines":[],"type":"specific"};

const fixSeriesData = (data) => {
  data.lines = data.lines.filter(lineEntry => lineEntry.departure || lineEntry.line).map(lineEntry => `${(lineEntry.line || '').padStart(4, '0')}_${(lineEntry.departure || '').padStart(4, '0')}`);
  return data;
};

export const updateKilometers = async (company, type, lines) => {
  showSpinnerOverlay();
  try {
    const seriesData = fixSeriesData({ company, type, lines });
    await getClient().put('api/kilometers', seriesData);
    await getKilometers(company);
    return true;
  } catch (error) {
    showNotification({ title: error.message, body: '', type: 'error' });
    return false;
  } finally {
    dismissSpinnerOverlay();
  }
};

export const getKilometers = async (company) => {
  const { data: result } = await getClient().get(`api/kilometers/${company}`);

  kilometersInternal.update(current => ({
    ...current,
    [company]: {
      ...result,
      type: result.kmType || '',
      lines: (result.lines || []).map(lines => {
        const [line, departure] = lines.split('_');
        return {
          line: line === '0000' ? '' : line,
          departure
        };
      })
    }
  }));
};


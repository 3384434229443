<script>
  import Big from 'big.js';
  import { onMount, tick } from 'svelte';
  import { number, _ } from 'svelte-i18n';
  import { TRAVELCARD, MULTIPLE, CARD, CASH } from '../../stores/search';
  import Checkbox from '../Checkbox.svelte';
  import NewRow from './NewRow.svelte';

  export let results = [];
  export let allCheckboxChecked = false;
  export let checkedRowIds = [];
  export let currentCompanyRowFilter = false;
  export let firstWidth;
  export let secondWidth;

  let priceElement;
  let e1, e6, e8;

  const getExplanationWidth = (productElement, priceElement) => {
    if (!productElement || !priceElement) return 0;
    const productRect = productElement.getBoundingClientRect();
    const priceRect = priceElement.getBoundingClientRect();
    return priceRect.right - productRect.left;
  };

  $: explanationWidth = getExplanationWidth(e8, priceElement);

  const updateDimensions = () => {
    let r1 = e1 && e1.getBoundingClientRect();
    let r6 = e6 && e6.getBoundingClientRect();
    let r8 = e8 && e8.getBoundingClientRect();

    firstWidth = r6.right - r1.left;
    secondWidth = r8.right - r1.left;
  };

  const delayedDimensionUpdate = async () => {
    await tick();
    updateDimensions();
  };

  const getPaymentMethodName = (item, index) => {
    let methodName;
    if (item.payment_method != MULTIPLE) {
      methodName = item.payment_method;
    }
    else if (index == 0) {
      methodName = CASH;
    }
    else if (index == 1) {
      methodName = CARD;
    }
    return `${$_(`payment.methods.${methodName}`, {
              default: methodName,
    })}${item.payment_method == MULTIPLE ? '*' : ''}`;
  };

  const getPaymentMethodSum = (item, index) => {
    let sum = 0.0;
    if (item.payment_method != MULTIPLE) {
      sum = item.sum;
    }
    else if (index == 0) {
      sum = item.cash_amount || 0.0;
    }
    else if (index == 1) {
      sum = item.card_amount || 0.0;
    }

    return $number(
              Big(sum).div(
                ((item.event_type || '').startsWith(TRAVELCARD)
                  ? 1
                  : item.quantity || 1)),
              {
                format: 'EUR',
              },
      ).replace('€', '');
  };

  const getPaymentMethodSumVatFree = (item, index) => {
    let sum = 0.0;
    if (item.payment_method != MULTIPLE) {
      sum = item.sum;
    }
    else if (index == 0) {
      sum = item.cash_amount || 0.0;
    }
    else if (index == 1) {
      sum = item.card_amount || 0.0;
    }


    return $number(
              Big(sum).minus(Big(sum).div(100 + item.vat).mul(item.vat)).div(
                ((item.event_type || '').startsWith(TRAVELCARD)
                  ? 1
                  : item.quantity || 1)),
              {
                format: 'EUR',
              },
      ).replace('€', '');
  };

  $: {
    results;
    delayedDimensionUpdate();
  }

  onMount(() => {
    updateDimensions();
  });
</script>

<style>
  table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  }
  table.results :global(th) {
    text-align: start;
    padding-top: 1em;
    padding-bottom: 1rem;
    padding-right: 2em;
    border-bottom: 1px solid #333333;
    font-weight: 600;
    top: 0;
    position: sticky;
    background-color: white;
    z-index: 1;
  }

  table.results :global(th):last-child,
  table.results :global(td):last-child {
    padding-right: 0;
  }

  table.results :global(td) {
    padding: 0.5em 2em 0.5em 0;
    border-bottom: 1px solid #cccccc;
  }

  table.results > :global(tr:last-child > td) {
    border-bottom: 1px solid #333333;
  }
</style>

<svelte:window on:resize={updateDimensions} />

<table class="results w-full text-mdark mt-6 relative" cellspacing="0">
  <tr>
    <th bind:this={e1}>
      <Checkbox
        bold={true}
        noReverse={true}
        on:change={event => {
          allCheckboxChecked = event.target.checked;
          checkedRowIds = event.target.checked
            ? results.map(x => x.transaction_id || x.id)
            : [];
        }}
        checked={allCheckboxChecked}
      />
    </th>
    <th>{$_('results.date')}</th>
    <th>{$_('results.line')}</th>
    <th>{$_('results.departure')}</th>
    <th>{$_('results.driver')}</th>
    <th bind:this={e6}>{$_('results.device')}</th>
    <th>{$_('results.receiptNumber')}</th>
    <th bind:this={e8}>{$_('results.productName')}</th>
    <th>{$_('results.paymentMethod')}</th>
    <th>{$_('results.vat')}</th>
    <th class="text-right" bind:this={priceElement}>
      {$_('results.vatFreePrice')}
    </th>
    <th class="text-right">{$_('results.priceVAT')}</th>
  </tr>
  {#each results as result, i}
    {#each { length: (result.event_type || '').startsWith(TRAVELCARD) ? 1 : result.quantity || 1 } as _i}
    {#each { length: result.payment_method === MULTIPLE ? 2 : 1 } as _j, j}
      {#if result.isAddedRow}
        <NewRow
          {explanationWidth}
          {result}
          {currentCompanyRowFilter}
          on:change={event => {
            allCheckboxChecked = false;
            checkedRowIds = event.target.checked
              ? [...checkedRowIds, result.id]
              : checkedRowIds.filter(x => x !== result.id);
          }}
          checked={checkedRowIds.includes(result.id)}
        />
      {:else}
        <tr
          class:bg-mlight={currentCompanyRowFilter}
          class:bg-mlight-gray={result.accounted && !currentCompanyRowFilter}
        >
          <td>
            <Checkbox
              noReverse={true}
              on:change={event => {
                allCheckboxChecked = false;
                checkedRowIds = event.target.checked
                  ? [...checkedRowIds, result.transaction_id]
                  : checkedRowIds.filter(x => x !== result.transaction_id);
              }}
              checked={checkedRowIds.includes(result.transaction_id)}
            />
          </td>
          <td class="whitespace-no-wrap">
            {result.date.format('D.M.YYYY H:mm')}
          </td>
          <td>{(result.line_id || '-').split('$')[0]}</td>
          <td>{(result.departure_id || '-').split('$')[0]}</td>
          <td>{result.driver_id || '-'}</td>
          <td>{result.device_id || '-'}</td>
          <td>
            {result.refund && result.original_transaction_id
              ? $_('results.cancelledSale') +
                ((result.refundDate && result.refundDate.format(' D.M.YYYY')) || '')
              : result.receipt_number || '-'}
          </td>
          <td class="text-sm lg:text-base">
            {$_(`results.productNames.${result.product_name}`, {
              default: result.product_name,
            })}{#if result.zone}
              {' - '}{result.zone}
            {/if}
            {#if result.travel_card_id}<br /> {result.travel_card_id}{/if}
          </td>
          <td>
            {getPaymentMethodName(result, j)}
          </td>
          <td>{result.vat}</td>
          <td class="text-right">
            {getPaymentMethodSumVatFree(result, j)}
          </td>
          <td class="text-right">
            {getPaymentMethodSum(result, j)}
          </td>
        </tr>
      {/if}
    {/each}
    {/each}
  {/each}
</table>

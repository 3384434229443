<script>
  import { _, locale } from 'svelte-i18n';
  import Icon from 'fa-svelte';
  import {
    faCaretDown,
    faCaretUp,
    faSearch,
  } from '@fortawesome/free-solid-svg-icons';
  import { getExcelDownloadDevicesList } from '../../common/http';
  import { fetchBasicDevices, devices as deviceList, consortiumIdentifiers } from '../../stores/devices';
  import Loading from '../../components/Loading.svelte';
  import Select from '../../components/Select.svelte';
  import Checkbox from '../../components/Checkbox.svelte';
  import { onMount } from 'svelte';

  export let activeView;
  export let editDeviceId;
  let devicesFetched = false;
  let hideEmpty = true;

  onMount(async () => {
    await fetchBasicDevices();
    devicesFetched = true;
  });

  let sorting = { field: 'deviceId', order: 'asc' };

  let filter = '';
  let consortiumIdentifier;
  const filterDevices = (devices, consortiumIdentifier, filter, hideEmpty) => {
    return devices.filter((device) =>
      (!hideEmpty || (device.features && device.features.length)) &&
      (consortiumIdentifier ? device.consortiumIdentifier == consortiumIdentifier : true) &&
      (device.deviceId.startsWith(filter) || device.consortiumIdentifier.startsWith(filter) || device.companyId.startsWith(filter))
    );
  };

  $: devicesFiltered = filterDevices($deviceList, consortiumIdentifier, filter, hideEmpty);

  $: devicesSorted = sortDevices(devicesFiltered, sorting);

  $: consortiumIdentifierList = [
    { label: $_('devices.allConsortia'), value: null },
    ...$consortiumIdentifiers.map((entry) => ({ label: entry, value: entry }))
  ];

  const sortDevices = (devices, { field }) => {
    const method = ({ [field]: a }, { [field]: b }) => a.localeCompare(b);
    return sorting.order === 'asc'
      ? devices.sort((a, b) => method(a, b))
      : devices.sort((a, b) => method(b, a));
  };

  const sortBy = field => {
    if (sorting.field === field) {
      sorting = {
        field: field,
        order: sorting.order === 'asc' ? 'desc' : 'asc',
      };
    } else {
      sorting = { field, order: 'asc' };
    }
  };

  const createExcel = async () => {
    const [lang] = ($locale || 'fi').split('-');
    const res = await getExcelDownloadDevicesList(
      {
        lang,
      },
    );
    const url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

</script>

<style>
  .content {
    width: 100%;
  }

  h1 {
    display: flex;
    align-items: baseline;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  table {
    width: 100%;
    color: #333333;
    margin-top: 1em;
  }

  th {
    color: #73899f;
    text-align: start;
    font-weight: 100;
    border-bottom: 1px solid #333333;
    padding-bottom: 0.5em;
  }

  td {
    padding: 0.8em 0;
    border-bottom: 1px solid #b8b8b8;
  }

  .edit {
    color: #001E61;
    cursor: pointer;
    text-align: right;
    padding-right: 0.5em;
  }

  .sortable {
    cursor: pointer;
  }

  th > :global(*) {
    color: #73899f;
  }

  .search {
    border-bottom: 1px solid #73899f00;
    transition: border-bottom 500ms ease-in-out;
    font-size: 1rem;
    margin-left: 1rem;
  }

  .search:hover,
  .search.shown {
    border-bottom: 1px solid #73899f;
  }

  .search > :global(*) {
    color: #73899f;
  }

  .search > input {
    width: 0;
    background: none;
    border: none;
    transition: width 500ms ease-in-out;
    margin: 0;
  }

  .select-container {
    width: min-content;
    min-width: 200px;
  }

  .search:hover > input,
  .search.shown > input {
    width: 20em;
  }

  .checkbox-hide > :global(label) {
    margin-bottom: 0;
    font-size: 1rem;
  }
</style>

<div class="content">
  <div class="header">
    <h1 class="my-6 text-3xl font-bold">
      {$_('devices.title')}
      <div class="search" class:shown={filter}>
        <input bind:value={filter} type="text" placeholder={$_('search.fetch')} />
        <Icon icon={faSearch} />
      </div>
    </h1>
    <button on:click={() => {
      editDeviceId = undefined;
      activeView = 'edit';
    }}>{$_('devices.addDevice')}</button>
  </div>
  <div class="flex flex-row items-end">
    <div class="flex flex-col">
      <p>{`${$_('devices.consortiumIdentifier')}:`}</p>
      <div class="select-container">
        <Select
          border={true}
          bind:value={consortiumIdentifier}
          entries={consortiumIdentifierList}
        />
      </div>
    </div>
    <div class="ml-12 checkbox-hide">
      <Checkbox size="39px" markColor={'#001E61'} bind:checked={hideEmpty} label={$_(`devices.hideEmpty`)} />
    </div>
  </div>
  {#if devicesFetched}
    {#if $deviceList && $deviceList.length}
      <table>
        <thead>
          <tr>
            <th class="sortable w-1/4" on:click={() => sortBy('deviceId')}>
              {$_('devices.deviceIdentifier')}
              {#if sorting.field === 'deviceId'}
                {#if sorting.order === 'asc'}
                  <Icon icon={faCaretDown} />
                {:else}
                  <Icon icon={faCaretUp} />
                {/if}
              {/if}
            </th>
            <th class="sortable w-1/4" on:click={() => sortBy('consortiumIdentifier')}>
              {$_('devices.consortiumIdentifier')}
              {#if sorting.field === 'consortiumIdentifier'}
                {#if sorting.order === 'asc'}
                  <Icon icon={faCaretDown} />
                {:else}
                  <Icon icon={faCaretUp} />
                {/if}
              {/if}
            </th>
            <th class="sortable w-1/4" on:click={() => sortBy('companyId')}>
              {$_('devices.companyId')}
              {#if sorting.field === 'companyId'}
                {#if sorting.order === 'asc'}
                  <Icon icon={faCaretDown} />
                {:else}
                  <Icon icon={faCaretUp} />
                {/if}
              {/if}
            </th>
            <th colspan="2">{$_('devices.additionalFeatures')}</th>
          </tr>
        </thead>
        <tbody>
          {#each devicesSorted as device}
            <tr>
              <td>{device.deviceId}</td>
              <td>{device.consortiumIdentifier}</td>
              <td>{device.companyId}</td>
              <td>{device.features.length > 0 ? device.features.map((feature) => $_(`devices.featureSymbols.${feature}`)).sort().join(', ') : '-'}</td>
              <td
                class="edit"
                on:click={() => {
                  editDeviceId = device.deviceId; // eslint-disable-line no-unused-vars
                  activeView = 'edit'; // eslint-disable-line no-unused-vars
                }}
              >
                {$_('devices.edit')}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
      <button class="mt-6" on:click={createExcel}>{$_('results.createExcel')}</button>
    {:else}
    <div class="mt-6">
        {$_('devices.noDevicesAvailable')}
    </div>
    {/if}
  {:else}
    <div>
      <Loading />
    </div>
  {/if}
</div>

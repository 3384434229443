import { writable } from 'svelte/store';
import { hasAnyFeature } from './features';
import { currentUser } from './user';

let _currentTab = localStorage.getItem('currentTab') || 'reports';

export const currentTab = writable(_currentTab);

export function setCurrentTab(tab) {
  _currentTab = tab;
  localStorage.setItem('currentTab', tab);
  currentTab.set(tab);
}

const TABS = {
  reports: 'REPORTS',
  'travel-reports': 'TRAVELREPORTS',
  'pricing-maintenance': 'PRICING',
  map: 'MAP',
  users: 'ADMIN',
  support: 'SUPPORT',
  messages: 'MESSAGING',
  lines: 'LINES',
  devices: 'DEVICES',
};

currentUser.subscribe(user => {
  if (!user) return;
  const requestedFeature = TABS[_currentTab];
  if (hasAnyFeature(user.features, requestedFeature)) return;
  const newTabFeature = user.features[0] || '';
  const newTab = Object.entries(TABS).reduce(
    (acc, [tab, feature]) => (newTabFeature === feature ? tab : acc),
    '',
  );
  if (newTab === _currentTab) return;
  setCurrentTab(newTab);
});

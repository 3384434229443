<script>
  import { onDestroy } from 'svelte';
  import SearchTerms from '../components/checkout_reports/SearchTerms.svelte';
  import SearchResults from '../components/checkout_reports/SearchResults.svelte';
  import { searchTerms } from '../stores/search';

  let start = $searchTerms.start || new Date();
  if (!$searchTerms.start) {
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMonth(start.getMonth() - 1);
  }
  let end = $searchTerms.end || new Date();
  let departureId = $searchTerms.departureId || null;
  let lineId = $searchTerms.lineId || null;
  let driverId = $searchTerms.driverId || null;
  let deviceId = $searchTerms.deviceId || null;
  let receiptNumber = $searchTerms.receiptNumber || null;
  let companies = $searchTerms.companies || [];
  let unaccounted = $searchTerms.unaccounted || false;
  let deviceType = $searchTerms.deviceType || null;
  let tickets =
    ($searchTerms.tickets &&
      $searchTerms.tickets.filter(
        ticket => ticket.groupingType !== 'NO_SALE_DESCRIPTION',
      )) ||
    []; // NO_SALE aren't shown in checkout reports

  onDestroy(() => {
    searchTerms.set({
      start,
      end,
      departureId,
      lineId,
      driverId,
      deviceId,
      receiptNumber,
      companies,
      unaccounted,
    });
  });
</script>

<style>
  .content {
    padding: 2em 4em 1em 4em;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .results {
    padding-top: 2em;
    width: 100%;
  }
</style>

<div class="content">
  <SearchTerms
    bind:start
    bind:end
    bind:departureId
    bind:lineId
    bind:driverId
    bind:deviceId
    bind:companies
    bind:receiptNumber
    bind:unaccounted
    bind:deviceType
    bind:tickets
  />
  <div class="results">
    <SearchResults
      bind:start
      bind:end
      bind:departureId
      bind:lineId
      bind:driverId
      bind:deviceId
      bind:companies
      bind:receiptNumber
      bind:unaccounted
      bind:deviceType
      bind:tickets
    />
  </div>
</div>

<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';

  const { close } = getContext('simple-modal');

  export let title = undefined;
  export let body;
  export let onConfirm;
  export let confirmText = $_('pricing.yes');
  export let cancelText = $_('pricing.no');
</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  button {
    min-width: 150px;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
  p {
    white-space: pre-line;
  }
</style>

<div>
  {#if title}
    <h2 class="mb-8">{title}</h2>
  {/if}
  <p class="mb-10">{body}</p>
  <div class="button-container">
    <button
      class="small"
      on:click={() => {
        onConfirm();
        close();
      }}
    >
      {confirmText}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        close();
      }}
    >
      {cancelText}
    </button>
  </div>
</div>

<script>
  import { onDestroy } from 'svelte';
  import { _ } from 'svelte-i18n';
  import Select from '../../components/Select.svelte';
  import Loading from '../../components/Loading.svelte';
  import TabBar from '../../components/TabBar.svelte';
  import {
    companiesLoaded,
    getCompanies,
    companies as companyList,
  } from '../../stores/companies';
  import { currentCompany as currentCompanyStore } from '../../stores/lines';
  import { showErrorMessage } from '../../stores/notifications';
  import CharterLines from './CharterLines.svelte';
  import LineSeries from './LineSeries.svelte';
  import ServiceLines from './ServiceLines.svelte';
  import Kilometers from './Kilometers.svelte';
  import Waltti from './waltti.svelte';
  import { currentUser } from '../../stores/user';
  import { getCompaniesByFeature } from '../../stores/features';

  $: features = ($currentUser && $currentUser.features) || [];
  $: companiesForLines = getCompaniesByFeature(features, 'LINES') || [];
  $: companyListFilteredByFeature = $companyList.filter(company => companiesForLines.includes(company.number));



  let currentCompany = $currentCompanyStore;
  
  const isWalttiEnabled = (companyToFind) => {
    const company = companies.find(entry => entry.value === companyToFind);
    return company && company.walttiFeaturesEnabled;
  };

  const TABS = ['charter', 'serviceLines', 'lineSeries', 'kilometers', 'waltti'];

  let tabEntries = [];

  const updateTabs = () => {
    tabEntries = TABS.filter(tab => tab !== 'waltti' || isWalttiEnabled(currentCompany)).map(tab => ({
      id: tab,
      title: $_(`lines.sections.${tab}`),
    }));
  };

  let activeTab = localStorage.getItem('linesTab') || TABS[0];

  let companies = [];

  let isLoading = false;
  let isError = false;

  const checkIfCompaniesLoaded = async () => {
    if (!$companiesLoaded) {
      try {
        isLoading = true;
        isError = false;
        await getCompanies();
        updateTabs();
      } catch (error) {
        showErrorMessage($_('lines.companiesFetchFailed'));
        isError = true;
      } finally {
        isLoading = false;
      }
    }
  };



  const resetCompanyIfNotExists = () => {
    if (!companies.some(entry => entry.value === currentCompany)) {
      currentCompany = companies[0] && companies[0].value;
      updateTabs();
    }
  };

  $: {
    companies = companyListFilteredByFeature.map(entry => ({
      value: entry.number,
      label: `${entry.name} (${entry.number})`,
      walttiFeaturesEnabled: entry.walttiFeaturesEnabled
    }));
    resetCompanyIfNotExists();
  }

  onDestroy(() => {
    currentCompanyStore.set(currentCompany);
  });

  checkIfCompaniesLoaded();

  updateTabs();
</script>

<div class="flex bg-mlight py-6 w-full px-6 space-x-4">
  <Select entries={companies} bind:value={currentCompany} on:change={(_) => updateTabs()} primaryText />
  <TabBar entries={tabEntries} bind:activeTab localStorageItem="linesTab" />
</div>

<div class="w-full pt-4 px-16 pb-2">
  {#if isLoading}
    <Loading />
  {:else if isError}
    {$_('lines.companiesFetchFailed')}
  {:else if activeTab === 'charter'}
    <CharterLines {currentCompany} />
  {:else if activeTab === 'serviceLines'}
    <ServiceLines {currentCompany} />
  {:else if activeTab === 'lineSeries'}
    <LineSeries {currentCompany} />
  {:else if activeTab === 'kilometers'}
    <Kilometers {currentCompany} />
  {:else if activeTab === 'waltti'}
    <Waltti {currentCompany} />
  {/if}  
</div>

<script>
  import UserList from '../components/users/UserList.svelte';
  import UserAdd from '../components/users/UserAdd.svelte';

  let selectedUser = /*{
    id: "fdgsdgf",
    name: "Thomas De Bluts",
    email: "thomas.debluts@cgi.com",
    companies: ["86"],
    admin: true
  };*/ null;
</script>

{#if selectedUser}
  <UserAdd bind:selectedUser />
{:else}
  <UserList bind:selectedUser />
{/if}

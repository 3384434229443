<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import AddCustomerGroupModal from '../../components/pricing/AddCustomerGroupModal.svelte';
  import ConfirmationModal from '../../components/ConfirmationModal.svelte';
  import DateRange from '../../components/pricing/DateRange.svelte';
  import ModifyCustomerGroupModal from '../../components/pricing/ModifyCustomerGroupModal.svelte';
  import ZoneTicketTable from '../../components/pricing/ZoneTicketTable.svelte';
  import DepartureTable from '../../components/pricing/DepartureTable.svelte';
  import { showNotification } from '../../stores/notifications';
  import {
    prices,
    addZoneTicketPriceZone,
    addZoneTicketLine,
    removeZoneTicketPriceZone,
    removeZoneTicketLine,
    removeZonePriceTable,
    removeProduct,
    createZoneTicket,
    modifyZoneTicketName,
    modifyZoneTicketPrice,
    modifyZoneTicketDuration,
    modifyZoneTicketPriceZoneName,
    modifyZoneTicketLine,
    updateNestedProductIndex,
    VALIDITY_START_COL,
    VALIDITY_END_COL,
    updateZonePriceTableValidity,
  } from '../../stores/pricing';
  import Select from '../../components/Select.svelte';
  import OverlappingError from '../../components/pricing/OverlappingError.svelte';

  export let activeView;
  export let currentCompany;
  export let currentZone =
    $prices[currentCompany].zoneTickets &&
    Object.keys($prices[currentCompany].zoneTickets)[0];

  let tempRowId = 0;

  let editingMode = false;

  $: {
    if (
      $prices[currentCompany].zoneTickets &&
      !$prices[currentCompany].zoneTickets[currentZone]
    ) {
      currentZone = Object.keys($prices[currentCompany].zoneTickets)[0];
    }
  }

  $: lines =
    ($prices[currentCompany].zoneTickets &&
      $prices[currentCompany].zoneTickets[currentZone] &&
      $prices[currentCompany].zoneTickets[currentZone][0].lines) ||
    [];
  $: lineNames =
    ($prices[currentCompany].zoneTickets &&
      $prices[currentCompany].zoneTickets[currentZone] &&
      $prices[currentCompany].zoneTickets[currentZone][0].lineNames) ||
    [];
  $: lineIds =
    ($prices[currentCompany].zoneTickets &&
      $prices[currentCompany].zoneTickets[currentZone] &&
      $prices[currentCompany].zoneTickets[currentZone][0].lineIds) ||
    [];

  const { open } = getContext('simple-modal');

  const showAddCustomerGroupModal = () => {
    open(AddCustomerGroupModal, {
      companyId: currentCompany,
      hideDiscount: true,
      onSuccess: (companyId, name) => createZoneTicket(companyId, currentZone, name),
    });
  };

  const showRemovePricingConfirmationModal = () => {
    open(ConfirmationModal, {
      title: $_('pricing.removeZoneTickets'),
      body: $_('pricing.removeZoneTicketsConfirmation'),
      onConfirm: () => {
        removeZonePriceTable(currentCompany, currentZone);
      },
    });
  };

  const onRowWillBeCreated = entry => {
    const currentEntries = $prices[currentCompany].zoneTickets[currentZone];
    const existingPriceZones =
      currentEntries && currentEntries[0] && currentEntries[0].zonePrices;
    const alreadyExists = existingPriceZones.some(
      field => field.zone === entry.zone && field.duration === entry.duration,
    );
    if (!alreadyExists) {
      addZoneTicketPriceZone(
        currentCompany,
        currentZone,
        entry.zone,
        entry.duration,
        entry.prices,
      );
      return true;
    } else {
      showNotification({
        type: 'warning',
        title: $_('error.title'),
        body: $_('pricing.errorZoneAlreadyExists'),
      });
      return false;
    }
  };

  const deleteRow = zoneEntry => {
    if ($prices[currentCompany].zoneTickets[currentZone][0].zonePrices.length <= 1) {
      showNotification({
        type: 'warning',
        title: $_('error.title'),
        body: $_('pricing.errorCannotDeleteLastZone'),
      });
    } else {
      removeZoneTicketPriceZone(
        currentCompany,
        currentZone,
        zoneEntry.zone,
        zoneEntry.duration,
      );
    }
  };

  const createBlankTempEntry = () => ({
    zone: null,
    prices: Array($prices[currentCompany].zoneTickets[currentZone].length).fill(null),
    id: tempRowId++,
  });

  const tempRowValid = entry => {
    entry.prices.slice(0, $prices[currentCompany].zoneTickets[currentZone].length);
    return (
      entry.zone &&
      entry.duration !== null &&
      entry.prices.length === $prices[currentCompany].zoneTickets[currentZone].length &&
      entry.prices.every(price => Number.isFinite(price))
    );
  };

  const modifyColumn = (item, _index) => {
    open(ModifyCustomerGroupModal, {
      companyId: currentCompany,
      itemId: item.ticketIdentifier,
      item,
      hideDiscount: true,
      disableDelete: $prices[currentCompany].zoneTickets[currentZone].length <= 1,
      onSuccess: (companyId, id, name) =>
        modifyZoneTicketName(companyId, currentZone, id, name),
      onDelete: removeProduct,
    });
  };

  const zoneNameModified = (item, _index, event) => {
    if (item.zone !== event.detail.value) {
      const currentEntries = $prices[currentCompany].zoneTickets[currentZone];
      const existingPriceZones =
        currentEntries && currentEntries[0] && currentEntries[0].zonePrices;
      const alreadyExists = existingPriceZones.some(
        field => field.zone === event.detail.value && field.duration === item.duration,
      );
      if (!alreadyExists) {
        modifyZoneTicketPriceZoneName(
          currentCompany,
          currentZone,
          item.zone,
          event.detail.value,
          item.duration,
        );
      } else {
        showNotification({
          type: 'warning',
          title: $_('error.title'),
          body: $_('pricing.errorZoneAlreadyExists'),
        });
      }
    }
  };

  const durationModified = (item, index, event) => {
    if (item.duration !== event.detail.value) {
      const currentEntries = $prices[currentCompany].zoneTickets[currentZone];
      const existingPriceZones =
        currentEntries && currentEntries[0] && currentEntries[0].zonePrices;
      const alreadyExists = existingPriceZones.some(
        field => field.zone === item.zone && field.duration === event.detail.value,
      );
      if (!alreadyExists) {
        modifyZoneTicketDuration(
          currentCompany,
          currentZone,
          item.zone,
          item.duration,
          event.detail.value,
        );
      } else {
        showNotification({
          type: 'warning',
          title: $_('error.title'),
          body: $_('pricing.errorZoneAlreadyExists'),
        });
      }
    }
  };

  const pricesModified = (item, row, column, event) => {
    if (item.zonePrices[row].price !== event.detail.value) {
      const zoneName = item.zonePrices[row].zone;
      const duration = item.zonePrices[row].duration;
      modifyZoneTicketPrice(currentCompany, item, zoneName, duration, event.detail.value);
    }
  };


  const getTicketIdentifier = () => {
    return $prices[currentCompany].zoneTickets[currentZone][0]['ticketIdentifier'];
  };

  const startDateChanged = (val) => {
    updateZonePriceTableValidity(currentCompany, currentZone, getTicketIdentifier(), VALIDITY_START_COL, val);
  };

  const endDateChanged = (val) => {
    updateZonePriceTableValidity(currentCompany, currentZone, getTicketIdentifier(), VALIDITY_END_COL, val);
  };

  let dateEnd;
  let dateStart;
  let hasDateRange;
  let hasLines = false;
  $: {
    const zoneTicket = currentCompany && currentZone && $prices[currentCompany] && $prices[currentCompany].zoneTickets && $prices[currentCompany].zoneTickets[currentZone] && $prices[currentCompany].zoneTickets[currentZone][0];
    dateEnd =   zoneTicket && zoneTicket[VALIDITY_END_COL];
    dateStart = zoneTicket && zoneTicket[VALIDITY_START_COL];
    hasLines =  zoneTicket && zoneTicket['lines'] && zoneTicket['lines'].length > 0;

    hasDateRange = dateEnd || dateStart;
  }

  $: overlappingItems = currentCompany && $prices[currentCompany]['overlapping'] && $prices[currentCompany]['overlapping']['zoneTickets'];

</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div {
    overflow: auto;
  }
  div.title-container,
  div.subtitle-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.subbutton-container {
    flex: 1 1 0;
  }
  div.select-container {
    display: flex;
    min-width: 250px;
    padding-bottom: 0.25rem;
    min-height: 42px;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
  }
  div.container > * {
    margin-left: 3rem;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  div.date-container {
    overflow: visible;
    padding-bottom: 3rem;
    padding-top: 1.75rem;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {editingMode ? $_('pricing.modifyZoneTickets') : $_('pricing.sections.zone')}
    </h2>
  </div>
  <div class="button-container">
    {#if !editingMode}
      <button
        on:click={() => {
          activeView = 'new'; // eslint-disable-line no-unused-vars
        }}
      >
        {$_('pricing.addPricing')}
      </button>
    {:else}
      <button
        on:click={() => {
          editingMode = false;
        }}
      >
        {$_('pricing.done')}
      </button>
    {/if}
  </div>
</div>
{#if overlappingItems && overlappingItems.length > 0}
  <OverlappingError overlappingItems={overlappingItems} nameProperty={'zoneName'} />
{/if}
{#if $prices[currentCompany].zoneTickets && Object.keys($prices[currentCompany].zoneTickets).length}
  <div class="subtitle-container">
    <div class="select-container">
      <Select
        flexGrow={true}
        disabled={editingMode}
        entries={Object.keys($prices[currentCompany].zoneTickets)}
        bind:value={currentZone}
      />
    </div>
    <div class="button-container subbutton-container">
      {#if !editingMode}
        <button
          on:click={() => {
            editingMode = true;
          }}
        >
          {$_('pricing.modify')}
        </button>
        <button class="secondary" on:click={showRemovePricingConfirmationModal}>
          {$_('pricing.remove')}
        </button>
      {/if}
    </div>
  </div>
  <div class="date-container">
    <DateRange
      isDefault={currentZone === 'default'}
      startDateChanged={startDateChanged}
      endDateChanged={endDateChanged}
      defaultInUse={hasLines}
      dateEnd={dateEnd}
      dateStart={dateStart}
      {editingMode}
      />
  </div>    
  <div class="container">
    <div class="price-container">
      <ZoneTicketTable
        list={$prices[currentCompany].zoneTickets[currentZone] || []}
        onAddRightClicked={showAddCustomerGroupModal}
        onRemoveRowClicked={deleteRow}
        onModifyColumnClicked={modifyColumn}
        onPrimaryColumnEdited={zoneNameModified}
        onDurationEdited={durationModified}
        onDataEdited={pricesModified}
        {onRowWillBeCreated}
        allowEmpty={false}
        showTempRows={true}
        {createBlankTempEntry}
        {tempRowValid}
        key="ticketIdentifier"
        on:sort={result => {
          updateNestedProductIndex(
            currentCompany,
            result.detail.item.ticketIdentifier,
            'zoneTickets',
            currentZone,
            result.detail.from,
            result.detail.to,
            result.detail.item.index,
          );
        }}
        {editingMode}
      />
    </div>
    <div class="line-container">
      <DepartureTable
        {lines}
        {lineNames}
        {lineIds}
        {editingMode}
        onLineAdded={(line, departure, name) =>
          addZoneTicketLine(currentCompany, currentZone, line, departure, name)}
        onLineModified={(id, line, departure, name) =>
          modifyZoneTicketLine(currentCompany, currentZone, id, line, departure, name)}
        onLineDeleted={id => removeZoneTicketLine(currentCompany, currentZone, id)}
      />
    </div>
  </div>
{:else}
  <p>{$_('pricing.zoneTicketsMissing')}</p>
{/if}

import { writable } from 'svelte/store';
import { getClient } from '../common/http';
import moment from 'moment-timezone';
import { currentUser } from './user';

export const searchLoaded = writable(false);
export const searchLoading = writable(false);
export const searchResults = writable({ products: [], days: [], lines: null });
export const searchTerms = writable({});
export const searchInError = writable(false);

export const reportLoaded = writable(false);
export const reportLoading = writable(false);
export const reportResults = writable([]);
export const reportInError = writable(false);

export const dayLoaded = writable(false);
export const dayLoading = writable(false);
export const dayResults = writable([]);
export const dayInResultError = writable(false);

export const preGeneratedReportsLoaded = writable(false);
export const preGeneratedReportsLoading = writable(false);
export const preGeneratedReports = writable({});

currentUser.subscribe(user => {
  if (!user || !user.loggedIn) {
    searchTerms.set({});
    searchResults.set({ products: [], days: [], lines: null });
    searchLoaded.set(false);
    searchLoading.set(false);
    reportResults.set([]);
    reportLoaded.set(false);
    reportLoading.set(false);
    dayResults.set([]);
    dayLoaded.set(false);
    dayInResultError.set(false);
    dayLoading.set(false);
    preGeneratedReportsLoaded.set(false);
    preGeneratedReportsLoading.set(false);
    preGeneratedReports.set({});
  }
});

export async function startSearch(params) {
  reportLoaded.set(false);
  reportLoading.set(false);
  reportResults.set([]);
  dayLoaded.set(false);
  dayInResultError.set(false);
  dayLoading.set(false);
  dayResults.set([]);
  searchLoaded.set(false);
  searchLoading.set(true);
  searchInError.set(false);
  searchResults.set({
    products: [],
    days: [],
    lines: params.lines === 'true' ? [] : null,
    noSales: params.noSales === 'true' ? true : null,
  });
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;

  const errorHandling = () => {
    searchInError.set(true);
    searchLoaded.set(false);
    searchLoading.set(false);
    searchResults.set({
      products: [],
      days: [],
      lines: params.lines === 'true' ? [] : null,
      noSales: params.noSales === 'true' ? true : null,
    });
  };

  try {
    const res = await getClient().get('/api/travelers/search', { params });
    if (res.status === 200) {
      searchLoaded.set(true);
      searchLoading.set(false);
      searchResults.set({
        products: res.data.products.map(line => ({
          ...line,
          id: `${line.company_id}_${line.line_id}_${line.departure_id}`,
        })),
        days: res.data.days.map(value => ({
          ...value,
          date: moment.tz(value.date, 'Europe/Helsinki'),
        })),
        lines: res.data.lines
          ? res.data.lines.map(line => ({
              ...line,
              id: `${line.company_id}_${line.line_id}`,
            }))
          : null,
        noSales: res.data.noSales
          ? res.data.noSales.map(noSale => ({
              ...noSale,
              id: noSale.target_group,
            }))
          : null,
      });
    } else {
      errorHandling();
    }
  } catch (err) {
    errorHandling();
  }
}

export async function fetchReport(company, params) {
  reportLoaded.set(false);
  dayLoaded.set(false);
  dayLoading.set(false);
  dayResults.set([]);
  reportLoading.set(true);
  reportResults.set([]);
  reportInError.set(false);
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;
  params.weekdays = params.weekdays ? JSON.stringify(params.weekdays) : undefined;

  const errorHandling = () => {
    reportInError.set(true);
    reportLoaded.set(false);
    reportLoading.set(false);
    reportResults.set([]);
  };
  
  try {
    const res = await getClient().get(`/api/travelers/getReportByCompany/${company}`, {
      params,
    });
    if (res.status === 200) {
      reportLoaded.set(`${company}-${params.lineId}-${params.departureId}`);
      reportLoading.set(false);
      reportResults.set({
        daily: res.data.daily.map(value => ({
          ...value,
          day: moment.tz(value.day, 'Europe/Helsinki'),
        })),
        stop: res.data.stop.map(value => ({
          ...value,
          date: (value.date || '-').replace(/:\d\d$/, ''),
        })),
        stopChanges: res.data.stopChanges,
        stopsPassengers: res.data.stopsPassengers.map(value => ({
          ...value,
          date: (value.date || '-').replace(/:\d\d$/, ''),
        })),
      });
    } else {
      errorHandling();
    }
  } catch (err) {
    errorHandling();
  }
}

export async function fetchPreGeneratedReports(company) {
  preGeneratedReportsLoading.set(true);
  preGeneratedReportsLoaded.set(false);

  try {
    const res = await getClient().get(`api/reports/monthly/${company}`);

    if (res.status === 200) {
      const d = {
        [company]: { monthly: res.data.reports.toSorted((a,b) => b.year - a.year + b.month-a.month).slice(0,13) }
      };

      preGeneratedReports.set(d);
    } else {
      preGeneratedReports.set({[company]: { monthly: [] }});
    }
  } catch (err) {
    console.log(err);
}
  preGeneratedReportsLoaded.set(true);
  preGeneratedReportsLoading.set(false);

}

export async function fetchDayReport(day, params) {
  dayLoaded.set(false);
  dayLoading.set(true);
  dayResults.set([]);
  dayInResultError.set(false);
  params.companies = params.companies ? JSON.stringify(params.companies) : undefined;
  params.tickets = params.tickets ? JSON.stringify(params.tickets) : undefined;


  const errorHandling = () => {
    dayLoaded.set(false);
    dayLoading.set(false);
    dayResults.set([]);
    dayInResultError.set(true);
  };

  try {
    const res = await getClient().get(`/api/travelers/getReportByDay/${day}`, {
      params,
    });
    if (res.status === 200) {
      dayLoaded.set(day);
      dayLoading.set(false);
      dayResults.set(
        res.data.map(value => ({
          ...value,
          date: moment.tz(value.date, 'Europe/Helsinki'),
          services: (value.services || '')
            .split(', ')
            .filter(it => it)
            .map(service => {
              const [name, price] = service.split('|');
              return { name, price };
            }),
        })),
      );
    } else {
      errorHandling();
    }
  } catch (err) {
    errorHandling();
  }
}

<script>
  export let checked = false;
  export let label = '';
  export let noReverse = false;
  export let itemsToStart = false;
  export let disabled = false;
  export let bold = false;
  export let size = '20px';
  export let markColor = '#333333';
  export let labelClass = 'label';
</script>

<style>
  .container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 0.8em;
    user-select: none;
    margin-bottom: 1em;
  }

  .container.no-reverse {
    flex-direction: unset;
    margin-bottom: unset;
  }

  .container.items-to-start {
    justify-content: flex-end;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    top: 0;
    left: 0;
    height: var(--size);
    width: var(--size);
    min-height: var(--size);
    min-width: var(--size);
    max-height: var(--size);
    max-width: var(--size);
    background-color: #fff;
    border: 1px solid #b2c3cf;
    padding: 1px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkmark.disabled {
    background-color: #b2c3cf;
  }

  .bold {
    border-width: 2px;
    padding: 0;
  }

  .checkmark:after {
    content: '';
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    width: calc(0.25 * var(--size));
    height: calc(0.6 * var(--size));
    border: 1px solid var(--markColor);
    border-width: 0 2px 2px 0;
    transform: translateY(-2px) rotate(45deg);
  }

  .label {
    margin-left: 0.3em;
  }

  .labelLarge {
    margin-left: 1rem;
  }
</style>

<label class="container" class:no-reverse={noReverse} class:items-to-start={itemsToStart} style="--size: {size}; --markColor: {markColor}">
  <span class={labelClass}>{label}</span>
  <input type="checkbox" bind:checked on:change disabled={disabled} />
  <span class="checkmark" class:bold class:disabled />
</label>

<script>
  import moment from 'moment-timezone';
  import { createEventDispatcher } from 'svelte';
  import { _, number } from 'svelte-i18n';
  import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
  import Icon from 'fa-svelte';
  import StopBasedProductReport from './StopBasedProductReport.svelte';

  export let stops;
  export let data = null;
  export let changes;
  // export let reportType; // Used if we need to add the radiobuttons
  let selectedLine;

  $: results = data && getResults(data);

  const getResults = searchResults => {
    let result = {};
    searchResults.forEach(item => {
      if (result[item.stop_id]) {
        result[item.stop_id] = [item, ...result[item.stop_id]];
      } else {
        result[item.stop_id] = [item];
      }
    });
    return result;
  };

  const dispatch = createEventDispatcher();
</script>

<style>
  table {
    color: #333333;
  }

  th {
    text-align: end;
    padding-right: 3rem;
    border-bottom: 1px solid #333333;
        font-weight: 600;
  }

  th:first-child {
    padding-left: 0;
    text-align: start;
  }

  td:first-child,
  td:nth-child(2) {
    text-align: left;
  }

  td {
    padding-right: 3rem;
    text-align: right;
    height: 2.5em;
  }

  td:last-child {
    padding: 0;
  }

  tr:last-child {
    border-bottom: 1px solid #333333;
  }

  .bar {
    background-color: #001E61;
    height: 100%;
  }

  button {
    margin-top: 2.5em;
  }

  .icon {
    display: flex;
    justify-content: center;
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }

  tr:not(.selected):not(:last-child) td {
    border-bottom: 1px solid #cccccc;
  }

  tr:not(.selected).last-result td {
    border-bottom: 1px solid #333333;
  }

  .changed-warning {
    color: #EA5798;
  }
</style>

{#if changes && changes.length > 1}
<p class="changed-warning pb-4">{$_('travel.routeChangedNotice', { values: { dates: (changes.slice(1).map((date) => moment(date).tz('Europe/Helsinki').format('D.M'))).join(', ') } })}</p>
{/if}

<table cellspacing="0">
  <tr>
    <th colspan="2" style="min-width: 20vw;">{$_('travel.stopName')}</th>
    <th>{$_('travel.max')}</th>
    <th>{$_('travel.boarding')}</th>
    <th>{$_('travel.leaving')}</th>
    <th>{$_('travel.travelerAvg')}</th>
    <th colspan="2" />
  </tr>
  {#each stops as result, i}
    <tr
      class:last-result={i === results.length - 1}
      class:font-semibold={selectedLine === result.stop_id}
      class:selected={selectedLine === result.stop_id}
      class:bg-mlight={selectedLine === result.stop_id}
    >
      <td>{result.date}</td>
      <td>{result.stop_name}{result.stop_area ? ', ' + result.stop_area : ''}</td>
      <td>{result.max}</td>
      <td>
        {$number(result.boarding / (result.shifts || 1), {
          format: 'EUR',
        }).replace('€', '')}
      </td>
      <td>
        {$number(result.leaving / (result.shifts || 1), {
          format: 'EUR',
        }).replace('€', '')}
      </td>
      <td>
        {$number(result.total / (result.shifts || 1), {
          format: 'EUR',
        }).replace('€', '')}
      </td>
      <td>
        <div
          class="bar"
          style={`width: ${(result.total / (result.shifts || 1)) * 10}px`}
        />
      </td>

      <td
        on:click|stopPropagation={() =>
          selectedLine === result.stop_id
            ? (selectedLine = null)
            : (selectedLine = result.stop_id)}
      >
        <div class="icon" class:expanded={selectedLine === result.stop_id}>
          <Icon icon={faChevronDown} />
        </div>
      </td>
    </tr>
    {#if selectedLine === result.stop_id}
      <StopBasedProductReport
        products={results[result.stop_id]}
        lastResult={i === results.length - 1}
        maxShifts={result.shifts}
      />
    {/if}
  {/each}
</table>

<!-- Not currently in use, but saved in case of future used -->
<!-- <div class="mt-4">
  <span class="mb-2">{$_("travel.createExcel")}</span>
  <RadioButton
    label={$_("travel.exportEvents")}
    value="stop"
    bind:group={reportType} />
  <RadioButton
    label={$_("travel.exportProducts")}
    value="stopOpened"
    bind:group={reportType} />
</div> -->
<button on:click={() => dispatch('excel')}>{$_('results.createExcel')}</button>

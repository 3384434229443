<script>
  import { getContext } from 'svelte';
  import { addOtherProduct } from '../../stores/pricing';
  import NumberInput from '../NumberInput.svelte';
  import { _ } from 'svelte-i18n';
  import Select from '../Select.svelte';

  const { close } = getContext('simple-modal');

  export let companyId;
  export let priceTable;

  let productName = '';
  let productPrice = null;
  let productVat = 14;
</script>

<style>
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0;
  }
  div {
    padding: 0.75rem 0.25rem;
  }
  button {
    min-width: 150px;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
  }
  input,
  select {
    width: 100%;
    height: unset;
    font-size: 1rem;
    padding: 0.4em;
    border-radius: 4px;
  }
  .input-container {
    width: 350px;
  }
  .button-container {
    display: flex;
    flex-flow: row;
    width: 55%;
    justify-content: space-between;
  }
</style>

<div>
  <h2>{$_('pricing.addOtherProduct')}</h2>
  <div class="input-container">
    <label for="name">{$_('pricing.name')}: *</label>
    <input type="text" name="name" id="name" bind:value={productName} />
  </div>
  <div class="input-container">
    <label for="price">{$_('pricing.price')}: *</label>
    <NumberInput
      class="add-other-product-input"
      type="price"
      maxIntegers="5"
      bind:value={productPrice}
    />
  </div>
  <div class="input-container">
    <label for="vat">{$_('pricing.vat')}: *</label>
    <Select id="vat" entries={[0, 10, 14, 25.5]} border={true} bind:value={productVat} />
  </div>
  <div class="button-container">
    <button
      class="small"
      disabled={!productName.length || !Number.isFinite(productPrice)}
      on:click={() => {
        addOtherProduct(companyId, productName, productPrice, productVat, priceTable);
        close();
      }}
    >
      {$_('pricing.done')}
    </button>
    <button
      class="small secondary"
      on:click={() => {
        close();
      }}
    >
      {$_('pricing.cancel')}
    </button>
  </div>
</div>

<script>
  import { _ } from 'svelte-i18n';

  export let retryAction = undefined;
  export let errorMessage = $_('travel.errorLoadingContent');
  export let btnMessage = $_('travel.clickHere');

  const performRetryAction = () => {
    if ( retryAction ) {
      retryAction();
    }
  };
</script>

<style>

.content {
  margin: auto;
  margin-top: 3rem;
  text-align: center;
}

.center button {
  margin: auto;
  margin-top: 1rem;
}
</style>

<div class="content">
  <span class="center">{errorMessage}
    <button on:click={() => performRetryAction()}>{btnMessage}</button>
  </span>
</div>

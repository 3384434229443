<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { resetPassword } from '../../stores/user';

  const dispatch = createEventDispatcher();

  export let email = '';

  let loading = false;

  let error = '';

  let needChanges = false;

  const reset = async () => {
    if (loading) return;
    loading = true;
    error = '';
    try {
      await resetPassword(email);
      dispatch('confirmation');
    } catch (e) {
      if (e.status === 404) {
        error = $_('login.userNotFound');
        return;
      }
      error = e.message || $_('login.error');
      //needChanges = !e.retryable;
      console.error(e);
    } finally {
      loading = false;
    }
  };
</script>

<style>
  .content {
    background: #fff;
    padding: 0 1.5rem 2.5rem 1.5rem;
    margin-top: 3rem;
    max-width: 25rem;
    display: grid;
    display: -ms-grid;
  }
  @supports (-ms-ime-align: auto) {
    .content {
      display: flex;
      flex-direction: column;
    }
  }

  input {
    width: 100%;
    height: 2rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  h1 {
    color: #001E61;
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }

  .actions > button {
    padding: 0.6em;
    white-space: nowrap;
  }

  .actions {
    min-width: 20rem;
    font-size: 0.8rem;
    padding-top: 1.8rem;
  }

  label {
    font-size: 0.8rem;
    margin-top: 1.8rem;
  }

  span {
    font-size: 0.8rem;
  }

  .actions > span {
    color: #001E61;
    text-align: right;
    margin-left: 1rem;
  }

  .disabled {
    pointer-events: none;
    color: #666666 !important;
  }

  .error {
    background: #ffbbd9;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 1rem;
  }
</style>

<form
  class="content"
  on:submit|preventDefault={reset}
  on:change={() => (needChanges = false)}
>
  <h1 class="mb-6 text-3xl font-bold">{$_('login.forgotten')}</h1>
  <span>{$_('login.reset_instr')}</span>
  <label for="email">{$_('login.email')}</label>
  <input type="email" id="email" bind:value={email} autocomplete="email" />
  {#if error}<span class="error">{error}</span>{/if}
  <div class="actions">
    <button type="submit" disabled={loading || !email || needChanges}>
      {$_('login.reset')}
    </button>
    <span class="text-mnormal underline" on:click={() => dispatch('confirmation')} class:disabled={!email}>
      {$_('login.alreadyConfirmed')}
    </span>
  </div>
</form>

<script>
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import AddOtherTicketModal from '../../components/pricing/AddOtherTicketModal.svelte';
  import AddOtherProductModal from '../../components/pricing/AddOtherProductModal.svelte';
  import SortableRowList from '../../components/SortableRowList.svelte';
  import PricingOtherTicketRow from '../../components/pricing/PricingOtherTicketRow.svelte';
  import PricingOtherProductRow from '../../components/pricing/PricingOtherProductRow.svelte';
  import {
    prices,
    updateOtherSaleProductIndex,
    updateOtherSaleTicketIndex,
    removeOtherSalePriceTable,
    updateOtherSaleTableValidity,
    makeOtherSaleDefault,
    VALIDITY_START_COL,
    VALIDITY_END_COL,
    addOtherSaleLine,
    modifyOtherSaleTableLine,
    removeOtherSaleLine,
    getCopyableOtherSalePriceTables
  } from '../../stores/pricing';
  import OtherSaleTableCreationModal from '../../components/pricing/OtherSaleTableCreationModal.svelte';
  import OverlappingError from '../../components/pricing/OverlappingError.svelte';
  import Select from '../../components/Select.svelte';
  import DateRange from '../../components/pricing/DateRange.svelte';
  import ConfirmationModal from '../../components/ConfirmationModal.svelte';
  import DepartureTable from '../../components/pricing/DepartureTable.svelte';

  const { open } = getContext('simple-modal');

  let editingMode = false;
  let currentPriceTable = undefined;

  const showOtherTicketModal = () => {
    open(AddOtherTicketModal, {
      companyId: currentCompany,
      priceTable: currentPriceTable,
    });
  };

  const showOtherProductModal = () => {
    open(AddOtherProductModal, {
      companyId: currentCompany,
      priceTable: currentPriceTable,
    });
  };

  export let currentCompany;

  $: {
    if (
      $prices[currentCompany].otherSales &&
      !$prices[currentCompany].otherSales[currentPriceTable]
    ) {
      currentPriceTable = Object.keys($prices[currentCompany].otherSales)[0];
    }
  }

  $: lines =
    ($prices[currentCompany].otherSales &&
      $prices[currentCompany].otherSales[currentPriceTable].lines) ||
    [];
  $: lineNames =
    ($prices[currentCompany].otherSales &&
      $prices[currentCompany].otherSales[currentPriceTable].lineNames) ||
    [];
  $: lineIds =
    ($prices[currentCompany].otherSales &&
      $prices[currentCompany].otherSales[currentPriceTable].lineIds) ||
    [];

  const showOtherSaleCreationModal = async () => {
    await getCopyableOtherSalePriceTables();
    open(OtherSaleTableCreationModal, {
      companyId: currentCompany,
      existingNames:
        ($prices[currentCompany].lineBasicTickets &&
          Object.keys($prices[currentCompany].lineBasicTickets)) ||
        [],
      onSuccess: priceTableName => {
        editingMode = true;
        currentPriceTable = priceTableName;
      },
    });
  };

  const showOtherSaleConfirmationModal = () => {
    open(ConfirmationModal, {
      title: $_('pricing.removeOtherSalePriceTable'),
      body: $_('pricing.removeOtherSalePriceTableConfirmation'),
      onConfirm: () => {
        removeOtherSalePriceTable(currentCompany, currentPriceTable);
      },
    });
  };

  const getTicketIdentifier = () => {
    return $prices[currentCompany].otherSales[currentPriceTable]['ticketIdentifier'];
  };

  const startDateChanged = val => {
    updateOtherSaleTableValidity(
      currentCompany,
      currentPriceTable,
      getTicketIdentifier(),
      VALIDITY_START_COL,
      val,
    );
  };

  const endDateChanged = val => {
    updateOtherSaleTableValidity(
      currentCompany,
      currentPriceTable,
      getTicketIdentifier(),
      VALIDITY_END_COL,
      val,
    );
  };

  const showMakePricingDefaultConfirmationModal = () => {
    open(ConfirmationModal, {
      body: $_('pricing.pricingAsDefaultConfirmationText'),
      confirmText: $_('pricing.continue'),
      cancelText: $_('pricing.cancel'),
      onConfirm: () => {
        makeOtherSaleDefault(currentCompany, currentPriceTable);
      },
    });
  };

  let dateEnd;
  let dateStart;
  let hasDateRange;
  let hasLines = false;

  let isDefault = false;

  $: {
    const otherSaleTicket =
      currentCompany &&
      currentPriceTable &&
      $prices[currentCompany] &&
      $prices[currentCompany].otherSales &&
      $prices[currentCompany].otherSales[currentPriceTable];
    dateEnd = otherSaleTicket && otherSaleTicket[VALIDITY_END_COL];
    dateStart = otherSaleTicket && otherSaleTicket[VALIDITY_START_COL];
    hasLines =
      otherSaleTicket && otherSaleTicket['lines'] && otherSaleTicket['lines'].length > 0;

    hasDateRange = dateEnd || dateStart;

    isDefault = otherSaleTicket && otherSaleTicket.default;
  }

  $: overlappingItems =
    currentCompany && $prices[currentCompany]['overlapping']['otherSales'];
</script>

<style>
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
    margin-top: 4rem;
  }
  div.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3rem;
    padding-top: 1rem;
  }
  div.table-container {
    margin-left: 3rem;
    margin-bottom: 2rem
  }

  div {
    overflow: auto;
  }

  div.title-container,
  div.subtitle-container {
    display: flex;
  }
  div.title-container > div {
    flex: 1 1 0;
  }
  div.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  div.subbutton-container {
    flex: 1 1 0;
  }
  div.select-container {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding-bottom: 0.25rem;
    min-height: 42px;
  }
  button {
    margin-left: 2rem;
    min-width: 120px;
  }
  div.line-container {
    padding-bottom: 3rem;
    max-width: 650px;
    margin-left: 3rem;
  }
  div.date-container {
    overflow: visible;
    padding-bottom: 3rem;
    padding-top: 1.75rem;
  }
</style>

<div class="title-container">
  <div>
    <h2 class="my-6">
      {editingMode ? $_('pricing.modifyOtherSalePricing') : $_('pricing.sections.other')}
    </h2>
  </div>
  <div class="button-container">
    {#if !editingMode}
      <button on:click={showOtherSaleCreationModal}>
        {$_('pricing.addPricing')}
      </button>
    {/if}
  </div>
</div>

{#if overlappingItems && overlappingItems.length > 0}
  <OverlappingError {overlappingItems} nameProperty={'name'} />
{/if}

{#if $prices[currentCompany].otherSales}
  <div class="subtitle-container">
    <div class="select-container">
      <Select
        flexGrow={true}
        disabled={editingMode}
        entries={Object.keys($prices[currentCompany].otherSales)}
        bind:value={currentPriceTable}
      />
    </div>
    <div class="button-container subbutton-container">
      {#if !editingMode}
        <button
          on:click={() => {
            editingMode = true;
          }}
        >
          {$_('pricing.modify')}
        </button>

        <button
          class="secondary"
          on:click={showOtherSaleConfirmationModal}
          disabled={isDefault === true}
        >
          {$_('pricing.remove')}
        </button>
        {#if isDefault !== true && !lines.length && !hasDateRange}
          <button class="tertiary" on:click={showMakePricingDefaultConfirmationModal}>
            {$_('pricing.setAsDefault')}
          </button>
        {/if}
      {:else}
        <button
          on:click={() => {
            editingMode = false;
          }}
        >
          {$_('pricing.done')}
        </button>
      {/if}
    </div>
  </div>
  <div class="date-container">
    <DateRange
      isDefault={isDefault === true}
      {startDateChanged}
      {endDateChanged}
      defaultInUse={hasLines}
      {dateEnd}
      {dateStart}
      {editingMode}
    />
  </div>
  <p>{$_('pricing.otherTicketsObservation')}</p>

  <div class="container">

    <div class="table-container">
      <SortableRowList
        droppableId={'otherTickets'}
        list={$prices[currentCompany].otherSales[currentPriceTable] &&
          $prices[currentCompany].otherSales[currentPriceTable].otherTickets || []}
        key={'ticketIdentifier'}
        renderComponent={PricingOtherTicketRow}
        componentProps={{ companyId: currentCompany, priceTable: currentPriceTable }}
        placeholder={$_('pricing.otherTicketsMissing')}
        title={$_('pricing.otherTicketProducts')}
        onAddClicked={showOtherTicketModal}
        editingMode={editingMode}
        on:sort={result => {
          updateOtherSaleTicketIndex(
            currentCompany,
            currentPriceTable,
            result.detail.item.ticketIdentifier,
            result.detail.to,
          );
        }}
        headerColumns={[
          {
            title: $_('pricing.name'),
          },
          {
            title: $_('pricing.priceShort'),
            align: 'right',
          },
        ]}
      />
    </div>
    <div class="table-container">
      <SortableRowList
        droppableId={'otherProducts'}
        list={$prices[currentCompany].otherSales[currentPriceTable] &&
          $prices[currentCompany].otherSales[currentPriceTable].otherProducts || []}
        key={'ticketIdentifier'}
        renderComponent={PricingOtherProductRow}
        componentProps={{ companyId: currentCompany, priceTable: currentPriceTable }}
        placeholder={$_('pricing.otherProductsMissing')}
        title={$_('pricing.otherProducts')}
        onAddClicked={showOtherProductModal}
        editingMode={editingMode}
      
        on:sort={result => {
          updateOtherSaleProductIndex(
            currentCompany,
            currentPriceTable,
            result.detail.item.ticketIdentifier,
            result.detail.to,
          );
        }}
        headerColumns={[
          {
            title: $_('pricing.name'),
          },
          {
            title: $_('pricing.priceShort'),
            align: 'right',
          },
          {
            title: $_('pricing.vat'),
            align: 'right',
          },
        ]}
      />
    </div>

    {#if !isDefault}
      <div class="table-container">

          <DepartureTable
            {lines}
            {lineNames}
            {lineIds}
            editingMode={editingMode}
            onLineAdded={(line, departure, name) =>
              addOtherSaleLine(currentCompany, currentPriceTable, line, departure, name)}
            onLineModified={(id, line, departure, name) =>
              modifyOtherSaleTableLine(
                currentCompany,
                currentPriceTable,
                id,
                line,
                departure,
                name,
              )}
            onLineDeleted={id =>
              removeOtherSaleLine(currentCompany, currentPriceTable, id)}
          />
      </div>
    {/if}    
  </div>
{:else}
  <p>{$_('pricing.otherSalesMissing')}</p>
{/if}

<script>
  import { _ } from 'svelte-i18n';
  import Icon from 'fa-svelte';
  import {
    faCheck,
    faCaretDown,
    faCaretUp,
    faSearch,
  } from '@fortawesome/free-solid-svg-icons';
  import { companiesLoaded, companies, getCompanies } from '../../stores/companies';
  import { usersLoaded, users, getUsers } from '../../stores/users';
  import { currentUser } from '../../stores/user';
  import { showError } from '../../stores/notifications';
  import Loading from '../Loading.svelte';

  export let selectedUser;

  if (!$companiesLoaded) {
    getCompanies().catch(showError($_('error.title')));
  }

  if (!$usersLoaded) {
    getUsers().catch(showError($_('error.title')));
  }
 
  const filterByEmail = displayAllUsers => user => {
    if (displayAllUsers) {
      return true;
    }

    const isUserAllowed = user => {
      
      const email = user && user.email;
      if (!email) {
        return false;
      }

      if (email.endsWith('@matkahuolto.fi')) {
        return false;
      }

      if (email.endsWith('@cgi.com')) {
        return false;
      }

      if (email.startsWith('astests1234')) {
        return false;
      }

      return true;
    };

    return isUserAllowed(user);
  };

  $: usersWithCompany = mapUsersAndCompanies($users, $companies, $currentUser);
  

  let sorting = { field: 'name', order: 'asc' };
  const sortMethods = {
    name: ({ name: a }, { name: b }) => a.localeCompare(b),
    companies: (a, b) =>
      `${(a.companies[0] || {}).name}${a.name}`.localeCompare(
        `${(b.companies[0] || {}).name}${b.name}`,
      ),
  };

  let filter = '';
  const filterUsers = (users, filter) => {
    if (!filter) return users;
    const search = filter.toLocaleUpperCase();
    return users.filter(
      ({ firstname, lastname, companies }) =>
        firstname.toLocaleUpperCase().startsWith(search) ||
        lastname.toLocaleUpperCase().startsWith(search) ||
        companies.some(({ name }) => name.toLocaleUpperCase().startsWith(search)),
    );
  };

  $: usersFiltered = filterUsers(usersWithCompany, filter);

  $: usersSorted = sortUsers(usersFiltered, sorting);

  const sortUsers = (users, { field }) => {
    const method = sortMethods[field];
    return sorting.order === 'asc'
      ? users.sort((a, b) => method(a, b))
      : users.sort((a, b) => method(b, a));
  };
  
  const GLOBAL = 'GLOBAL';
  const addFeatureToCompany = (company, feature, acc) => {
    if (!acc[company]) {
      acc[company] = [];
    }
    
    acc[company].push(feature);
  };
  const mapUsersAndCompanies = (users, companies, currentUser) => {
    const email = currentUser && (currentUser.email || currentUser.signInUserName);
    const displayAllUsers = email && (email.endsWith('@matkahuolto.fi') || email.endsWith('@cgi.com') || email.startsWith('astests1234'));

return users.filter(filterByEmail(displayAllUsers)).map(user => ({
      ...user,
      name: `${user.lastname} ${user.firstname}`,
      companies: user.companies
        .map(
          company =>
            companies.filter(it => it.number === company)[0] || {
              name: company,
              number: company,
            },
        )
        .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
      featuresByCompany: user.features.reduce((acc, featureStr) => {
        if ( featureStr.includes('$') ) {
          const [company, feature] = featureStr.split('$');
          addFeatureToCompany(company, feature, acc);
        } else {
          // global feature
          addFeatureToCompany(GLOBAL, featureStr, acc);
        }
        
        return acc;
      }, {}),

    }));
  };

  const sortBy = field => {
    if (sorting.field === field) {
      sorting = {
        field: field,
        order: sorting.order === 'asc' ? 'desc' : 'asc',
      };
    } else {
      sorting = { field, order: 'asc' };
    }
  };
</script>

<style>
  .content {
    padding: 2em 4em 1em 4em;
    width: calc(100vw - 8em);
  }

  h1 {
    margin: 0 0 1em 0;
    display: flex;
    align-items: baseline;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  table {
    width: 100%;
  }

  table {
    width: 100%;
    color: #333333;
    margin-top: 1em;
  }

  th {
    color: #73899f;
    text-align: start;
    font-weight: 100;
    border-bottom: 1px solid #333333;
    padding-bottom: 0.5em;
  }

  td {
    padding: 0.8em 0;
    border-bottom: 1px solid #b8b8b8;
  }

  .admin {
    text-align: center;
  }

  .edit {
    color: #001E61;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
  }

  .sortable {
    cursor: pointer;
  }

  th > :global(*) {
    color: #73899f;
  }

  .search {
    border-bottom: 1px solid #73899f00;
    transition: border-bottom 500ms ease-in-out;
    font-size: 1rem;
    margin-left: 1rem;
  }

  .search:hover,
  .search.shown {
    border-bottom: 1px solid #73899f;
  }

  .search > :global(*) {
    color: #73899f;
  }

  .search > input {
    width: 0;
    background: none;
    border: none;
    transition: width 500ms ease-in-out;
    margin: 0;
  }

  .search:hover > input,
  .search.shown > input {
    width: 20em;
  }
</style>

<div class="content">
  <div class="header">
    <h1 class="mb-6 text-3xl font-bold">
      {$_('users.users')}
      <div class="search" class:shown={filter}>
        <input bind:value={filter} type="text" placeholder={$_('search.fetch')} />
        <Icon icon={faSearch} />
      </div>
    </h1>
    <button on:click={() => (selectedUser = {})}>{$_('users.create')}</button>
  </div>
  {#if $usersLoaded}
    <table cellspacing="0">
      <thead>
        <tr>
          <th class="sortable" on:click={() => sortBy('name')}>
            {$_('users.name')}
            {#if sorting.field === 'name'}
              {#if sorting.order === 'asc'}
                <Icon icon={faCaretDown} />
              {:else}
                <Icon icon={faCaretUp} />
              {/if}
            {/if}
          </th>
          <th>{$_('users.username')}</th>
          <th class="sortable" on:click={() => sortBy('companies')}>
            {$_('users.company')}
            {#if sorting.field === 'companies'}
              {#if sorting.order === 'asc'}
                <Icon icon={faCaretDown} />
              {:else}
                <Icon icon={faCaretUp} />
              {/if}
            {/if}
          </th>
          <th colspan="2">{$_('users.admin')}</th>
        </tr>
      </thead>
      <tbody>
        {#each usersSorted as user}
          <tr>
            <td>{user.lastname} {user.firstname}</td>
            <td>{user.email}</td>
            <td>{user.companies.map(({ name }) => name).join(', ')}</td>
            <td class="admin">
              {#if user.features.includes('ADMIN')}
                <Icon icon={faCheck} />
              {/if}
            </td>
            <td
              class="edit"
              on:click={() => {
                selectedUser = { ...user }; // eslint-disable-line no-unused-vars
              }}
            >
              {$_('users.edit')}
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {:else}
    <div>
      <Loading />
    </div>
  {/if}
</div>

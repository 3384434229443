<script>
  import { _, date } from 'svelte-i18n';
  import DatePicker from '../DatePicker.svelte';
  import Icon from 'fa-svelte';
  import { faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
  export let isDefault;
  export let dateEnd;
  export let dateStart;
  export let editingMode = false;
  export let startDateChanged;
  export let endDateChanged;
  export let defaultInUse = false;


  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);

  let end;
  let start;
  $: {
    end = dateEnd && new Date(dateEnd);
    start = dateStart && new Date(dateStart);    
  }

  let datePickerStartResetFn;
  let datePickerEndResetFn;

  const onStartReset = (e) => { e.stopPropagation();datePickerStartResetFn(); startDateChanged(undefined); };
  const onEndReset = (e) => { e.stopPropagation();datePickerEndResetFn(); endDateChanged(undefined); };
</script>

<style>
  label {
    font-size: 1em;
    margin-bottom: 0.25rem;
    display: block;
  }
  .input-div {
    margin-bottom: 1.5rem;
    margin-right: 1em;
    width: 12em;
    white-space: nowrap;
  }
  .input {
    width: 100%;
    padding: 0.4rem;
    height: unset;
    font-size: 1rem;
    margin: 0;
    max-width: 640px;
  }
  .input-main-container {
    max-width: 800px;
  }
  .picker > :global(.datepicker) {
    width: 100%;
  }
  .lowercase {
    text-transform: lowercase;
  }
</style>
{#if editingMode}
  {$_('pricing.dateRange.title')}: 
{/if}
<div class="flex w-full justify-between">
  <div class="input-main-container flex flex-col lg:flex-row flex-grow">  
  {#if !isDefault}
    {#if editingMode}
      <div class="input-div picker">
        <label for="start">{$_('search.start')}: </label>
        <DatePicker bind:selected={start} dateSelected={startDateChanged} allowEmpty={true} registerResetHook={(fn) => datePickerStartResetFn=fn} end={end || new Date(2050,1,1)}>
          <div class="input flex justify-between cursor-pointer items-center"
            placeholder={$_('search.choose.date')}
          >
            {#if start} 
              <span>{$date(start, { format: 'twoDigits' })}</span>
              <span on:click={(e) => onStartReset(e)} on:keydown={(e) => onStartReset(e)}><Icon icon={faTrash} /></span>
            {:else} 
              {$_('search.choose.date')}
              <Icon icon={faCalendar} />
            {/if}
          </div>
        </DatePicker>
      </div>
      <div class="input-div picker">
        <label for="end">{$_('search.end')}: </label>
        <DatePicker bind:selected={end} {start} dateSelected={endDateChanged} allowEmpty={true} registerResetHook={(fn) => datePickerEndResetFn=fn}>
          <div
           class="input flex justify-between cursor-pointer items-center"
            placeholder={$_('search.choose.date')}
          >
            {#if end}
              <span>{$date(end, { format: 'twoDigits' })}</span>
              <span on:click={(e) => onEndReset(e)} on:keydown={(e) => onEndReset(e)}><Icon icon={faTrash} /></span>
            {:else} 
              {$_('search.choose.date')}
              <Icon icon={faCalendar} />
            {/if}            
          </div>
        </DatePicker>
    </div>  
    {:else}

      {#if !start && !end}
        {#if defaultInUse}
          {$_('pricing.dateRange.alwaysInUse')} 
        {:else}
          {$_('pricing.dateRange.notInUse')} 
        {/if}
      {:else}
        {$_('pricing.dateRange.title')}&nbsp;
 
        <span class="lowercase">{$_('search.start')}&nbsp;{#if start} {$date(start, { format: 'twoDigits' })}{:else}-{/if}&nbsp;</span>
        <span class="lowercase">{$_('search.end')}&nbsp;{#if end} {$date(end, { format: 'twoDigits' })}{:else} - {/if}</span>
      {/if}
    {/if}
  {:else}
    {$_('pricing.dateRange.defaultNotValid')} 
  {/if}
  </div>
</div>
<script>
  import Login from '../components/login/Form.svelte';
  import PasswordForgotten from '../components/login/PasswordForgotten.svelte';
  import ChangePassword from '../components/login/ChangePassword.svelte';
  import SignUpUser from '../components/login/SignUpUser.svelte';
  
  let view = 'login';
  let email = '';
  let session = '';

  let needConfirmation = false;
  let confirmationCode = '';
  let encryptedData = '';
  let params = new URLSearchParams(window.location.search);
  if (params.has('user') && params.has('code')) {
    needConfirmation = true;
    confirmationCode = params.get('code');
    encryptedData = params.get('user');

    if (params.has('signup')) {
      view = 'signup';
    } else {
      view = 'change';
    }
  }

  const userSignedUp = () => {
    window.location.href=window.location.origin;
  };
</script>

<style>
  .container {
    background: url('/bg.jpg');
    background-size: cover;
    width: calc(100vw - (100vw - 100%));
    height: calc(100vh - 6rem);
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
</style>

<div class="container">
  {#if view === 'login'}
    <Login
      on:forgotten={() => (view = 'forgotten')}
      on:password={({ detail }) => {
        view = 'change';
        needConfirmation = false;
        session = detail;
      }}
      on:activate={() => (view='signup')}
      bind:email
    />
  {:else if view === 'forgotten'}
    <PasswordForgotten
      on:confirmation={() => {
        view = 'change';
        needConfirmation = true;
      }}
      bind:email
    />
  {:else if view === 'change'}
    <ChangePassword
      {needConfirmation}
      bind:confirmationCode
      {encryptedData}
      {session}
      bind:email
    />
  {:else if view === 'signup'}
    <SignUpUser
      on:back={() => view = 'login'}
      {needConfirmation}
      bind:confirmationCode
      {encryptedData}
      {session}
      bind:email
      on:signedUp={() => userSignedUp()}
    />
  {/if}
</div>

<script>
  import { tick, createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import * as KeyCode from 'keycode-js';

  export let value = '';
  export let type = 'text';
  export let placeholder = '';
  export let maxIntegers = '';
  export let textAlignment = undefined;
  export let inputId = undefined;
  export let maxlength = undefined;
  export let allowEmpty = false;
  export let dataFormatter = undefined;

  const PRICE_REGEX = new RegExp(`^\\d{0,${maxIntegers}}((,|\\.)\\d{0,2})?$`);
  const NUMBER_REGEX = new RegExp('^\\d*$');

  const dispatch = createEventDispatcher();

  let editing = false;
  let inputEl;
  let label;
  let initialValue;
  let previousValue;
  let duration;

  $: isText = type === 'text';
  $: isNumber = type === 'number';
  $: isPrice = type === 'price';
  $: isDecimal = type === 'decimal';
  $: isDuration = type === 'duration'; //Unfinished
  $: regex = isPrice || isDecimal ? PRICE_REGEX : NUMBER_REGEX;

  $: if (dataFormatter) {
    label = dataFormatter(value);
  } else if (isNumber) {
    label = !value ? placeholder : value;
  } else if (isPrice) {
    label = Number.isFinite(value)
      ? (+value).toLocaleString('fi-FI', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        })
      : placeholder;
  } else if (isDecimal) {
    label = Number.isFinite(value)
      ? (+value).toLocaleString('fi-FI', { useGrouping: false })
      : placeholder;
  } else if (isDuration) {
    label = `${Math.floor(duration / 60) ? `${Math.floor(duration / 60)} h ` : ''}${
      duration % 60 ? `${duration % 60} min` : ''
    }`;
  } else {
    label = value ? value : placeholder;
  }

  const toggle = async _ => {
    editing = !editing;

    if (editing) {
      initialValue = value;
      previousValue = value;
      await tick();
      inputEl.focus();
    }
  };

  const handleInput = e => {
    let success = true;
    if (isPrice || isNumber || isDecimal) {
      if (regex.test(e.target.value)) {
        value = e.target.value;
      } else {
        success = false;
      }
    } else {
      value = isNumber ? +e.target.value : e.target.value;
    }

    if (success) {
      previousValue = value;
    } else {
      inputEl.value = previousValue;
    }
  };

  const handleKey = e => {
    if (e.keyCode === KeyCode.KEY_ENTER || e.keyCode === KeyCode.KEY_RETURN) {
      inputEl.blur();
    } else if (e.keyCode === KeyCode.KEY_ESCAPE) {
      value = initialValue;
      toggle();
    }
  };

  const handleBlur = () => {
    if (isPrice || isNumber || isDecimal) {
      value = value && value.length ? +value.replace(',', '.') : value;

      if (!Number.isFinite(value)) {
        value = allowEmpty ? null : initialValue;
      }
      dispatch('complete', {
        id: inputId,
        value,
      });
    } else if (!allowEmpty && (value === undefined || value === null || value.length === 0)) {
      value = initialValue;
    } else {
      dispatch('complete', {
        id: inputId,
        value,
      });
    }
    toggle();
  };
</script>

<style>
  input {
    margin: 0;
    padding: 0.4em !important;
    width: 100%;
    font-size: 1rem;
    height: unset;
  }
  div.label {
    margin: 1px;
    padding: 0.4em;
    min-height: 20px;
    cursor: pointer;
  }
</style>

{#if editing}
  <input
    style={textAlignment ? `text-align: ${textAlignment}` : undefined}
    bind:this={inputEl}
    type={isPrice || isNumber || isDecimal ? 'text' : type}
    value={(isPrice || isDecimal) && value ? value.toString().replace('.', ',') : value || ''}
    {placeholder}
    {maxlength}
    on:input={handleInput}
    on:keyup={handleKey}
    on:blur={handleBlur}
    size="1"
  />
{:else}
  <div
    class="label"
    style={textAlignment ? `text-align: ${textAlignment}` : undefined}
    tabindex="0"
    on:focus={toggle}
  >
    {label}
  </div>
{/if}

<script>
  import { onDestroy } from 'svelte';
  import { _ } from 'svelte-i18n';
  import Select from '../../components/Select.svelte';
  import Loading from '../../components/Loading.svelte';
  import TabBar from '../../components/TabBar.svelte';
  import {
    companiesLoaded,
    getCompanies,
    companies as companyList,
  } from '../../stores/companies';
  import {
    prices,
    updatePricingData,
    currentCompany as currentCompanyStore,
  } from '../../stores/pricing';
  import PricingBasic from './PricingBasic.svelte';
  import PricingSpecial from './PricingSpecial.svelte';
  import PricingOther from './PricingOther.svelte';
  import PricingNoSale from './PricingNoSale.svelte';
  import PricingZone from './PricingZone.svelte';
  import { showError } from '../../stores/notifications';
  import { currentUser } from '../../stores/user';
  import { getCompaniesByFeature } from '../../stores/features';

  $: features = ($currentUser && $currentUser.features) || [];
  $: companiesForPricing = getCompaniesByFeature(features, 'PRICING') || [];
  $: companyListFilteredByFeature = $companyList.filter(company => companiesForPricing.includes(company.number)).map(entry => ({
      value: entry.number,
      label: `${entry.name} (${entry.number})`,
    }));


  const TABS = ['basic', 'special', 'other', 'noSale', 'zone'];

  const tabEntries = TABS.map(tab => ({
    id: tab,
    title: $_(`pricing.sections.${tab}`),
  }));
  let activeTab = localStorage.getItem('pricingTab') || TABS[0];

  let currentCompany = $currentCompanyStore;

  const checkIfCompaniesLoaded = async () => {
    if (!$companiesLoaded) {
      try {
        await getCompanies();
      } catch (error) {
        showError($_('error.title', error.message));
      }
    }
  };

  const getCompanyData = () => {
    if (currentCompany && !$prices[currentCompany]) {
      updatePricingData(currentCompany);
    }
  };

  $: {
    if (!companyListFilteredByFeature.map(({ value }) => value).includes(currentCompany))
          currentCompany = companyListFilteredByFeature[0] && companyListFilteredByFeature[0].value;
    getCompanyData();
  }
/*
  companyList.subscribe(value => {
    companies = value.map(entry => ({
      value: entry.number,
      label: `${entry.name} (${entry.number})`,
    }));
    if (!companies.map(({ value }) => value).includes(currentCompany))
      currentCompany = companies[0] && companies[0].value;
    getCompanyData();
  });
*/
  onDestroy(() => {
    currentCompanyStore.set(currentCompany);
  });

  checkIfCompaniesLoaded();
</script>

<div class="flex bg-mlight py-6 w-full px-6 space-x-4">
  <Select entries={companyListFilteredByFeature} bind:value={currentCompany} on:change={getCompanyData} primaryText />
  <TabBar entries={tabEntries} bind:activeTab localStorageItem="pricingTab" />
</div>

<div class="w-full pt-4 px-16 pb-2">
  {#if $prices[currentCompany]}
    {#if activeTab === 'basic'}
      <PricingBasic {currentCompany} />
    {:else if activeTab === 'special'}
      <PricingSpecial {currentCompany} />
    {:else if activeTab === 'other'}
      <PricingOther {currentCompany} />
    {:else if activeTab === 'noSale'}
      <PricingNoSale {currentCompany} />
    {:else if activeTab === 'zone'}
      <PricingZone {currentCompany} />
    {/if}
  {:else}
    <Loading />
  {/if}
</div>

<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy } from 'svelte';
  import SearchTerms from '../components/travel_reports/SearchTerms.svelte';
  import SelectedLineReports from '../components/travel_reports/SelectedLineReports.svelte';
  import { searchTerms } from '../stores/travel_reports';
  import FirstLevelReport from '../components/travel_reports/FirstLevelReport.svelte';
  import PreGeneratedReports from '../components/travel_reports/PreGeneratedReports.svelte';
  import { featuresLoaded } from '../stores/features';
  import { FEATURE_BY_FEATURE, currentUser } from '../stores/user';

  import TabBar from '../components/TabBar.svelte';

  const SEARCH_SECTION = 'search';
  const PREGERATED_SECTION = 'pregenerated';

  $: features = ($currentUser && $currentUser.features) || [];

  $: TABS = ($featuresLoaded && features && Object.keys(features && features[FEATURE_BY_FEATURE] || {}).some(it => it.startsWith(`REPORTS_MONTHLY`))) ? [SEARCH_SECTION, PREGERATED_SECTION] : [SEARCH_SECTION];

  let activeTab = SEARCH_SECTION;
  $: tabEntries = TABS.map(tab => ({
    id: tab,
    title: $_(`travelReports.sections.${tab}`),
  })) || [];
  

  let start = $searchTerms.start || new Date();
  if (!$searchTerms.start) {
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setDate(start.getDate() - 1);
  }
  let end = $searchTerms.end || new Date(start);
  let lineId = $searchTerms.lineId || null;
  let departureId = $searchTerms.departureId || null;
  let companies = $searchTerms.companies || [];
  let tickets = $searchTerms.tickets || [];
  let weekdays = $searchTerms.weekdays || [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  let selectedLine = {};
  let selectedDay = {};
  let fromFirstLevel = false;
  let reportType = 'products';
  let openedReportDay = null;
  let deviceType = undefined;

  onDestroy(() => {
    searchTerms.set({
      start,
      end,
      lineId,
      departureId,
      companies,
      weekdays,
      tickets,
    });
  });

  const handleDaySelected = ({ detail: { line, day } }) => {
    selectedLine = line;
    selectedDay = { day };
    fromFirstLevel = true;
  };

  let registeredSearchFunction = undefined;
  const retrySearch = () => {
    registeredSearchFunction && registeredSearchFunction();
  };

  const registerSearchFunction = (fn) => registeredSearchFunction = fn;
</script>

<style>
  .content {
    padding: 2em 4em 1em 4em;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .results {
    padding-top: 2em;
    width: 100%;
  }
</style>

<div class="flex bg-mlight py-6 w-full px-6 space-x-4">
  <TabBar entries={tabEntries} bind:activeTab localStorageItem="travelReportsTab" />
</div>

<div class="content">
  {#if activeTab === SEARCH_SECTION}
    <div class="header">
      <h1 class="mb-6 text-3xl font-bold">{$_('travelReports.title')}</h1>
    </div>

    <SearchTerms
      bind:start
      bind:end
      bind:lineId
      bind:departureId
      bind:companies
      bind:weekdays
      bind:line={selectedLine}
      bind:tickets
      bind:deviceType
      registerSearchFunction={registerSearchFunction}
    />
    <div class="results">
      {#if selectedLine.departure_id}
        <SelectedLineReports
          bind:start
          bind:end
          bind:line={selectedLine}
          bind:selectedDay
          bind:fromFirstLevel
          bind:weekdays
          bind:tickets
          bind:deviceType
        />
      {:else}
        <FirstLevelReport
          bind:start
          bind:end
          bind:lineId
          bind:departureId
          bind:companies
          bind:weekdays
          bind:openedReportDay
          bind:reportType
          bind:tickets
          bind:deviceType
          on:line={({ detail: line }) => (selectedLine = line)}
          on:day={handleDaySelected}
          retrySearch={retrySearch}
        />
      {/if}
    </div>
  {:else}
    <PreGeneratedReports />
  {/if}
</div>

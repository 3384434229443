<script>
  export let activeTab;
  export let localStorageItem;
  export let entries;
</script>

<style>
  span::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0;
    background-color: #fff0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0%;
    transition: all 250ms;
  }

  span.active::after {
    background-color: #307FE2;
    width: calc(100% - 14px);
  }
</style>

<div class="flex space-x-4 lg:space-x-8">
  {#each entries as tab}
    <span
      class="tab text-sm lg:text-base relative cursor-pointer py-2"
      on:click={() => {
        activeTab = tab.id;
        if (!localStorageItem) return;
        localStorage.setItem(localStorageItem, tab.id);
      }}
      class:active={activeTab === tab.id}
    >
      {tab.title}
    </span>
  {/each}
</div>

<script>
  import { _ } from 'svelte-i18n';
  import { slide } from 'svelte/transition';

  import RadioButton from '../RadioButton.svelte';

  export let feature;
  export let features;
  export let valid;
  export let updateFeature;
  export let labelClass='label';

  const isFeature = (code, feature) => feature === code || feature.startsWith(`${code};`);

  const getFeatureForLines = (feature, lines) => {
    return `${feature};${lines
      .map(({ line, departure }) => `${line || ''}-${departure || ''}`)
      .join(';')}`;
  };

  const currentFeature =
    features.find(featureCode => isFeature(feature && feature.code, featureCode)) || '';
  let limit = currentFeature.includes(';') ? 'selected' : 'all';

  const getLines = (feature, features) => {
    const code = feature && feature.code;
    if (!code || limit === 'all') return [{ line: '', departure: '' }];
    const currentFeature = features.find(featureCode => isFeature(code, featureCode));
    const [, ...lines] = currentFeature.split(';');
    const accessLines = lines.map(access => {
      const [line, departure] = access.split('-');
      return { line, departure };
    });
    if (!accessLines.length) accessLines.push({ line: '', departure: '' });
    return accessLines;
  };

 // let lines = getLines(feature, features);
 $: lines = getLines(feature, features);
  $: handleLineChange(lines);

  $: valid =
    limit === 'all' ||
    (!!lines.length && !lines.some(({ line, departure }) => !line && !departure));

  const handleLineChange = lines => {
    const code = feature && feature.code;
    if (!code) return;
    if (limit === 'all') {
      features = features.map(it => (isFeature(code, it) ? it.split(';')[0] : it));
      return;
    }

    features = features.map(it =>
      isFeature(code, it) ? getFeatureForLines(code, lines) : it,
    );
  };

  const updateLineOrDeparture = (event, property, index) => {
    const val = event.target.value;
    lines[index][property] = val;
    updateFeature(feature, getFeatureForLines(feature.code, lines));
  };
</script>

<style>
  .feature-line-access {
    font-size: small;
    margin-left: 2.3rem;
  }

  .error {
    background: #ffbbd9;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 1rem;
  }
</style>

<div class="ml-2 mb-4 feature-line-access">
  <RadioButton
    label={$_('users.editor.allLines')}
    value="all"
    on:change={handleLineChange}
    bind:group={limit}
  />
  <RadioButton
    label={$_('users.editor.selectedLines')}
    value="selected"
    bind:group={limit}
  />
  {#if limit === 'selected'}
    <table cellspacing="0">
      <tr>
        <th class="font-semibold text-left border-b border-mdark-gray">
          {$_('users.editor.lines.line')}
        </th>
        <th class="font-semibold text-left border-b border-mdark-gray" colspan="2">
          {$_('users.editor.lines.departure')}
        </th>
      </tr>
      {#each lines as { line, departure }, i}
        <tr>
          <td class="border-b border-mdark-gray bg-mlight">
            <input class="border-none bg-mlight pb-0" type="text" on:change={(event) => updateLineOrDeparture(event, 'line', i)} value={line} />
          </td>
          <td class="border-b border-mdark-gray bg-mlight">
            <input
              class="border-none bg-mlight pb-0"
              type="text"
              on:change={(event) => updateLineOrDeparture(event, 'departure', i)}
              value={departure}
            />
          </td>
          <td
            class="font-semibold text-mnormal cursor-pointer border-b border-mdark-gray bg-white pl-1"
            on:click={() => (lines = lines.filter((_, index) => index !== i))}
          >
            {$_('users.editor.lines.remove')}
          </td>
        </tr>
      {/each}
      <tr>
        <td
          colspan="3"
          class="font-semibold text-mnormal cursor-pointer"
          on:click={() => (lines = [...lines, { line: '', departure: '' }])}
        >
          {$_('users.editor.lines.add')}
        </td>
      </tr>
    </table>
    {#if !valid}
      <p transition:slide class="error">{$_('users.editor.lines.invalid')}</p>
    {/if}
  {/if}
</div>

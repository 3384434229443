<script>
  /*eslint-disable no-undef*/
  import { onMount, createEventDispatcher } from 'svelte';
  import { generateBusIcon, generateLineIcon } from '../../common/map_icons';
  import { tripInfo } from '../../stores/map_tracking';

  const dispatch = createEventDispatcher();

  const platform = new H.service.Platform({
    apikey: process.env.HERE_API_KEY,
  });
  const router = platform.getRoutingService();

  const defaultLayers = platform.createDefaultLayers();

  let map = null;
  export let positions;
  export let selectedLine;
  export let selectedLineTrip;
  let lastSelectedLineTrip = '';
  let lastDrawnLine = '';

  const isMSIE = window.navigator.userAgent.indexOf('Trident/') > 0;

  let mapElement = null;
  let clusteredDataProvider = null;

  let zoomInitPositionLength = 0;
  let zoomInitDate = new Date().getTime();
  const ZOOM_INIT_TIME = 3000; // Needed because we do not get a list of all buses at once

  const zoomOnMarkers = markers => {
    const group = new H.map.Group();
    group.addObjects(markers);
    map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() });
  };

  $: {
    if (
      zoomInitDate > new Date().getTime() - ZOOM_INIT_TIME &&
      zoomInitPositionLength < positions.length &&
      map
    ) {
      zoomInitPositionLength = positions.length;
      const markers = positions
        .map(({ vehicles }) => vehicles)
        .flat()
        .map(
          position =>
            new H.map.Marker({
              lat: position.latitude,
              lng: position.longitude,
            }),
        );
      zoomOnMarkers(markers);
    }
  }

  $: {
    if (selectedLineTrip && lastSelectedLineTrip !== selectedLineTrip) {
      const markers = positions
        .map(({ vehicles }) => vehicles)
        .flat()
        .filter(({ trip_id }) => trip_id === selectedLine)
        .map(
          position =>
            new H.map.Marker({
              lat: position.latitude,
              lng: position.longitude,
            }),
        );
      zoomOnMarkers(markers);
    }
    lastSelectedLineTrip = selectedLineTrip;
  }

  const busIconParams = {
    size: { w: 48, h: 48 },
    anchor: { x: 24, y: 24 },
  };

  const clusterSvgTemplate = `<div><svg xmlns="http://www.w3.org/2000/svg" height="{diameter}" width="{diameter}" style="transform: translate(-50%, -50%)">
  <g id="UrTavla">
    <circle cx="{radius}px" cy="{radius}px" r="{radius}px" fill="#333333" />
    <text x="50%" y="50%" text-anchor="middle" fill="white" dy=".3em" style="font-family: 'Arial'; font-size: {fontSize}px;">{cluster}</text></g>
    </svg></div>`;

  onMount(() => {
    const engineType = isMSIE ? H.Map.EngineType.P2D : H.Map.EngineType.WEBGL;
    const baseLayers = isMSIE ? defaultLayers.raster : defaultLayers.vector;

    map = new H.Map(mapElement, baseLayers.normal.map, {
      zoom: 13,
      center: { lat: 60.1699, lng: 24.9384 },
      engineType,
    });

    H.ui.UI.createDefault(map, defaultLayers, 'fi-FI');
    const mapEvents = new H.mapevents.MapEvents(map);
    const behavior = new H.mapevents.Behavior(mapEvents);
    behavior.enable(H.mapevents.Behavior.WHEELZOOM);
    behavior.enable(H.mapevents.Behavior.PINCH_ZOOM);
    behavior.enable(H.mapevents.Behavior.DBL_TAP_ZOOM);
    behavior.enable(H.mapevents.Behavior.DRAGGING);
    clusteredDataProvider = new H.clustering.Provider([], {
      theme: {
        getClusterPresentation: function (cluster) {
          let size = 0;
          cluster.forEachDataPoint(() => size++);
          const radius = 20;
          const diameter = radius * 2;
          const svgString = clusterSvgTemplate
            .replace(/\{cluster\}/g, size)
            .replace(/\{fontSize\}/g, radius / size.toString().length)
            .replace(/\{radius\}/g, radius)
            .replace(/\{diameter\}/g, diameter);
          const clusterIcon = new H.map.DomIcon(svgString, {
            size: { w: diameter, h: diameter },
            anchor: { x: radius, y: radius },
          });

          const clusterMarker = new H.map.DomMarker(cluster.getPosition(), {
            icon: clusterIcon,
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom(),
          });

          clusterMarker.setData(cluster);

          return clusterMarker;
        },
        getNoisePresentation: function (busPoint) {
          const data = busPoint.getData();
          const busMarker = new H.map.DomMarker(busPoint.getPosition(), {
            icon: new H.map.DomIcon(
              data.trip_id == selectedLine
                ? generateBusIcon(data.bearing)
                : generateLineIcon(data.bearing, data.departureId.split('$')[0], data.basic),
              busIconParams,
            ),
            min: busPoint.getMinZoom(),
          });
          busMarker.setData(busPoint);
          return busMarker;
        },
      },
    });
    const layer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    map.addLayer(layer);

    clusteredDataProvider.addEventListener('tap', async evt => {
      if (!evt.target.getData) return;
      const data = evt.target.getData();
      if (!data || !data.getData) return;
      dispatch('lineSelected', data.getData() || data);
    });
  });

  $: {
    selectedLine; // Statement needed so that svelte knows to run this code when selectedLine changes
    if (map && clusteredDataProvider) {
      clearMap();
      drawPositions(positions);
    }
  }

  const getPointsOnMap = positions => {
    if (!positions || !positions.vehicles) return [];
    return positions.vehicles.map(position => {
      const point = new H.clustering.DataPoint(
        position.latitude,
        position.longitude,
        1,
        position,
      );
      return point;
    });
  };

  const drawPositions = positions => {
    const dataPoints = Object.values(positions)
      .filter(it => it)
      .reduce((acc, cur) => [...acc, ...getPointsOnMap(cur)], []);
    clusteredDataProvider.setDataPoints(dataPoints);
  };

  const getRouteWaypoint = stop => `geo!${stop.nCoordinate},${stop.eCoordinate}`;

  const drawRouteCircle = stop => {
    const circle = new H.map.Marker(
      { lat: stop.nCoordinate, lng: stop.eCoordinate },
      {
        data: { type: 'ROUTE' },
        icon: new H.map.Icon(
          `<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10">
    <circle cx="5px" cy="5px" r="5px" fill="#121212" />
    </svg>`,
          {
            size: { w: 20, h: 20 },
            anchor: { x: 10, y: 10 },
          },
        ),
      },
    );
    map.addObject(circle);
  };

  const calculatedRoutes = {};

  const getCalculatedRoute = (tripParams, currentRoute) =>
    new Promise(resolve => {
      if (calculatedRoutes[tripParams]) return resolve(calculatedRoutes[tripParams]);
      const routingParameters = {
        mode: 'fastest;car',
        ...currentRoute.reduce((acc, cur, i) => {
          acc[`waypoint${i}`] = getRouteWaypoint(cur);
          return acc;
        }, {}),
        representation: 'display',
      };
      router.calculateRoute(routingParameters, response => {
        lastDrawnLine = selectedLine;
        const shape = response.response.route[0].shape.map(x => x.split(','));
        const linestring = new H.geo.LineString();
        shape.forEach(s => linestring.pushLatLngAlt(s[0], s[1]));
        const routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: '#121212', lineWidth: 3 },
          data: { type: 'ROUTE' },
        });
        //map.addObject(routeLine);
        calculatedRoutes[tripParams] = routeLine;
        return resolve(routeLine);
      });
    });

  $: {
    const isNewLine = lastDrawnLine !== selectedLine;

    if (isNewLine) {
      clearRoute();
    }
    let [currentLine] = Object.values(positions).filter(
      ({ trip_id }) => trip_id === selectedLine,
    );
    if (currentLine) {
      const currentRoute = $tripInfo[currentLine.trip_id];
      if (currentRoute && currentRoute.length) {
        getCalculatedRoute(currentLine.tripParams, currentRoute)
          .then(routeLine => {
            map.addObject(routeLine);
          })
          .catch(console.error);
        const firstStop = currentRoute[0];
        drawRouteCircle(firstStop);
        const lastStop = currentRoute[currentRoute.length - 1];
        drawRouteCircle(lastStop);
      }
    }
  }

  const clearRoute = () => {
    if (!map) return;
    map.removeObjects(
      map
        .getObjects()
        .filter(obj => obj.getData && (obj.getData() || {}).type === 'ROUTE'),
    );
  };

  const clearMap = () => {
    if (!map) return;
    map.removeObjects(
      map
        .getObjects()
        .filter(obj => !obj.getData || (obj.getData() || {}).type !== 'ROUTE'),
    );
    clusteredDataProvider.setDataPoints([]);
  };
</script>

<style>
  div.map {
    width: calc(100vw - (100vw - 100%));
    height: calc(100vh - 6rem);
  }
</style>

<svelte:window on:resize={() => map && map.getViewPort().resize()} />

<div class="map" bind:this={mapElement} />

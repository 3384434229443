import { writable, get } from 'svelte/store';
import { getClient, setAuthHeader, removeAuthHeader } from '../common/http';
import { getFeatures } from './features';

export const currentUser = writable();

const profileSessionKey = 'profile';

const REFRESK_TOKEN_BEFORE_EXPIRATION_BUFFER = 600000; //10 minutes

export const FEATURE_BY_COMPANY = 'byCompany';
export const FEATURE_BY_FEATURE = 'byFeature';
export const SUPERUSER = 'SUPERUSER';

export const isSuperUser = (features) => {
  return features && features[SUPERUSER] === true;
};

export const getUserCompanies = () => {
  const user = get(currentUser);
  return user && user.companies;
};

function arrayOfFeaturesToGroupedFeatures(arrayOfFeatures) {
  return arrayOfFeatures.reduce(groupFeatures, {[FEATURE_BY_COMPANY]: {}, [FEATURE_BY_FEATURE]: {}});
}

function getCurrentUserDetailsFromSession() {
  const profile = getTokenFromStorage(profileSessionKey);
  if (!profile) return { loggedIn: false };

  const profileObj = { ...JSON.parse(profile) };
  console.log('profile', Array.isArray(profileObj.features), profileObj.features, profileObj);

  if ( Array.isArray(profileObj.features)) {
    profileObj.features = arrayOfFeaturesToGroupedFeatures(profileObj.features);
  }
  
  return { ...profileObj, loggedIn: true };
}


const groupFeatures = (acc, feature) => {
  if ( feature === 'DEVICES' ) {
    acc[FEATURE_BY_FEATURE][feature] = [];
    return acc;
  }

  if (feature === SUPERUSER ) {
    acc[SUPERUSER] = true;
    return acc;
  }
  
  
  const [company, featureCode] = feature.split('$');
  if (!acc[FEATURE_BY_FEATURE][featureCode]) {
    acc[FEATURE_BY_FEATURE][featureCode] = [];
  }

  if (!acc[FEATURE_BY_COMPANY][company]) {
    acc[FEATURE_BY_COMPANY][company] = [];
  }

  acc[FEATURE_BY_COMPANY][company].push(featureCode);
  acc[FEATURE_BY_FEATURE][featureCode].push(company);

  return acc;
};

export async function getCurrentUserProfile() {

  const { data } = await getClient().get('/api/me');

  const user = {
    ...data,
    companies: ((data && data.companies) || '').split(',').map(it => it.trim()),
    features: arrayOfFeaturesToGroupedFeatures(((data && data.features) || '').split(',').map(it => it.trim()))
  };

  return user;
}

function genCurrentUserDetails(idToken, email, name, exp, companies, features) {

  return {
    idToken,
    username: email,
    name,
    loggedIn: true,
    exp: exp * 1000,
    companies,
    features,
  };
}

function getRefreshTimeout(expiry) {
  return expiry - new Date().getTime() - REFRESK_TOKEN_BEFORE_EXPIRATION_BUFFER;
}

export async function init() {

  const data = getCurrentUserDetailsFromSession();
  if (!data || !data.loggedIn) return;
  setAuthHeader(data.idToken);


  currentUser.set(data);
  const profile = await getCurrentUserProfile();
  currentUser.set({ ...data, ...profile });
  getFeatures().catch(console.error);
  const refreshTime = getRefreshTimeout(data.exp);
  scheduleRefreshing(refreshTime);
}

export async function logout() {
  if (lastScheduledRefreshing) {
    clearTimeout(lastScheduledRefreshing);
  }
  await getClient().post('/auth/logout').catch(console.error);

  currentUser.set(null);
  sessionStorage.removeItem(profileSessionKey);
  localStorage.removeItem(profileSessionKey);
  removeAuthHeader();
}

const getTokenFromStorage = name => {
  if (localStorage.getItem(name) !== null) {
    return localStorage.getItem(name);
  }
  return sessionStorage.getItem(name);
};

export async function signIn(username, password) {
  const res = await getClient().post('/auth/login', { username, password });
  if (res.status !== 200) {
    const msg = (res.data && res.data.errorMessage) || `${res.status}: ${res.statusText}`;
    throw new Error(msg);
  }
  if (!res.data) {
    throw new Error('Login failed');
  }
  if (res.data.newPassword) {
    throw res.data;
  }
  if (!res.data.IdToken) {
    throw new Error('Login failed');
  }
  const { IdToken, ExpiresIn, email, name, companies, features } = res.data;
  const userData = genCurrentUserDetails(
    IdToken,
    email,
    name,
    ExpiresIn,
    companies,
    arrayOfFeaturesToGroupedFeatures(features),
  );
  saveTokens(true, userData);
  setAuthHeader(IdToken);

  // signInUserName temporary fix for loading user list directly after logging in
  currentUser.set({...userData, signInUserName: username});
  const refreshTime = getRefreshTimeout(userData.exp);
  scheduleRefreshing(refreshTime);
  getFeatures().catch(console.error);
}

let lastScheduledRefreshing = null;
function scheduleRefreshing(when) {
  if (lastScheduledRefreshing) {
    clearTimeout(lastScheduledRefreshing);
  }
  if (!when || when < 0) return;
  setTimeout(() => {
    refreshToken().catch(console.error);
  }, when);
}

export async function refreshToken() {
  const res = await getClient().get('/auth/refresh');
  if (res.status !== 200) {
    const msg = (res.data && res.data.errorMessage) || `${res.status}: ${res.statusText}`;
    throw new Error(msg);
  }
  if (!res.data || !res.data.IdToken) {
    throw new Error('Login failed');
  }
  const { IdToken } = res.data;
  const userData = { ...getCurrentUserDetailsFromSession(), idToken: IdToken };
  saveTokens(true, userData);
  setAuthHeader(IdToken);

  currentUser.set(userData);
  const refreshTime = getRefreshTimeout(userData.exp);
  scheduleRefreshing(refreshTime);
}

const saveTokens = (rememberMe, profileData) => {
  const profile = JSON.stringify(profileData);
  if (rememberMe === true || rememberMe === 'true') {
    sessionStorage.removeItem(profileSessionKey);
    localStorage.setItem(profileSessionKey, profile);
  } else {
    localStorage.removeItem(profileSessionKey);
    sessionStorage.setItem(profileSessionKey, profile);
  }
};

export async function resetPassword(email) {
  await getClient().post('/auth/reset', { email });
}

export async function confirmChangePassword(
  email,
  password,
  confirmationCode,
  encryptedData,
) {
  await getClient().post('/auth/confirmChange', {
    email,
    password,
    confirmationCode,
    encryptedData,
  });
}

export async function confirmSignUp(
  email,
  password,
  confirmationCode,
  encryptedData,
) {
  const res = await getClient().post('/auth/confirmSignUp', {
    email,
    password,
    confirmationCode,
    encryptedData,
  });


  return res;
}

export async function changePassword(email, password, session) {
  await getClient().post('/auth/change', {
    email,
    password,
    session,
  });
}

<script>
  import { fly } from 'svelte/transition';
  import * as KeyCode from 'keycode-js';
  export let id;
  export let label = undefined;
  export let required = false;
  export let placeholder = '';
  export let onChange;
  export let onSelection;
  export let onBlur = undefined;
  export let options = [];
  export let errorMessage = undefined;
  export let loading = false;

  let input;
  export let inputValue;
  let activeOption;
  let showOptions = false;

  $: error = !!errorMessage;

  function setOptionsVisibility(show) {
    if (typeof show === 'boolean') {
      showOptions = show;
      show && input.focus();
    } else {
      showOptions = !showOptions;
    }
    if (!showOptions) {
      activeOption = undefined;
    }
  }

  function handleKeyup(e) {
    if (e.keyCode === KeyCode.KEY_RETURN) {
      inputValue = activeOption.name;
      setOptionsVisibility(false);
      onSelection(activeOption.value);
    }
    if ([KeyCode.KEY_UP, KeyCode.KEY_DOWN].includes(e.keyCode)) {
      // up and down arrows
      const increment = e.keyCode === KeyCode.KEY_UP ? -1 : 1;
      const calcIndex = options.indexOf(activeOption) + increment;
      activeOption =
        calcIndex < 0
          ? options[options.length - 1]
          : calcIndex === options.length
          ? options[0]
          : options[calcIndex];
    }
  }

  function handleBlur() {
    setOptionsVisibility(false);
    if (onBlur) onBlur();
  }

  function handleOptionMousedown(e) {
    const value = e.target.dataset.value;
    inputValue = e.target.dataset.name;
    setOptionsVisibility(false);
    onSelection(value);
  }
</script>

<style>
  .autocomplete {
    background-color: white;
    border: 1px solid #b2c3cf;
    border-radius: 2px;
    position: relative;
  }

  .actions {
    align-items: center;
    display: flex;
    flex: 1;
    min-width: 15rem;
  }

  input {
    border: none;
    padding: 0.4em;
    height: unset;
    font-size: 1em;
    line-height: 1.5rem;
    margin: 0;
    outline: none;
    width: 100%;
  }

  .options {
    box-shadow: 0px 2px 4px #0001, 0px -2px 4px #0001;
    left: 0;
    list-style: none;
    margin-block-end: 0;
    margin-block-start: 0;
    max-height: 70vh;
    overflow: auto;
    padding-inline-start: 0;
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    z-index: 100;
  }
  li {
    background-color: white;
    cursor: pointer;
    padding: 0.5rem;
  }
  li:last-child {
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  li:not(.selected):hover {
    background-color: #E5E8EF;
  }
  li.active {
    background-color: #E5E8EF;
  }
  .autocomplete.input-error {
    border-color: #EA5798;
  }
  p.input-error {
    color: #EA5798;
  }
</style>

<div class="input-field-main-div">
  {#if label}
    <label class="input-label" for={id}>{label}:{required ? ' *' : ''}</label>
  {/if}
  <div class="autocomplete" class:input-error={error}>
    <div class="actions">
      <input
        {id}
        autocomplete="off"
        bind:value={inputValue}
        on:input={(event) => {
          setOptionsVisibility(true);
          onChange(event);
        }}
        bind:this={input}
        on:keyup={handleKeyup}
        on:blur={handleBlur}
        class="autocomplete-input"
        {placeholder}
      />
      {#if loading}
      <svg
        class="animate-spin color-mnormal mr-1"
        xmlns="http://www.w3.org/2000/svg"
        width="1.2em"
        height="1.2em"
        viewBox="0 0 24 24"
      >
        <path fill="#001E61" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      {/if}
    </div>

    {#if showOptions}
      <ul
        class="options"
        transition:fly={{ duration: 200, y: 5 }}
        on:mousedown|preventDefault={handleOptionMousedown}
      >
        {#each options as option}
          <li
            class:active={activeOption === option}
            data-value={option.value}
            data-name={option.name}
          >
            {option.name}
          </li>
        {/each}
      </ul>
    {/if}
  </div>
  {#if errorMessage}
    <p class="input-error">{errorMessage}</p>
  {/if}
</div>

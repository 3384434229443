<script>
  import { _, date } from 'svelte-i18n';
  import DatePicker from '../DatePicker.svelte';
  import Expandable from '../Expandable.svelte';
  import Checkbox from '../Checkbox.svelte';
  import Select from '../Select.svelte';
  import Loading from '../Loading.svelte';
  import MultiSelect from '../MultiSelect.svelte';
  import DropDownArrowSmall from '../icons/DropDownArrowSmall.svelte';
  import Icon from 'fa-svelte';
  import { faChevronDown, faCalendar } from '@fortawesome/free-solid-svg-icons';
  import { searchResults, startSearch } from '../../stores/travel_reports';
  import {
    companies as companyList,
    companiesLoaded,
    getCompanies,
  } from '../../stores/companies';
  import {
    updatePricingData,
    prices,
    currentCompany as currentCompanyStore,
  } from '../../stores/pricing';
  import { showError } from '../../stores/notifications';
  import { getCompaniesByFeature } from '../../stores/features';
  import { currentUser } from '../../stores/user';

  $: features = ($currentUser && $currentUser.features) || [];

  let expanded = $searchResults.products.length === 0 && $searchResults.days.length === 0;
  let weekdaysExpanded = false;

  $: companiesForReporting = getCompaniesByFeature(features, 'TRAVELREPORTS') || [];
  $: companyListFilteredByFeature = $companyList.filter(company => companiesForReporting.includes(company.number));
  
  
  const WEEKDAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  export let start;
  export let end;
  export let lineId;
  export let departureId;
  export let weekdays;
  export let companies;
  export let line;
  export let lines = false;
  export let noSales = false;
  export let tickets;
  export let deviceType;
  export let registerSearchFunction;

  let currentCompany = $currentCompanyStore;



  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);

  if (!$companiesLoaded) {
    getCompanies().catch(showError($_('error.title')));
  }

  const search = () => {
    line = {};
    startSearch({
      start,
      end: new Date(new Date(end).setDate(end.getDate() + 1)),
      lineId,
      departureId,
      companies,
      weekdays,
      lines,
      tickets,
      noSales,
      deviceType,
    })
      .then(() => (expanded = false))
      .catch(showError($_('error.title')));
  };

  if (registerSearchFunction) {
    registerSearchFunction(search);
  }
  
  const updateCompanyData = () => {
    if ($companiesLoaded) {
      $companyList
        .filter(company => company && !$prices[company.number])
        .forEach(company => updatePricingData(company.number));
    }
  };

  companyList.subscribe(() => {
    if (!companies.map(({ value }) => value).includes(currentCompany))
      currentCompany = companies[0] && companies[0].value;
    updateCompanyData();
  });

  let ticketFilter = [];

  const handleAdd = company => {
    companies = [...companies, company.number];
    const ticketProperties = [
      'basicTickets',
      'noSaleDescriptions',
      'otherProducts',
      'otherTickets',
    ];
    const companyPricing = $prices[company.number];
    ticketProperties.forEach(property => {
      if (!companyPricing || !companyPricing[property]) return;
      companyPricing[property].forEach(
        ticket => (ticketFilter = [...ticketFilter, ticket]),
      );
    });

    if ($prices[company.number] && $prices[company.number].zoneTickets) {
      const zoneTickets = $prices[company.number].zoneTickets;
      const zoneTicketObjects = Object.keys(zoneTickets);
      zoneTicketObjects.forEach(priceList =>
        zoneTickets[priceList].forEach(ticket =>
          ticket.zonePrices.forEach(zonePrice => {
            ticketFilter = [...ticketFilter, { ...ticket, zone: zonePrice.zone }];
          }),
        ),
      );
    }
    return companies;
  };

  const handleRemove = company => {
    companies = companies.filter(number => number !== company.number);
    ticketFilter = ticketFilter.filter(ticket => company.number !== ticket['company']);
    return companies;
  };

  const getProductValue = product => {
    let productObj = {};

    if (product.groupingType === 'NO_SALE_DESCRIPTION') {
      productObj = {
        zone: product.zone,
        targetGroup: product.name,
      };
    } else {
      productObj = {
        zone: product.zone,
        productName: product.name,
      };
    }
    return JSON.stringify(productObj);
  };
</script>

<style>
  section {
    background-color: #E5E8EF;
    color: #333333;
    padding: 0.5em 3em;
    transition: padding 250ms ease-in-out;
  }

  section.expanded {
    padding: 0.5em 3em 2em 3em;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    transition: transform 250ms ease-in-out;
  }

  div.icon > :global(*) {
    font-size: 1.5em;
  }

  .icon.expanded {
    transform: rotate(-180deg);
  }

  h1 {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1em;
  }

  h2 {
    font-weight: 600;
    font-size: 1.2em;
    margin-right: 5em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    transition: margin-bottom 250ms ease-in-out;
  }
  .expanded h2 {
    margin-bottom: 2em;
  }

  button {
    margin-top: 2rem;
    min-width: 8em;
  }
  .container {
    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
  }

  .input-div {
    display: flex;
    margin-bottom: 1.5rem;
    flex-direction: column;
  }

  input,
  .input {
    margin-right: 3rem;
    width: 12rem;
  }

  .input-calendar {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
  }

  label {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }

  .companies {
    display: flex;
    flex-flow: row wrap;
  }

  .company-entry {
    display: flex;
    width: 326px;
    margin-right: 0.5rem;
  }

  .weekdays-title {
    display: flex;
    cursor: pointer;
    align-items: baseline;
    margin-bottom: 1.5rem;
  }

  .weekdays-title label {
    margin-bottom: 0;
    color: #001E61;
    margin-right: 1em;
  }

  .weekdays-title > div.icon > :global(*) {
    font-size: 1em;
    color: #001E61;
  }

  .weekdays-content {
    flex-direction: column;
  }

  .weekday-entry {
    display: flex;
    width: 326px;
  }

  .select-field > :global(.container) {
    height: 1.5rem;
  }

  .select-field > :global(.container) > :global(select) {
    font-size: 0.8rem;
    padding: 0 0.8em;
    background-color: white;
    border-radius: 2px;
    margin: 0 0 0.5em 0;
  }
</style>


<section class:expanded>
  <div class="header" on:click={() => (expanded = !expanded)}>
    <h2>{$_('search.define')}:</h2>
    <div class="icon" class:expanded>
      <Icon icon={faChevronDown} />
    </div>
  </div>
  <Expandable bind:expanded>
    <div class="container">
      <div class="content">
        <div>
          <div class="input-div">
            <label for="start">{$_('search.start')}: *</label>
            <DatePicker bind:selected={start} end={end} start={new Date(2010,1,1)}>
              <div class="input input-calendar" placeholder={$_('search.choose.date')}>
                <span>{$date(start, { format: 'twoDigits' })}</span>
                <Icon icon={faCalendar} />
              </div>
            </DatePicker>
          </div>
          <div class="input-div">
            <label for="end">{$_('search.end')}: *</label>
            <DatePicker bind:selected={end} end={now} {start}>
              <div class="input input-calendar" placeholder={$_('search.choose.date')}>
                <span>{$date(end, { format: 'twoDigits' })}</span>
                <Icon icon={faCalendar} />
              </div>
            </DatePicker>
          </div>
        </div>
        <div class="input-div">
          <label for="companies">{$_('search.company')}: *</label>
          <div class="companies" id="companies">
            {#if $companiesLoaded}
              {#each companyListFilteredByFeature as company}
                <div class="company-entry">
                  <Checkbox
                    checked={companies.includes(company.number)}
                    on:change={({ target: { checked } }) =>
                      (companies = checked ? handleAdd(company) : handleRemove(company))}
                    label={`${company.name} (${company.number})`}
                  />
                </div>
              {/each}
            {:else}
              <Loading />
            {/if}
          </div>
        </div>
      </div>
      <div class="content weekdays-content">
        <div
          class="weekdays-title"
          on:click={() => (weekdaysExpanded = !weekdaysExpanded)}
        >
          <label>{$_('search.choose.weekdayFilter')}</label>
          <div class="icon" class:expanded={weekdaysExpanded}>
            <Icon icon={faChevronDown} />
          </div>
        </div>
        <Expandable bind:expanded={weekdaysExpanded}>
          {#each WEEKDAYS as day}
            <div class="weekday-entry">
              <Checkbox
                on:change={({ target: { checked } }) =>
                  (weekdays = checked
                    ? [...weekdays, day]
                    : weekdays.filter(number => number !== day))}
                label={$_(`search.choose.weekdays.${day}`)}
                checked={weekdays.includes(day)}
              />
            </div>
          {/each}
        </Expandable>
      </div>
      <div class="content">
        <div class="input-div">
          <label for="line">{$_('search.lineId')}:</label>
          <input
            type="text"
            id="line"
            placeholder={$_('search.choose.lineId')}
            bind:value={lineId}
          />
        </div>
        <div class="input-div">
          <label for="departure">{$_('search.departureId')}:</label>
          <input
            type="text"
            id="departure"
            placeholder={$_('search.choose.departureId')}
            bind:value={departureId}
          />
        </div>
        <div class="flex flex-col mb-4 select-field w-48 mr-12">
          <label>{$_('search.deviceType')}:</label>
          <Select
            border={true}
            bind:value={deviceType}
            customIcon={DropDownArrowSmall}
            entries={[
              {
                value: null,
                label: $_('search.deviceTypes.all')
              },
              {
                value: 'PRO',
                label: $_('search.deviceTypes.pro')
              },
              {
                value: 'LITE',
                label: $_('search.deviceTypes.lite')
              },
            ]}
          />
        </div>
        <div class="flex flex-col mb-4 mb-1 text-md input-div w-64">
          <label for="product_name">{$_('search.product')}:</label>
          <MultiSelect
            placeholder={$_('search.choose.product')}
            id="tickets"
            bind:value={tickets}
            removeProducts={false}
            reports
          >
            {#each ticketFilter as product}
              <option value={getProductValue(product)}>
                {product.zone ? `${product.name} - ${product.zone}` : `${product.name}`}
              </option>
            {/each}
          </MultiSelect>
        </div>
      </div>
      <div class="content">
        <div class="input-div mr-8">
          <Checkbox bind:checked={lines} label={$_('travel.searchGroupedLines')} />
        </div>
        <div class="input-div">
          <Checkbox bind:checked={noSales} label={$_('travel.searchGroupedNoSales')} />
        </div>
      </div>
    </div>

    <button on:click={search} disabled={!companies.length || !weekdays.length}>
      {$_('search.fetch')}
    </button>
  </Expandable>
</section>

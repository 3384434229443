<script>
  import ConfirmableInlineInput from './ConfirmableInlineInput.svelte';

  export let list;
  export let onDataEdited;
  export let editingMode = false;
  export let headerRowTitles;
  export let headerColumnTitles;
  export let dataType;
  export let allowEmpty = true;
  export let dataField = undefined;
  export let dataFormatter = undefined;
  export let tableTitle = undefined;
</script>

<style>
  th,
  td {
    padding: 0.75rem 0.25rem;
  }
  th {
    text-align: left;
    border-bottom: solid 1px #173043;
    font-weight: normal;
  }
  td > div {
    white-space: nowrap;
    width: 140px;
    color: #5B6670;
    overflow: hidden;
  }
  td.editable {
    padding: 0rem calc(0.6rem - 1px);
    background-color: #eef5fb;
  }
  td:not(.spacer) {
    width: 140px;
    border-bottom: solid 1px #D1D1D2;
  }
  td.separator {
    border-left: solid 1px #D1D1D2;
  }
  table {
    min-width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    overflow: hidden;
  }
  .center {
    text-align: center;
  }
</style>

<table>
  {#if tableTitle}
    <tr>
      <th colspan={list.length + 1}>{tableTitle}</th>
    </tr>
  {/if}
  {#each list as row, rowIndex}
    <tr>
      {#if headerColumnTitles}
        <td class="header col-header">
          <div title={headerColumnTitles[rowIndex]}>
            {headerColumnTitles[rowIndex]}
          </div>
        </td>
      {/if}
      {#each row as column, columnIndex}
        <td class="center" class:editable={editingMode}>
          {#if editingMode}
            <ConfirmableInlineInput
              {dataFormatter}
              {allowEmpty}
              type={dataType}
              value={dataField ? column[dataField] : column}
              on:complete={event => onDataEdited(row, rowIndex, columnIndex, event)}
            />
          {:else}
            {dataFormatter
              ? dataFormatter(dataField ? column[dataField] : column)
              : dataField
              ? column[dataField]
              : column}
          {/if}
        </td>
      {/each}
      {#if headerRowTitles}
        <td class="header row-header">
          <div title={headerRowTitles[rowIndex]} class="center">
            {headerRowTitles[rowIndex]}
          </div>
        </td>
      {/if}
      {#if rowIndex < list.length - 1}
        <td class="bg-mgray separator" />
      {/if}
      {#if rowIndex < list.length - 2}
        <td class="bg-mgray spacer" colspan={list.length - 2 - rowIndex} />
      {/if}
    </tr>
  {/each}
</table>

<script>
  import { _ } from 'svelte-i18n';
  import SvelteTable from '../SvelteTable.svelte';
  import { createEventDispatcher } from 'svelte';
  import moment from 'moment';

  export let action;
  export const clearSelection = () => {
    svelteTableRef && svelteTableRef.clearSelection();
  };

  const dispatch = createEventDispatcher();
  let svelteTableRef;

  const DEFAULT_ROWS_TO_SHOW = 10;
  const ROWS_TO_SHOW_INCREMENT = 50;

  let showDefault = () => {
    rowsToShow = DEFAULT_ROWS_TO_SHOW;
    updateTableRows();
  };

  let showAll = () => {
    rowsToShow = dataSize;
    updateTableRows();
  };

  let showMore = () => {
    rowsToShow += ROWS_TO_SHOW_INCREMENT;
    updateTableRows();
  };

  let doNotStripWordsChanged = () => {
    do_not_strip_words = !do_not_strip_words;
    updateTableRows();
  };

  let hideCommands = true;
  const toggleDisplayCommands = () => {
    hideCommands = !hideCommands;
  };

  $: showBtnShowMore = true;
  $: showBtnShowAll = true;
  $: showBtnReset = false;

  $: columns = undefined;
  $: tableRows = [];
  $: rowsToShow = DEFAULT_ROWS_TO_SHOW;

  $: data = [];
  $: {
    if (action) {
      data = JSON.parse(action);
      sortedData = data.items.sort((a, b) => b.values[0] - a.values[0]);
      dataSize = sortedData.length;

      updateTableRows();
    }
  }

  $: dataSize = 0;
  $: sortedData = [];

  const updateSelectedRows = selectedElements => {
    const selectedData = [...selectedElements].reduce(
      (acc, item) => {
        acc.ids.push(item.getAttribute('data-id'));
        return acc;
      },
      { ids: [] },
    );

    const rows = tableRows.filter(item => selectedData.ids.includes(item.id));
    selectedData.commandTypes = [...new Set(rows.map(item => item.commandType))];

    dispatch('commandsSelectionChanged', selectedData);
  };

  let do_not_strip_words = false;
  const MAX_WORD_LENGTH = 15;

  const shortenStr = str => {
    let result = str;
    if (!do_not_strip_words) {
      result = result
        .split('\n')
        .map(line => {
          return line
            .split(' ')
            .map(word => {
              if (word.length > MAX_WORD_LENGTH) {
                return word.substring(0, MAX_WORD_LENGTH - 2) + '..';
              }
              return word;
            })
            .join(' ');
        })
        .join('\n');
    }

    return result;
  };

  const formatStr = str => {
    return str.replace(/\n/g, '<br />');
  };

  const toProperTypeAndFormat = (name, val) => {
    switch (name) {
      case 'createdAt':
      case 'lastRunStartedAt': {
        try {
          return moment(+val).format('DD.MM hh:mm:SS');
        } catch (err) {
          console.error(err);
        }

        return '';
      }
      default: {
        return isNaN(val) ? shortenStr(val) : val;
      }
    }
  };

  const columnsDef = () => {
    if (!data || data.header) {
      return undefined;
    }

    const c = data.headers.map(item => {
      const config = {
        key: item,
        title: item,
        value: v => formatStr(toProperTypeAndFormat(item, v[item])),
        sortable: true,
        filterOptions: rows => {
          const keys = rows.reduce((acc, val) => ((acc[val[item]] = val[item]), acc), {});
          const nameValuePairs = Object.keys(keys).map(name => {
            return {
              name: toProperTypeAndFormat(item, name),
              value: formatStr(toProperTypeAndFormat(item, name)),
            };
          });

          return nameValuePairs;
        },
      };

      return config;
    });

    return c;
  };

  const updateTableRows = () => {
    if (sortedData && sortedData.length > 0) {
      // map ['2','foo','bar'] into { id: '2', name: 'foo', value: 'bar' } structure
      tableRows = sortedData.slice(0, rowsToShow).map(item =>
        item.values.reduce((acc, val, index) => {
          acc[data.headers[index]] = val;
          return acc;
        }, {}),
      );

      columns = columnsDef();

      showBtnShowMore = rowsToShow + DEFAULT_ROWS_TO_SHOW < dataSize;
      showBtnShowAll = rowsToShow < dataSize;
      showBtnReset = rowsToShow > DEFAULT_ROWS_TO_SHOW;
    }
  };
</script>

<style>
  .content tableWrapper {
    margin: 0em 0 2em 0;
    display: block;
    padding: 0.5em 0 0.5em 0;
    border: solid 0.05em #cccccc;
  }

  :global(td.tableCell) {
    border-bottom: solid 0.01em #cccccc;
    background-color: #edf0f0;
  }

  .table-btn {
    display: inline-block;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .toggleStripWords {
    float: right;
    margin-right: 1em;
    margin-top: -0.5em;
  }

  .totalCmds {
    margin-left: 1em;
    margin-right: 1em;
  }

  .textButton {
    padding: 0.5em;
    color: #001E61;
    cursor: pointer;
    font-weight: bold;
  }

  .textButtonDisabled {
    color: #cccccc;
    cursor: not-allowed;
  }

  .right {
    float: right;
  }
</style>

<div class="content">
  <tableWrapper>
    {#if columns}
      <span class="totalCmds">
        {$_('supportRequests.commandTable.commandAmount').replace(
          /\{1\}/,
          sortedData.length,
        )}</span
      >
      <span class="textButton" on:click={toggleDisplayCommands}>
        {hideCommands
          ? $_('supportRequests.commandTable.showCommands')
          : $_('supportRequests.commandTable.hideCommands')}
      </span>

      <span
        hidden={hideCommands}
        on:click={() => {
          doNotStripWordsChanged();
        }}
      >
        <span class="toggleStripWords textButton"
          >{#if do_not_strip_words}{$_(
              'supportRequests.commandTable.shortenText',
            )}{:else}{$_('supportRequests.commandTable.!shortenText')}{/if}</span
        >
      </span>

      <div hidden={hideCommands}>
        <SvelteTable
          bind:this={svelteTableRef}
          {columns}
          rows={tableRows}
          classNameCell="tableCell"
          on:selectionsChanged={event =>
            updateSelectedRows(event.detail.selectedElements)}
        />

        <div class="buttons">
          <span
            class="table-btn textButton {!showBtnReset ? 'textButtonDisabled' : ''}"
            on:click={() => {
              if (showBtnReset) {
                showDefault();
              }
            }}
          >
            {$_('supportRequests.commandTable.showDefaultAmount')}
          </span>

          <span
            class="table-btn textButton  {!showBtnShowMore ? 'textButtonDisabled' : ''}"
            on:click={() => {
              if (showBtnShowMore) {
                showMore();
              }
            }}
          >
            {$_('supportRequests.commandTable.showMore')}
          </span>
          <span
            class="table-btn textButton  {!showBtnShowAll ? 'textButtonDisabled' : ''}"
            on:click={() => {
              if (showBtnShowAll) {
                showAll();
              }
            }}
          >
            {$_('supportRequests.commandTable.showAll').replace(/\{1\}/, dataSize)}
          </span>
        </div>
      </div>
    {/if}
  </tableWrapper>
</div>

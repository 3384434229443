<script>
  export let opened = false;
</script>

<style>
  .popup-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #33333355;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup-content {
    background: #fff;
    border-radius: 5px;
    padding: 1em;
    border: 1px solid #333333;
  }
</style>

<svelte:window
  on:keydown={event => (opened && event.key === 'Escape' ? (opened = false) : null)}
/>
{#if opened}
  <div class="popup-container" on:mousedown={() => (opened = false)}>
    <div class="popup-content" on:mousedown|stopPropagation>
      <slot />
    </div>
  </div>
{/if}

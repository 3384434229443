<script>
  import { afterUpdate } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { fetchInfo, tripInfo } from '../../stores/map_tracking';
  import { showError } from '../../stores/notifications';
  import DriverStopList from './DriverStopList.svelte';
  import ToggleSwitch from '../ToggleSwitch.svelte';
  import BusIcon from './BusIcon.svelte';
  import Back from '../Back.svelte';
  export let lines = [];
  export let selectedLine;
  export let showOtherLines;
  let selectedDriver = '';
  let lastSelectedLine = selectedLine;

  export let selectedLineTrip = '';

  afterUpdate(() => {
    if (lastSelectedLine != selectedLine) selectedLineTrip = '';
    else return;
    lastSelectedLine = selectedLine;
  });

  const dispatch = createEventDispatcher();

  const clickedOnLine = line => {
    dispatch('select', line);
  };

  const seeMore = line => {
    selectedLineTrip = line.tripParams;
    if (!$tripInfo[selectedLine])
      fetchInfo(line.tripParams, line.trip_id).catch(showError($_('error.title')));
    if (line.vehicles.length === 1) selectedDriver = line.vehicles[0].driverId;
    else selectedDriver = '';
  };
</script>

<style>
  section {
    display: flex;
    justify-content: stretch;
    border-bottom: 1px solid #dddddd;
    padding: 0.7em 0.5em;
  }

  section > .line-desc {
    flex-grow: 1;
  }

  .more {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .more.basic {
    min-width: 4rem;
  }

  .line-desc {
    display: grid;
  }
  @supports (-ms-ime-align: auto) {
    .line-desc {
      display: flex;
      flex-direction: column;
    }
  }

  button.secondary {
    font-size: 0.7em;
  }

  .line-title {
    cursor: pointer;
    font-weight: 100;
    color: #001E61;
    margin-bottom: 0;
    transition: font-size 250ms ease-in-out;
  }

  .line-title.basic {
    color: #005c50;
  }

  .line-title.focus {
    font-weight: bold;
    font-size: 1.4em;
    cursor: initial;
  }

  .line-title.selected {
    font-weight: 600;
  }

  .focus-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }
  .focus-header :global(.back) {
    margin-bottom: 0;
  }

  .focus-header > div {
    display: flex;
    align-items: center;
  }

  .focus-header > div > span {
    padding-right: 0.5em;
  }

  .late {
    font-size: 0.7em;
    border-radius: 1em;
    background: #ea5798;
    color: white;
    padding: 0.1em 1.5em;
  }

  .info {
    display: flex;
    align-items: center;
  }

  section {
    background-color: #fff;
    transition: background-color 250ms ease-in-out;
  }

  section.active {
    background-color: #ecf5fc;
  }

  .growing {
    flex-grow: 1;
    max-height: min-content;
    min-height: 1.5em;
    overflow-y: auto;
  }
</style>

{#if selectedLineTrip}
  <div class="focus-header">
    <Back on:click={() => (selectedLineTrip = '')} />
    <div>
      <span>{$_('map.showOtherLines')}</span>
      <ToggleSwitch bind:checked={showOtherLines} />
    </div>
  </div>
{/if}
{#each lines as line (line.tripParams)}
  {#if !selectedLine || showOtherLines || !selectedLineTrip || selectedLine === line.trip_id}
    <section
      class:active={selectedLine === line.trip_id && !selectedLineTrip}
      class:growing={selectedLineTrip}
    >
      <div class="line-desc">
        <span
          class="line-title"
          class:focus={selectedLineTrip && line.trip_id === selectedLine}
          class:basic={line.basic}
          on:click={selectedLineTrip ? null : () => clickedOnLine(line)}
          class:selected={selectedLine === line.trip_id}
        >
          {$_('results.line')}
          {line.lineId && line.lineId.split('$')[0]}
          ·
          {$_('results.departure')}
          {line.departureId && line.departureId.split('$')[0]}
          {line.basic ? $_('map.basic') : ''}
          {line.lite ? $_('map.lite') : ''}
        </span>
        {#if !selectedLineTrip && line.startStopName && line.arrivalStopName}
          <span>{line.startStopName} → {line.arrivalStopName}</span>
        {/if}
        {#if selectedLine === line.trip_id && !line.basic}
          {#each line.vehicles as vehicle, index}
            <DriverStopList
              driver={vehicle.driverId}
              showStops={selectedLineTrip && line.trip_id === selectedLine}
              stops={$tripInfo[line.trip_id]}
              currentStop={vehicle.currentStop}
              workshift={vehicle.workshiftId}
              companyId={vehicle.companyId}
              lineId={vehicle.lineId}
              departureId={vehicle.departureId}
              bind:selectedDriver
              border={index < line.length - 1}
            />
          {/each}
        {/if}
      </div>
      {#if !selectedLineTrip && (line.vehicles.length > 1 || line.isLate || (selectedLine === line.trip_id && !line.basic))}
        <div class="more" class:basic={line.basic}>
          <div class="info">
            {#if line.vehicles.length > 1}
              <BusIcon count={line.vehicles.length} />
            {/if}
            {#if line.isLate}
              <div class="late">{$_('map.late')}</div>
            {/if}
          </div>
          {#if selectedLine === line.trip_id && !line.basic}
            <button class="secondary" on:click={() => seeMore(line)}>
              {$_('map.details')}
            </button>
          {/if}
        </div>
      {/if}
    </section>
  {/if}
{/each}

<script>
  import { _, date } from 'svelte-i18n';
  import Loading from '../Loading.svelte';
  import {
    searchLoading,
    searchLoaded,
    searchResults,
    searchInError,
  } from '../../stores/travel_reports';
  import ReportGroupedLinesWithProducts from './ReportGroupedLinesWithProducts.svelte';
  import ReportGroupedNoSalesWithLines from './ReportGroupedNoSalesWithLines.svelte';
  import ReportByLineWithProducts from './ReportByLineWithProducts.svelte';
  import ReportByLineByDay from './ReportByLineByDay.svelte';
  import LoadingError from '../LoadingError.svelte';

  export let start;
  export let end;
  export let lineId;
  export let departureId;
  export let companies;
  export let weekdays;
  export let tickets;
  export let reportType;
  export let deviceType;

  export let openedReportDay;
  export let retrySearch;
</script>

<style>
  .tabs {
    margin-top: 2em;
    margin-bottom: 3em;
  }

  .tab {
    color: #06324c;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    padding: 0.5em 1em;
    transition: color 250ms ease-in-out;
  }

  .tab.active {
    color: #001E61;
  }

  .tab.active {
    position: relative;
  }

  .tab::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0;
    background-color: #fff0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0%;
    transition: width 250ms ease-in-out;
  }

  .tab.active::after {
    background-color: #001E61;
    width: calc((100% - 2em) * 0.8);
  }

  .back > *:not(:first-child) {
    margin-left: 0.3em;
  }

  :global(.back *) {
    color: #001E61 !important;
    cursor: pointer;
  }
</style>

{#if start && end}
  <b>
    {$date(start, { format: 'twoDigitsFullYear' })}
    -
    {$date(end, { format: 'twoDigitsFullYear' })}
  </b>
  <br />
{/if}

{#if $searchLoading}
  <div class="loading-content">
    <Loading />
  </div>
{:else if $searchInError}
  <LoadingError retryAction={retrySearch} />
{:else if $searchLoaded}
  <div class="tabs">
    <span
      class="tab"
      on:click={() => (reportType = 'products')}
      class:active={reportType === 'products'}
    >
      {$_('travel.reportByLine')}
    </span>
    <span
      class="tab"
      on:click={() => (reportType = 'daily')}
      class:active={reportType === 'daily'}
    >
      {$_('travel.reportByDay')}
    </span>
    {#if $searchResults && $searchResults.lines}
      <span
        class="tab"
        on:click={() => (reportType = 'lines')}
        class:active={reportType === 'lines'}
      >
        {$_('travel.reportGroupedByLine')}
      </span>
    {/if}
    {#if $searchResults && $searchResults.noSales}
      <span
        class="tab"
        on:click={() => (reportType = 'noSales')}
        class:active={reportType === 'noSales'}
      >
        {$_('travel.reportGroupedByNoSales')}
      </span>
    {/if}
  </div>

  {#if reportType === 'products'}
    <ReportByLineWithProducts
      bind:start
      bind:end
      bind:lineId
      bind:departureId
      bind:companies
      bind:weekdays
      bind:tickets
      bind:deviceType
      on:line
    />
  {:else if reportType === 'daily'}
    <ReportByLineByDay
      bind:start
      bind:end
      bind:lineId
      bind:departureId
      bind:companies
      bind:weekdays
      bind:tickets
      bind:deviceType
      bind:selectedDay={openedReportDay}
      on:day
    />
  {:else if reportType === 'lines'}
    <ReportGroupedLinesWithProducts
      bind:start
      bind:end
      bind:lineId
      bind:departureId
      bind:companies
      bind:weekdays
      bind:tickets
      bind:deviceType
      on:line
    />
  {:else if reportType === 'noSales'}
    <ReportGroupedNoSalesWithLines
      bind:start
      bind:end
      bind:lineId
      bind:departureId
      bind:companies
      bind:weekdays
      bind:tickets
      bind:deviceType
      on:noSales
    />
  {/if}
{/if}

<script>
  import Spinner from 'svelte-spinner';

  export let active = false;
</script>

<style>
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.66);
    z-index: 5000;
  }
</style>

{#if active}
  <div>
    <Spinner size="50" speed="750" color="#ffffff" thickness="2" gap="40" />
  </div>
{/if}

<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { fly } from 'svelte/transition';
  import { charterLineCreateState } from '../../stores/lines';
  import InputField from '../../components/InputField.svelte';
  import RadioButton from '../../components/RadioButton.svelte';
import Form from '../../components/Form.svelte';

  export let isValid = false;
  export let direction;

  let formValid = false;

  const pricingBases = [
    'EACH',
    'ONE',
    'INVOICE'
  ];

  $: {
    isValid = formValid && $charterLineCreateState.pricingBasis;
  }

  onMount(() => {
    isValid = formValid && $charterLineCreateState.pricingBasis;
  });

</script>

<style>
  .label {
    font-size: 1em;
    margin-bottom: 0.5rem;
    display: block;
  }
  .input-div {
    margin-bottom: 1.5rem;
  }
  .input-container > :global(.input-field-main-div) {
    margin-bottom: 1.5rem;
    max-width: 12rem;
  }
  div.input-container {
    flex-grow: 1;
    flex-basis: 12rem;
  }
  .input-main-container {
    max-width: 800px;
  }
</style>

<div class="input-main-container flex flex-col lg:flex-row flex-grow"
  in:fly="{{ x: direction * -250, duration: 250, delay: 300 }}"
  out:fly="{{ x: direction * 250, duration: 250 }}">
  <Form bind:isValid={formValid}>
    <div class="input-container lg:mr-4">
      <InputField
        id="departureTime"
        type="price"
        label={$_('lines.charter.totalPrice')}
        bind:value={$charterLineCreateState.totalPrice}
        required
      />
      <div class="input-div">
        <p class="label">{$_('lines.charter.pricingBasis')}: *</p>
        {#each pricingBases as pricingBasis}
          <RadioButton
            label={$_(`lines.charter.pricingBases.${pricingBasis}`)}
            value={pricingBasis}
            bind:group={$charterLineCreateState.pricingBasis}
          />
        {/each}
      </div>
    </div>
  </Form>
</div>
